import { Utils } from '../../utils';
import { AppService } from '../../app.service';
import { Subscription } from 'rxjs';
import { SlidePanelAnimation } from '../../shared/animations/animations';
import { Component, OnInit, HostListener, Output, EventEmitter, TemplateRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { PopUpService } from '../../shared/pop-up/pop-up.service';
import { MessageService } from 'primeng/api';
import { InitialService } from '../../initial/initial.service';
import { SharedService } from '../../shared/shared.service';
import { PatientService } from '../../patient/patient.service';
import { HttpClient } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppExService } from '../../app-ex.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getLanguage } from './../../languages';
import { LanguageInterface } from './../../languages/interface.language';
import { SpotsService } from 'src/app/spots/spots.service';
import { PatientModel } from '../patient.model';
import { ClinicService } from 'src/app/clinics/clinics.service';
import { value } from 'src/app/shared/pop-up/pop-up.model';
import { config } from 'src/app/config';
import { ContractsService } from 'src/app/contracts/contracts.service';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { base64ToFile } from 'ngx-image-cropper';
import { SkillsService } from 'src/app/skills/skills.service';

@Component({
  selector: 'app-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.scss','../../../assets/stylesheets/form.scss'],
  animations: SlidePanelAnimation,
}
)
export class PatientProfileComponent implements OnInit, OnDestroy  {
  profileSubscription: Subscription;
  url = '';
  userRole = "";
  submitted = false;
  isMobileView:Boolean = false;
  date5:Date;
  date6:Date;
  patientUser:PatientModel;
  @Input() filter;
  @Output() updateTableData = new EventEmitter<any>();
  model: any = {
    description: 'App Description...',
    theme: {
      bgColor: "ffffff",
      textColor: "555555",
      bannerImage: ""
    },
    attributes: []
  };

  selectedItems = [];
  selectedTeams=[];

  
  autoComplete = "autocomplete";
  profileSection = false;
  overlay = false;
  facilities;
  listTeams=[
    {name: 'Amy Cole (Sr. Doctor)', value: 'Amy Cole (Sr. Doctor)', designation: 'Physical Therapy'},
    {name: 'Amy Jones (Sr. Doctor)', value: 'Amy Jones (Sr. Doctor)', designation: 'Physical Therapy'},
    {name: 'Gary Camara', value: 'Gary Camara', designation: 'Dentist'},
    {name: 'Gary Kerse', value: 'Gary Kerse', designation: 'Dentist'},
    {name: 'Jeff Culin', value: 'Jeff Culin', designation: 'Dentist'},
    {name: 'Natasha Camara', value: 'Natasha Camara', designation: 'Dentist'}
  ];
  listaggerssiveness=[
    {label:'1',value:1},
    {label:'2',value:2},
    {label:'3',value:3},
    {label:'4',value:4},
    {label:'5',value:5},
    {label:'6',value:6},
    {label:'7',value:7},
    {label:'8',value:8},
    {label:'9',value:9},
    {label:'10',value:10}
  ];

  pchoice=[
    {label:'Male',value:'M'},
    {label:'Female',value:'F'},
    {label:'Any',value:'A'}
  ];

  edit = false;
  public form: FormGroup;
  selectedfacilities;
  teamSection = false;
  tableColumns = [
    { field: 'name', header: 'Name' },
  ];
  tableData = [];
  rows = 10;
  total = 10;
  rowNumber = 1
  pageSize = 50;
  listClinic:any[]=[]
  listGroups:any[] = []
  listSkills:any[]=[]
  listContracts:any[]=[]
  langSubs: Subscription;
  pageStrings: LanguageInterface;

  invalidSymbol = ["$","~","@","%","^","&","*",
    "(",")","{","}","!","[","]",",","=","+",
    "<",">",".","/","?",";","#", "'", "\"",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  invalidChars = [
    "-",
    "e",
    "E",
  ]; 
  imgBaseURL = config.baseUrl;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  addSkillsList = [];
  specialSkillIds =[]
  listpriority=[
    {label:'1',value:1},
    {label:'2',value:2},
    {label:'3',value:3},
    {label:'4',value:4},
    {label:'5',value:5},
    {label:'6',value:6},
    {label:'7',value:7},
    {label:'8',value:8},
    {label:'9',value:9},
    {label:'10',value:10},
  ];
  skillFormData = {
    "priority": null,
    "skillId": null,
  };
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  saveCropImgBtn:any = false;
  uploadCropImgBtn:any = true;
  previousImgShow:any = true;
  profilephoto = false;

  patientResClinicIdsSave:any;
  patientSavedPayload:any;
  warnPatientClinicChangePopup = false;

  skillDisable = true;

  patientPastData:any;
  patientPastDataColumns = [
    { field: 'srno', header: 'S.No' },
    { field: 'clinicName', header: 'Clinic Name' },
    { field: 'patientName', header: 'Patient Name' },
    { field: 'sessionCount', header: 'Sessions' }
  ];
  skillsSelectedValues = [];

  @ViewChild('pageContainingForm') pageContainingForm : any;

  checkChar(e){
    if (this.invalidSymbol.includes(e.key)) {
      e.preventDefault();
    }
  }
  checkInput(e){
    this.patientUser['phoneMin'] = !!(this.patientUser.phone?.toString().length < 10);
    this.patientUser['phoneMax'] = !!(this.patientUser.phone?.toString().length > 15);
    if (this.invalidChars.includes(e.key)) {
      e.preventDefault();
    }
    if(this.patientUser.phone?.toString().length > 15){
      // this.patientUser.phone = parseInt(this.patientUser.phone.toString().substring(0,14))
    }
  }

  constructor(
    readonly modalService: BsModalService,
    public appService: AppService,
    public popUpService: PopUpService,
    public messageService: MessageService,
    public skillsSevice:SkillsService,
    public initialService: InitialService,
    public httpclient: HttpClient,
    public shareService: SharedService,
    public patientService: PatientService,
    public utils: Utils,
    public appExService: AppExService,
    private formBuilder: FormBuilder,
    public contractsService: ContractsService,
  ) {
    this.patientUser = new PatientModel(); 
    this.isMobileView = window.innerWidth <= 600;
  }
  closeChips(i,){
    this.selectedTeams.splice(i, 1);
  }
  closeTeam(i, item){
    item.selected = false
  }
  closeGroup(i){
    this.patientUser.groupsIds.splice(i,1);
    this.patientUser.groupsIds = this.patientUser.groupsIds.map(o=>o);
    this.pageContainingForm.control.markAsDirty();
  }
  closeClinic(i){
    this.patientUser.clinicIds.splice(i,1)
    this.patientUser.clinicIds = this.patientUser.clinicIds.map(o=>o);
    this.pageContainingForm.control.markAsDirty();
  }

  ngOnInit() {
    const remD = JSON.parse(localStorage.getItem('optplanner_user'));
    this.userRole = remD.userType.type;    
    this.facilities = [
      { value: "Critical Care",name: "Critical Care" },
      { value: "Physical Therapy",name: "Physical Therapy" },
      { value: "Medical Care",name: "Medical Care"}
    ]; 
    this.profileSubscription = this.appService.patientProfileSidebarSource$.subscribe(
      (res: any) => {
        if (res.state) {
          this.selectedItems = [];
          this.selectedTeams = [];
          this.getGroups({ page: 1, limit: 1000, sortBy:'name'});
          this.getClinicList({ page: 1, limit: 1000, status:true, sortBy:'name'});
          this.getSkillList({ page: 1, limit: 1000});
          this.profileSection = res.state;
          this.edit = res.edit;
          this.dataMapping(this.edit ? res.rowData.formData : null);
          this.overlay = res.state;
          document.getElementsByTagName("body")[0].style.overflow = "hidden";
        } else {
          this.addScroll();
        }
        this.patientUser['phoneMin'] = false;
        this.patientUser['phoneMax'] = false;
      } 
    );
    this.form = this.formBuilder.group({
      // selectedfacilities: [[{ name: "Critical Care"}], Validators.required]
    });
    this.langSubs = this.appExService.getLanguageOptions.subscribe(data => {
      if(data.lang){
        this.pageStrings = getLanguage(data.lang);
      }
    });
  
  }

  removePhoto(){
    this.patientUser.documentId = '';
    this.url = '';
    this.pageContainingForm.control.markAsDirty();
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Image deleted successfully' });
  }
  displayBasic:boolean
  dataMapping(res){
    const patientUser = this.patientUser;
    if(this.edit){
      patientUser.firstName = res.firstName;
      patientUser.lastName = res.lastName;
      patientUser.phone = res.phoneNo;
      patientUser.documentId = res.documentId;
      patientUser.gender = res.gender;
      patientUser.providerChoice = res.providerChoice;
      // patientUser.aggerssiveness = res.aggerssiveness;
      patientUser.patientSkillForms = res.patientSkillDTOS.map(obj=>{
        return ({
          "priority": obj.priority,
          "skillId": obj.skill.id,
        })
      });
      patientUser.email = res.email;
      patientUser["id"] = res.id; 
      patientUser.state = res.patientLocation.state;
      patientUser.street = res.patientLocation.street;
      patientUser.zipCode = res.patientLocation.zipCode;
      patientUser.country = res.patientLocation.country;
      patientUser.city = res.patientLocation.city;
      patientUser.patientId = res.patientId;
      this.url = patientUser.documentId;
      patientUser.groupsIds = res.groupTherapyGroups.map(obj=>{
        return ({
          name: obj.groupName,
          value: obj.id,
        })
      });
      patientUser.clinicIds = res.clinics.map(obj=>{
        return ({
          name: obj.clinicName,
          value: obj.id,
        })
      });
      this.patientResClinicIdsSave = res.clinics.map(obj=>{return obj.id});
    }
    
    else{
      const assignedClinics = JSON.parse(localStorage.getItem('optplanner_user')).assignedClinics;
      patientUser.firstName  = '';
      patientUser.lastName  = '';
      patientUser.groupsIds = [];
      patientUser.phone = '';
      patientUser.documentId = '';
      patientUser.providerChoice='A';
      // patientUser.aggerssiveness = null,
      patientUser.patientSkillForms = [];
      patientUser.email = '';
      patientUser.gender ='prefer not to say';
      patientUser.state = '';
      patientUser.street = '';
      patientUser.zipCode = '';
      patientUser.patientId = '';
      patientUser.country = 'USA';
      patientUser.city = '';
      this.url = null;
      assignedClinics.forEach((obj)=>{
        if(obj.value == JSON.parse(localStorage.getItem('assignedClinic'))){
          patientUser.clinicIds = [{
            name: obj.label,
            value: obj.value,
          }];
        }
      });
    }
  }
  onAddSkills(event,i){
    console.log(this.addSkillsList,i);
    this.skillsSelectedValues[i] = event.skillId;
    let first = [];
    if(this.addSkillsList.length > 0){
      if(this.addSkillsList[i].skillId != null){
      this.skillDisable = false;
      }
      this.addSkillsList.forEach(obj=>{
        if(obj.skillId){   
          first.push(1);
        }
      });
      if(this.addSkillsList.length <= first.length){
        this.addSkillsList.push({
          "priority": 1,
          "skillId": null,
        });
      }
    }
  }

  onPatientAddSkills(event,i){

    const data = this.listSkills.filter((obj)=>{return obj.value === event.value})[0].label;

    if(data == 'Behavioral Supervision'|| data =='Occupation Individual Therapy' || data =='Speech Individual Therapy'){
      this.patientUser.patientSkillForms[i].priority = 0;
    }else{
      if(this.patientUser.patientSkillForms[i].priority === 0){
        this.patientUser.patientSkillForms[i].priority = 1;
      }
    }
    
  }

  onPatientAddSkillsForm(event,i){
    
    const data = this.listSkills.filter((obj)=>{return obj.value === event.value})[0].label;

    if(data == 'Behavioral Supervision'|| data =='Occupation Individual Therapy' || data =='Speech Individual Therapy'){
      this.addSkillsList[i].priority = 0;
    }else{
      if(this.addSkillsList[i].priority === 0){
        this.addSkillsList[i].priority = 1;
      }
    }
    
  }

  onRemoveSkillAdd(i){
    this.addSkillsList.splice(i,1);
    this.addSkillsList = this.addSkillsList.map(obj => obj);
  }
  onRemoveSkill(i){
    this.patientUser.patientSkillForms.splice(i,1);
    this.patientUser.patientSkillForms = this.patientUser.patientSkillForms.map(obj => obj);
    this.pageContainingForm.control.markAsDirty();
  }
  showBasicDialog() {
    if(!this.displayBasic){     
      this.addSkillsList = [];
      if(this.patientUser.patientSkillForms.length > 0){
        let first = [];
        this.addSkillsList = this.patientUser.patientSkillForms.map(obj=> ({...obj}));
        this.addSkillsList.forEach(obj=>{
          if(obj.skillId){   
            first.push(1);   
          }
        });
        if(this.addSkillsList.length <= first.length){
          this.addSkillsList.push({
            "priority": 1,
            "skillId": null,
          });
        }
      }
      else{        
        this.addSkillsList.push({
          "priority": 1,
          "skillId": null,
        });
      }

      this.displayBasic = true;
      this.skillsSelectedValues = []
      this.skillFormData = {
        "priority": 1,
        "skillId": null,
      };

      this.addSkillsList.forEach((obj,i)=>{
        if(obj.skillId !== null){
          this.skillsSelectedValues[i] =  obj.skillId;
        }
      })

    }  
}
onSaveClick(){
  this.displayBasic=false;
  this.skillsSelectedValues = [];
  this.assignValues('onSaveCloseClick');
  this.pageContainingForm.control.markAsDirty();
}
assignValues(caller){
  this.patientUser.patientSkillForms = [];
  let i = 1;
  let msgi = 1;
  
  this.addSkillsList.map(obj=>obj).forEach((element) =>{
    if(element.skillId){
      if(this.specialSkillIds.includes(element.skillId)){
        if(i===1){
          this.patientUser.patientSkillForms.push(element);
        }else if(msgi === 1){
          this.displayBasic=true;
           this.messageService.add({ severity: 'error', summary: 'Error', detail: ' Cannot add more than one special skill.' })
          
           msgi++;
        }
        i++;
      }else{
        this.patientUser.patientSkillForms.push(element);
        
      }
    }
  });

}
getSkillList(data) {
  this.skillsSevice.getSkills(data).subscribe((response: any) => {
    if (response.data) {
      this.listSkills = response.data.map(obj=>{
        if(obj.skillName == 'Behavioral Supervision'|| obj.skillName =='Occupation Individual Therapy' || obj.skillName =='Speech Individual Therapy'){
          this.specialSkillIds.push(obj.id)

        }
        return {label: obj.skillName, value: obj.id}
      });
      this.listSkills.unshift({label: '--Select--', value: null})
    }
  });
}
  addScroll() {
    this.profileSection = false;
    this.overlay = false;
    this.teamSection = false;
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }

  ngOnDestroy() {
    this.langSubs && this.langSubs.unsubscribe();
    this.profileSubscription && this.profileSubscription.unsubscribe();
    this.profileSection = false;
    this.teamSection = false;
    this.skillsSelectedValues = [];
    this.overlay = false;
    this.appExService.callActivityData = [];
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (event.target.closest('.overlay') && !event.target.closest('.profileSection')) {
      this.profileSection = !this.profileSection;
      this.overlay = !this.overlay;
      this.addScroll();
    }
  }

  onSelectFile(event) {
    const fileObj = event.target.files[0]
    if (event.target.files && fileObj) {
      const ap = this.appService;
      const ms = this.messageService;
      const reader = new FileReader();
      ap.showCustomLoader(true);
      reader.readAsDataURL(fileObj); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
          const formData = new FormData();
          formData.append('image', fileObj);
          this.httpclient.post<any>(config.uploadImage, formData).subscribe((response) => {
            // this.url = response.data.path;
            this.url = response.data.documentId;
            this.patientUser.documentId = response.data.documentId;
            

            ap.showCustomLoader(false);
            ms.add({ severity: 'success', summary: 'Success', detail: 'Image uploaded successfully' });
            let img = new Image();
            img.onload = function() { 
             }
            img.src = this.url;
          }, (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
          });
      }
    }
  }

  getGroups(data){
    this.patientService.getGrouptherapy(data).subscribe((response:any)=>{
      if (response.data) {
        this.listGroups = response.data.map(obj=>{
          return {name: obj.groupName, value: obj.id}
        });
      }
    });
  }

  getClinicList(data) {
    this.listClinic = JSON.parse(localStorage.getItem('optplanner_user')).assignedClinics.map(obj=>{
      return { name: obj.label,value:obj.value };
    });
  }

  createUpdatePatient(form) {
    if(form.submitted){
      this.submitted = true
    }
    if(form.form.valid){
    const successMessage = this.edit ? 'updated' : 'created';
    const form = this.patientUser;
    const payload = {
      ...form,
      clinicIds: form.clinicIds.map(obj=>obj.value),
      groupsIds: form.groupsIds.map(obj=>obj.value),
    };
    if(this.edit){
      this.patientSavedPayload = payload;
      let checkClinicIDs = [];
      this.patientResClinicIdsSave.map(obj=>{
        checkClinicIDs.push(payload.clinicIds.includes(obj))
        
      });
      if(checkClinicIDs.includes(false)){
        const data = {
          type:'patient',
          clinicIds:payload.clinicIds,
          sectionId:this.patientUser.id
        };
        this.patientService.getPatientPastDataAPI(data).subscribe((response) => {
          this.patientPastData = response.data;
          this.warnPatientClinicChangePopup = true;
          
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      }else{
        this.afterWarnCreatePatient(payload);
      }
    }else{
      this.afterWarnCreatePatient(payload);
    }
  }
} 

  afterWarnCreatePatient(payload){
    if(this.edit){
      this.patientService.updatepatient(payload).subscribe((response) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });
        this.addScroll();
        this.updateTableData.emit({success:true});
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
    }
    else{
      this.patientService.addpatient(payload ).subscribe((response) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });
        this.addScroll();
        this.updateTableData.emit({success:true, id: this.patientUser.patientId, edit: false});
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
    }
  }


  checkFirstName(e){
    let regex = /^[A-Za-z-/\s]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }


  checkEmail(e){
    let regex = /^[A-Za-z0-9\.\@\+\_]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }


  checkStreet(e){
    let regex = /^[A-Za-z0-9/\s/.,///-]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }

  checkCity(e){
    let regex = /^[A-Za-z/\s]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }

  checkState(e){
    let regex = /^[A-Za-z/\s]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }
  checkZipCode(e){
    if(e.keyCode !==8 && this.patientUser.zipCode && this.patientUser.zipCode.toString().length == 14) {

      return false
    }

    let regex = /^[A-Za-z0-9-]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }
  
  checkLastName(e){
    let regex = /^[A-Za-z-/\s]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }

  profilePhotoPopupClose(){
    this.profilephoto = false;
    this.saveCropImgBtn = false;
    this.uploadCropImgBtn = true;
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.previousImgShow = true;

  }

  profilePhotoPopupSave(){
    this.appService.showCustomLoader(true);

    const fileObj = base64ToFile(this.croppedImage)
    
    if (fileObj) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObj); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
          const formData = new FormData();
          formData.append('image', fileObj);            
          this.httpclient.post<any>(config.uploadImage, formData).subscribe((response) => {
            
            // this.url = response.data.path;
            this.url = response.data.documentId;
            this.patientUser.documentId = this.url;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Image uploaded successfully' });
            this.pageContainingForm.control.markAsDirty();
            this.appService.showCustomLoader(false);
            this.saveCropImgBtn = false;
            this.uploadCropImgBtn = true;
            this.previousImgShow = true;
            this.profilephoto = false;
            this.croppedImage = '';
            this.imageChangedEvent = '';


          }, (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
            this.appService.showCustomLoader(false);
          });
      }
    }

  }

  /** Crop Image Functions Start Here... */

  fileChangeEvent(event: any): void {    
    var url = event.target.files[0].name;
    var urlExt = url.substr(url.lastIndexOf('.') + 1);
    if(urlExt === 'png' || urlExt === 'jpeg' || urlExt === 'jpg'){
      if(event.target.files[0].size < 2000000){
        this.appService.showCustomLoader(true);
        this.imageChangedEvent = event;
        if(this.imageChangedEvent != ''){
          this.uploadCropImgBtn = false;
          this.saveCropImgBtn = true;
          this.previousImgShow = false;
        }
      }
      else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Image size must be less than 2mb' });
        
      }
    }
    else{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload png / jpg / jpeg file' });
    }
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      // console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
      this.showCropper = true;
      console.log('Image loaded');
      this.appService.showCustomLoader(false);
  }

  cropperReady(sourceImageDimensions: Dimensions) {
      // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
      console.log('Load failed');
  }

}
	