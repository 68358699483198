import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { config } from '../config';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

constructor(public sharedService: SharedService,private http:HttpClient) { }
 addpatient(formData){
   return this.sharedService.postData(config.addEditPatient,formData)
 }
 updatepatient(formData){
  return this.sharedService.putData(config.addEditPatient,formData)
}
cancelpatient(formData){ 
  return this.sharedService.putData(config.cancelPatientSchedule,formData)
}
getPatient(data) {
  let url = `${config.getPatientTable}`;
  url = url.replace(":page", data.page).replace(":limit", data.limit);
  if(data.searchParam){
    url = `${url}&searchParam=${encodeURIComponent(data.searchParam)}`;
  }
  if(data.clinicId){
    url = `${url}&clinicId=${data.clinicId}`;
  }
  if(data.sortBy){
    url = `${url}&sortBy=${data.sortBy}`;
  }
  if(data.status !== null && data.status !== undefined){
    url = `${url}&status=${data.status}`;
  }
  return this.sharedService.getData(url);
}
getSchdlCalPatientList(data) {
  let url = `${config.clinicWisePatientInfo}`;
  if(data.searchParam){
    url=`${url}?searchParam=${encodeURIComponent(data.searchParam)}`;
  }
  return this.sharedService.getData(url);
}
getPatientList(data) {
  let url = `${config.clinicWisePatientInfo}?isActive=true`;
  return this.sharedService.getData(url);
}
deletePatient(id) {
  return this.sharedService.deleteData(`${config.deletePatient}/${id}`);
}
getGrouptherapy(data){
  let url =`${config.getgrouptherapygroups}`;
  url=url.replace(":page",data.page).replace(":limit",data.limit);
  if(data.searchParam){
    url=`${url}&searchParam=${encodeURIComponent(data.searchParam)}`;
  }
  return this.sharedService.getData(url);
}

getPatientrById(data){
  return this.sharedService.getData(`${config.addEditPatient}/${data.id}?fromDate=${data.fromDate}&toDate=${data.toDate}`);
 }

 getPatientPastDataAPI(data){
  return this.sharedService.getData(`${config.patientPastDataGet}${data.type}?clinicIds=${data.clinicIds}&sectionId=${data.sectionId}`);
 }

//  V2 API
 getAuthrztnPatientDataAPI(id,isExpired){
  return this.sharedService.getData(`${config.patientAuthorizationDataGET}/${id}?isExpired=${isExpired}`);
 }
 getScheduledInfoPatientDataAPI(id,date){
  return this.sharedService.getData(`${config.patientScheduledInfoDataGET}${id}/scheduledInformation?weekStartDate=${date}`);
 }
 addPatientSkills(data){
  return this.sharedService.postData(`${config.patientSkillsURL}`,data)
}
addAuthorisation(data,id){
  // let params = new HttpParams().append('patientId', id)

  const formData: FormData = new FormData();
  formData.append('createAuthorizationForm', JSON.stringify(data));
  return this.sharedService.postData(`${config.addAuth}${id}`,data)
}
editAuthorisation(data,id){
  return this.sharedService.putData(`${config.editAuth}${id}`,data)
}
deleteAuth(id:any){
  return this.sharedService.deleteData(`${config.delAuth}${id}`)
}
payerList(id:any){
  return this.sharedService.getData(`${config.getPayer}?patientId=${id}`);
}
getFacility(){
  return this.sharedService.getData(`${config.getFacility}`);
}
getCodeAndMinById(id){
return this.sharedService.getData(`${config.getCode}?spotId=${id}`);
}
}
