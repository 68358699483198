import { Utils } from '../../utils';
import { AppService } from '../../app.service';
import { interval, Subscription } from 'rxjs';
import { SlidePanelAnimation } from '../../shared/animations/animations';
import { Component, OnInit, HostListener, Output, EventEmitter, TemplateRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { PopUpService } from '../../shared/pop-up/pop-up.service';
import { MessageService } from 'primeng/api';
import { InitialService } from '../../initial/initial.service';
import { SharedService } from '../../shared/shared.service';
import { HttpClient } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppExService } from '../../app-ex.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { value } from 'src/app/shared/pop-up/pop-up.model';
import * as moment from 'moment';
import 'moment-timezone';
import { getLanguage } from './../../languages';
import { LanguageInterface } from './../../languages/interface.language';
import { CustomRecurrenceModel, SchedulingModel } from '../scheduling.model';
import { PatientService } from 'src/app/patient/patient.service';
import { SpotsService } from 'src/app/spots/spots.service';
import { SkillsService } from 'src/app/skills/skills.service';
import { ProviderService } from 'src/app/provider/provider.service';
import { SchedulingService } from '../scheduling.service';
import { Dropdown } from 'primeng/dropdown';
import { event } from 'jquery';
import { RadioButtonModule } from 'primeng/radiobutton';
import { Calendar } from 'primeng/calendar';


@Component({
  selector: 'app-add-shifts',
  templateUrl: './add-shifts.component.html',
  styleUrls: ['./add-shifts.component.scss', '../../../assets/stylesheets/form.scss'],
  animations: SlidePanelAnimation,
}
)
export class AddShiftsComponent implements OnInit, OnDestroy {
  profileSubscription: Subscription;
  patientAddSessionSubscription: Subscription;
  openSocialMedialLinkSubs: Subscription;
  schedulingUser: SchedulingModel;
  isPinnedOpen = false;
  show1 = true;
  pinnedPopupNoBtn = false;
  PopupOPenedOnce = false;
  editOption = "Edit Session";
  addOption = "Add Session";
  listPatient: any[] = [];
  listSkills: any[] = [];
  ListPlaceOfService: any[] = [];
  listSkilltype: any[] = [];
  sessionSkillForm = [];
  clinicTimeZone: any
  currentDate = new Date()
  listProvider: any[] = []
  listSpots: any[] = [];
  specialSkillIds = [];
  displayBasic: boolean;
  url = '';
  showIsPinned = false;
  date11: Date;
  date12: Date;
  date13: Date;
  checked1: boolean = false;
  @Output() updateTableData = new EventEmitter<any>();
  @Input() filter;
  @Output() getFiltertedData = new EventEmitter<any>();
  @Output() updatePatientDetailData = new EventEmitter<any>();
  @Output() updateProviderDetailData = new EventEmitter<any>();
  model: any = {
    description: 'App Description...',
    theme: {
      bgColor: "ffffff",
      textColor: "555555",
      bannerImage: ""
    },
    attributes: []
  };
  selectedItems = [];
  selectedTeams = [];
  autoComplete = "autocomplete";
  profileSection = false;
  overlay = false;
  facilities;
  providerUnavlablePopup = false;
  providerNameUnavlable: any;
  selectedTargetText:any;
  EditPrvdrResponse: any;
  ReapetsTypeSet1 = [
    { label: 'Custom Recurrence', value: 'customRecurrence' },
    { label: 'Do Not Repeat', value: 'doNotRepeat' }
  ];
  skillFormData =
    {
      "priority": 10,
      "skillId": null,
      "skillTypeId": 2
    };
  typeList = [
    { label: 'Current', value: 'current' },
    { label: 'Upcoming With Recurrence', value: 'upcomingWithRecurrence' },
  ];
  edit = false;
  public form: FormGroup;
  selectedfacilities;
  teamSection = false;
  tableColumns = [
    { field: 'name', header: 'Name' },
  ];
  tableData = [
    { name: 'Dr. John Smith', selected: false },
    { name: 'Dr. David Lee', selected: false },
    { name: 'Frank Camly', selected: false },
    { name: 'Gary Camara', selected: false },
    { name: 'Amy Jones', selected: false },
    { name: 'Dr. David Lee', selected: false },
    { name: 'Gary Camara', selected: false },
    { name: 'Dr. David Lee', selected: false },
    { name: 'Amy Jones', selected: false },
    { name: 'Amy Cole', selected: false },
    { name: 'Dr. David Lee', selected: false },
    { name: 'Amy Cole', selected: false },
    { name: 'Dr. David Lee', selected: false },
    { name: 'Dr. David Lee', selected: false },
    { name: 'Amy Cole', selected: false },
    { name: 'Dr. David Lee', selected: false },
    { name: 'Gary Camara', selected: false },
    { name: 'Dr. David Lee', selected: false },
    { name: 'Dr. David Lee', selected: false },
    { name: 'Amy Cole', selected: false },
    { name: 'Amy Cole', selected: false },
    { name: 'Dr. David Lee', selected: false },
    { name: 'Amy King', selected: false },
    { name: 'Dr. David Lee', selected: false },
    { name: 'Amy King', selected: false },
  ];
  rows = 10;
  total = 10;
  rowNumber = 1
  pageSize = 50;
  minDate = new Date();
  langSubs: Subscription;
  addSkillsList = [];
  pageStrings: LanguageInterface;
  limits = {
    'nap': 3,
    'normal': 4,
    'behaviour': 5,
    'individual': 6,
  }
  listpriority = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
  ];
  listindexno: Array<any> = [];
  startTimeHrs: any;
  startTimeMnts: any;
  startTimeAMPM: any;
  startTimedrpdwnHrs = '07';
  startTimedrpdwnMnts = '00';
  startTimedrpdwnAMPM = 'AM';

  endTimedrpdwnHrs = '12';
  endTimedrpdwnMnts = '00';
  endTimedrpdwnAMPM = 'PM';

  patientListids: any = [];
  editSessionFirstTime = 0;
  isPatientModuleCall: boolean = false;
  isPatientModuleCallData: any;
  isProviderModuleCall: boolean = false;
  isProviderModuleCallData: any;
  specialSessionTypeSelected: boolean = false;
  prvdrPtntModuleAddSession: Boolean = false;
  authWarnPopup: boolean = false;
  selectedSessionTypeTEXT: any = '';
  specialSessionSelectedTEXT: any = '';
  specialSessionTypelistIds: any = [];
  patientauthrztnData: any;
  resSpotName: any;
  clinicStartTime: any
  isMobileView: Boolean = false;
  isTabView = window.innerWidth > 600 && window.innerWidth <= 800;
  LastTwoDate = new Date();
  skillsSelectedValues: any = [];
  isButtonDisabled = false;
  delayDurationInSeconds = 5;
  @ViewChild('Sessiondropdown') Sessiondropdown: Dropdown;
  @ViewChild('Patientdropdown') Patientdropdown: Dropdown;
  @ViewChild('Providerdropdown') Providerdropdown: Dropdown;
  @ViewChild('Servicedropdown') Servicedropdown: Dropdown;
  @ViewChild('Typedropdown') Typedropdown: Dropdown;
  @ViewChild('Calendardropdown') Calendardropdown: Calendar;
  @ViewChild('pageContainingForm') pageContainingForm: any;

  skillDisable = true;

  constructor(
    readonly modalService: BsModalService,
    public appService: AppService,
    public popUpService: PopUpService,
    public messageService: MessageService,
    public initialService: InitialService,
    public httpclient: HttpClient,
    public shareService: SharedService,
    public utils: Utils,
    public schedulingService: SchedulingService,
    public appExService: AppExService,
    private formBuilder: FormBuilder,
    public spotService: SpotsService,
    public patientService: PatientService,
    public skillService: SkillsService,
    public providerService: ProviderService,
  ) {
    this.isMobileView = window.innerWidth <= 600;
    this.schedulingUser = new SchedulingModel();
    this.startTimeHrs = this.timedrpdwnHrsFun();
    this.startTimeMnts = this.timedrpdwnMntsFun();
    this.startTimeAMPM = [{ label: 'AM', value: 'AM' }, { label: 'PM', value: 'PM' }];
  }
  onTimePickerClick() {
    this.Sessiondropdown.hide(event)
    this.Patientdropdown.hide(event)
    this.Providerdropdown.hide(event)
    this.Servicedropdown.hide(event)
    this.Typedropdown.hide(event)
    this.Calendardropdown.hideOverlay()
  }
  timedrpdwnHrsFun() {
    let data = [];
    for (let index = 1; index <= 12; index++) {
      data.push({ label: index.toString().length == 1 ? '' + 0 + '' + index : '' + index + '', value: index.toString().length == 1 ? '' + 0 + '' + index : '' + index + '' })
    }
    return data;
  }
  timedrpdwnMntsFun() {
    let data = [];
    for (let index = 0; index <= 59; index++) {
      data.push({ label: index.toString().length == 1 ? '' + 0 + '' + index : '' + index + '', value: index.toString().length == 1 ? '' + 0 + '' + index : '' + index + '' })
    }
    return data;
  }
  closeChips(i,) {
    this.selectedTeams.splice(i, 1);
  }
  closeTeam(i, item) {
    item.selected = false
  }
  closeSkill(i) {
    this.selectedItems.splice(i, 1);

  }
  setLimits(event) {
   var selectedSessionType = this.listSpots.filter((obj) => {
      return obj.value === this.schedulingUser.spotId
    });
    this.selectedTargetText = selectedSessionType[0].label;
    
    if (this.specialSessionTypelistIds.includes(event.value)) {
      this.specialSessionTypeSelected = true;
      this.specialSessionSelectedTEXT = event.originalEvent.target.innerText;
      this.getProvider({ page: 1, limit: 1000, sortBy: 'name', status: true });
    } else {
      this.specialSessionTypeSelected = false;
      this.specialSessionSelectedTEXT = event.originalEvent.target.innerText;
      this.getProvider({ page: 1, limit: 1000, sortBy: 'name', status: true });
    }
    this.selectedSessionTypeTEXT = this.selectedTargetText? this.selectedTargetText : event.originalEvent.target.innerText;
    
    this.checkAuthorization();
  }
  changeTime() {
    // this.schedulingUser.endTime = this.schedulingUser.startTime["addHours"](4)
  }
  ngOnInit() {
    let cDate = new Date();

    let pastTwoDayFromCurrent = cDate.setDate(cDate.getDate() - 2);
    let cNewDate = (new Date(pastTwoDayFromCurrent).toLocaleString('en-US'));
    this.LastTwoDate = new Date(moment(new Date(cNewDate.split(",")[0]), "YYYY-MM-DD").format("YYYY-MM-DD"));

    Date.prototype["addHours"] = function (h) {
      this.setTime(this.getTime() + (h * 60 * 60 * 1000));
      return this;
    }
    this.facilities = [
      { value: "Critical Care", name: "Critical Care" },
      { value: "Physical Therapy", name: "Physical Therapy" },
      { value: "Medical Care", name: "Medical Care" }
    ];
    this.profileSubscription = this.appService.filtercontactSidebarSource$.subscribe((res: any) => {
      console.log(res);
      this.startTimedrpdwnHrs = '07';
      this.startTimedrpdwnMnts = '00';
      this.startTimedrpdwnAMPM = 'AM';

      this.endTimedrpdwnHrs = '12';
      this.endTimedrpdwnMnts = '00';
      this.endTimedrpdwnAMPM = 'PM';
      if (res.state) {
        if (res.edit) {
          this.showIsPinned = res.isPinned;
          this.pinnedPopupNoBtn = false;
          this.editSessionFirstTime = 0;
        } else {
          this.clinicStartTime = res.clinicStartTime;
          this.clinicTimeZone = res.timezone;
          this.showIsPinned = false;
        }
        this.selectedItems = [];
        this.selectedTeams = [];
        this.profileSection = res.state;
        this.edit = res.edit;
        this.overlay = res.state;
        if (!res.isPatientModule && res.isProviderModule) {
          this.isProviderModuleCall = true;
        }
        else if (res.isPatientModule && !res.isProviderModule) {
          this.isPatientModuleCall = true;
        }
        this.getPatient({ page: 1, limit: 1000, sortBy: 'name', status: true });
        this.getSpots({ page: 1, limit: 1000 });
        this.getSkillList({ page: 1, limit: 1000 });
        this.getPlaceOfServiceList();
        this.getSkillTypeId();
        this.dataMapping(this.edit ? res : null);
        this.getProvider({ page: 1, limit: 1000, sortBy: 'name', status: true });
        this.onPatientChange();
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
        this.providerNameUnavlable = '';
        this.addSkillsList = [{
          "priority": 10,
          "skillId": null,
          "skillTypeId": 2
        }];
      } else {
        this.addScroll();
      }
    }
    );
    this.patientAddSessionSubscription = this.appService.addSessionSidebarSource$.subscribe((res: any) => {
      console.log(res);
      // this.prvdrPtntModuleAddSession = true;
      this.startTimedrpdwnHrs = '07';
      this.startTimedrpdwnMnts = '00';
      this.startTimedrpdwnAMPM = 'AM';

      this.endTimedrpdwnHrs = '12';
      this.endTimedrpdwnMnts = '00';
      this.endTimedrpdwnAMPM = 'PM';
      if (res.state) {
        if (res.edit) {
          this.showIsPinned = res.isPinned;
          this.pinnedPopupNoBtn = false;
        } else {
          this.showIsPinned = false;
        }
        this.selectedItems = [];
        this.selectedTeams = [];
        this.profileSection = res.state;
        this.edit = res.edit;
        this.overlay = res.state;
        if (res.isPatientModule) {
          this.clinicStartTime = res.clinicStartTime;
          this.isPatientModuleCall = true;
          this.listPatient = [{ label: `${this.toTitleCase(res.patientData.firstName)} ${this.toTitleCase(res.patientData.lastName)} (${res.patientData.patientId})`, value: res.patientData.id }];
          this.isPatientModuleCallData = res.patientData;
          this.getProvider({ page: 1, limit: 1000, sortBy: 'name', status: true });

        } else {
          this.prvdrPtntModuleAddSession = true;
          this.clinicStartTime = res.clinicStartTime;
          if (res.providerData.id) {
            const payload = {
              endTime: moment("" + this.endTimedrpdwnHrs + ':' + this.endTimedrpdwnMnts + ' ' + this.endTimedrpdwnAMPM + "", ["h:mm A"]).format("HH:mm:ss"),
              providerId: Number(res.providerData.id),
              shiftDate: moment(new Date()).format("YYYY-MM-DD"),
              startTime: moment("" + this.startTimedrpdwnHrs + ':' + this.startTimedrpdwnMnts + ' ' + this.startTimedrpdwnAMPM + "", ["h:mm A"]).format("HH:mm:ss")
            }
            this.schedulingService.getCheckProviderUnvlty(payload).subscribe((response) => {
              this.providerNameUnavlable = response.data;
            })
          }
          this.isProviderModuleCall = true;
          this.getPatient({ page: 1, limit: 1000, sortBy: 'name', status: true });
          this.isProviderModuleCallData = res.providerData;
          this.listProvider = [{ label: `${this.toTitleCase(res.providerData.firstName)} ${this.toTitleCase(res.providerData.lastName)} (${res?.providerData.employeeId})`, value: res.providerData.id }];

        }

        this.getSpots({ page: 1, limit: 1000 });
        this.getSkillList({ page: 1, limit: 1000 });
        this.getPlaceOfServiceList();
        this.getSkillTypeId();
        this.dataMapping(this.edit ? res : null);
        setTimeout(() => {
          this.onPatientChange();
        }, 50);
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
        this.providerNameUnavlable = '';
        this.addSkillsList = [{
          "priority": 10,
          "skillId": null,
          "skillTypeId": 2
        }];
      } else {
        this.addScroll();
      }
    });
    this.openSocialMedialLinkSubs = this.appExService.getCustomReoccurData.subscribe(res => {
      console.log(res);
      if (res) {
        this.schedulingUser["customRecurrenceForm"] = res.data;
        this.pageContainingForm.control.markAsDirty();
        // this.schedulingUser.shiftDate = res.data.endDate;
      }
    });
    this.langSubs = this.appExService.getLanguageOptions.subscribe(data => {
      if (data.lang) {
        this.pageStrings = getLanguage(data.lang);
      }
    });

    this.onAddSkills();

  }
  onRemoveSkill(i) {
    this.addSkillsList.splice(i, 1);
    this.addSkillsList = this.addSkillsList.map(obj => obj);
    this.schedulingUser.sessionSkillForm.splice(i, 1);
    this.schedulingUser.sessionSkillForm = this.schedulingUser.sessionSkillForm.map(o => o);
    this.pageContainingForm.control.markAsDirty();
  }
  onSaveClick() {
    this.assignValues('save');
    this.pageContainingForm.control.markAsDirty();
  }
  onAddSkills() {
    this.addSkillsList.push({
      "priority": 10,
      "skillId": null,
      "skillTypeId": 2
    });
    if (this.addSkillsList.length > 1) {
      this.skillDisable = false;
    }
  }

  onShiftAddSkillsForm(event, i) {
    this.skillsSelectedValues[i] = event;
  }
  warn() {
    // let uobj = user.find(obj=> obj.value === this.schedulingUser.providerId);
    // this.providerNameUnavlable = uobj.label;

    setTimeout(() => {
      if (this.schedulingUser.providerId) {
        const payload = {
          endTime: moment(this.schedulingUser.endTime, ["h:mm A"]).format("HH:mm:ss"),
          // endTime: moment(""+this.endTimedrpdwnHrs+':'+this.endTimedrpdwnMnts+' '+this.endTimedrpdwnAMPM+"", ["h:mm A"]).format("HH:mm:ss"),
          providerId: Number(this.schedulingUser.providerId),
          shiftDate: moment(this.schedulingUser.shiftDate).format("YYYY-MM-DD"),
          startTime: moment(this.schedulingUser.startTime, ["h:mm A"]).format("HH:mm:ss")
          // startTime: moment(""+this.startTimedrpdwnHrs+':'+this.startTimedrpdwnMnts+' '+this.startTimedrpdwnAMPM+"", ["h:mm A"]).format("HH:mm:ss")
        }
        this.schedulingService.getCheckProviderUnvlty(payload).subscribe((response) => {
          // if(response.data){
          // this.providerUnavlablePopup = true;
          // this.providerNameUnavlable = uobj.label;
          this.providerNameUnavlable = response.data;
          // }
        })
      }
    }, 10);
  }
  onPatientChange() {
    if (this.schedulingUser.patientId) {
      this.appService.showCustomLoader(true)

      this.patientService.getAuthrztnPatientDataAPI(this.schedulingUser.patientId,false).subscribe((response: any) => {
        this.patientauthrztnData = response.data;
        this.editSessionFirstTime++;
        if (this.editSessionFirstTime > 1) {
          this.checkAuthorization();
        }
        this.appService.showCustomLoader(false);
      }, (error) => {
        this.appService.showCustomLoader(false);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      })
    }
  }

  checkAuthorization() {
    if (this.schedulingUser.spotId && this.schedulingUser.patientId) {
      this.appService.showCustomLoader(true);
      if (this.patientauthrztnData.length > 0) {
        const matchData = [];
        this.patientauthrztnData.forEach((obj) => {
          if (obj.spotDTO.id === this.schedulingUser.spotId) {
            matchData.push(obj);
          }
        });

        if (matchData.length > 0) { // if value matched with selected Session Type
          if (matchData[0].takenUnit >= matchData[0].totalUnit) {
            this.specialSessionSelectedTEXT = 'Taken_Unit_Msg';
            setTimeout(() => {
                this.authWarnPopup = true;
            }, 310);
          } else {
            this.specialSessionSelectedTEXT = '';
            this.authWarnPopup = false;
          }
        } else { // else NO value matched with selected Session Type
          this.specialSessionSelectedTEXT = '';
          setTimeout(() => {
              this.authWarnPopup = true;
          }, 300);
        }
        this.appService.showCustomLoader(false);

      } else {
        this.selectedSessionTypeTEXT = this.Sessiondropdown.selectedOption.label;
        this.specialSessionSelectedTEXT = '';
        this.authWarnPopup = true;
        this.appService.showCustomLoader(false);
      }
    }
  }
  closeAuthWarnPopup() {
    this.authWarnPopup = false;
    this.specialSessionSelectedTEXT = '';
  }
  onSaveCloseClick() {
    this.displayBasic = false;
    this.skillsSelectedValues = [];
    this.assignValues('saveClose');
    this.pageContainingForm.control.markAsDirty();
  }
  // assignValues(caller){
  //   this.schedulingUser.sessionSkillForm = [];
  //   let FinalData = [];
  //   let i = 1;
  //   let msgi = 1;
  //   this.addSkillsList.map(obj=>obj).forEach((element) =>{
  //     if(element.skillId && element.priority){
  //       if(this.specialSkillIds.includes(element.skillId)){
  //         if(i===1){
  //           this.schedulingUser.sessionSkillForm.push(element);
  //         }else if(msgi === 1){
  //           this.displayBasic=true;
  //           this.messageService.add({ severity: 'error', summary: 'Error', detail: ' Cannot add more than one special skill.' });
  //           msgi++;
  //         }
  //         i++;
  //       }else{
  //         this.schedulingUser.sessionSkillForm.push(element);

  //       }
  //     }
  //   });
  // }

  assignValues(caller) {
    const values = [];
    this.addSkillsList.map(obj => obj).forEach((element, i) => {
      if (element.skillId) {
        if (this.specialSkillIds.includes(element.skillId)) {
          values.push(element.skillId);
        }
      }
    });
    if (values.length > 1) {
      this.displayBasic = true;
      this.skillsSelectedValues = [];
      this.messageService.add({ severity: 'error', summary: 'Error', detail: ' Cannot add more than one special skill.' })
    } else {

      let cleanDuplicate = this.addSkillsList.filter((arr, index, self) =>
        index === self.findIndex((t) => (t.priority === arr.priority && t.skillId === arr.skillId && t.skillTypeId === arr.skillTypeId)))

      this.schedulingUser.sessionSkillForm = [];
      cleanDuplicate.forEach(element => (element.skillId) ? this.schedulingUser.sessionSkillForm.push(element) : '');
    }

  }

  dataMapping(res) {
    const obj = this.schedulingUser;
    obj["customRecurrenceForm"] = {
      "endDate": res ? res.endDate : new Date(),
      "recurrenceType": res ? res.recurrenceType : 'Never',
      "occurrenceType": res ? res.occurrenceType : 'week',
      "recurrenceRange": res ? res.recurrenceRange : 1,
      "recurrenceCount": res ? res.recurrenceCount : '1',
      "weekDays": res ? res.weekDays : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    };
    if (this.edit) {
      this.startTimedrpdwnHrs = moment(`${res.shiftDate} ${res.startTime}`).format("hh").toString();
      this.startTimedrpdwnMnts = moment(`${res.shiftDate} ${res.startTime}`).format("mm").toString();
      this.startTimedrpdwnAMPM = moment(`${res.shiftDate} ${res.startTime}`).format("A").toString();

      this.endTimedrpdwnHrs = moment(`${res.shiftDate} ${res.endTime}`).format("hh").toString();
      this.endTimedrpdwnMnts = moment(`${res.shiftDate} ${res.endTime}`).format("mm").toString();
      this.endTimedrpdwnAMPM = moment(`${res.shiftDate} ${res.endTime}`).format("A").toString();
      obj.endTime = moment(`${res.shiftDate}T${res.endTime}`).format('hh:mm a').toUpperCase();
      obj.startTime = moment(`${res.shiftDate}T${res.startTime}`).format('hh:mm a').toUpperCase();

      obj.patientId = res.patient ? res.patient.id : null;
      obj.isPinned = res.isPinned;
      obj.providerId = res.provider ? res.provider.id : null;
      this.EditPrvdrResponse = res.provider ? res.provider : null;
      obj.recurrence = res.recurrence || 'doNotRepeat';
      obj.type = 'current';
      obj.shiftDate = new Date(moment(res.shiftDate).toString());
      obj.sessionSkillForm = res.sessionSkills.map(ses => {
        return {
          "priority": ses.priority || 10,
          "skillId": ses.skill.id,
          "skillTypeId": ses.skillType.id
        }
      });
      obj.spotId = res.spot ? res.spot.id : null;
      if (!this.prvdrPtntModuleAddSession) {
        this.resSpotName = res.spot.name;
        if (res.spot.name == 'Behavioral Supervision' || res.spot.name == 'Occupation Individual Therapy' || res.spot.name == 'Speech Individual Therapy') {
          this.specialSessionTypeSelected = true;
        }
      }
      obj.placeOfServiceId = res.placeOfServiceDTO ? res.placeOfServiceDTO.id : 5;
      obj["id"] = res.id;
      const cRD = res.customRecurrenceDTO;
      if (cRD) {
        obj.customRecurrenceForm.endDate = cRD.endDate ?
          new Date(moment(cRD.endDate).toString()) : new Date();
        obj.customRecurrenceForm.occurrenceType = cRD.occurrenceType;
        obj.customRecurrenceForm.recurrenceCount = cRD.recurrenceCount;
        obj.customRecurrenceForm.recurrenceType = cRD.recurrenceType;
        obj.customRecurrenceForm.recurrenceRange = cRD.recurrenceRange;
        obj.customRecurrenceForm.weekDays = cRD.weekDays.map((objj) => { return (objj.type); });;
      }
      else {
        obj.customRecurrenceForm.endDate = new Date();
        obj.customRecurrenceForm.recurrenceType = 'On';
        obj.customRecurrenceForm.recurrenceCount = 1;
        obj.customRecurrenceForm.occurrenceType = 'week';
      }

      this.allRecurrencePinned = res.allRecurrencePinned.toString();
      this.reccurnceIsPinned();
    }
    else {
      this.specialSessionTypeSelected = false;
      obj.customRecurrenceForm.recurrenceType = 'On';
      obj.customRecurrenceForm.recurrenceCount = 1;
      obj.customRecurrenceForm.occurrenceType = 'week';

      obj.startTime = moment(`${new Date().toISOString().split('T')[0]}T${this.clinicStartTime}`).format('hh:mm a').toUpperCase();
      obj.endTime = this.formatAMPM(new Date);
      obj.shiftDate = (this.clinicTimeZone === 'MST') ? new Date(this.currentDate.toLocaleString('en-US', { timeZone: 'America/Denver' })) : new Date(this.currentDate.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
      // obj.shiftDate = (this.clinicTimeZone === 'MST') ? new Date().toLocaleString('en-US',{timeZone : 'America/Denver'}): new Date().toLocaleString('en-US',{timeZone : 'America/Denver'});
      obj.customRecurrenceForm.endDate = null;
      obj.patientId = this.isPatientModuleCall ? this.isPatientModuleCallData.id : null;
      obj.isPinned = false;
      obj.providerId = this.isProviderModuleCall ? this.isProviderModuleCallData?.id : null;
      obj.recurrence = 'doNotRepeat';
      obj.sessionSkillForm = [];
      obj.spotId = null;
      obj.placeOfServiceId = 5;
      this.reccurnceIsPinned();
      this.allRecurrencePinned = false;
    }
  }


  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  addScroll() {
    this.profileSection = false;
    this.overlay = false;
    this.teamSection = false;
    this.displayBasic = false;
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.patientAddSessionSubscription) {
      this.patientAddSessionSubscription.unsubscribe();
    }
    this.profileSection = false;
    this.teamSection = false;
    this.displayBasic = false;
    this.skillsSelectedValues = [];
    this.overlay = false;
    this.appExService.callActivityData = [];
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (event.target.closest('.overlay') && !event.target.closest('.profileSection')) {
      this.profileSection = !this.profileSection;
      this.overlay = !this.overlay;
      this.addScroll();
    }
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result.toString();
      }
    }
  }

  change(event) {
    console.log("AKASH----------", this.schedulingUser)
    if (event.value === "customRecurrence") {
      this.schedulingUser.customRecurrenceForm["minDate"] = this.schedulingUser.shiftDate;
      if (this.schedulingUser.customRecurrenceForm.endDate) {
        this.appService.filterCustomReccurence.next({
          state: true,
          edit: true,
          formEdit: this.edit,
          minDate: this.schedulingUser.shiftDate,
          data: this.schedulingUser.customRecurrenceForm
        });
      } else {
        this.schedulingUser.customRecurrenceForm.endDate = this.schedulingUser.shiftDate;
        this.appService.filterCustomReccurence.next({
          state: true,
          edit: true,
          formEdit: this.edit,
          minDate: this.schedulingUser.shiftDate,
          data: this.schedulingUser.customRecurrenceForm
        });
      }

    }
  }

  getPatient(data) {
    this.patientService.getPatientList(data).subscribe((response: any) => {
      if (response.data) {
        this.listPatient = response.data.map(obj => {
          this.patientListids.push(obj.id);
          return {
            label: `${this.toTitleCase(obj.firstName)} ${this.toTitleCase(obj.lastName)} (${obj.patientId})`,
            value: obj.id, title: `${obj.firstName} ${obj.lastName}`
          }
        })
        this.listPatient.unshift({ label: '--Select--', value: null });

        if (!this.patientListids.includes(this.schedulingUser.patientId)) {
          this.schedulingUser.patientId = null;
        }
        this.sortPatietnList({ sortField: 'title', sortOrder: 1 });
      }
    })
  }

  sortPatietnList(event) {
    this.listPatient.sort((data1, data2) => {
      let value1 = data1[event.sortField];
      let value2 = data2[event.sortField];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.sortOrder * result);
    });
  }

  sortProviderList(event) {
    this.listProvider.sort((data1, data2) => {
      let value1 = data1[event.sortField];
      let value2 = data2[event.sortField];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.sortOrder * result);
    });
  }


  isPinnedYesNoClick(event) {
    this.showIsPinned = event;
    this.pageContainingForm.control.markAsDirty();
  }

  getSpots(data) {
    this.spotService.getSpot(data).subscribe((response: any) => {
     
      
      if (response.data) {
        this.listSpots = response.data.map(obj => {
          if (obj.name == 'Behavioral Supervision' || obj.name == 'Occupation Individual Therapy' || obj.name == 'Speech Individual Therapy') {
            this.specialSessionTypelistIds.push(obj.id)
          }
          return { label: obj.name, value: obj.id,isOutsideClinicHours:obj.isOutsideClinicHours }
        })
      }
    })
  }
  getSkillList(data) {
    this.skillService.getSkills(data).subscribe((response: any) => {
      if (response.data) {
        this.listSkills = response.data.map(obj => {
          if (obj.skillName == 'Behavioral Supervision' || obj.skillName == 'Occupation Individual Therapy' || obj.skillName == 'Speech Individual Therapy') {
            this.specialSkillIds.push(obj.id)

          }
          return { label: obj.skillName, value: obj.id }
        });

        this.listSkills.unshift({ label: '--Select--', value: null })
      }
    });
  }

  getPlaceOfServiceList() {
    this.skillService.getPlaceOfServiceAPI().subscribe((response: any) => {
      if (response.data) {
        this.ListPlaceOfService = response.data.map(obj => {
          return { label: obj.code + ' - ' + obj.type, value: obj.id }
        });
      }
    });
  }

  onRemoveSkillAdd(i) {
    this.skillDisable = false
    this.skillsSelectedValues.splice(i, 1);
    this.addSkillsList.splice(i, 1);
    this.addSkillsList = this.addSkillsList.map(obj => obj);
    this.schedulingUser.sessionSkillForm.splice(i, 1);
    this.schedulingUser.sessionSkillForm = this.schedulingUser.sessionSkillForm.map(o => o);
  }

  getSkillTypeId() {
    this.providerService.getSkillType().subscribe((response: any) => {
      if (response.data) {
        this.listSkilltype = response.data.map(obj => {
          return { label: obj.type, value: obj.id }
        });
        this.listSkilltype = this.listSkilltype.filter(obj => obj.label === 'additional');
        this.listSkilltype[0].label = 'Additional';
        // this.listSkilltype.unshift({label: '--Select--', value: null})
      }
    });
  }
  getProvider(data) {
    let getspecialSessionData;
    let getspecialSessionParam = null;
    if (!this.prvdrPtntModuleAddSession) {
      if (this.specialSessionTypeSelected) {//if special session selected
        getspecialSessionData = this.listSpots.filter((obj) => {
          return obj.value === this.schedulingUser.spotId
        });
        if (getspecialSessionData.length != 0) {
          if (getspecialSessionData[0].label == 'Behavioral Supervision') {
            getspecialSessionParam = 'BCBA';
          } else if (getspecialSessionData[0].label == 'Occupation Individual Therapy') {
            getspecialSessionParam = 'OT';
          } else if (getspecialSessionData[0].label == 'Speech Individual Therapy') {
            getspecialSessionParam = 'SPEECH';
          }
        } else {
          if (this.resSpotName == 'Behavioral Supervision') {
            getspecialSessionParam = 'BCBA';
          } else if (this.resSpotName == 'Occupation Individual Therapy') {
            getspecialSessionParam = 'OT';
          } else if (this.resSpotName == 'Speech Individual Therapy') {
            getspecialSessionParam = 'SPEECH';
          }
        }
      }
    }
    this.providerService.getProviderListDataforAddSession(getspecialSessionParam).subscribe((response: any) => {
      if (response.data) {
        this.listProvider = response.data.map(obj => {
          return { label: `${obj.firstName} ${obj.lastName} ${obj?.employeeId ? ` (${obj.employeeId})` : ''}`, value: obj.id, title: `${obj.firstName} ${obj.lastName}` }
        });
        this.listProvider.unshift({ label: '--Select--', value: null });
      }
      if (this.edit && (this.EditPrvdrResponse != null)) {
        this.checkProviderListPrvdrExist(this.EditPrvdrResponse);
      }
      this.sortProviderList({ sortField: 'title', sortOrder: 1 });
    });
  }

  checkProviderListPrvdrExist(pObj) {
    if (pObj.id) {
      const hasPrvdr = this.listProvider.filter((obj) => { return obj.value === pObj.id })
      if (hasPrvdr.length === 0) {
        this.listProvider.push({ label: `${pObj.firstName} ${pObj.lastName}`, value: pObj.id })
      }
    }
  }

  showBasicDialog() {
    if (!this.displayBasic) {
      if (this.schedulingUser.sessionSkillForm) {
        this.addSkillsList = this.schedulingUser.sessionSkillForm.map(obj => ({ ...obj }));
      }
      else {
        this.addSkillsList = [{
          "priority": 10,
          "skillId": null,
          "skillTypeId": 2
        }];
      }
      this.addSkillsList.push({
        "priority": 10,
        "skillId": null,
        "skillTypeId": 2
      });
      this.displayBasic = true;
      this.addSkillsList.forEach((obj, i) => {
        if (obj.skillId !== null) {
          this.skillsSelectedValues[i] = obj.skillId;
        }
      })
    }

  }

  createScheduling(data) {
    var timeToGetData = 0;
    if (!(data == 'skip')) {
      if (!this.showIsPinned) {
        if (this.schedulingUser.providerId || this.schedulingUser.patientId) {
          if (!this.showIsPinned && !this.pinnedPopupNoBtn && (this.specialSessionTypeSelected && this.schedulingUser.patientId) || (!this.specialSessionTypeSelected && this.schedulingUser.providerId)) {
            this.isPinnedOpen = true
            this.PopupOPenedOnce = true;
            return;
          }
        }
      }
    }


    const successMessage = this.edit ? 'updated' : 'created';
    const form = this.schedulingUser;
    const payload = {
      ...form,
      startTime: moment(this.schedulingUser.startTime, ["h:mm A"]).format('kk:mm:ss'),
      endTime: moment(this.schedulingUser.endTime, ["h:mm A"]).format('kk:mm:ss'),
      shiftDate: moment(this.schedulingUser.shiftDate).format('YYYY-MM-DD'),
    };


    if (payload.recurrence !== "customRecurrence") {
      timeToGetData = 1000;
      delete payload.customRecurrenceForm;
      // if 'Recurrence Interval' field selected value is 'Do Not Repeat'
      payload.type = 'current';

    } else {
      timeToGetData = 5500;
      // else 'Recurrence Interval' field selected value is 'Custom Reccurence'
      payload.type = 'upcomingWithRecurrence';
      if (payload.customRecurrenceForm.endDate) {
        payload.customRecurrenceForm.endDate = moment(payload.customRecurrenceForm.endDate).format('YYYY-MM-DD');
      }

    }
    if (this.edit) {
      payload["isPinned"] = this.showIsPinned;
      payload["allRecurrencePinned"] = this.allRecurrencePinned;
      if (payload.providerId === null) {
        payload.providerId = -1;
      }
      this.schedulingService.updateScheduling(payload).subscribe((response) => {
        this.PopupOPenedOnce = false
        this.showIsPinned = false
        this.appService.showCustomLoader(true)
        this.addScroll();
        setTimeout(() => {
          this.updateTableData.emit({ success: true });
          this.updatePatientDetailData.emit({ success: true });
          this.updateProviderDetailData.emit({ success: true });
        }, timeToGetData);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });
        this.disableButtonForDuration();

      }, (error) => {
        this.appService.showCustomLoader(false)
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        if (!this.showIsPinned) {
          this.PopupOPenedOnce = false;
        } else {
          this.PopupOPenedOnce = true;
        }
        this.disableButtonForDuration();

      });
    }
    else {
      delete payload.type;
      payload["isPinned"] = this.showIsPinned;
      payload["allRecurrencePinned"] = this.allRecurrencePinned;
      this.schedulingService.addScheduling(payload).subscribe((response) => {
        this.PopupOPenedOnce = false;
        this.showIsPinned = false
        this.specialSessionTypeSelected = false;
        this.addScroll();
        this.appService.showCustomLoader(true)
        setTimeout(() => {
          this.updateTableData.emit({ success: true });
          this.updatePatientDetailData.emit({ success: true });
          this.updateProviderDetailData.emit({ success: true });
        }, timeToGetData);
        this.appService.showCustomLoader(false);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });
        this.disableButtonForDuration();
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        if (!this.showIsPinned) {
          this.PopupOPenedOnce = false;
        } else {
          this.PopupOPenedOnce = true;
        }
        this.disableButtonForDuration();
        // this.showIsPinned = true; 

      });
    }

  }
  reccIsPinnedPopup: Boolean = false;
  reccurnceIsPinned() {
    if (this.schedulingUser.recurrence === 'customRecurrence' && this.showIsPinned === true) {
      this.reccIsPinnedPopup = true;
    } else {
      this.reccIsPinnedPopup = false;
    }
  }
  allRecurrencePinned: Boolean = false;
  ClickOnOk(item) {
    console.log(this.allRecurrencePinned);

  }
  addNew(i) {

    if (this.addSkillsList.length === i + 1) {//check array length or click index
      this.onAddSkills()
    }

  }

  ClickOnYes() {
    this.disableButtonForDuration()
    this.isPinnedOpen = false;
    this.showIsPinned = true;
    this.createScheduling('skip')

  }

  toggleData() {
    this.show1 === !this.show1;
  }

  CloseAndExit() {
    this.disableButtonForDuration()
    this.isPinnedOpen = false;
    this.pinnedPopupNoBtn = true;
    this.createScheduling('skip')
  }

  onHideAdditionalSkillForm() {
    this.skillDisable = true
    this.skillsSelectedValues = [];
  }
  disableButtonForDuration() {
    this.isButtonDisabled = true;
    setTimeout(() => {
      this.isButtonDisabled = false;
    }, 5000);
  }
  getDynamicYearRange(): string {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear;
    const endYear = currentYear + 5;
    return `${startYear}:${endYear}`;
  }
  itemCheck(data){
    if(this.edit && this.addSkillsList.length < 1){
      if(data == null){
        this.skillDisable = true;
      }
    }
  }
  toTitleCase(input: string): string {
    return input.toLowerCase().replace(/(?:^|\s|-|')\S/g, match => match.toUpperCase());
  }
}
