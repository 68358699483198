<div (click)="profileSection = false; overlay = false;teamSection = false;isInputEnabled=false"
[ngClass]="overlay?'overlay-block':'overlays'" [@moreFilterSectionEmp]="overlay"></div>
<div class="personal-inner-custom property-details filter-detail employee-details cusRecFormInTab" [@moreFilterSectionEmp]="profileSection">
  <div>
    <div class="top-header">
      <span class="header-text headerTxtcolor">
        Custom Recurrence
      </span>
      <span pTooltip="Close" tooltipPosition="top" class="close-sidebar pointer"
        (click)="profileSection = false; overlay = false;isInputEnabled=false">
        <img src="../../assets/images/close.svg" alt="close" />
      </span>
    </div>
    <div class="row personal-wrapper filter-wrappers-custom emp-filter-wrapper">

      <div class="col-md-12 form-group user-field selected-contact-data DateChooosePatient">
        <div class="startpatientTime">
          <ion-label class="label ">Repeat Every</ion-label>
          <div class="search_ico filter-ico top-margin dropDwnDgn">
            <input (keydown)="onChangeSettings()" [(ngModel)]="customRecurrenceuser.recurrenceCount" type="number" class="common-input " name=""
              style="width: 50px; margin-right: 5px;">
            <p-dropdown (onChange)="onChangeSettings()" styleClass="hederDrop" appendTo="body" class=" week" placeholder="Select"
              [options]="week" [(ngModel)]="customRecurrenceuser.occurrenceType" panelStyleClass="drpDwnClr">
            </p-dropdown>
          </div>
        </div>
      </div>
      <div 
      *ngIf="customRecurrenceuser.occurrenceType === 'week' || customRecurrenceuser.occurrenceType === 'weekdays'"
      class="col-md-12 form-group user-field selected-contact-data">
        <ion-label class="label">Repeat On</ion-label>
        <div style="display: flex;flex-direction: row;">
          <div 
            (click)="weekDaysList[0].value  =  !weekDaysList[0].value;onChangeWeekDayClick();"
            [ngClass]="{'highlightWeekDay': weekDaysList[0].value}"
            class="weekname">S</div>
          <div 
            (click)="weekDaysList[1].value  =  !weekDaysList[1].value;onChangeWeekDayClick();"
            [ngClass]="{'highlightWeekDay': weekDaysList[1].value}"
            class="weekname">M</div>
          <div 
            (click)="weekDaysList[2].value  =  !weekDaysList[2].value;onChangeWeekDayClick()"
            [ngClass]="{'highlightWeekDay': weekDaysList[2].value}"
            class="weekname">T</div>
          <div 
            (click)="weekDaysList[3].value  =  !weekDaysList[3].value;onChangeWeekDayClick();"
            [ngClass]="{'highlightWeekDay': weekDaysList[3].value}"
            class="weekname">W</div>
          <div 
            (click)="weekDaysList[4].value  =  !weekDaysList[4].value;onChangeWeekDayClick();"
            [ngClass]="{'highlightWeekDay': weekDaysList[4].value}"
            class="weekname">T</div>
          <div 
            (click)="weekDaysList[5].value  =  !weekDaysList[5].value;onChangeWeekDayClick();"
            [ngClass]="{'highlightWeekDay': weekDaysList[5].value}"
            class="weekname">F</div>
          <div 
            (click)="weekDaysList[6].value  =  !weekDaysList[6].value;onChangeWeekDayClick();"
            [ngClass]="{'highlightWeekDay': weekDaysList[6].value}"
            class="weekname">S</div>
        </div>
      </div>
      <div class="col-md-12 form-group user-field selected-contact-data">
        <ion-label class="label" style="font-size: 13px;">Ends</ion-label>
        <div [ngClass]="{'disableCalendar': isInputEnabled}" style=" width: 100%; display: flex; flex-direction: row;align-items: center;  margin-top: 2%;">
          <input [(ngModel)]="customRecurrenceuser.recurrenceType" (click)="enableAfterInput('disable');onChangeWeekDayClick()" type="radio" id="html" name="fav_language"
            value="On">
          <label for="On" style=" font-size:13px;  margin-left: 5px; margin-top: 2.5%;">On</label>
          <div class="sched_datetime">
            <p-calendar [disabled]="isInputEnabled && customRecurrenceuser.recurrenceType ==='After'" (onBlur)="onChangeWeekDayClick()"
            [monthNavigator]="true" [yearNavigator]="true" [yearRange]="getDynamicYearRange()"
            [minDate]="minDate"
            [(ngModel)]="customRecurrenceuser.endDate" class="oncalender" appendTo="body"
              [showIcon]="true" inputId="icon"></p-calendar>
          </div>

        </div>
        <div style=" width: 100%; display: flex; flex-direction: row;align-items: center; margin-top: 2%; ">
          <input [(ngModel)]="customRecurrenceuser.recurrenceType" (click)="enableAfterInput('enable');onChangeWeekDayClick()" type="radio" id="html" name="fav_language"
            value="After">
          <label for="After" style=" font-size:13px;  margin-left: 5px; margin-top: 2.5%;">After</label>
          <input  [ngClass]="{'highlightInput': isInputEnabled}" (keydown)="checkChar($event); onChangeWeekDayClick()" min="1" [(ngModel)]="customRecurrenceuser.recurrenceRange" [disabled]="!isInputEnabled" type="number" class="common-input " name="recurrenceRange"
            style="width: 50px;  margin-left: 12px;">
          <p style="margin-top:4.4%; font-size: 13px; margin-left: 5px;">Occurrences</p>

        </div>
      </div>
    </div>
    <div class="save-reset">
      <div class="more-filterbtns marginbtn">
        <ion-button (click)="getCustomFormData()" [disabled]="saveBtnDisabled"
          class="btn-save save-btn btn-save-head" type="button">
          Save
        </ion-button>
      </div>
    </div>
  </div>
</div>