export const ProviderColumns = [
  {label: 'Provider Information', value: 'firstName' },
  // {label: 'Email', value: 'email' },
  {label: 'Job Title', value: 'employeePosition' },
  {label: 'Phone', value: 'phone' },
  {label: 'Provider Payroll', value: 'payroll' },
  {label: 'Address', value: 'address' },
  {label: 'Skills', value: 'skills' },
  {label: 'Assigned Patient', value: 'patient' },
  // {label: 'Status', value: 'status' },
  // {label: 'Action', value: 'actions' },

];

export const ProviderDetailAdditionalData = {
  clinicOpenHoursPercentage : 0,
  clinicOpenHoursCount : 0,
  providerAvailabilityHourPercentage : 0,
  providerAvailabilityHours : 0,
  providerSessionHourPercentage:0,
  providerSessionHour : 0
};

export class ProviderModel {
  clinicIds:	Array<any>;
  contractId:	 number;
  jobId:	 number;
  email	:string;
  resetPasswordMail: boolean;
    city:	string;
    country:	string;
    state	:string;
    street	:string;
    zipCode	:string;
    employeeId	:string;
    employeeTypeId:	 number;
    firstName	:string;
    gender	:string;
    payroll:string;
    lastName	:string;
    phone	:any;
    // profilePic	:string;
    id: any;
    documentId	:string;
    providerAddress:Object;
    providerSkillsForms = [];
     constructor(obj?) {
       this.providerSkillsForms = [];
      this.city = obj ? obj.city : '';
      this.contractId = obj ? obj.contractId:''; 
      this.jobId = obj ? obj.jobId:'',
      this.payroll = obj ? obj.payroll : '';
      this.clinicIds = obj ? obj.clinicIds:[]; 
      this.providerAddress = obj ? obj.providerAddress : '';
      this.country = obj ? obj.country:''; 
      this.email = obj ? obj.email:''; 
      this.employeeId = obj ? obj.employeeId:''; 
      this.firstName = obj ? obj.firstName:''; 
      this.gender = obj ? obj.gender:'prefer not to say'; 
      this.lastName = obj ? obj.lastName:''; 
      this.phone = obj ? obj.phone:''; 
      this.documentId = obj ? obj.documentId:''; 
      // this.profilePic = obj ? obj.profilePic:''; 
      this.zipCode = obj ? obj.zipCode:''; 
      this.state = obj ? obj.state:''; 
      this.street = obj ? obj.street:''; 


      
    }
  }