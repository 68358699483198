import { Utils } from '../../utils';
import { AppService } from '../../app.service';
import { Subscription } from 'rxjs';
import { SlidePanelAnimation } from '../../shared/animations/animations';
import { Component, OnInit, HostListener, Output, EventEmitter, TemplateRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { PopUpService } from '../../shared/pop-up/pop-up.service';
import { MessageService } from 'primeng/api';
import { InitialService } from '../../initial/initial.service';
import { SharedService } from '../../shared/shared.service';
import { ProviderService } from '../provider.service';
import { HttpClient } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderModel } from '../provider.model';
import { getLanguage } from './../../languages';
import { AppExService } from "../../app-ex.service";
import { LanguageInterface } from './../../languages/interface.language';
import { config } from 'src/app/config';
import { ClinicService } from 'src/app/clinics/clinics.service';
import { ContractsService } from 'src/app/contracts/contracts.service';
import { SkillsService } from 'src/app/skills/skills.service';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { base64ToFile } from 'ngx-image-cropper';
import { JobConfigService } from 'src/app/job-config/job-config.service';

@Component({
  selector: 'app-provider-profile',
  templateUrl: './provider-profile.component.html',
  styleUrls: ['./provider-profile.component.scss', '../../../assets/stylesheets/form.scss'],
  animations: SlidePanelAnimation,
}
)
export class ProviderProfileComponent implements OnInit, OnDestroy  {
  profileSubscription: Subscription;
  openSocialMedialLinkSubs: Subscription;
  updatePlaybookSubscription: Subscription;
  profileDataSubscription: Subscription;
  addConnectionSubs: Subscription;
  url = '';
  @Input() filter;
  @Output() updateTableData = new EventEmitter<any>();
  model: any = {
    description: 'App Description...',
    theme: {
      bgColor: "ffffff",
      textColor: "555555",
      bannerImage: ""
    },
    attributes: []
  };
providerUser:ProviderModel;
  selectedItems = [];
  removeSkillPop = false;
  isBcba:Boolean=false;
  skillDisable = true;
  isMobileView:Boolean = false;
  isTabView = window.innerWidth > 600 && window.innerWidth <= 800;
  selectedTeams=[];
  autoComplete = "autocomplete";
  userRole = "";
  profileSection = false;
  overlay = false;
  facilities;
  listTeams=[
    {name: 'Amy Cole (Sr. Doctor)', value: 'Amy Cole (Sr. Doctor)', designation: 'Physical Therapy'},
    {name: 'Amy Jones (Sr. Doctor)', value: 'Amy Jones (Sr. Doctor)', designation: 'Physical Therapy'},
    {name: 'Gary Camara', value: 'Gary Camara', designation: 'Dentist'},
    {name: 'Gary Kerse', value: 'Gary Kerse', designation: 'Dentist'},
    {name: 'Jeff Culin', value: 'Jeff Culin', designation: 'Dentist'},
    {name: 'Natasha Camara', value: 'Natasha Camara', designation: 'Dentist'}
  ];
  listProviderIds=[
    {label:'Fulltime Provider',value:1},
    {label:'Hourly Provider',value:2},
  ];
  listpriority=[
    {label:'1',value:1},
    {label:'2',value:2},
    {label:'3',value:3},
    {label:'4',value:4},
    {label:'5',value:5},
    {label:'6',value:6},
    {label:'7',value:7},
    {label:'8',value:8},
    {label:'9',value:9},
    {label:'10',value:10},
  ];
  skillFormData = {
    "priority": null,
    "skillId": null,
  };
  edit = false;
  selectedfacilities;
  teamSection = false;
  tableColumns = [
    { field: 'name', header: 'Name' },
  ];
  tableData = [
    {name: 'Dr. John Smith',selected: false},
    {name: 'Dr. David Lee',selected: false},
    {name: 'Frank Camly',selected: false},
    {name: 'Gary Camara',selected: false},
    {name: 'Amy Jones',selected: false},
    {name: 'Dr. David Lee',selected: false},
    {name: 'Gary Camara',selected: false},
    {name: 'Dr. David Lee',selected: false},
    {name: 'Amy Jones',selected: false},
    {name: 'Amy Cole',selected: false},
    {name: 'Dr. David Lee',selected: false},
    {name: 'Amy Cole',selected: false},
    {name: 'Dr. David Lee',selected: false},
    {name: 'Dr. David Lee',selected: false},
    {name: 'Amy Cole',selected: false},
    {name: 'Dr. David Lee',selected: false},
    {name: 'Gary Camara',selected: false},
    {name: 'Dr. David Lee',selected: false},
    {name: 'Dr. David Lee',selected: false},
    {name: 'Amy Cole',selected: false},
    {name: 'Amy Cole',selected: false},
    {name: 'Dr. David Lee',selected: false},
    {name: 'Amy King',selected: false},
    {name: 'Dr. David Lee',selected: false},
    {name: 'Amy King',selected: false},
  ];
  resetOptions = [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
  ];
  rows = 10;
  total = 10;
    addSkillsList = [];
    rowNumber = 1
  listClinic:any[]=[]
  listJobTitle:any[]=[]
  listProviderPayroll:any[] = [
    {label:"ADP",value:"ADP"},
    {label:"NONE",value:"NONE"}
  ]
  listClinicIDs:any[]=[];
  listSkills:any[]=[]
  listContracts:any[]=[]
  listSkilltype:any[]=[]
  specialSkillIds =[]
  deleteSkillSpecialType = false;
  pageSize = 50;
  langSubs: Subscription;
  pageStrings: LanguageInterface;
  skillIndex:any;

  invalidChars = [
    "-",
    "e",
    "E",
  ];
  invalidSymbol = ["$","~","@","%","^","&","*",
  "(",")","{","}","!","[","]",",","=","+","-",
  "<",">",".","/","?",";",":","_","`","#", "'","|", "\"",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  invalidSymbolLastName = ["$","~","@","%","^","&","*",
  "(",")","{","}","!","[","]",",","=","+",
  "<",">",".","/","?",";",":","_","`","#", "'","|", "\"",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  invalidSymbolId = ["$","~","@","%","^","&","*",
  "(",")","{","}","!","[","]",",","=","+","-",
  "<",">",".","/","?",";",":","_","`","#", "'","|", "\""
  ];

  imgBaseURL = config.baseUrl;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  saveCropImgBtn:any = false;
  uploadCropImgBtn:any = true;
  previousImgShow:any = true;
  profilephoto = false;
  submitted = false

  providerResClinicIdsSave:any;
  providerSavedPayload:any;
  warnProviderClinicChangePopup = false;
  providerAddAnotherClinicPopup:boolean = false;
  anothercliniclists:any;
  skillsSelectedValues = [];


  providerPastData:any;
  providerPastDataColumns = [
    { field: 'srno', header: 'S.No' },
    { field: 'clinicName', header: 'Clinic Name' },
    { field: 'providerName', header: 'Provider Name' },
    { field: 'sessionCount', header: 'Sessions' }
  ];

  @ViewChild('pageContainingForm') pageContainingForm : any;

  checkInput(e){    
    this.providerUser['phoneMin'] = !!(this.providerUser.phone?.toString().length < 10);
    this.providerUser['phoneMax'] = !!(this.providerUser.phone?.toString().length > 15);    
    if (this.invalidChars.includes(e.key)) {
      e.preventDefault();
    }
    if(this.providerUser.phone?.toString().length > 14){
      // this.providerUser.phone = parseInt(this.providerUser.phone.toString().substring(0,14))
    }
  }
  checkChar(e){
    if (this.invalidSymbol.includes(e.key)) {
      e.preventDefault();
    }
  }
  checkCharLastName(e){
    if (this.invalidSymbolLastName.includes(e.key)) {
      e.preventDefault();
    }
  }
  checkCharId(e){
    if (this.invalidSymbolId.includes(e.key)) {
      e.preventDefault();
    }
  }
  constructor(
    public appService: AppService,
    public messageService: MessageService,
    public initialService: InitialService,
    public httpclient: HttpClient,
    public providerService: ProviderService,
    public skillsSevice:SkillsService,
    public utils: Utils,
    public appExService: AppExService,
    private formBuilder: FormBuilder,
    public contractsService: ContractsService,
    public jobConfigService: JobConfigService


  ) {
    this.providerUser = new ProviderModel();
    this.isMobileView = window.innerWidth <= 600;
    
  }
  closeChips(i,){
    this.selectedTeams.splice(i, 1);
  } 
  removePhoto(){
    this.providerUser.documentId = '';
    this.url = '';
    this.pageContainingForm.control.markAsDirty();
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Image deleted successfully' });
  }
  closeTeam(i, item){
    item.selected = false
  }
  closeSkill(i){
    this.selectedItems.splice(i, 1);
  }
  closeClinic(i,item){    
    if(this.listClinicIDs.includes(item.value)){
      this.providerUser.clinicIds.splice(i,1);
      this.providerUser.clinicIds = this.providerUser.clinicIds.map(o=>o);
      this.pageContainingForm.control.markAsDirty();
    }
  }
 


  ngOnInit() {
    const remD = JSON.parse(localStorage.getItem('optplanner_user'));
    this.userRole = remD.userType.type;
    
    this.langSubs = this.appExService.getLanguageOptions.subscribe(data => {
      if(data.lang){
        this.pageStrings = getLanguage(data.lang);
      }
    });
    this.profileSubscription = this.appService.providerProfileSidebarSource$.subscribe((res: any) => {
        if (res.state) {
          this.profileSection = res.state;
          this.edit = res.edit;
          this.getJobTitleList();
          this.getClinicList({ page: 1, limit: 1000, status:true, sortBy:'name'});
          this.getSkillList({ page: 1, limit: 1000});
          this.dataMapping(this.edit ? res.rowData.formData : null);
          this.overlay = res.state;
          document.getElementsByTagName("body")[0].style.overflow = "hidden";
        } else {
          this.addScroll();
        }
        this.providerUser['phoneMin'] = false;
        this.providerUser['phoneMax'] = false;
      });
      
  }
  displayBasic: boolean;
  dataMapping(res){
    const providerUser = this.providerUser;
    if(this.edit){
      providerUser.firstName = res.firstName;
      providerUser.lastName = res.lastName;
      providerUser.phone = res.phoneNo;
      providerUser.employeeId=res.employeeId;
      providerUser.employeeTypeId=res.employeeType ? res.employeeType.id : null;
      providerUser.documentId = res.documentId;
      providerUser.providerSkillsForms = res.providerSkills.map(obj=>{
        return ({
          "priority": obj.priority,
          "skillId": obj.skill.id,
        })
      });
      providerUser.gender = res.gender;
      providerUser.email = res.email;
      providerUser.resetPasswordMail = false;
      providerUser.id = res.id;
      this.url = providerUser.documentId;
      providerUser.state= res.providerAddress.state;
      providerUser.street= res.providerAddress.street;
      providerUser.zipCode= res.providerAddress.zipCode;
      providerUser.country= res.providerAddress.country;
      providerUser.city=res.providerAddress.city;
      providerUser.contractId = res.contract.id;
      providerUser.jobId = res.jobId;
      providerUser.payroll = res.payroll ? res.payroll : "NONE";
      providerUser.clinicIds = res.clinics.map(obj=>{
        return ({
          name: obj.clinicName,
          value: obj.id,
        })
      });
      this.providerResClinicIdsSave = res.clinics.map(obj=>{return obj.id});
    }
    else {
      const assignedClinics = JSON.parse(localStorage.getItem('optplanner_user')).assignedClinics;
      providerUser.firstName  = '';
      providerUser.providerSkillsForms = [];
      providerUser.lastName  = '';
      providerUser.phone = '';
      providerUser.documentId = '';
      providerUser.contractId = null;
      providerUser.jobId = 10;
      providerUser.email = '';
      providerUser.gender ='prefer not to say';
      providerUser.employeeId = null;
      providerUser.employeeTypeId = null;
      this.url = null;
      assignedClinics.forEach((obj)=>{
        if(obj.value == JSON.parse(localStorage.getItem('assignedClinic'))){
          providerUser.clinicIds = [{
            name: obj.label,
            value: obj.value,
          }];
          this.providerResClinicIdsSave = [obj.value];
        }
      });
      providerUser.state= '';
      providerUser.street= '';
      providerUser.zipCode= '';
      providerUser.country= 'USA';
      providerUser.city='';
      providerUser.payroll = "";
      this.getSkillByJob({value:providerUser.jobId})
    }
    this.onClinicChange();
  }

  addScroll() {
    this.profileSection = false;
    this.overlay = false;
    this.teamSection = false;
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.profileDataSubscription) {
      this.profileDataSubscription.unsubscribe();
    }
    if (this.openSocialMedialLinkSubs) {
      this.openSocialMedialLinkSubs.unsubscribe();
    }
    if (this.addConnectionSubs) {
      this.addConnectionSubs.unsubscribe();
    }
    this.updatePlaybookSubscription && this.updatePlaybookSubscription.unsubscribe();
    this.profileSection = false;
    this.teamSection = false;
    this.displayBasic=false;
    this.skillsSelectedValues = [];
    this.overlay = false;
    this.appExService.callActivityData = [];
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (event.target.closest('.overlay') && !event.target.closest('.profileSection')) {
      this.profileSection = !this.profileSection;
      this.overlay = !this.overlay;
      this.addScroll();
    }
  }

  onSelectFile(event) {
    const fileObj = event.target.files[0]
    if (event.target.files && fileObj) {
      const ap = this.appService;
      const ms = this.messageService;
      const reader = new FileReader();
      ap.showCustomLoader(true);
      reader.readAsDataURL(fileObj); // read file as data url
       reader.onload = (event) => { // called once readAsDataURL is completed
          const formData = new FormData();
          formData.append('image', fileObj);
          this.httpclient.post<any>(config.uploadImage, formData).subscribe((response) => {
            // this.url = response.data.path;
            this.url = response.data.documentId;
            this.providerUser.documentId = response.data.documentId;
           
            ap.showCustomLoader(false);
            ms.add({ severity: 'success', summary: 'Success', detail: response.message });
            let img = new Image();
            img.onload = function() {
             }
            img.src = this.url;
            
          }, (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
          });
      }
    }
  }


  createprovider(form) {
    if(form.submitted){
      this.submitted = true
    }
    if(form.form.valid){
    const successMessage = this.edit ? 'updated' : 'created';
    const form = this.providerUser;
    const payload = {
      ...form,
      clinicIds: form.clinicIds.map(obj=>obj.value),
      payroll: form.payroll === "ADP" ? "ADP" : null
    };
    if(this.edit){
      this.providerSavedPayload = payload;
      let checkClinicIDs = [];
      this.providerResClinicIdsSave.map(obj=>{
        checkClinicIDs.push(payload.clinicIds.includes(obj))
        
      });
      if(checkClinicIDs.includes(false)){
        const data = {
          type:'provider',
          clinicIds:payload.clinicIds,
          sectionId:this.providerUser.id
        };
        this.providerService.getPatientPastDataAPI(data).subscribe((response) => {
          this.providerPastData = response.data;
          this.warnProviderClinicChangePopup = true;
          
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });

        
      }else{
        this.afterWarnCreateProvider(payload);
      }
    }else{
      this.afterWarnCreateProvider(payload);
    }   
  }
  }

  afterWarnCreateProvider(payload){
    if(this.edit){
      this.providerService.updateProvider(payload).subscribe((response) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });
        this.addScroll();
        this.updateTableData.emit({success:true, id: this.providerUser.employeeId, edit: true});
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
    }
    else{
      delete payload["resetPasswordMail"];
      this.providerService.addProvider(payload ).subscribe((response) => {
        let addedProviderName = response.body.data?.providerName;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });
        this.providerSavedPayload = payload;
        let checkClinicIDs = [];
        this.providerResClinicIdsSave.map(obj=>{
          checkClinicIDs.push(payload.clinicIds.includes(obj))
          
        });
        if(checkClinicIDs.includes(false)){
          this.anothercliniclists = this.providerUser.clinicIds;
          this.providerAddAnotherClinicPopup = true;
        }
        this.addScroll();
        this.updateTableData.emit({success:true, id: this.providerUser.employeeId, providerName:addedProviderName, edit: false});
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
    }
  }
  showBasicDialog() {
    if (!this.displayBasic) {
        this.addSkillsList = [];

        if (this.providerUser.providerSkillsForms.length > 0) {
            let first = [];
            this.addSkillsList = this.providerUser.providerSkillsForms.map(obj => ({
                ...obj,
                disabled: true // Disable existing skills
            }));

            this.addSkillsList.forEach(obj => {
                if (obj.skillId) {
                    first.push(1);
                }
            });

            if (this.addSkillsList.length <= first.length) {
                this.addSkillsList.push({
                    "priority": 1,
                    "skillId": null,
                    "disabled": false // New entries should be enabled
                });
            }
        } else {
            this.addSkillsList.push({
                "priority": 1,
                "skillId": null,
                "disabled": false
            });
        }

        this.displayBasic = true;
        this.skillsSelectedValues = [];
        this.skillFormData = {
            "priority": 1,
            "skillId": null
        };

        this.addSkillsList.forEach((obj, i) => {
            if (obj.skillId !== null) {
                this.skillsSelectedValues[i] = obj.skillId;
            }
        });
    }
}
skillDeleteIndex(values,i){
  if (this.userRole === 'superAdmin') {
    this.skillIndex = i;
  const skillType  = this.listSkills.filter((obj)=>{return obj.value === values.skillId})[0].label
  if(skillType == 'Behavioral Supervision'|| skillType =='Occupation Individual Therapy' || skillType =='Speech Individual Therapy'){
    this.deleteSkillSpecialType = true
    skillType == 'Behavioral Supervision' ? this.isBcba=true : this.isBcba=false;
  }else{
    this.deleteSkillSpecialType = false
  }
  this.removeSkillPop=true
  }
}
onRemoveSkill(){
  // this.providerUser.providerSkillsForms.splice(i,1);
  this.providerUser.providerSkillsForms.splice(this.skillIndex,1);
  this.providerUser.providerSkillsForms = this.providerUser.providerSkillsForms.map(obj => obj);
  this.pageContainingForm.control.markAsDirty();
  console.log("Removed skills");
  this.removeSkillPop = false
}
onRemoveSkillAdd(i){
  this.addSkillsList.splice(i,1);
  this.addSkillsList = this.addSkillsList.map(obj => obj);
}
  onSaveClick(){
    this.displayBasic=false;
    this.skillsSelectedValues = [];
    this.assignValues('onSaveClick');
    this.pageContainingForm.control.markAsDirty();
    
  }
  onAddSkills(event,i){
    console.log(this.addSkillsList,i);
    this.skillsSelectedValues[i] = event.skillId;
    let first = [];
    if(this.addSkillsList.length > 0){
      if(this.addSkillsList[i].skillId != null){
        this.skillDisable = false;
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select a skill.' })
      }
      this.addSkillsList.forEach(obj=>{
        if(obj.skillId){   
          first.push(1);
        }
      });
      if(this.addSkillsList.length <= first.length){
        this.addSkillsList.push({
          "priority": 1,
          "skillId": null,
        });
      }
    }
  }
  onProviderAddSkills(event,i){

    const data = this.listSkills.filter((obj)=>{return obj.value === event.value})[0].label;

    if(data == 'Behavioral Supervision'|| data =='Occupation Individual Therapy' || data =='Speech Individual Therapy'){
      this.providerUser.providerSkillsForms[i].priority = 0;
    }else{
      if(this.providerUser.providerSkillsForms[i].priority === 0){
        this.providerUser.providerSkillsForms[i].priority = 1;
      }
    }
    
  }

  onProviderAddSkillsForm(event,i){
    
    const data = this.listSkills.filter((obj)=>{return obj.value === event.value})[0].label;

    if(data == 'Behavioral Supervision'|| data =='Occupation Individual Therapy' || data =='Speech Individual Therapy'){
      this.addSkillsList[i].priority = 0;
    }else{
      if(this.addSkillsList[i].priority === 0){
        this.addSkillsList[i].priority = 1;
      }
    }
    
  }

  onSaveCloseClick(){
    this.displayBasic=false;
    this.skillsSelectedValues = [];
    this.assignValues('onSaveCloseClick');
    this.pageContainingForm.control.markAsDirty();
  }
  assignValues(caller){
    const values = [];    
    this.addSkillsList.map(obj=>obj).forEach((element) =>{
      if(element.skillId){
          if(this.specialSkillIds.includes(element.skillId)){
            values.push(element.skillId);
          }
      }
    });
    if(values.length > 1){
      this.displayBasic=true;
      this.skillsSelectedValues = [];
      this.messageService.add({ severity: 'error', summary: 'Error', detail: ' Cannot add more than one special skill.' })
    }else{
      this.providerUser.providerSkillsForms = [];
      this.addSkillsList.map(obj=>obj).forEach(element => (element.skillId) ? this.providerUser.providerSkillsForms.push(element) : '');
    }
    
  }

  getJobTitleList(){
    this.providerService.getJobTitleAPI().subscribe((response: any) => {
      console.log(response);
      
      if (response.data) {
        this.listJobTitle = response.data.map(obj=>{
          if (obj.title === 'NEW HIRED BT') {
            obj.title = 'New Hired BT';
          }
          return {label: obj.title, value: obj.id}
        });
      }
    });    
  }

  getSkillByJob(data){
    this.appService.showCustomLoader(true);
    this.jobConfigService.getJobConfigureDetailAPI(data.value).subscribe((response)=>{
      let data = response?.data;
      if(data){
        data.jobTitleSkillDtos.map(obj=>{
          if(this.providerUser.providerSkillsForms.filter((objj)=>{ return obj.skill.id === objj.skillId}).length <= 0){
            this.providerUser.providerSkillsForms.push({
              "priority": obj.priority,
              "skillId": obj.skill.id,
            })
          }
        });
      }
      this.appService.showCustomLoader(false);
    },(error)=>{
      this.appService.showCustomLoader(false);
      this.messageService.add({severity:'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  getClinicList(data) {
    this.listClinic = JSON.parse(localStorage.getItem('optplanner_user')).assignedClinics.map(obj=>{
      return {
        name: obj.label,value:obj.value
      };
    });
    this.listClinicIDs = this.listClinic.map(o=>o.value);
  }

  getSkillList(data) {
    this.skillsSevice.getSkills(data).subscribe((response: any) => {
      if (response.data) {
        this.listSkills = response.data.map(obj=>{
          if(obj.skillName == 'Behavioral Supervision'|| obj.skillName =='Occupation Individual Therapy' || obj.skillName =='Speech Individual Therapy'){
            this.specialSkillIds.push(obj.id)

          }
          return {label: obj.skillName, value: obj.id}
        });
        this.listSkills.unshift({label: '--Select--', value: null})
      }
    });
  }

  getSkillTypeId(){
    this.providerService.getSkillType().subscribe((response:any)=>{
      if (response.data) {
        this.listSkilltype = response.data.map(obj=>{
          return {label: obj.type, value:obj.id}
        });
        this.listSkilltype.unshift({label: '--Select--', value: null})
      }
    });
  }
  onClinicChange(){
    // this.providerUser.contractId = null;
    
    if(this.providerUser.clinicIds.length > 0){
      const list = this.providerUser.clinicIds.map(obj=>obj.value).join(",")
      this.getContractList(list);
    }
    else{
      this.listContracts = [];
    }
  }
  getContractList(data){
    this.contractsService.getContractList(data).subscribe((response:any)=>{
      if (response.data) {
        this.listContracts = response.data.map(obj=>{
          return {label: obj.name, value:obj.value}
        });
      }
    });
  }
  checkCity(e){
    let regex = /^[A-Za-z/\s]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }

  checkState(e){
    let regex = /^[A-Za-z/\s]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }

  checkStreet(e){
    let regex = /^[A-Za-z0-9/\s/.,///-]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }


  checkFirstName(e){
    let regex = /^[A-Za-z-/\s]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }

  checkLastName(e){
    let regex = /^[A-Za-z/\s]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }

  checkEmail(e){
    let regex = /^[A-Za-z0-9\.\@\+\_]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }

  checkZipCode(e){
    console.log(e);
    console.log(this.providerUser.zipCode)
    if(e.keyCode !==8 && this.providerUser.zipCode && this.providerUser.zipCode.toString().length == 14) {

      return false
    }

    let regex = /^[A-Za-z0-9-]+$/
    if(!regex.test(e.key)) {
      return false
    } 
  }

  profilePhotoPopupClose(){
    this.profilephoto = false;
    this.saveCropImgBtn = false;
    this.uploadCropImgBtn = true;
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.previousImgShow = true;

  }

  profilePhotoPopupSave(){
    this.appService.showCustomLoader(true);

    const fileObj = base64ToFile(this.croppedImage)
    
    if (fileObj) {
      console.log(fileObj);
      
      const reader = new FileReader();
      reader.readAsDataURL(fileObj); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
          const formData = new FormData();
          formData.append('image', fileObj);      

          
          this.httpclient.post<any>(config.uploadImage, formData).subscribe((response) => {
            
            // this.url = response.data.path;
            this.url = response.data.documentId;
            this.providerUser.documentId = this.url;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Image uploaded successfully' });
            this.pageContainingForm.control.markAsDirty();
            this.appService.showCustomLoader(false);
            this.saveCropImgBtn = false;
            this.uploadCropImgBtn = true;
            this.previousImgShow = true;
            this.profilephoto = false;
            this.croppedImage = '';
            this.imageChangedEvent = '';


          }
          , (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
            this.appService.showCustomLoader(false);
          });
      }
    }

  }

  /** Crop Image Functions Start Here... */

  fileChangeEvent(event: any): void {  
    var url = event.target.files[0].name;
    var urlExt = url.substr(url.lastIndexOf('.') + 1);
    if(urlExt === 'png' || urlExt === 'jpeg' || urlExt === 'jpg'){
      if(event.target.files[0].size < 2000000){
        this.appService.showCustomLoader(true);
        this.imageChangedEvent = event;
        if(this.imageChangedEvent != ''){
          this.uploadCropImgBtn = false;
          this.saveCropImgBtn = true;
          this.previousImgShow = false;
        }
      }
      else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Image size must be less than 2mb' });
        
      }
    }
    else{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload png / jpg / jpeg file' });
    }
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      // console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
      this.showCropper = true;
      this.appService.showCustomLoader(false);
      console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
      // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
      console.log('Load failed');
  }
}
	