import { Utils } from '../../utils';
import { Component, HostListener, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from '../../app.service';
import { MessageService } from 'primeng/api';
import { ProviderService } from '../provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CalendarOptions } from '@fullcalendar/angular';
import { environment } from 'src/environments/environment';
import { PatientService } from 'src/app/patient/patient.service';
import { SkillsService } from 'src/app/skills/skills.service';
import { AppExService } from 'src/app/app-ex.service';
import { config } from 'src/app/config';
import { SchedulingService } from 'src/app/scheduling/scheduling.service';
import { SettingService } from 'src/app/setting/setting.service';
// import {ProviderDynamicDialog} from './provider-dynamic-dialog';
import {DynamicDialog} from '../../scheduling/dynamicdialog';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import tippy from 'tippy.js';
import { Calendar } from 'primeng/calendar';
import { Table } from 'primeng/table';
import { ProviderDetailAdditionalData } from '../provider.model';
@Component({
  selector: 'app-provider-details',
  templateUrl: './provider-details.component.html',
  styleUrls: ['./provider-details.component.scss'],
  providers: [DialogService]
})
export class ProviderDetailsComponent implements OnInit {
  changedIndex:any;
  openMail = false;
  openMailSubs: Subscription;
  openTextMsg = false;
  openTextMsgSubs: Subscription;
  @ViewChild('actualTargetSolverdetail', { static: false }) actualTarget: HTMLDivElement;
  @ViewChild('containerwrapper', { static: false }) containerwrapper: HTMLDivElement;
  @ViewChild("fullWeek") fullWeek!: any;
  @ViewChild ('filterBoxElement') filterBoxElement:ElementRef;
  @ViewChild('pcalnedarDesk') pcalnedarDesk: Calendar;
  @ViewChild('assignPatientTable') assignPatientTable: Table;
  @ViewChild('dateRangeRef') dateRangeRef: Calendar

  id = null;
  showTeam = false;
  providerAdditionalDataArr = ProviderDetailAdditionalData;
  providerAdditionalDataFilter:any = [new Date(),new Date()];
  skillDisable = true;
  Tippyinstance:any
  selectedTeam = [];
  listProvider = [];
  providerData= null;
  startDate1 = null;
  endDate1 = null;
  viewType = 'basic';
  selectedEvent = null;
  showEvent = false;
  showEventData = null;
  savedPayload = null;
  showIcon = true;
  UnavailableEventdeletePopup = false;
  cancelSessionPopup = false;
  cancellingReason = null;
  cancellingSessionReason = null;
  viewTypeScudule = 'current';
  patientDeleteConfirmBox:Boolean = false;
  patientDeleteConfirmBoxid:any;
  patientDeleteConfirmBoxindex:any;
  displayBasic:Boolean = false;
  addSkillsList = [];
  calendarRerenderSubs: Subscription;
  Customtitle:any;
  CustoTodayBtn:Boolean = true;
  disableDates:any = [];
  CalendarFirstWeekDate:any;
  EventStatusType:any;
  clinicStartTime:any
  clinicEndTime:any
  clinicId:any
  aprvDisprvPopup = false;
  deleteSkillSpecialType = false;
  removeSkillPop = false
  skillIndex:any;
  aprvDisprvRecurencePopup = false;
  aprvDisprvisRecurence = false;
  AprvPopupSection = false;
  DisprvPopupSection = false;
  aprvDisprvEventid:any;
  aprvDisprvComment:any = '';
  showOperations = false;
  cstmReccurenceDetailHeader:any;
  cstmReccurenceDetailPopup = false;
  cstmReccurenceDetailData:any;
  planned_count:any = 0;
  escalated_count:any = 0;
  isBcba:Boolean=false;
  cstmReccurenceDetailDataColumns = [
    { field: 'srno', header: 'S.No' },
    { field: 'startDate', header: 'Start Date' },
    { field: 'startTime', header: 'Start Time' },
    { field: 'endTime', header: 'End Time' },
  ];
  sessionDataColumns = [
    { field: 'srno', header: 'S.No' },
    { field: 'shiftDate', header: 'Shift Date' },
    { field: 'startTime', header: 'Start Time' },
    { field: 'endTime', header: 'End Time' },
    { field: 'patientName', header: 'Patient Name' },
    // { field: 'providerName', header: 'Provider Name' },
    { field: 'sessionType', header: 'Session Type' },
  ];
  ListViewTableData:any = [];
  checkBoxItem =0;
  checkBox_count:Array<any> = [];
  checkBoxArr: Array<any> = [];
  checkBoxArrId: Array<any> = [];
  ListViewToggle:Boolean = false;
  CalendarFirstWeekDateindex = 1;
  listpriority=[
    {label:'1',value:1},
    {label:'2',value:2},
    {label:'3',value:3},
    {label:'4',value:4},
    {label:'5',value:5},
    {label:'6',value:6},
    {label:'7',value:7},
    {label:'8',value:8},
    {label:'9',value:9},
    {label:'10',value:10},
  ];
  listSkills:any[]=[];
  providerSkillsFormsData:any[] = [];
  listSkilltype = [
    { label: 'Current', value: 'current' },
    { label: 'Upcoming With Recurrence', value: 'upcomingWithRecurrence' },
  ];
  listExcusetype = [
    { label: 'Execused', value: true },
    { label: 'UnExecused', value: false },
  ];
  listExcuse = true;
  listSkilltypeApprvDny = [
    { label: 'Current', value: 'current' },
    { label: 'Upcoming With Recurrence', value: 'upcomingWithRecurrence' },
  ];
  listSkilltypeValue = ['current'];
  weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  viewUnavailabilityTypeRecurrence:any;
  AssignedPatientTableColumns = [
    // { field: 'srno', header: 'S.No' },
    { field: 'name', header: 'Name' },
    { field: 'email', header: 'Email' },
    { field: 'number', header: 'Phone No.' },
    { field: 'address', header: 'Address' },
    { field: 'patientActive', header: 'Status' },
    { field: 'action', header: 'Action' },
  ];

  highlightFilter:Boolean = true;
  onApplyFilterBtn:Boolean = false;

  isDateSelected:boolean = true;
  APPROVEDToolTipData:any;
  PENDINGToolTipData:any;
  weekDateFilterData = new Date();
  imgBaseURL = config.baseUrl;
  isMobileView:Boolean = false;
  isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
  isTabView = window.innerWidth > 600 && window.innerWidth <= 800;
  filterCols = [
    { label: 'All', value: 'ALL' },
    { label: 'Individual & Group', value: 'I&G' },
    { label: 'BCBA', value: 'BCBA' },
    { label: 'OT', value: 'OT' },
    { label: 'Speech', value: 'SPEECH' },
    { label: 'Planned', value: 'PLANNED' },
    { label: 'Escalated', value: 'ESCLATED' },
    { label: 'Unavailability', value: 'UNAVAILABILITY' },
    { label: 'Pending Unavailability', value: 'PENDINGUNAVAILABILITY' },
    { label: 'Male', value: 'MALE' },
    { label: 'Female', value: 'FEMALE' },
  ];

  lastChecked:any =null;
  statusChecked:any = null;
  genderChecked:any = null;
  solverType = [
    { label: 'Individual & Group', value: 'I&G',checked:false },
    { label: 'BCBA', value: 'BCBA',checked:false },
    { label: 'OT', value: 'OT',checked:false },
    { label: 'Speech', value: 'SPEECH',checked:false },
  ]
  sessionStatus = [
    { label: 'Planned', value: 'PLANNED',checked:false },
    { label: 'Escalated', value: 'ESCLATED',checked:false },
    { label: 'Unavailability', value: 'UNAVAILABILITY',checked:false },
    { label: 'Pending Unavailability', value: 'PENDINGUNAVAILABILITY',checked:false },
    { label: 'Is-Pinned Only', value: 'IsPin',checked:false },
  ]
  genderChoice = [
    { label: 'Male', value: 'MALE',checked:false },
    { label: 'Female', value: 'FEMALE',checked:false },
    // { label: 'Prefer not to say', value: 'PREFER',checked:false },
  ]
  statusOptionsAll = [
    { label: 'All', value: null },
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];
  AssignPateintfilterStatus = true;

  filterCheckedData = {
    solverType:null,
    sessionStatus:null,
    genderChoice:null,
  }

  filterDataCols = 'ALL';
  EventsDataArr:any = [];
  getHolidays: any[] =[];
  upcomingHolidayData:any;
  prvdrAddtnlFilterChangeDetect:boolean = false;

  invalidSymbol = ["$","~","@","%","^","&","*",
  "(",")","{","}","!","[","]","=","+","-",
  "<",">","/","?",";",":","_","`","#", "'","|", "\""
  ];
  routerNavigateSelectors:boolean = false;
  dynamicDialogref: DynamicDialogRef;
  filterbox:boolean = false;
  ListViewTableTotal = 0;
  tablePaginatorFirst: number = 0;
  ProviderAccessSolverTypes:any = [];
  IndividualGroupToolTipData:any;
  OTToolTipData:any;
  BCBAToolTipData:any;
  SpeechToolTipData:any;
  pad_Filter_fromDate:any;
  pad_Filter_toDate:any;
  first_Load = 0;
  skillsSelectedValues = [];




  calendarOptionsWeekview: CalendarOptions = {
    schedulerLicenseKey: environment.schedulerLicenseKey,
    firstDay: 1,
    initialView: 'timeGridWeek',
    eventBackgroundColor: '#041D5B',
    // locale: 'en-GB',
    eventBorderColor: 'transparent',
    allDaySlot:false,
    editable: true,  
    eventDrop: this.popup.bind(this),
    eventResize: this.popup.bind(this),
    displayEventTime: true,
    eventTextColor: '#fff',
    slotDuration: '01:00',
    slotMinTime: "07:00:00",
    slotMaxTime: "20:30:00",
    eventMouseEnter: ({ el,event })=> {
      const elm = el;
      let title = '';
      if(!elm.className.includes('HolidayClass')){
        if (elm.children[2] && (elm.children[2].innerHTML)) {
          title = elm.children[2].innerHTML;
        }
        if (title === '') {
          title = elm.children[0].children[0].children[0]["innerText"];
        }
        if(!this.isMobileView && !this.isTabView){
          this.Tippyinstance = tippy(elm, { content: title+' '+event._def.title  });
          this.Tippyinstance.show();
        }
      }
    },
    eventMouseLeave : ()=> {
      if(!this.isMobileView && !this.isTabView){
        this.Tippyinstance.destroy();
      }
    },
    allDayText: 'All-day',
    slotLabelFormat: [
      {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        meridiem: 'lowercase',
        week: 'numeric'
      }
    ],
    eventTimeFormat: { // like '02:30:pm'
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      meridiem: 'lowercase',
    },
    customButtons: {
      next: {
        text: 'Next',
        click: this.nextWeek.bind(this),
      },
      prev: {
        text: 'Prev',
        click: this.prevWeek.bind(this),
      },
      today: {
        text: "Today",
        click: this.currentWeek.bind(this),
      },
    },
    events: [],
    eventClick: this.handleEventClick.bind(this), // 
  };

  dialogShow(event,isNewResource,cc) {
    console.log(cc);
    this.dynamicDialogref = this.dialogService.open(DynamicDialog, {
          data: {
          customRecurrence:this.customRecurrence,
          newResource: isNewResource,
          isPinned: event.event.extendedProps.isPinned,
          recurrenceType: event.event.extendedProps.recurrence
        },
        header: 'Confirmation',
        width: '70%',
        closable: true,
        styleClass: 'dynamicDialogStyle',
        contentStyle: {"max-height": "500px", "overflow": "auto"},
        baseZIndex: 10000
    });

    this.dynamicDialogref.onClose.subscribe((response) =>{
      // this.dropEventUpdate(event,response.isPinnedstatus,response.recorrenceStatus);
      if(response.status){
        if(event.event.extendedProps.isPinned || event.event.extendedProps.recurrence === 'customRecurrence'){
          // this.dialogShow(event)
          this.dropEventUpdate(event,true,response.recorrenceStatus)
        }else{
          this.dropEventUpdate(event,event.event.extendedProps.isPinned,this.customRecurrence)
        }
      }else{
        event.revert();
      }
    });
  }
  customRecurrence:Boolean;
  popup(event){
    if(!this.isMobileView){
      this.Tippyinstance.destroy();
    }
    if(event.event.extendedProps.recurrence === 'customRecurrence'){
      this.customRecurrence = true;
    }
    else{
      this.customRecurrence = false;
    }
    if(event.event.extendedProps.recurrence === 'customRecurrence'){
      this.dialogShow(event,false,1);
      // this.dropEventUpdate(event,true,false)
    }else{
      this.dropEventUpdate(event,event.event.extendedProps.isPinned,this.customRecurrence)
    }
  }

  dropEventUpdate(event,isPinnedres,isRecorrenceRes){
    
    const UpdateNewEventPayload = {
      customRecurrenceForm:{
      }
    };
    const CurrnetEventId = event.event.extendedProps.eventId;
    const NewEventStart = event.event.start;
    const NewEventEnd = event.event.end;
    
    this.appService.showCustomLoader(true);
    this.schedulingService.getSchedulingById(CurrnetEventId).subscribe((response: any) => {
      const data = response.data;
      
      if(data){
        if(isRecorrenceRes){
          // if(isRecorrenceRes != 'doNotRepeat'){
          if(data.customRecurrenceDTO.hasOwnProperty("weekDays")){
            const week = data.customRecurrenceDTO.weekDays.map((obj)=>{ return obj.type});
            data.customRecurrenceDTO.weekDays = [...week];
          }
          UpdateNewEventPayload.customRecurrenceForm = data.customRecurrenceDTO;
          // UpdateNewEventPayload["allRecurrencePinned"] = true;
        }else{
          delete UpdateNewEventPayload.customRecurrenceForm;
        }

        UpdateNewEventPayload["startTime"] = moment(NewEventStart, ["h:mm A"]).format('kk:mm:ss');
        UpdateNewEventPayload["endTime"] = moment(NewEventEnd, ["h:mm A"]).format('kk:mm:ss');
        UpdateNewEventPayload["id"] = Number(data.id);
        UpdateNewEventPayload["isPinned"] = isRecorrenceRes ? false : isPinnedres;
        UpdateNewEventPayload["placeOfServiceId"] = Number(data.placeOfServiceDTO.id);
        UpdateNewEventPayload["recurrence"] = isRecorrenceRes ? "customRecurrence" : "doNotRepeat";
        UpdateNewEventPayload["sessionSkillForm"] = data.sessionSkillForm;
        UpdateNewEventPayload["shiftDate"] = moment(NewEventEnd).format('YYYY-MM-DD');
        UpdateNewEventPayload["spotId"] = Number(data.spot.id);
        UpdateNewEventPayload["type"] = isRecorrenceRes ? "upcomingWithRecurrence" : "current";

        UpdateNewEventPayload["patientId"] = data.hasOwnProperty("patient") ? Number(data.patient.id) : -1;
        UpdateNewEventPayload["providerId"] = data.hasOwnProperty("patient") ? Number(data.provider.id) : -1;

        this.schedulingService.updateScheduling(UpdateNewEventPayload).subscribe((response) => {
          this.onClear();
          this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });
          
        }, (error) => {

          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
          event.revert();
          this.appService.showCustomLoader(false);

        },()=>{

          // this.schedulingService.getSchedulingById(CurrnetEventId).subscribe((response: any) => {
          // const data = response.data;

          //   event.event._def.ui.backgroundColor = data.isEscalated === true ? '#802000' : '#0bb453',
          //   event.event._def.ui.textColor = data.isEscalated === true ? 'white' : '#000000',
  
          //   event.event.setExtendedProp('escalatedReason', data.escalatedReason ? data.escalatedReason : null);
          //   event.event.setExtendedProp('startTime',moment(data.startTime, ["h:mm A"]).format('kk:mm:ss'));
          //   event.event.setExtendedProp('endTime',moment(data.endTime, ["h:mm A"]).format('kk:mm:ss'));
          //   event.event.setExtendedProp('shiftDate',moment(data.shiftDate).format('YYYY-MM-DD'));
          //   event.event.setExtendedProp('isPinned', data.isPinned);
          //   event.event.setExtendedProp('recurrence', data.recurrence);

          //   //this.appService.showCustomLoader(false);

          //   const payload = {
          //     fromDate: this.savedPayload.fromDate,
          //     toDate: this.savedPayload.toDate,
          //     providerId: data?.provider.id,
          //     sectionType: 'provider'
          //   }
            
          //   this.schedulingService.getCount(payload).subscribe((res)=>{
          //     const counts = res.data;

          //       this.escalated_count = counts.escalatedCount;
          //       this.planned_count = counts.plannedCount;
              
          //   });
          // });
          //this.appService.showCustomLoader(true);
          setTimeout(() => {
            this.onProviderChange({value:this.id});
            // this.appService.showCustomLoader(false);
          }, 4000); // for 3seconds
        });
      }
    }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        event.revert();
        this.appService.showCustomLoader(false);
    });
  }
  onProviderAddSkillsForm(event,i){
    
    const data = this.listSkills.filter((obj)=>{return obj.value === event.value})[0].label;

    if(data == 'Behavioral Supervision'|| data =='Occupation Individual Therapy' || data =='Speech Individual Therapy'){
      this.addSkillsList[i].priority = 0;
    }else{
      if(this.addSkillsList[i].priority === 0){
        this.addSkillsList[i].priority = 1;
      }
    }
    
  }
  nextWeek(e) {
    this.filterbox = false;
    this.fullWeek.calendar.next();
    let d = moment(this.CalendarFirstWeekDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let Current = moment(this.fullWeek.calendar.currentData.dateProfile.currentRange.start, "YYYY-MM-DD").format("YYYY-MM-DD");  
    if(d !== Current){
      this.CustoTodayBtn = false;
    }else if(d === Current){
      this.CustoTodayBtn = true;
    }
    this.id &&
    this.onProviderChange({value:this.id});
  }
  prevWeek(e) {
    this.filterbox = false;
    this.fullWeek.calendar.prev();
    let d = moment(this.CalendarFirstWeekDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let Current = moment(this.fullWeek.calendar.currentData.dateProfile.currentRange.start, "YYYY-MM-DD").format("YYYY-MM-DD");         
    if(d !== Current){
      this.CustoTodayBtn = false;
    }else if(d === Current){
      this.CustoTodayBtn = true;

    }
    this.id &&
    this.onProviderChange({value:this.id});
  }
  currentWeek(e) {
    this.filterbox = false;
    this.pcalnedarDesk.onClearButtonClick('event');
    
    this.fullWeek.calendar.today();
    let d = moment(this.CalendarFirstWeekDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let Current = moment(this.fullWeek.calendar.currentData.dateProfile.currentRange.start, "YYYY-MM-DD").format("YYYY-MM-DD");   
    if(d === Current){
      this.CustoTodayBtn = true;
    }
    this.id &&
    this.onProviderChange({value:this.id});
  }
  WeekDateFilter(e){
    this.filterbox = false;
    this.fullWeek.calendar.gotoDate(e);
    let d = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
    this.onProviderChange({value:this.id});
    let Current = moment(this.fullWeek.calendar.currentData.currentDate, "YYYY-MM-DD").format("YYYY-MM-DD");      
    if(d !== Current){
      this.CustoTodayBtn = false;
    }else if(d === Current){
      this.CustoTodayBtn = true;

    }
  }
  getCalendarDateRange() {
    if(this.viewType == 'session'){
      setTimeout(() => {
        this.Customtitle = this.fullWeek.calendar.currentData.viewTitle;  
      }, 100);    
      if(this.CalendarFirstWeekDateindex === 1){
        this.CalendarFirstWeekDateindex++;
        this.CalendarFirstWeekDate = this.fullWeek.calendar.currentData.dateProfile.currentRange.start;
      }
    }
    const curr = new Date(this.fullWeek ? this.fullWeek.calendar.currentData.currentDate : new Date());
    
    let checkFirstDate = curr;
    checkFirstDate.setFullYear(checkFirstDate.getFullYear(), checkFirstDate.getMonth(), checkFirstDate.getDate() - Math.abs((curr.getDay() != 0 ? curr.getDay()-1 : curr.getDay()-6)))
    // console.log(new Date(checkFirstDate));
    
    // const first = ((curr.getDay()) - curr.getDate()); // First day is the day of the month - the day of the week
    // console.log(first);
    
    // const last = first + 6; // last day is the first day + 6
    // const firstdays = new Date(curr.setDate(first)).toUTCString();

    const firstdays = new Date(checkFirstDate);
    let lastdays = new Date(firstdays);
    lastdays.setFullYear(lastdays.getFullYear(), lastdays.getMonth(), lastdays.getDate() + 6);


    // const lastdays = new Date(curr.setDate(last)).toUTCString();
  
    const firstday = this.fullWeek ? this.fullWeek.calendar.currentData.dateProfile.activeRange.start  : firstdays;
    const lastday = this.fullWeek ? moment(this.fullWeek.calendar.currentData.dateProfile.activeRange.start, "YYYY-MM-DD").add(6, 'days').format("YYYY-MM-DD")  : lastdays;
        
      this.providerAdditionalDataFilter[0] = firstday;
      this.providerAdditionalDataFilter[1] = lastday;
      this.dateRangeRef?.updateInputfield();
    
  this.disableDates = this.enumerateDaysBetweenDates(moment(firstday).format('YYYY-MM-DD'),moment(lastday).format('YYYY-MM-DD'));
    
      return { 
        fromDate: moment(firstday).format('YYYY-MM-DD'), 
        toDate: moment(lastday).format('YYYY-MM-DD'),
      };
  }

  enumerateDaysBetweenDates(startDate, endDate) {
    let dates = [];

    let currDate = moment(startDate).startOf('day');
    let lastDate = moment(endDate).startOf('day');
    dates.push(currDate.clone().toDate());
    while(currDate.add(1, 'days').diff(lastDate) <= 0) {
        dates.push(currDate.clone().toDate());
    }

    return dates;
  }

  onProviderChange(e){
    const dates = this.getCalendarDateRange();
    const payload = {id: e.value,...dates};
    this.savedPayload = payload
    this.showEvent = false;
    this.getProvider(payload);
  }

  handleDateClick(arg) {
    if(this.id){
      arg.stopPropagation();
      this.onProviderChange({value:this.id});
    }
  }

  openDeleScheduling (){
    // this.showEventSchdule = true;
    // this.showEvent = false;
    // this.showEvent = false;
    // this.viewTypeScudule = 'current';
  }

  changeViewTab(type){
    this.viewType = type;
    localStorage.ProviderDetailsViewTypeTab = this.viewType;
    setTimeout(() => {
      if(this.viewType == 'session'){
        this.Customtitle = this.fullWeek.calendar.currentData.viewTitle;      
        if(this.CalendarFirstWeekDateindex === 1){
          this.CalendarFirstWeekDateindex++;
          this.CalendarFirstWeekDate = this.fullWeek.calendar.currentData.dateProfile.currentRange.start;
        }
      }
    }, 1100);
  }

  ngAfterViewInit(): void {
    this.loadEvents();
    if(this.id){
      setTimeout(() => {
        this.onProviderChange({value:this.id});
        this.getProviderAdditionalDetails(this.id,'default');
      }, 5);
    }
    this.calendarRerenderSubs = this.appExService.CalendarToRerender$.subscribe(data => {
      this.reRenderCalendar();
    })
  }
  reRenderCalendar(){    
    setTimeout(() => {
      let calendarCall = this.fullWeek.getApi();
      calendarCall.render();
    }, 10);
  }
  changeDate(): void {
  }

  filterBoxToggle(){
    this.filterbox = !this.filterbox;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: any){
    const clickedInside = this.filterBoxElement?.nativeElement.contains(target);
    if (!clickedInside){
      if(this.filterbox){
        this.filterbox = true;
      }
    }
  }
  clickedOutside(event) {
    if (event.target.closest('.overlay') && !event.target.closest('.profileSection')) {
      this.showEvent = false;
    }
  }

  loadEvents(): void {
  }
  handleEventClick(e) {
    const props = e.event.extendedProps;
    
    if(props.status === 'approved'){
      this.EventStatusType = 'approved';
    }else if(props.status === 'pending' ){
      this.EventStatusType = 'pending';
    }else{
      this.EventStatusType = 'disApproved';
    }
    let cDate = new Date();   

    let pastTwoDayFromCurrent = cDate.setDate(cDate.getDate() - 2);
    let cNewDate = (new Date(pastTwoDayFromCurrent).toLocaleString('en-US'));

    let selectedSessionDate = moment(props.shiftDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let currentDatelocal = moment(new Date(cNewDate.split(",")[0]), "YYYY-MM-DD").format("YYYY-MM-DD");

    if(selectedSessionDate < currentDatelocal) {
      this.showIcon = false
    }
    else {
      this.showIcon = true;
    }
    this.showEventData = {
    ...e.event.extendedProps,
    backgroundColor: e.event.backgroundColor,
    eventid: props.id,
    unavailability: e.event.extendedProps.unavailability,
    providerSkills: e.event.extendedProps.providerSkills ? e.event.extendedProps.providerSkills.map(obj => obj.skill.skillName).join(", ") : ''
    };
    if(!this.showEventData.unavailability && !this.showEventData.holiday){
      this.showEventData["eventSessionSkills"] = this.showEventData.sessionSkills.map((obj)=>{
        return (obj.skill.skillName);
      });
      this.showEventData.eventSessionSkills.join(", ");
    }
    if(!props?.holiday){// hide on holiday event
      this.showEvent = true;
      this.openEventDetailsPopUp(e);
    }
  }
  viewWeek(): void {
  }

  detectDateFilterChange(event){
    this.prvdrAddtnlFilterChangeDetect = true;
    this.isDateSelected = false;
    let date = new Date(event);
      
    date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 6);
    
    this.providerAdditionalDataFilter[0] = event;
    this.providerAdditionalDataFilter[1] = date;
    this.filterProviderAdditionalDetails()
    this.dateRangeRef.overlayVisible = false
  }
 filterProviderAdditionalDetails(){  
  if(this.prvdrAddtnlFilterChangeDetect){
    this.pad_Filter_fromDate = moment(this.providerAdditionalDataFilter[0]).format('YYYY-MM-DD');
    this.pad_Filter_toDate = moment(this.providerAdditionalDataFilter[1]).format('YYYY-MM-DD');
    if(this.pad_Filter_fromDate && this.pad_Filter_toDate){
      this.getProviderAdditionalDetails(this.id,'filter');
    }
  }  
 }
 onBasicBtnClick(){
  let curr = new Date; // get current date
  let first = curr.getDate() - (curr.getDay()-1); // First day is the day of the month - the day of the week        
  let Weekfirstday = new Date(curr.setDate(first)).toUTCString();
  let date = new Date(Weekfirstday);
      
  date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 6);

  this.providerAdditionalDataFilter = [new Date(Weekfirstday),date];
  this.pad_Filter_fromDate = moment(this.providerAdditionalDataFilter[0]).format('YYYY-MM-DD');
  this.pad_Filter_toDate = moment(this.providerAdditionalDataFilter[1]).format('YYYY-MM-DD');
  if(this.pad_Filter_fromDate && this.pad_Filter_toDate){
    this.getProviderAdditionalDetails(this.id,'filter');
  }
  this.assignedPatientTableFilter();
 }
 getProviderAdditionalDetails(id,caller){
  this.appService.showCustomLoader(true)
  let payload = {
    id: id
  }
  if(caller === 'filter'){
    payload["fromDate"] = this.pad_Filter_fromDate;
    payload["toDate"] = this.pad_Filter_toDate;
  }else{
    payload["fromDate"] = this.savedPayload.fromDate;
    payload["toDate"] = this.savedPayload.toDate;
  }

  this.providerService.getProviderAddtitionalDetailsById(payload).subscribe((response)=>{
      
        if(response.data){
          this.providerAdditionalDataArr = response.data;
          this.prvdrAddtnlFilterChangeDetect = false;
        }
        this.appService.showCustomLoader(false)
    },(error)=>{
      this.messageService.add({severity:'error', summary: 'Error', detail: error.error ? error.error.message : 'Please select end date.' });
      this.appService.showCustomLoader(false)
    })
  }

  openEventDetailsPopUp(args) {
    this.selectedEvent = args.e;
    this.showEvent = true;
    let yPos = args["jsEvent"]["clientY"] - 35;
    setTimeout(() => {
      const ele = document.getElementById("actualTargetSolverdetail");
      let clttX = args["jsEvent"]["clientX"];
      const expectedWidth = clttX + 465;
      const expectedHeight = yPos + ele.clientHeight;
      if (expectedWidth > (window.innerWidth || document.documentElement.clientWidth)) {
        const normalizedCordidate = expectedWidth - window.innerWidth;
        clttX = clttX - normalizedCordidate - 100;
      }
      if (expectedHeight > (window.innerHeight || document.documentElement.clientHeight)) {
        const normalizedCordidate = expectedHeight - window.innerHeight;
        yPos = yPos - normalizedCordidate - 18;
      }
      document.getElementById("actualTargetSolverdetail").style.left = `${clttX}px`;
      document.getElementById("actualTargetSolverdetail").style.top = `${yPos}px`;
    }, 2);
  }



  constructor(
    public settingService: SettingService,
    public providerService: ProviderService,
    public messageService: MessageService,
    public dialogService: DialogService,
    public appService: AppService,
    readonly schedulingService: SchedulingService,
    public route: ActivatedRoute,
    public utils: Utils,
    public patientService: PatientService,
    public skillsSevice:SkillsService,
    public appExService: AppExService,
    public router: Router,
 ) { this.isMobileView = window.innerWidth <= 600;
  }
  holidayList:any
  ngOnInit() {
    this.first_Load = 0;
    this.holidayList = JSON.parse( localStorage.getItem('allHolidayList'))
    this.routerNavigateSelectors = true;
    this.clinicId = JSON.parse(localStorage.getItem('assignedClinic'));
    this.getSettings(this.clinicId);
    this.appService.updateHeaderName({ name: 'Providers', subName: 'Provider Details', count: 0 });
    this.route.queryParams.subscribe((params) => {
      this.id = parseInt(params.id);
      this.getPatientList({ page: 1, limit: 1000 , status:true, sortBy:'name'});
    });
    this.getSkillList({ page: 1, limit: 1000});
    if(localStorage?.providerListView){
      this.ListViewToggle = JSON.parse(localStorage?.providerListView);
    }
    if(localStorage?.ProviderDetailsViewTypeTab){
      setTimeout(() => {
        this.viewType = localStorage.ProviderDetailsViewTypeTab;
        this.changeViewTab(this.viewType)
      }, 1000);
    }
  }
  getSettings(cId){
    this.settingService.getServiceAPI(cId).subscribe((response)=>{
      let data = response.data;
      if(data){
        this.clinicStartTime = data.clinicStartTime
        this.clinicEndTime = data.clinicEndTime
      }
      let EndTime = data.clinicEndTime;
      let EndTimeHour = Number(EndTime.slice(0,2))+1;
      const DataEndTime = EndTime.replace(EndTime.slice(0,2),EndTimeHour.toString());
      
      this.calendarOptionsWeekview.slotMinTime = this.clinicStartTime
      this.calendarOptionsWeekview.slotMaxTime = DataEndTime.toString();
    },(error)=>{
      this.messageService.add({severity:'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  onSolverTypeChange(event:any,changedCheckbox:any){
    const checked = event.target.checked;
    if(checked){ 
    if( event.target !== this.lastChecked){
      if(this.lastChecked){
        this.lastChecked.checked = false;
      }
      changedCheckbox.checked = true;
      this.filterCheckedData.solverType = changedCheckbox.value;
    }else{
      this.filterCheckedData.solverType = changedCheckbox.value;
    }
  }
  else{
    this.filterCheckedData.solverType = null;
  }
    this.lastChecked = event.target;
  }

  onStatusChange(event:any,changedCheckbox:any){
    const checked = event.target.checked;
    if(checked){
    if( event.target !== this.statusChecked){
      if(this.statusChecked){
        this.statusChecked.checked = false;
      }
      changedCheckbox.checked = true;
      this.filterCheckedData.sessionStatus = changedCheckbox.value;
    }else{
      this.filterCheckedData.sessionStatus = changedCheckbox.value;
    }
  }
  else{
    this.filterCheckedData.sessionStatus = null;
  }
    this.statusChecked = event.target;
  }

  onGenderChange(event:any,changedCheckbox:any){
    const checked = event.target.checked;
    if(checked){
    if(event.target !== this.genderChecked){
      if(this.genderChecked){
        this.genderChecked.checked = false;
      }
      changedCheckbox.checked = true;
      this.filterCheckedData.genderChoice = changedCheckbox.value;
    }else{
      this.filterCheckedData.genderChoice = changedCheckbox.value;
    }
  }
  else{
    this.filterCheckedData.genderChoice = null;
  }
    this.genderChecked = event.target;
  }

  onApplyFilter(){
    this.onApplyFilterBtn = true;
    // if(this.filterCheckedData.solverType != null || this.filterCheckedData.sessionStatus != null || this.filterCheckedData.genderChoice != null){
      this.updateDataFilter(this.filterCheckedData);
    // }
  }

  onClear(){
    this.highlightFilter = true;
    this.solverType.forEach(Item =>{
      Item.checked = false;
    });
    this.sessionStatus.forEach(Item =>{
      Item.checked = false;
    })
    this.genderChoice.forEach(Item =>{
      Item.checked = false;
    })
    this.filterCheckedData.solverType = null;
    this.filterCheckedData.sessionStatus = null;
    this.filterCheckedData.genderChoice = null;
    this.lastChecked = null;
    this.statusChecked = null;
    this.genderChecked = null;
    this.updateDataFilter(this.filterCheckedData)
  }

  callAssignTeam(data){
    this.appService.updatePatientTeamSidebarState({state: true,data:{id:this.id,assignedPatient:data}});
  }

  addTeam(){

    this.showTeam = true;
    this.selectedTeam = [];
    let patient = this.providerData.assignedPatients
    if(patient) {

      let values = patient ?
      patient.map(obj=>{
        return ({
          name: `${obj.firstName} ${obj.lastName}` ,
          value: obj.id,
        });
      }) : [];      
      let check = values.map(element => {        
        return(this.listProvider.filter((obj)=> obj.value === element.value)[0]);
      });
      this.selectedTeam = check.filter((obj)=>{return obj !== undefined;});
    }
    // this.selectedPatient = {...patient, edit: true};
  }
  confirmViewDeleteCall(rowData, i){
    this.patientDeleteConfirmBoxid = rowData;
    this.patientDeleteConfirmBoxindex = i;
    this.patientDeleteConfirmBox = true; 
  }
  deleteProvider(item, i) {    
    this.providerService.deleteProviderTeam(item.id,this.id).subscribe(() => {
      // this.providerData.assignedPatients.splice(i, 1);
      this.patientDeleteConfirmBox = false;
      this.getProvider(this.savedPayload);
      this.getPatientList({ page: 1, limit: 1000 , status:true, sortBy:'name'});
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Patient deleted successfully from the team' });
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong' });
    });
  } 

  deleteEvents(data){
    this.showEvent = false;
    if(data){// Un-availability Details:-
      this.UnavailableEventdeletePopup = true;
      this.viewTypeScudule = 'current';
    }else{// Session Details:-
      this.cancelSessionPopup = true;
    }
  }

  deleteUnavailability(){
    const payload = {
      "cancellingReason": this.cancellingReason,
      "id": this.showEventData.eventid,
      "type": this.viewTypeScudule
    };    
    this.providerService.deleteUnavailibity(payload).subscribe((response: any) => {
      this.getProvider(this.savedPayload);
      this.UnavailableEventdeletePopup = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Unavailability deleted successfully` });
      this.cancellingReason = null;
      this.viewTypeScudule = 'current';
    }, (error) => {
      this.UnavailableEventdeletePopup = false;
      this.cancellingReason = null;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  openApDispPopup(data,callType){    
    this.aprvDisprvEventid = data.eventid;
    this.viewUnavailabilityTypeRecurrence = data.recurrence;
    const startDate = new Date(data.startDate).getDay();
    const DayOfStartDate = this.weekday[startDate];
    this.listSkilltypeApprvDny.push({label: 'Upcoming '+DayOfStartDate, value: DayOfStartDate})
    if(data.recurrence === 'customRecurrence'){
      // this.aprvDisprvisRecurence = true;
    }else{
      // this.aprvDisprvisRecurence = false;
    }
    this.showEvent = false;
    this.AprvPopupSection = callType === 'Approve' ? true : false;
    this.DisprvPopupSection = callType === 'Deny' ? true : false;
    this.aprvDisprvPopup = true;
  }

  closeAprvDisaprvPopup(){
    this.listSkilltypeApprvDny.pop();
    this.aprvDisprvPopup = false; 
    this.listSkilltypeValue = ['current'];
  }

  filterAddSchedule() {
    this.router.navigate(['/scheduling/all-scheduling/add-session']);
    // this.appService.updateFilterSidebarState({ state: true });
  }

  opencstmReccurenceDetailPopup(eid){
    this.providerService.getPendingUnavalityWithIdAPI(eid).subscribe((response: any) => {     
    this.cstmReccurenceDetailHeader = response.data[0];
    this.cstmReccurenceDetailData = response.data[0].providerUnavailabilityDetails.map(obj=>{
      return {
        startDate: obj.startDate,
        startTime: moment(`${obj.startDate}T${obj.startTime}`).format('HH:mm').toUpperCase(),
        endTime: moment(`${obj.startDate}T${obj.endTime}`).format('HH:mm').toUpperCase(),
      }
    });
    this.showEvent = false;
    this.cstmReccurenceDetailPopup = true; 
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });   
  }

  checkDeny(){
    if(this.aprvDisprvComment != ''){
      if(this.aprvDisprvComment.length >= 10){
        let AlphaCheck = this.aprvDisprvComment.match(/[a-zA-Z]/);
        if(AlphaCheck != null){
          if(this.aprvDisprvisRecurence){
            this.aprvDisprvPopup = false;
            this.aprvDisprvRecurencePopup = true;
          }else{
            this.unavailabilityApprovel(this.aprvDisprvEventid,'disApproved');
          }
        }else{
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please add some alphabet character in reason field.' })    
        }
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Character limit must be between 10 to 255 for reason field.' })    
      }
    }else{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter a reason.' })    
    }
  }

  checkChar(e){
    if (this.invalidSymbol.includes(e.key)) {
      e.preventDefault();
    }
  }

  unavailabilityApprovel(id,status){
    const payload = {
      "id": id,
      "status": status,
      "showAllData": this.clinicId != -1 ? false : true,
    };
    if(this.DisprvPopupSection){
      payload["comment"] = this.aprvDisprvComment;
      payload["type"] = this.listSkilltypeValue;
    }else{
      payload["isExcused"] = this.listExcuse;
      payload["type"] = this.listSkilltypeValue;
    }    
    this.providerService.updateUnavailabilityApprovel(payload).subscribe((response: any) => {
      this.aprvDisprvPopup = false;
      this.listSkilltypeApprvDny.pop();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });
      this.aprvDisprvComment = '';
      this.listExcuse = true;
      this.viewTypeScudule = 'current';
      this.getProvider(this.savedPayload);
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  cancelSessionEvent(){
    const payload = {"cancellingReason": this.cancellingSessionReason,"isCancelled": true,"sessionId": this.showEventData.eventid};
    this.patientService.cancelpatient(payload).subscribe(() => {
      this.getProvider(this.savedPayload);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `Session cancelled successfully` });
      this.cancelSessionPopup = false;
      this.cancellingSessionReason = null;
    },(error) => {
        this.cancelSessionPopup = false;
        this.cancellingSessionReason = null;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
  }

  getSkillList(data) {
    this.skillsSevice.getSkills(data).subscribe((response: any) => {
      if (response.data) {
        this.listSkills = response.data.map(obj=>{
          return {label: obj.skillName, value: obj.id}
        });
        this.listSkills.unshift({label: '--Select--', value: null})
      }
    });
  }

  showBasicDialog() {
    this.addSkillsList = [];
    console.log(this.providerData.providerSkills);
    let data:any[] = [];

    if(this.providerData.providerSkills.length > 0){
      let first = [];
     data =  this.providerData.providerSkills.map(obj=>{return ({priority: obj.priority, skillId: obj.skill.id})});
      this.addSkillsList = data;
      this.addSkillsList.forEach(obj=>{
        if(obj.skillId){   
          first.push(1);    
        }
      });
      if(this.addSkillsList.length <= first.length){
        this.addSkillsList.push({
          "priority": 1,
          "skillId": null,
        });
      }
    }
    else{
      this.addSkillsList.push({
        "priority": 1,
        "skillId": null,
      });
    }
    this.displayBasic = true;
    this.skillsSelectedValues = []
    this.addSkillsList.forEach((obj,i)=>{
      if(obj.skillId !== null){
        this.skillsSelectedValues[i] =  obj.skillId;
      }
    })
  }
  skillDeleteIndex(values,i){
    this.skillIndex = i;
    console.log(this.skillIndex);
    
    const skillType  = this.listSkills.filter((obj)=>{return obj.value === values.skillId})[0].label
    if(values.skillId !== null){
    if(skillType == 'Behavioral Supervision'|| skillType =='Occupation Individual Therapy' || skillType =='Speech Individual Therapy'){
      this.deleteSkillSpecialType = true;
      skillType == 'Behavioral Supervision' ? this.isBcba=true : this.isBcba=false;
    }else{
      this.deleteSkillSpecialType = false
    }
    if(this.changedIndex === this.skillIndex){
      this.onRemoveSkillAdd()
    this.skillDisable = false;
    }else{
      this.removeSkillPop=true
    }
  }
  else{
    this.onRemoveSkillAdd()
    this.skillDisable = false;
  }
  }
  onRemoveSkillAdd(){
    this.addSkillsList.splice(this.skillIndex,1);
    this.addSkillsList = this.addSkillsList.map(obj => obj);
    this.removeSkillPop = false
  }
  onAddSkillSaveClick(){
    this.assignValues()

  }
  onAddSkills(event,i){
    console.log(this.addSkillsList,i);
    this.changedIndex = i
    this.skillsSelectedValues[i] = event.skillId;
    let first = [];
    if(this.addSkillsList.length > 0){
      if(this.addSkillsList[i].skillId != null){
        this.skillDisable = false;
      }
      this.addSkillsList.forEach(obj=>{
        if(obj.skillId){   
          first.push(1);   
        }
      });
      if(this.addSkillsList.length <= first.length){
        this.addSkillsList.push({
          "priority": 1,
          "skillId": null,
        });
      }
    }
  }
  assignValues(){
    this.providerSkillsFormsData = [];
    this.addSkillsList.map(obj=>obj).forEach(element => (element.skillId) ? this.providerSkillsFormsData.push(element) : '');
    const payload = {
      providerId: this.id,
      providerSkillsForm: this.providerSkillsFormsData
    }

    console.log(payload);
    this.providerService.addProviderSkills(payload).subscribe((response: any)=>{
      console.log(response);
      if(response.body.success){
      this.skillsSelectedValues = [];
      this.displayBasic = false;
      this.addSkillsList = [];
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Provider skill added successfully.' });
      this.getProvider(this.savedPayload);
      }
    },(error)=>{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
    });
  }

  getPatientList(data) {
    this.providerService.getPatientList(data).subscribe((response: any) => {
      if (response.data) {
        this.listProvider = response.data.map(obj => {
          return { name: `${obj.firstName} ${obj.lastName}`, value: obj.id }
        });
        console.log(this.listProvider)
      }
    });
  }

  onSaveClick(){
    this.skillsSelectedValues = []
    const payload = {
      providerId: this.providerData.id,
      patientIds: this.selectedTeam.map(obj=>obj.value),
    };
    
    if(this.selectedTeam.length === 0){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select any value.' });
    }else{
      this.showTeam = false;
      if(this.providerData.assignedPatients.length === 0){//table data is blank call post API
        this.providerService.addProviderTeam(payload).subscribe((res) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.message });
          this.providerData.assignedPatients = res.body.data?.assignedPatients.sort(this.Sortarr) || [];
          this.getProvider(this.savedPayload);
          this.getPatientList({ page: 1, limit: 1000 , status:true, sortBy:'name'});
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      }else{//call put API
        this.providerService.updateProviderTeam(payload).subscribe((res) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.message });
          this.providerData.assignedPatients = res.body.data?.assignedPatients.sort(this.Sortarr) || [];
          this.getProvider(this.savedPayload);
          this.getPatientList({ page: 1, limit: 1000 , status:true, sortBy:'name'});
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      }
    }
  }


  Sortarr( a, b ) {// To Sort array of Object with name
    if ( a.firstName < b.firstName ){
      return -1;
    }
    if ( a.firstName > b.firstName ){
      return 1;
    }
    return 0;
  }

  HolidayDateChecker(Holidaydate){
    const StartDate = this.savedPayload.fromDate;
    const EndDate = this.savedPayload.toDate;
    
    if(Holidaydate >= StartDate  && Holidaydate <= EndDate){
      return true;
    }else{
      return false;
    }
  }


  HolidayYearChange = 0;
  getProvider(payload) {
    if(new Date().getFullYear() < Number(moment(payload.fromDate).format('YYYY'))){
      if(this.HolidayYearChange === 0){
        this.getHoliday();
        this.HolidayYearChange++;
      }
    }
    this.planned_count = 0;
    this.escalated_count = 0;
    this.appService.showCustomLoader(true);
    let HolidayPayload = {...payload};
    let holidayData = [];
    this.upcomingHolidayData = '';
    this.EventsDataArr = [];
    this.calendarOptionsWeekview.events = [];



    // this.providerService.providerUnavailabilityHoliday(HolidayPayload).subscribe((response: any) => {
    //   if(response.data){
      this.getHolidays = this.holidayList;
              if(this.getHolidays.length === 0){
                this.upcomingHolidayData += 'No Holidays';
              }
              else{
                for (let index = 0; index < this.getHolidays.length; index++) {
                  const element = this.getHolidays[index];
                  if(index === 0){
                  }
                  let isValidHoliday = this.HolidayDateChecker(element.holidayDate);
                  if(isValidHoliday){
                    this.upcomingHolidayData += element.holidayName +': '+ element.holidayDate+'<br>';
                  } 

                    // this.upcomingHolidayData += element.holidayName +': '+ element.holidayDate+'<br>';
                
                }
              }
    //     holidayData = response.data.holidays;
    //   }
    // });
    
    this.providerService.getProviderById(payload).subscribe((response: any) => {
      this.appService.showCustomLoader(false);

      if (response.data) {
        this.APPROVEDToolTipData = response.data.approvedCount.toString();
        this.PENDINGToolTipData = response.data.pendingCount.toString();
        this.providerData = {...response.data};
        this.providerData.assignedPatients.sort(this.Sortarr);

        this.IndividualGroupToolTipData = response.data.individualAndGroupCount;
        this.BCBAToolTipData = response.data.bcbaCount;
        this.OTToolTipData = response.data.otCount;
        this.SpeechToolTipData = response.data.speechCount;
        this.ProviderAccessSolverTypes = response.data?.solverTypes ? response.data?.solverTypes : [];  
        //   this.solverType = [];

        // if(this.IndividualGroupToolTipData > 0 || this.ProviderAccessSolverTypes.includes('INDIVIDUAL_AND_GROUP')){
        //   this.solverType.push({ label: 'Individual & Group', value: 'I&G',checked:false })
        // }
        // if(this.BCBAToolTipData > 0 || this.ProviderAccessSolverTypes.includes('BCBA')){
        //   this.solverType.push({ label: 'BCBA', value: 'BCBA',checked:false })
        // }
        // if(this.OTToolTipData > 0 || this.ProviderAccessSolverTypes.includes('OT')){
        //   this.solverType.push({ label: 'OT', value: 'OT',checked:false })
        // }
        // if(this.SpeechToolTipData > 0 || this.ProviderAccessSolverTypes.includes('SPEECH')){
        //   this.solverType.push({ label: 'Speech', value: 'SPEECH',checked:false });
        // }


        const arr = [];
        this.providerData.sessionList.forEach(element => {
            element["shiftDate"] = element.shiftDate;
            element["lastendDate"] = `${element.shiftDate}T${element.endTime}`;
            const startDate = `${element.shiftDate}T${element.startTime}`;
            const endDate = `${element.shiftDate}T${element.endTime}`;
            const dates = {
              fromDate: moment(startDate).format('hh:mm a'),
              toDate: moment(endDate).format('hh:mm a'),
            };
            const toTitleCase = (phrase) => {
              return phrase.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            };
            let date = `${dates.fromDate} - ${dates.toDate}`;
            // date = `${element.patient.firstName} ${element.patient.lastName} (${element.spotName})`;
            let pFirstName = element.patient.firstName; 
            let pLastName = element.patient.lastName; 
            date = `${pFirstName.replace(pFirstName[0], pFirstName[0].toUpperCase())} ${pLastName.replace(pLastName[0], pLastName[0].toUpperCase())} (${element.spotName})`;
            let badgeClass;
          if(element.providerChoice == 'M'){
            badgeClass = element.sessionType+'Bedge Male';
          }else if(element.providerChoice == 'F'){
            badgeClass = element.sessionType+'Bedge Female';
          }else{
            badgeClass = element.sessionType+'Bedge';
          }   

            if(element.isEscalated){
              this.escalated_count++
            }else{
              this.planned_count++;
            }

            const propData = {...this.providerData ,...element};

            arr.push({
                // constraint: JSON.stringify(element), 
                title: date, start: startDate,
                extendedProps: propData, 
                end: endDate,
                eventId: element.id,
                backgroundColor: element.isCancelled === true ? '#0a765e' : element.isEscalated === true ? '#802000' : '#0bb453',
                textColor: element.isEscalated === true ? 'white' : '#000000',
                // className: element.providerChoice == 'M' ? 'Male' : element.providerChoice == 'F' ? 'Female' : '',
                className: [badgeClass,propData.recurrence === 'customRecurrence' ? 'customReccurrenceBadge' : ''],
              });              
        });

        if (this.providerData.providerUnavailabilityDTOS) {
          this.providerData.providerUnavailabilityDTOS.forEach(unav => {
            unav["shiftDate"] = unav.startDate;
            unav["lastendDate"] = `${unav.endDate}T${unav.endTime}`;
                const startDate = `${unav.startDate}T${unav.startTime}`;
                const endDate = `${unav.endDate}T${unav.endTime}`;
                const dates = {
                  fromDate: moment(startDate).format('hh:mm a'),
                  toDate: moment(endDate).format('hh:mm a'),
                };
              const label = unav.allDay ? 'All Day' : `${dates.fromDate} - ${dates.toDate}`;
                let date = `${unav.unavailabilityReasonTypeDTO.type}`;

              const unPropData = { ...this.providerData, ...unav, unavailability:true }  
                if(unav.allDay){
                  arr.push({
                    constraint: JSON.stringify(unav), title: date, start: `${unav.startDate}T${this.clinicStartTime}`,
                    extendedProps: unPropData, end: `${unav.startDate}T${this.clinicEndTime}`,
                    backgroundColor: unav.status === 'approved' ? '#88898b' : unav.status === 'pending' ? '#FFE84C' : '#d76868',
                    textColor: unav.status === 'approved' ? 'white' : unav.status === 'pending' ? 'black' : 'white',
                    className : [unav.recurrence === 'customRecurrence' ? 'availabilityCustomReccurrenceBadge' : ''],
                  });
                }else{
                  arr.push({
                    constraint: JSON.stringify(unav), title: date, start: startDate,
                    extendedProps: unPropData, end: endDate,
                    backgroundColor: unav.status === 'approved' ? '#88898b' : unav.status === 'pending' ? '#FFE84C' : '#d76868',
                    textColor: unav.status === 'approved' ? 'white' : unav.status === 'pending' ? 'black' : 'white',
                    className : [unav.recurrence === 'customRecurrence' ? 'availabilityCustomReccurrenceBadge' : ''],
                  });
                }
              });
          }
          // this.holidayList.forEach((obj)=>{
          //   obj['holiday'] = true;
          //   obj['reason'] = obj.holidayName;
          //   arr.push({
          //     // data: obj,
          //     constraint: JSON.stringify(obj),
          //     extendedProps: obj,
          //     title: obj.holidayName, 
          //     start: `${obj.holidayDate}T00:00:00`,
          //     end: `${obj.holidayDate}T23:59:59`, 
          //     backgroundColor: '#9cff8d',
          //     className: 'HolidayClass',
          //     textColor: '#000000',
          //   });
          // });

          this.EventsDataArr = [...arr];
          if(this.onApplyFilterBtn){
            this.updateDataFilter(this.filterCheckedData);
            this.assignedPatientTableFilter();
          }else{
            this.filterCheckedData.genderChoice = null;
            this.filterCheckedData.sessionStatus = null;
            this.filterCheckedData.solverType = null;
            this.updateDataFilter(this.filterCheckedData);
            this.assignedPatientTableFilter();
          }
      
          if(this.Tippyinstance){
            this.Tippyinstance.destroy();
          }
        // this.calendarOptionsWeekview.events = arr;
      }
    },(error)=>{
      // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      this.appService.showCustomLoader(false);
      if(error.error.errorCode === 'user.not.allowed.operation'){
        this.router.navigate(['provider']);
      }
    });
  }

  getHoliday(){
    this.schedulingService.getHolidayScheduling(this.savedPayload).subscribe((res: any) => {
      if(res.data){
        res.data.holidays.map(obj=>{
          this.holidayList.push(obj);
        })
        }
    });
  }

  
  listWeekViewData(){
    this.onClear();
    this.filterbox = false;
    localStorage.providerListView = this.ListViewToggle;
    if(!this.ListViewToggle){
      this.reRenderCalendar();
    }
  }
  updateDataFilter(event){
    if((this.filterCheckedData.solverType === null && this.filterCheckedData.sessionStatus === null && this.filterCheckedData.genderChoice === null)){
      this.highlightFilter = true;
      this.solverType.forEach(Item =>{
        Item.checked = false;
      });
      this.sessionStatus.forEach(Item =>{
        Item.checked = false;
      })
      this.genderChoice.forEach(Item =>{
        Item.checked = false;
      })
    }else{
      this.highlightFilter = false;
    }

    let arrSt = [];
    let arrSS = [];
    let arr = [];
    
    switch (event.solverType) {      
      case "BCBA":
        this.EventsDataArr.forEach(element => {
          if(element.className[0]?.includes('BCBABedge')){
            arrSt.push(element);
          }
        });
      break;
      case "OT":
      this.EventsDataArr.forEach(element => {
        if(element.className[0]?.includes('OTBedge')){
          arrSt.push(element);
        }
      });
      break;
      case "SPEECH":
      this.EventsDataArr.forEach(element => {
        if(element.className[0]?.includes('SPEECHBedge')){
          arrSt.push(element);
        }
      });
      break;
      case "I&G":
      this.EventsDataArr.forEach(element => {
        if(element.className[0]?.includes('INDIVIDUALBedge') || 
          element.className[0]?.includes('GROUPBedge') || 
          element.className[0]?.includes('BEHAVIOURBedge') || 
          element.className[0]?.includes('NAPBedge') ||
          element.className[0]?.includes('NORMALBedge')){
            arrSt.push(element);
        }
      });
      break;
      default:
        arrSt = [...this.EventsDataArr];
      break;
    }

    switch (event.sessionStatus) {
      case "PLANNED":
        arrSt.forEach(element => {
          if(element.backgroundColor === '#0bb453'){
            arrSS.push(element);
          }
        });
      break;
      case "ESCLATED":
        arrSt.forEach(element => {
        if(element.backgroundColor === '#802000'){
          arrSS.push(element);
        }
      });
      break;
      case "UNAVAILABILITY":
        arrSt.forEach(element => {
          if(element.backgroundColor === '#88898b'){
            arrSS.push(element);
          }
        });
        break;
      case "PENDINGUNAVAILABILITY":
      arrSt.forEach(element => {
        if(element.backgroundColor === '#FFE84C'){
          arrSS.push(element);
        }
      });
      break;
      case "IsPin":
          arrSt.forEach(element => {   
            console.log(element);
                     
            if((!element.className[0]?.includes('providerLoginMonthViewUnavltyAprv')) && element.extendedProps.isPinned){
            console.log(element);

              arrSS.push(element);
            }
          })
      break;
    
      default:
        arrSS = [...arrSt];
      break;
    }

    switch (event.genderChoice) {
      case "MALE":
        arrSS.forEach(element => {
          if(element.className[0]?.includes('Male')){
            arr.push(element);
          }
        });
      break;
      case "FEMALE":
        arrSS.forEach(element => {
          if(element.className[0]?.includes('Female')){
            arr.push(element);
          }
        });
      break;

      case "PREFER":
        arrSS.forEach(element => {
          if(!element.className[0]?.includes('Female') && !element.className[0]?.includes('Male')){
            if(element.backgroundColor != '#FFE84C' && element.backgroundColor != '#88898b'){
              arr.push(element);
            }
          }
        });
        break;
    
      default:
        arr = [...arrSS];
      break;
    }

    this.holidayList.forEach((obj)=>{
      obj['holiday'] = true;
      obj['reason'] = obj.holidayName;
      arr.push({
        // data: obj,
        constraint: JSON.stringify(obj),
        extendedProps: obj,
        title: obj.holidayName, 
        start: `${obj.holidayDate}T${this.clinicStartTime}`,
        end: `${obj.holidayDate}T${this.clinicEndTime}`, 
        backgroundColor: '#9cff8d',
        className: 'HolidayClass',
        textColor: '#000000',
      });
    });
   
    this.calendarOptionsWeekview.events = [...arr];

    this.ListViewTableData = arr.filter((obj)=>{
      if(!obj.extendedProps?.unavailability && !obj.className.includes('HolidayClass')){
        return obj
      }
    });
    this.sortListView({sortField: 'shiftDate', sortOrder: 1});
    this.filterbox = false;    
    this.ListViewTableTotal = this.ListViewTableData.length;
  }


  sortListView(event){
    this.ListViewTableData.sort((data1, data2) => {      
      let value1 = data1?.extendedProps[event.sortField];
      let value2 = data2?.extendedProps[event.sortField];
      let result = null;
      
      if (value1 == null && value2 != null)
      result = -1;
      else if (value1 != null && value2 == null)
      result = 1;
      else if (value1 == null && value2 == null)
      result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
      result = value1.localeCompare(value2);
      else
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      
      return (event.sortOrder * result);
      });
  }

  confirmViewMultiDeleteCall(){
    // this.deleteEventComment = '';
    // this.viewStatusScudule = 'CLIENT_CANCELLATION';
    // this.viewTypeScudule = 'current';
    // this.plannedShiftDeletePopup = true;
  }

  check_sub(event, rowData, rowindex){
    console.log(rowData.extendedProps.id);
    
    if (event.checked) {
      this.checkBoxItem++;
      this.checkBox_count.push(this.checkBoxItem);
      this.checkBoxArrId.push(rowData.extendedProps.id);
      this.checkBoxArr[rowindex] = true;

    } else {
      
      this.checkBox_count.pop();
      this.checkBoxArr[rowindex] = false;
      let index = this.checkBoxArrId.indexOf(rowData.extendedProps.id);

      this.checkBoxArrId.splice(index,1);
    }
  }


  closeProvider(i){
    this.selectedTeam.splice(i,1);
    this.selectedTeam = this.selectedTeam.map(o=>o);
  }
  scrollHandler(){

  }
  moveToBottom(scrollHeight){
    this.containerwrapper["nativeElement"].scrollTop = scrollHeight;
  }
  ngOnDestroy() {
    localStorage.ProviderDetailsViewTypeTab = 'basic';
    localStorage.providerListView = false;
    if (this.openTextMsgSubs) {
      this.openTextMsgSubs.unsubscribe();
    }
    if (this.openMail) {
      this.openMailSubs.unsubscribe();
    }
    if(this.calendarRerenderSubs){
      this.calendarRerenderSubs.unsubscribe();
    }
  }
  filter() {
    this.appService.providerProfileSidebarState({state: true, edit: true, 
      rowData: {formData: this.providerData}
    });
  }

  editScheduling(){
    this.schedulingService.getSchedulingById(this.showEventData.eventid).subscribe((response: any) => {
      this.showEvent = false;
      this.appService.updateFilterSidebarState({ state: true, edit:true, ...response.data,isPatientModule:false,isProviderModule:true });
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  updateTableData(data){
    if(data.success){
      this.getProvider(this.savedPayload);
    }
  }

  callAddSession(sendData){
    this.appService.updateAddSessionSidebarState({state: true,isPatientModule:false,isProviderModule:true,providerData:sendData, clinicStartTime: this.clinicStartTime});
  }

  subscribePopups() {
    this.openTextMsgSubs = this.appService.textpopup.subscribe(res => {
      this.openTextMsg = res;
    });

    this.openMailSubs = this.appService.openMail.subscribe(res => {
      this.openMail = res;
    });
  }
  tConvert(time) {
    time  = time.slice(0,5);// Remove seconds...
    // Check correct time format and split into components
    time = time.toString().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value      
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }
  getTooltipText(color:string){
    if(color === '#0bb453'){
      return 'Planned';
    }
    else if(color === '#802000'){
      return 'Escalated';
    }
    else if(color === '#FFE84C'){
      return 'Pending Unavailability';
    }
  }
  isTextExceed(): boolean {
    const fullName = `${this.providerData.firstName} ${this.providerData.lastName}`;
    return fullName.length > 15;
  }

  assignedPatientTableFilter(){
    if(this.viewType === 'basic'){
      setTimeout(() => {
        this.assignPatientTable.filter(this.AssignPateintfilterStatus,'patientActive','contains');
      }, 0); 
    }   
  }
get truncatedEmail() {
  const maxLength = 30;
  if (this.providerData.email.length > maxLength) {
  return this.providerData.email.substring(0, maxLength) + '...';
  }
  return this.providerData.email;
}
get truncatedProviderName() {
  const maxLength = 20;
  var providerFullName = this.providerData?.firstName + ' ' + this.providerData?.lastName;
  if (providerFullName.length > maxLength) {
  return providerFullName.substring(0, maxLength) + '...';
  }
  return providerFullName;
}
get truncatedStreet() {
  const maxLength = 20;
  if (this.providerData.providerAddress.street.length > maxLength) {
  return this.providerData.providerAddress.street.substring(0, maxLength) + '...';
  }
  return this.providerData.providerAddress.street;
}
itemCheck(data){
  if(data == null){
    this.skillDisable = true
  }
}
getDynamicYearRange(): string {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 1;
  const endYear = currentYear + 5;
  return `${startYear}:${endYear}`;
}
getFilteredPatientCount(): number {
  if (this.AssignPateintfilterStatus === true) {
    return this.providerData.assignedPatients.filter(patient => patient.patientActive === true).length;
  } 
  // else if(this.AssignPateintfilterStatus === false) {
  //   return this.providerData.assignedPatients.filter(patient => patient.patientActive === false).length;
  // } 
  else{
    return this.providerData.assignedPatients.filter(patient => patient.patientActive === false).length + this.providerData.assignedPatients.filter(patient => patient.patientActive === true).length;
  }
}
sortAssignedPatient(assignedPatient: any[]): any[] {
  if (!Array.isArray(assignedPatient)) {
    return assignedPatient;
  }

  return assignedPatient.slice().sort((a, b) => {
    const nameA = (a.firstName || '').toLowerCase();
    const nameB = (b.firstName || '').toLowerCase();

    return nameA.localeCompare(nameB);
  });
}

}