import { AppService } from './../../app.service';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

   
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  editStatusSub: Subscription;
  publicData = {
    "company_name": 'Opta Planner',"company_title": 'Sign In To Admin',
    "company_logo": '../../assets/images//Web-Logo@2x.png',"company_fav_icon": 'assets/icon/favicon.png'};
  constructor(public router: Router,
    public appService: AppService,
    ) { }

  ngOnInit() {
    this.editStatusSub = this.appService.updateCacheCard.subscribe((data: any) => {
      this.updateData();
     });
      this.updateData();
  }
  updateData(){
    if(localStorage.getItem("pubDataSaas") && localStorage.getItem("pubDataSaas") !== ""){
      this.publicData = JSON.parse(localStorage.getItem("pubDataSaas"));
    }
  }

}
