<footer
  *ngIf="!(router.url.includes('/maintainance/maintainance-page') || router.url.includes('/contacts/contact-detail') || router.url.includes('/error/error-page'))">

  <div class="row w-100 desktop-footer">
    <div class="col-sm-12">
    </div>
    <div class="col-md-6">
      <p>Copyright &copy; 2021 | {{publicData.company_name}}</p>
    </div>
    <div class="col-md-6 text-right">
      <ul>
        <li routerLinkActive="active"><a>About</a></li>
        <li routerLinkActive="active"><a>Team</a></li>
        <li routerLinkActive="active"><a>Contact Us</a></li>
        <li routerLinkActive="active"><a>Privacy Policy</a></li>
        <li routerLinkActive="active"><a>T&C</a></li>

      </ul>
    </div>
  </div>
  <div class="d-flex mobile-footer-menu">
    <ul class="mb-0 mobile-footer">
      <li [routerLink]="['/customer/all-customers']">
        <figure class="text-center mb-0"><img src="../../assets/images/sidebar/contact.svg" alt="contacts"> </figure>
        <span class="d-block">Customers</span>
      </li>
    </ul>
  </div>
</footer>
