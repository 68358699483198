import { AppService } from '../../app.service';
import { Subscription } from 'rxjs';
import { SlidePanelAnimation } from '../../shared/animations/animations';
import { Component, OnInit, HostListener, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SharedService } from '../../shared/shared.service';
import { AppExService } from '../../app-ex.service';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { CustomRecurrenceModel } from '../scheduling.model';

@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss', '../../../assets/stylesheets/form.scss'],
  animations: SlidePanelAnimation,
}
)
export class CustomComponent implements OnInit, OnDestroy {
  profileSubscription: Subscription;
  @Input() filter;
  @Output() getFiltertedData = new EventEmitter<any>();

  profileSection = false;
  overlay = false;
  isInputEnabled:Boolean = false;
  ReapetsTypeSet1 = [
    { label: 'Every Weekday', value: 'Every Weekday' },
    { label: 'Daily', value: 'Daily' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Custom Recurrence', value: 'Custom Recurrence' },
    { label: 'Never Ending', value: 'Never Ending' }
  ];
  week = [
    { label: 'Week', value: 'week' },
    { label: 'Day', value: 'day' },
    { label: 'Month', value: 'month' },
    { label: 'Year', value: 'year' }
  ];
  minDate = new Date();
  weekDaysList = [
    { label: 'sunday', value: false },
    { label: 'monday', value: false },
    { label: 'tuesday', value: false },
    { label: 'wednesday', value: false },
    { label: 'thursday', value: false },
    { label: 'friday', value: false },
    { label: 'saturday', value: false },
  ];
  date15: Date;
  edit = false;
  public form: FormGroup;
  selectedfacilities;
  teamSection = false;
  // maxDateUI = new Date();
  rows = 10;
  total = 10;
  rowNumber = 1
  pageSize = 50;
  saveBtnDisabled:any = true;

  customRecurrenceuser: CustomRecurrenceModel;
  constructor(
    public appService: AppService,
    public messageService: MessageService,
    public shareService: SharedService,
    public appExService: AppExService,
  ) {
    this.customRecurrenceuser = new CustomRecurrenceModel();
  }
  getCustomFormData() {
    if(this.customRecurrenceuser.occurrenceType === 'week' || this.customRecurrenceuser.occurrenceType === 'weekdays'){
      this.customRecurrenceuser.weekDays = this.weekDaysList.filter(obj => obj.value).map(obj => obj.label);
    }else{
      this.customRecurrenceuser.weekDays = [];
    }

    const payload = { ...this.customRecurrenceuser };

    this.appExService.getCustomReoccurData.next({ data: payload });
    this.getFiltertedData.emit({});
    this.addScroll();
  }
  ngOnInit() {
    // this.maxDateUI = new Date(this.maxDateUI.setMonth(this.maxDateUI.getMonth()+9));
    this.saveBtnDisabled = true;
    this.profileSubscription = this.appService.filterCustomReccurence.subscribe((res: any) => {
      if(res.data.weekDays === undefined){
        this.weekDaysList = [
          { label: 'sunday', value: false },
          { label: 'monday', value: true },
          { label: 'tuesday', value: true },
          { label: 'wednesday', value: true },
          { label: 'thursday', value: true },
          { label: 'friday', value: true },
          { label: 'saturday', value: false },
        ];
       }else{
        this.weekDaysList = [
          { label: 'sunday', value: false },
          { label: 'monday', value: false },
          { label: 'tuesday', value: false },
          { label: 'wednesday', value: false },
          { label: 'thursday', value: false },
          { label: 'friday', value: false },
          { label: 'saturday', value: false },
        ];
       }
      if (res.state) {
        this.profileSection = res.state;
        this.edit = res.edit;
        this.overlay = res.state;
        this.minDate = res.minDate;
        this.dataMapping(res.data);
        res.data?.weekDays?.map((objj)=> {
          return(this.weekDaysList.filter(obj => obj.label == objj)[0].value = true);
        });
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      } else {
        this.addScroll();
      }
    });
  }
  invalidSymbol = ["$","~","@","%","^","&","*",
  "(",")","{","}","!","[","]",",","=","+","-",
  "<",">",".","/","?",";",":","_","`","#","|", "\""
];
  checkChar(e){
    if (this.invalidSymbol.includes(e.key)) {
      e.preventDefault();
    }
  }
  enableAfterInput(item){
    if(item === 'disable'){
      this.isInputEnabled = false;
    }
    else if(item === 'enable'){
      this.isInputEnabled = true;
    }
  }
  dataMapping(obj) {
    const data = this.customRecurrenceuser;
    data.occurrenceType = obj ? obj.occurrenceType : '';
    data.recurrenceCount = obj ? obj.recurrenceCount : 1;
    data.recurrenceType = obj ? obj.recurrenceType : 'On';
    data.recurrenceRange = obj ? obj.recurrenceRange : 1;
    if(data.recurrenceRange === undefined) return data.recurrenceRange = 1;
    data.weekDays = obj ? obj.weekDays : [];


    let ScheduleStartdDate = moment(obj.minDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let ScheduleReccurenceEnddDate = moment(obj.endDate, "YYYY-MM-DD").format("YYYY-MM-DD");

    if(ScheduleStartdDate < ScheduleReccurenceEnddDate) {
      data.endDate = obj.endDate ? new Date(obj.endDate) : new Date();      
    }else {
      data.endDate = obj.minDate;
    }

    if(obj.recurrenceType === 'After'){
      this.isInputEnabled = true;
    }    
    
  }
  addScroll() {
    this.profileSection = false;
    this.overlay = false;
    this.teamSection = false;

    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }

  onChangeSettings(){    
    if(this.customRecurrenceuser.occurrenceType === 'week'){
      this.weekDaysList = [
        { label: 'sunday', value: false },
        { label: 'monday', value: true },
        { label: 'tuesday', value: true },
        { label: 'wednesday', value: true },
        { label: 'thursday', value: true },
        { label: 'friday', value: true },
        { label: 'saturday', value: false },
      ];
    }else if(this.customRecurrenceuser.occurrenceType === 'week'){
      this.weekDaysList.forEach((obj)=> obj.value = false);
    }
    this.saveBtnDisabled = false;    
  }

  onChangeWeekDayClick(){
    this.saveBtnDisabled = false;
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    this.profileSection = false;
    this.teamSection = false;
    this.overlay = false;
    this.appExService.callActivityData = [];
    this.saveBtnDisabled = true;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (event.target.closest('.overlay') && !event.target.closest('.profileSection')) {
      this.saveBtnDisabled = true;
      this.profileSection = !this.profileSection;
      this.overlay = !this.overlay;
      this.addScroll();
    }
  }
  getDynamicYearRange(): string {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear;
    const endYear = currentYear + 5;
    return `${startYear}:${endYear}`;
  }
}
