import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class JobConfigService {

  constructor(
    public sharedService : SharedService
  ) { }

  getJobConfigAPI(){
    const url = `${config.jobConfigDataGet}`;
    return this.sharedService.getData(url)
  }

  getJobConfigureDetailAPI(id){
    const url = `${config.jobConfigureDetailGet}/${id}`;
    return this.sharedService.getData(url)
  }

  addJobSkillsAPI(data){
    const url = `${config.addjobConfigureSkills}`;
    return this.sharedService.postData(`${url}`,data)
  }
  updateJobSkillsAPI(data){
    const url = `${config.addjobConfigureSkills}`;
    return this.sharedService.putData(`${url}`,data)
  }
}