import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { config } from '../config';
@Injectable({
  providedIn: 'root'
})
export class PatientTeamService {

constructor(public sharedService: SharedService) { }

updatePatientTeam(formData){
  return this.sharedService.putData(config.addEditPatientTeam,formData)
}
addPatientTeam(formData){
  return this.sharedService.postData(config.addEditPatientTeam,formData)
}

getPatientTeam(data){
  let url =`${config.getPatientTeam}`;
  url=url.replace(":page",data.page).replace(":limit",data.limit);
  if(data.searchParam){
    url=`${url}&searchParam=${encodeURIComponent(data.searchParam)}`;
  }
  if(data.clinicId){
    url = `${url}&clinicId=${data.clinicId}`;
  }
  return this.sharedService.getData(url);
}

deletetPatientTeam(id) {
  return this.sharedService.deleteData(`${config.deletePatientTeam}/${id}`);
}
addPrimaryProvider(data:any){
  var url = `${config.primaryProvider}?patientId=${data.patientId}&providerId=${data.providerId}`
  // if(data.providerId === null){
  //    url = `${config.primaryProvider}?patientId=${data.patientId}`
  // }else{
  //    url = `${config.primaryProvider}?patientId=${data.patientId}&providerId=${data.providerId}`
  // }
  return this.sharedService.postData(url,data)
}

getAssignedProvider(patientId:any){
  const url = `${config.previousSupervisor}?patientId=${patientId}`
  return this.sharedService.getData(url);
}
supervisorHistory(data:any){
  const url = `${config.supervisorHistory}?pageNo=${data.page}&pageSize=${data.size}&patientId=${data.patientId}`
  return this.sharedService.getData(url);
}
 
}
