
import { AppService } from './../../app.service';
import { Subscription, from } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Utils } from './../../utils';
import { Component, OnInit,OnDestroy, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../shared.service';
import { PopUpService } from '../pop-up/pop-up.service';
import * as moment from 'moment';

import { InitialService } from '../../initial/initial.service';

import { FormBuilder} from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss', '../../../assets/stylesheets/form.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ height: '0', opacity: 0, overflow: 'hidden' }),
        animate('500ms', style({ height: '*', opacity: 1, overflow: 'visible' })),
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1, overflow: 'visible' }),
        animate('500ms', style({ height: '0', opacity: 0, overflow: 'hidden' })),
      ]),
    ]),
  ],
})
export class FilterComponent implements OnInit, OnDestroy {
  openMoreOptionData = false;
  active = false;
  cardActive = false;
  @Input("filter") filter = "";
  showFilters = false;
  constructor(
    public utils: Utils,
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    public appService: AppService,
    public title: Title,
    public shareService: SharedService,
    public popUpService: PopUpService,
    public _activatedRoute: ActivatedRoute,
    public router: Router,
    public route: ActivatedRoute,
    public initialService: InitialService,
  ) {
  }

  getSubPlans(): void {
    
  }

  ngOnInit() {
   
  }
  ngOnDestroy() {
   
  }

  @HostListener('document:click', ['$event'])
  clickedOutside($event) {
    this.openMoreOptionData = false;
    this.active = false;
    this.cardActive = false;
  }
  clickedInside($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
  }
}
