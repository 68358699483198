import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { config } from '../config';
import { HttpClient, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ProviderService {

constructor(public sharedService: SharedService,private http:HttpClient) { }


updateProvider(formData){
  return this.sharedService.putData(config.addEditProvider,formData)
}
addProvider(formData){
  return this.sharedService.postData(config.addEditProvider,formData)
}
addProviderTeam(formData){
  return this.sharedService.postData(config.providerPatientTeam,formData)
}
updateProviderTeam(formData){
  return this.sharedService.putData(config.providerPatientTeam,formData)
}
getPatientList(data) {
  let url = `${config.clinicWisePatientInfo}?isActive=true`;
  return this.sharedService.getData(url);
}
getJobTitleAPI(){
  let url = `${config.typeJobTitleURL}`;
  return this.sharedService.getData(url);
}
getPatient(data, id) {
  let url = `${config.getPatientNotAssignedTable}${id}`;
  url = url.replace(":page", data.page).replace(":limit", data.limit);
  return this.sharedService.getData(url);
}
getProviderListData() {
  let url = `${config.clinicWiseProviderInfo}?isActive=true`;
  return this.sharedService.getData(url);
}
getSchdlCalProviderListData(data) {
  let url = `${config.clinicWiseProviderInfo}?fromDate=${data.fromDate}&toDate=${data.toDate}`;
  if(data.searchParam){
    url=`${url}&searchParam=${encodeURIComponent(data.searchParam)}`;
  }
  return this.sharedService.getData(url);
}
getProviderListDataforAddSession(isSpecailSession) {
  let url = `${config.clinicWiseProviderInfo}?isActive=true`;
  if(isSpecailSession != null){
    url = `${url}&solverType=${isSpecailSession}`;
  }
  return this.sharedService.getData(url);
}
getProviderWiseClinicListData(id) {
  let url = `${config.getUpdatedClinicsList}`;
  if(id){
    url = `${config.getUpdatedClinicsList}?providerId=`+id;
  }else{
    url = `${config.getUpdatedClinicsList}`;
  }
  return this.sharedService.getData(url);
}
getProvider(data) {
  let url = `${config.getProviderTable}`;
  url = url.replace(":page", data.page).replace(":limit", data.limit);
  if(data.searchParam){
    url = `${url}&searchParam=${encodeURIComponent(data.searchParam)}`;
  }
  if(data.sortBy){
    url = `${url}&sortBy=${data.sortBy}`;
  }
  if(data.status !== null && data.status !== undefined){
    url = `${url}&status=${data.status}`;
  }
  return this.sharedService.getData(url);
}

 getProviderById(data){
  return this.sharedService.getData(`${config.addEditProvider}/${data.id}?fromDate=${data.fromDate}&toDate=${data.toDate}`);
 }

 getProviderAddtitionalDetailsById(data){
  return this.sharedService.getData(`${config.getProviderAdditionalData}/${data.id}?fromDate=${data.fromDate}&toDate=${data.toDate}`);
 }

 getSkillType(){
  return this.sharedService.getData(config.getSkillType);
 }
 updateProviderStatus(dataToSend){
  if(dataToSend.isProvider != undefined){
    let params = new HttpParams().append('isProvider', dataToSend.isProvider)
    return this.http.put<any>(`${config.addEditProviderStatus}/${dataToSend.id}/${dataToSend.sectionId}`,params)
  }
  else{
    return this.sharedService.putData(`${config.addEditProviderStatus}/${dataToSend.id}/${dataToSend.sectionId}`, {});
  }
 }
deleteProvider(id) {
  return this.sharedService.deleteData(`${config.deleteProvider}/${id}`);
}
deleteProviderTeam(id,providerId) {
  return this.sharedService.deleteData(`${config.deleteProviderTeam}/${id}?providerId=${providerId}`);
}
getUnavailibity(id) {
  return this.sharedService.getData(`${config.deleteUnavailabilityGet}/${id}`);
}
deleteUnavailibityProvider(id, type) {
  return this.sharedService.deleteData(`${config.deleteUnavailability}/${id}/${type}`);
}
deleteUnavailibity(data) {
  return this.sharedService.deleteDataWithPayload(`${config.deleteUnavailability}`, data);
}
providerUnavailability(data) {
  if(data.hasOwnProperty('clinicIds')){
    if(data.clinicIds.length > 0){
      return this.sharedService.getData(`${config.providerUnavailability}/${data.id}?clinicIds=${data.clinicIds}&fromDate=${data.fromDate}&toDate=${data.toDate}`);
    }else{
      return this.sharedService.getData(`${config.providerUnavailability}/${data.id}?fromDate=${data.fromDate}&toDate=${data.toDate}`);
    }
  }else{
    return this.sharedService.getData(`${config.providerUnavailability}/${data.id}?fromDate=${data.fromDate}&toDate=${data.toDate}`);
  }

}
providerUnavailabilityHoliday(data) {
  let url =`${config.getScheduleholidays}?fromDate=${data.fromDate}&toDate=${data.toDate}`;
  return this.sharedService.getData(`${url}`);
}
updateUnavailabilityApprovel(dataToSend){
  let data = '';
  if(dataToSend.comment){
    data = `comment=${dataToSend.comment}&status=${dataToSend.status}&type=${dataToSend.type}&showAllData=${dataToSend.showAllData}`
  }else{
    data = `isExcused=${dataToSend.isExcused}&status=${dataToSend.status}&type=${dataToSend.type}&showAllData=${dataToSend.showAllData}`
  }
  // if(dataToSend.current){
  //   data = data+`&type=current`;
  // }
  return this.sharedService.putData(`${config.providerUnavailabilityApprovel}/?id=${dataToSend.id}&${data}`,{});
 }

 getPendingUnavalityAPI(response,userTypeId) {
  let url;
  if(response){
    url = (` ${config.pendingUnavailabilityGet}?showAllData=${response}&userTypeId=${userTypeId}`)
  }else{
    url = (` ${config.pendingUnavailabilityGet}?showAllData=${response}`)
  }
  return this.sharedService.getData(url);
}

getTodayUnavalityAPI(response) {
  let url = `${config.getTodayUnavalityList}`;
  if(response){
    url = url+`?showAllData=${response}`;
  }
  return this.sharedService.getData(url);
} 

getPlannedSessionAPI(response) {
  let url = `${config.getPlannedSessionList}`;
  if(response){
    url = url+`?showAllData=${response}`;
  }
  return this.sharedService.getData(url);
} 

getEsclatedSessionAPI(response) {
  let url = `${config.getEsclatedSessionList}`;
  if(response){
    url = url+`?showAllData=${response}`;
  }
  return this.sharedService.getData(url);
} 

addProviderSkills(data){
  return this.sharedService.postData(`${config.providerSkillsURL}`,data)
}
getPendingUnavalityWithIdAPI(id) {
  return this.sharedService.getData(`${config.pendingUnavailabilityGet}?unavailabilityId=${id}`);
} 


getPatientPastDataAPI(data) {
  return this.sharedService.getData(`${config.patientPastDataGet}${data.type}?clinicIds=${data.clinicIds}&sectionId=${data.sectionId}`);
}

getDeleteUnavalityReasonAPI() {
  return this.sharedService.getData(`${config.deleteUnavailabilityGetReason}`);
} 

getAllSpecialSessionByIdAPI(id,pId) {
  return this.sharedService.getData(`${config.allSpecialSession}/${id}?providerId=${pId}`);
}
getSpotDetails(id:any){
  return this.sharedService.getData(`${config.getSessionDetail}?sessionId=${id}`);
}

}
