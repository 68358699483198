export class SchedulingModel {
  endTime: any;
  patientId: 0;
  isPinned = false;
  providerId: any;
  recurrence: string;
  type: string;
  shiftDate: any;
  sessionSkillForm=[];
  spotId: 0;
  placeOfServiceId: 5;
  startTime: any;
  customRecurrenceForm: CustomRecurrenceModel;

    constructor(obj?) {
      this.sessionSkillForm = [];
      this.patientId = obj ? obj.patientId : '';
      this.isPinned = obj ? obj.isPinned : '';
      this.endTime = obj ? obj.endTime:''; 
      this.providerId = obj ? obj.providerId:''; 
      this.shiftDate = obj ? obj.shiftDate:''; 
      this.recurrence = obj ? obj.recurrence:''; 
      this.spotId = obj ? obj.spotId:''; 
      this.placeOfServiceId = obj ? obj.placeOfServiceId:'',
      this.startTime = obj ? obj.startTime:''; 
    }
  }

  export const skillStatusListDeletePopup = [
    {label: 'Client Cancellation', value: 'CLIENT_CANCELLATION'},
    {label: 'Provider Cancellation', value: 'PROVIDER_CANCELLATION'},
    {label: 'Holiday', value: 'HOLIDAY'},
    {label: 'Patient Covid', value: 'PATIENT_COVID'},
    {label: 'Provider Covid', value: 'PROVIDER_COVID'},
    {label: 'Rescheduled', value: 'RESCHEDULED'},
    {label: 'Unexcused', value: 'UNEXCUSED'},
    {label:'School-Field Trip',value:'SCHOOL_FIELD_TRIP'},
    {label:'School-Class/EP Meeting',value:'SCHOOL_CLASS_EP_MEETING'},
    {label:'Patient Sick',value:'PATIENT_SICK'},
    {label:'Service Plan Change',value:'SERVICE_PLAN_CHANGE'},
    {label:'Error',value:'ERROR'},
    {label:'Excused Absence - Vacation',value:'EXCUSED_ABSENCE_VACATION'},
    {label:'Early Pickup',value:'EARLY_PICKUP'},
    {label:'Late Pickup',value:'LATE_PICKUP'},
    {label:'Late Arrival',value:'LATE_ARRIVAL'},
    {label: 'Other', value: 'OTHER'},
  ];
  export class CustomRecurrenceModel {
    endDate: any;;
    occurrenceType: string;
    recurrenceCount: number;
    recurrenceType: string;
    recurrenceRange: number;
    weekDays:Array<any>;

    constructor(obj?) {
      this.endDate = obj ? obj.endDate : new Date();
      this.recurrenceType = obj ? obj.recurrenceType : 'Never';
      this.occurrenceType = obj ? obj.occurrenceType:'week'; 
      this.recurrenceRange = obj ? obj.recurrenceRange:0; 
      this.recurrenceCount = obj ? obj.recurrenceCount:'1'; 
      this.weekDays = obj ? obj.weekDays:[]; 

    }
  }
