<div class="user-interaction-bar filter-date-wrapper filter-wrapper mobile-wrapper">
  <div class="row w-100 align-items-center h100">
    <ng-container *ngIf="filter === 'blogs'">
      <div class="col-md-9 subheader-wrapper blogsubheader">
        <div class="social-images">
          <ul class="filter-bar-wrap">
            <li>
              <div class="social-inner social-contact" pTooltip="Upload" tooltipPosition="top">
                <img src="../../assets/images/upload.svg" class="default defaulter" alt="active call" />
                <img src="../../assets/images/upload-white.svg" class="white" alt="active call" />
              </div>
            </li>
            <li>
              <div class="social-inner social-contact" pTooltip="Download" tooltipPosition="top">
                <img src="../../assets/images/download.svg" class="default defaulter" alt="active call" />
                <img src="../../assets/images/download-white.svg" class="white" alt="active call" />
              </div>
            </li>
            <li>
              <div class="social-inner subheader-wrapper" pTooltip="Assign To" tooltipPosition="top">
                <img src="../../assets/images/select-icons/assign-grey.svg"
                  class="assign-grey default defaulter assign-header-grey" alt="active call" />
                <img src="../../assets/images/select-icons/assign-white.svg"
                  class="white assign-grey assign-header-grey" alt="active call" />
              </div>
            </li>
            <li>
              <div class="social-inner social-contact" pTooltip="Fax" tooltipPosition="top">
                <img src="../../assets/images/fax.svg" class="default defaulter" alt="active call" />
                <img src="../../assets/images/fax-white.svg" class="white" alt="active call" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="(filter === 'blogedit')">
      <div class="col-md-9 subheader-wrapper blogsubheader">
      </div>
    </ng-container>
    <ng-container *ngIf="(filter === 'idxPageEdit')">
      <div class="col-md-9 subheader-wrapper blogsubheader">
        <div class="social-images">
          <ul class="filter-bar-wrap">
            <li>
              <div class="social-inner social-contact blogsButton" pTooltip="More" tooltipPosition="top">
                <img src="../../assets/images/add-black.svg" class="default defaulter" alt="active call" />
                <img src="../../assets/images/add-white.svg" class="white" alt="active call" />
              </div>
            </li>
            <li>
              <div class="social-inner subheader-wrapper blogsButton" pTooltip="Information" tooltipPosition="top">
                <img src="../../assets/images/info1.svg" class="assign-grey default defaulter assign-header-grey"
                  alt="active call" />
                <img src="../../assets/images/info-white.svg" class="white assign-grey assign-header-grey fixIcon"
                  alt="active call" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!(filter === 'property-details' || filter === 'blogedit' || filter === 'idxPageEdit')">
      <div class="col-md-9" *ngIf="!showFilters">
        <div class="interaction-bar-inner-left date-custom-style d-flex">
          <ul>
         
            <li
             >
              <div class="form-group">
                <input type="text" name="search" autocomplete="off" class="input-area" placeholder="Search"
                  name="search" >
                <img class="search-icon" src="../../assets/images/search.svg" alt="#">
              </div>
            </li>
            <li *ngIf="filter === 'document-page'" class="selected-contact-data selected_property_type">
              <p-dropdown name="createdBy" appendTo="body" [options]="" placeholder="Table Type"
                styleClass="filter-dropdown owned-by" 
                >
              </p-dropdown>
            </li>
            
            <li *ngIf="filter === 'sub-invoices'">
              <p-calendar appendTo="body" name="dueDateRange"  selectionMode="range"
                [numberOfMonths]="2" #dateRangeRef [readonlyInput]="true"
                styleClass="p-calendar" [locale]="'en'"
                placeholder="{{filter === 'sub-invoices' ? 'Invoice Date' : 'Delivery Date'}}" [showIcon]="true">
                <p-footer class="calendar-footer">
                  <div>
                    <span class="date-data"></span>
                  </div>
                  <div>
                    <a class="cancel mt-0"
                      >Cancel</a>
                    <a class="save" >Save</a>
                  </div>
                </p-footer>
              </p-calendar>
            </li>
            
          </ul>
        </div>
      </div>
    </ng-container>
    <div class="col-md-3 text-right pr-0">
      <ul class="interaction-bar-inner-right" *ngIf="filter === 'group-detail'">
        <li>
          <div class="box" pTooltip="Print" tooltipPosition="top">
            <img alt="" src="../../assets/images/print.svg"  class="default print">
            <img alt="" src="../../assets/images/print-white.svg"  class="white">
          </div>
        </li>
        <li>
          <div class="box" pTooltip="Settings" tooltipPosition="top">
            <img alt="" src="../../assets/images/settings.svg" class="default">
            <img alt="" src="../../assets/images/settings-white.svg" class="white">
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<app-setting-sidebar></app-setting-sidebar>
