import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core'; 
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContactRoutingModule } from './patient.routing.module';
import { TagInputModule } from 'ngx-chips';
import { CheckboxModule } from 'primeng/checkbox';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import {TabMenuModule} from 'primeng/tabmenu';
import {MultiSelectModule} from 'primeng/multiselect';
import {LightboxModule} from 'primeng/lightbox';
import {CarouselModule} from 'primeng/carousel';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DragDropModule } from '@angular/cdk/drag-drop';
import {PickListModule} from 'primeng/picklist';
import { PatientProfileComponent } from './patient-profile/patient-profile.component';
import { PatientDetailsComponent } from './patient-details/patient-details.component';
import { PatientComponent } from './patient.component';
// import { AddShiftsComponent } from './add-shifts/add-shifts.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGrigPlugin from '@fullcalendar/timegrid';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { ToastModule } from 'primeng/toast';
import { ImageCropperModule } from 'ngx-image-cropper';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { MenuModule } from 'primeng/menu';
import {SidebarModule} from 'primeng/sidebar';
import {NgPipesModule} from 'ngx-pipes';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ChartsModule } from 'ng2-charts';
import { AddPatientTeamComponent } from './add-patient-team/add-patient-team.component';
import { SchedulingModule } from '../scheduling/scheduling.module';
import {PatientDynamicDialog} from './patient-details/patient-dynamic-dialog';



FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGrigPlugin
]);

@NgModule({
    declarations: [
      PatientComponent,
       PatientProfileComponent,
       PatientDetailsComponent,
      //  AddShiftsComponent,
       AddPatientTeamComponent,
       PatientDynamicDialog,

    ],
    imports: [
      CommonModule,
      EditorModule,
      RouterModule,
      IonicModule,
      SharedModule,
      FormsModule,
      ReactiveFormsModule,
      TableModule,
      InputSwitchModule,
      TagInputModule,
      DropdownModule,
      CheckboxModule,
      ContactRoutingModule,
      ProgressSpinnerModule,
      DialogModule,
      CalendarModule,
      TabMenuModule,
      MultiSelectModule,
      LightboxModule,
      CarouselModule,
      DragDropModule,
      PickListModule,  
      FullCalendarModule,
      AccordionModule,
      ButtonModule,
      ToastModule,
      ImageCropperModule,
      OverlayPanelModule,
      MenuModule,
      SidebarModule,
      NgPipesModule,
      SelectButtonModule,
      ChartsModule,
      SchedulingModule
    ],
    exports:      [ AddPatientTeamComponent, ],
    entryComponents: [
      PatientDynamicDialog
    ],

  })
  export class PatientModule { }
  