import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
// import { SolverComponent } from './scheduling.component';
import { SchedulingComponent } from './scheduling.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'all-scheduling',
    pathMatch: 'full'
  }, 
  {
    path: 'all-scheduling',
    component: SchedulingComponent
  },
  {
    path: 'all-scheduling/add-session',
    component: SchedulingComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContactRoutingModule { }

