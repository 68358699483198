import { Utils } from '../utils';
import { AppService } from '../app.service';
import { PatientService } from './patient.service';
import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { PopUpService } from '../shared/pop-up/pop-up.service'
import { Title } from '@angular/platform-browser';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { PatientColumns } from './patient.model';
import { Subscription } from 'rxjs';
import { getLanguage } from './../languages';
import { LanguageInterface } from './../languages/interface.language';
import { AppExService } from '../app-ex.service';
import * as _ from 'underscore';
import { ProviderService } from '../provider/provider.service';
import { PatientTeamService } from '../patient-team/patient-team.service';
import { config } from 'src/app/config';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit, OnDestroy  {
  tableColumns = PatientColumns;
  tableColumnsLabel = PatientColumns.map(obj=>{
    return obj.value;
  })
  filtertableColumns = ['firstName','email','phone','patientTeam'];
  filtertableColumnsDev = [...this.filtertableColumns];
  selectedPatient: any;
  searchParam:string
  page:number;
  searchText = '';
  integrationWithSdBook:boolean = false;
  getPatient: any[] =[]
  clinicId = null;
  rows = 50;
  total = 0;
  checkElSorted = true;
  rowNumber = 1
  pageSize = 50;
  confirmDelete = false;
  langSubs: Subscription;
  @ViewChild('searchInput') searchInput: ElementRef;
  pageStrings: LanguageInterface;
  debounceFunction = _.debounce(() => this.createFilterData(), 500);
  clinicFilterSubs: Subscription;
  listProvider = [];
  selectedTeam = [];
  @ViewChild("multiInput") multiInput: any;

  filterStatus = true;
  statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
];
statusOptionsAll = [
  { label: 'All', value: null },
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
];
imgBaseURL = config.baseUrl;
isMobileView:Boolean = false;
isTabView = window.innerWidth > 600 && window.innerWidth <= 800;
searchTextPTeam:any;

  constructor(
    public patientService: PatientService,
    public providerService: ProviderService,
    public patientTeamService: PatientTeamService,
    public appService: AppService,
    public utils: Utils,
    public popUpService: PopUpService,
    public title: Title,
    public messageService: MessageService,
    public router: Router,
    public appExService:AppExService
  ) { this.isMobileView = window.innerWidth <= 600; }
  displaySideBarFun(){
    setTimeout(() => {
    this.multiInput.show();
    }, 300);
  }
    showPopup = true;
  onPanelHide(event){
    if(this.showPopup ){
      this.showPopup = true;
      this.multiInput.show();
      // event.stopPropagation();
    }
  }
  hideMe(event){
    if(event){
    this.showPopup = false;
    this.multiInput.hide();
    event.stopPropagation();
  }
}
  hideMeNot(event){
    if(event){
    this.showPopup = true;
    this.multiInput.show();
    event.stopPropagation();
  }
}

  createFilterData(){
    const payload = { page: this.page, limit: this.rows }
    if(this.searchText !== ''){
      payload["searchParam"] = this.searchText;
    }
    if(this.clinicId){
      payload["clinicId"] = this.clinicId;
    }
    this.getPatients(payload);
  }
  callDelete(data){
    this.confirmDelete = true;
    this.selectedPatient = data;

  }
  callAssignTeam(data){
    this.appService.updateProviderTeamSidebarState({state: true,data:data});
  }
  callProviderTeam(data){
    this.appService.updatePrimaryProviderTeam({state: true,data:data})
  }
  callAssignTeamEdit(data){
    this.searchTextPTeam = '';

    let selectedIds = data.providerInfoList ? data.providerInfoList.map((obj)=>{return (obj.id)}): [];
    let listProviderIds = this.listProvider.map((obj)=>{return (obj.value)});
    let findIndexSelectedIds = [];

    selectedIds.forEach((obj)=>{
      findIndexSelectedIds.push(this.listProvider[listProviderIds.indexOf(obj)]);
    });
    this.selectedTeam = findIndexSelectedIds;
    
    this.selectedPatient = {...data, edit: true};    
  }
  onSaveClick(){
    const successMessage = this.selectedPatient.edit ? 'updated' : 'created';
    const payload = {patientId: this.selectedPatient.id,providerIds: this.selectedTeam.map(obj=>obj.value),};
    if(this.selectedPatient.edit){
      if(payload.providerIds.length === 0){
        this.patientTeamService.deletetPatientTeam(this.selectedPatient.id).subscribe((response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });

          this.getPatients({ page: this.page, limit: this.rows });
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong' });
        });
      }
      else{
        this.patientTeamService.updatePatientTeam(payload).subscribe((response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });

          this.getPatients({ page: this.page, limit: this.rows });
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      }

    }
    else{
      this.patientTeamService.addPatientTeam(payload).subscribe((res) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.message });

        this.getPatients({ page: this.page, limit: this.rows });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
    }
  }



  getProviderList(data) {
    this.providerService.getProviderListData().subscribe((response: any) => {
      if (response.data) {
        this.listProvider = response.data.map(obj => {
          return ({ name: `${obj.firstName} ${obj.lastName} (${obj?.employeeId})`, value: obj.id, documentId:obj.documentId });
        });
      }
    });
  }

  callEditPage(data){
    if(data.active === true){
      this.router.navigate(["/patient/patient-details"], {
        queryParams: { id: data.id},
      });
    }
  }

 

  updateTableData(data){
    if(data.success){
    const payload = { page: this.page, limit: this.rows }
    if(this.searchText !== ''){
        payload["searchParam"] = this.searchText;
      }
      if(this.clinicId){
        payload["clinicId"] = this.clinicId;
      }
      this.getPatients(payload, data);
    }
  }
  filter(data) {
    // this.appService.updateFilterSidebarState({state: true, ...data});
    this.appService.patientProfileSidebarState({state: true, ...data});
  }
  ngOnInit(): void { 
    this.getProviderList({ page: 1, limit: 1000 , status:true, sortBy:'name'});
    this.appService.updateHeaderName({ name: 'Patients', subName: 'All Patients', count: 0 });
    this.langSubs = this.appExService.getLanguageOptions.subscribe(data => {
      if(data.lang){
        this.pageStrings = getLanguage(data.lang);
        const str = this.pageStrings;
        // this.tableColumns[0].header = 'Patient Information';
        // this.tableColumns[1].header = str.lblGroups
        // this.tableColumns[2].header = 'Patient Team';
        // this.tableColumns[3].header = 'Status';
      }
    });
    this.clinicFilterSubs = this.appExService.getFilteredClinicList.subscribe(data => {
      if(data.clinicNo){
        const payload = { page: this.page, limit: this.rows };
        payload["clinicId"] = data.clinicNo;
        this.searchText = '';
        this.clinicId = data.clinicNo;
        this.getPatients(payload);
        this.getProviderList({ page: 1, limit: 1000 , status:true, sortBy:'name'});
      }
    })

  }

  filterTable(event){   

    if(event.hasOwnProperty('itemValue')){ 
      if(this.filtertableColumns.includes(event.itemValue)){// add
        this.filtertableColumnsDev.splice(this.tableColumnsLabel.indexOf(event.itemValue),0,event.itemValue);
      }else{// remove
        this.filtertableColumnsDev.splice(this.filtertableColumnsDev.indexOf(event.itemValue),1);
      }
    }else{
      this.filtertableColumnsDev = event.value;
    }
    if(this.filtertableColumnsDev.length <= 0){
      this.filtertableColumns = ['firstName','email','phone','patientTeam'];
      this.filtertableColumnsDev = [...this.filtertableColumns];
    }
     
  }

  loadContactPageData(res) {
   
  }

  resetCheckBoxList() {
    
  }

  ngOnDestroy(): void {
    this.langSubs && this.langSubs.unsubscribe();
    this.clinicFilterSubs && this.clinicFilterSubs.unsubscribe();
  }

  /**
   * To popup opening subscription
   */
  subscribePopups() {
  }

  /**
   * To load contact list
   * @param event Table event
   */
  loadpatient(event:LazyLoadEvent) {


    // sort by sortfield
    if(event.sortField && (this.page == (event.first / this.rows + 1) && this.rows == event.rows)) {
      this.sortWithPagination(event)
    }else{
      
      this.rows = event.rows;
      this.pageSize = event.first + this.rows;
      this.rowNumber = event.first + 1;
      this.page = event.first / this.rows + 1;
      const payload = { page: this.page, limit: this.rows }
      if(this.searchText !== ''){
        payload["searchParam"] = this.searchText;
      }
      if(this.clinicId){
        payload["clinicId"] = this.clinicId;
      }
      this.getPatients(payload);
    }

  }
  onSortPatient(event){
    if(event.order == 1){
      this.checkElSorted = true;
    }
    else{
      this.checkElSorted = false;
    }
  }
  sortWithPagination(event){
    this.getPatient.sort((data1, data2) => {
      let value1 = data1[event.sortField];
      let value2 = data2[event.sortField];
      let result = null;
      
      if (value1 == null && value2 != null)
      result = -1;
      else if (value1 != null && value2 == null)
      result = 1;
      else if (value1 == null && value2 == null)
      result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
      result = value1.localeCompare(value2);
      else
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      
      return (event.sortOrder * result);
      });
  }
  updateTableDataFilter(){
    const payload = { page: this.page, limit: this.rows}
    if(this.searchText !== ''){
        payload["searchParam"] = this.searchText;
      }
      if(this.clinicId){
        payload["clinicId"] = this.clinicId;
      }
      if(this.filterStatus !== null){
        payload["status"] = this.filterStatus;
      }
      this.getPatients(payload);
  }
  
  getPatients(data, addedData?){
    this.appService.showCustomLoader(true);
    this.patientService.getPatient({...data,status: this.filterStatus}).subscribe((response:any)=>{
     if(response.data){
        this.appService.showCustomLoader(false);
        this.integrationWithSdBook = response?.isIntegrationWithSdBook;
       this.getPatient=response.data.map((el)=>{
         const patientDTO = el.patientDTO;
         return {
           formData: patientDTO,
           id:patientDTO.id,
           
           userId:patientDTO.userId,
           active:patientDTO.active,
           email:patientDTO.email,
           phone:patientDTO.phoneNo,
           aggerssiveness: patientDTO.aggerssiveness,
           skillsName: patientDTO.patientSkillDTOS ? patientDTO.patientSkillDTOS.map(obj=>
            {
              return(
                `${obj.skill.skillName}`
              )
            }) : '',
           city: patientDTO.city,
           clinics:patientDTO.clinics.map(obj=>obj.clinicName).join(", "),
           groups:patientDTO.groupTherapyGroups ? patientDTO.groupTherapyGroups.map(obj=>
            {
              return(
                `${obj.groupName}`
              )
            }) : '',
           country: patientDTO.country,
           firstName: patientDTO.firstName,
           gender: patientDTO.gender,
           patientLocation:patientDTO.patientLocation,
           lastName: patientDTO.lastName,
           patientId: patientDTO.patientId,
          //  profilePic: patientDTO.profilePic,
           documentId: patientDTO.documentId,
           authorizedUnits: patientDTO.authorizedUnits,
           totalUnits: patientDTO.totalUnits,
           state: patientDTO.state,
           street: patientDTO.street,
           togglePatient:true,
           toggleAttachment: true,
           zipCode: patientDTO.zipCode,
           providerInfoList: el.providerInfoList.sort((a, b) => a.firstName.localeCompare(b.firstName)),
           providerName:el.providerInfoList ? el.providerInfoList.map(obj=>
            {
              return(
                `${obj.firstName} ${obj.lastName}`
              )
            }).join(", ") : '',
         }
       });  
       this.sortWithPagination({sortField: 'firstName', sortOrder: this.checkElSorted ? 1 : -1});
       setTimeout(() => {
         if(!addedData?.edit) {
           this.getPatient.forEach(item => {
             if(item.formData.patientId == addedData?.id) {
              this.selectedTeam = [];
              this.callAssignTeam({id:item?.id,assignedPatient:[]})
            }
          });
        }
       }, 800);
     }
     else {
      this.getPatient = [];
      this.appService.showCustomLoader(false);
    }
      this.total = response.count;
    },(error)=>{
      this.appService.showCustomLoader(false);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong' });
    });
  }
  deletePatient() {
    this.patientService.deletePatient(this.selectedPatient.id).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Patient deleted successfully' });
      this.confirmDelete = false;
      this.getPatients({ page: this.page, limit: this.rows });
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong' });
    });
  }

  updateSpecField(rowData, type, i = 0) {
    const dataToSend = { contact_id: rowData.id };
    if (type === "Status") {
      dataToSend["id"] = rowData.userId;
      dataToSend["sectionId"] = rowData.id;
    } else {
      dataToSend["groups"] = rowData.groupList;
    }
    this.providerService.updateProviderStatus(dataToSend).subscribe(
      (response) => {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: response.body.message,
        });
        this.updateTableDataFilter();
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong' });
      });
  }
  sortSkills(skills: any[]){
    if (!Array.isArray(skills)) {
      return skills;
    }

    return skills.slice().sort((a, b) => {
      const nameA = (a || '').toLowerCase();
      const nameB = (b || '').toLowerCase();

      return nameA.localeCompare(nameB);
    });
  }
  checkClick(e){
    e.preventDefault()
  }
  
}