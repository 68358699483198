<form #loginForm="ngForm"  (ngSubmit)="login(loginModel)" class="flipInX">
  <div class="main-block" *ngIf="!isMobileView && !isTabView">
   <div class="sub-main">
    <div class="left">
     <div class="imageOne mySlides" style="width: 100%;" >
      <img src="../../assets/images/sliderImg1.jpg" alt="">
    </div>
    <div class="mySlides" style="width: 100%;">
     <img src="../../assets/images/slideImg4.jpg" alt="">
    </div>
    <div class="mySlides" style="width: 100%;" >
     <img src="../../assets/images/sliderImg5.jpg" alt="">
    
    </div>
    
     <div class="slider-dots" style="text-align:center">
      <span class="dot"></span> 
      <span class="dot"></span> 
      <span class="dot"></span> 
    </div>
    </div>
    <div class="right">
      <div class="login">
        <!-- <div class="login-header-img">
          <img [src]="publicData.company_logo" alt="logo">
        </div> -->
        <div class="login-header setBoxStyle user-data">
          <div class="login-header-img">
            <img [src]="publicData.company_logo" alt="logo">
          </div>
          <h4 class="logtext">Login </h4>
          <h6 class="subText">Sign in to your account </h6>
          <div class="vaildation-area">
            <div class="col-md-12 user-field">
              <ion-label class="label required">Email</ion-label>
              <ion-input 
              required #email="ngModel" name="email" [(ngModel)]="loginModel.email" 
                class="user-input" type="text" [ngClass]="(email.errors && ( email.touched || loginForm.submitted)) ? 'LoginerrorBorder' : ''"></ion-input>
                <div class="vaiidate-field" *ngIf="email.errors && ( email.touched || loginForm.submitted)">
                  <p [hidden]="!email.errors.required">Email is required</p>
                  <p [hidden]="!email.errors.pattern">Please enter a valid email</p>
                </div>
            </div>
            <div class="col-md-12 user-field">
              <ion-label class="label required">Password</ion-label>
              <!-- <ion-item> -->
                <ion-input maxlength="30" (keyup.enter)="login(loginModel)" [autocomplete]="'off'" [type]="isTextFieldType ? 'text' : 'password'" required #password="ngModel" name="password"
                  [(ngModel)]="loginModel.password" class="user-input" style="background-color: white !important;" [ngClass]="(password.errors && ( password.touched || loginForm.submitted)) ? 'LoginerrorBorder' : ''">
                </ion-input>
                <span (click)="togglePasswordFieldType($event)"
                  class="eye-btn" [ngClass]="(password.errors && ( password.touched || loginForm.submitted)) ? 'eye-btn-error-top' : 'eye-btn-top'">
                  <ion-icon [name]="isTextFieldType ? 'eye' : 'eye-off'">
                  </ion-icon>
                </span>
              <!-- </ion-item> -->
              <div class="vaiidate-field" *ngIf="password.errors && ( password.touched || loginForm.submitted)">
                <p [hidden]="!password.errors.required">Password is required</p>
              </div>
            </div>
          </div>
          <div class="setReset">
            <!-- <label class="rmbr-me-btn">
              <p-checkbox [(ngModel)]="loginModel.remember_me" styleClass="custom-checkbox rmbr-me-checkbox"
                name="rememberMe" id="rememberMe" binary="true">
              </p-checkbox>Remember me
            </label>        -->
            <a class="sign-in-btn" [routerLink]="['/forgot']">Forgot Password?</a>
          </div>
          <div class="setWidth">
            <div *ngIf="false" class="capthcha">
              <p-captcha #recaptcha siteKey="{{env.captcha}}" (onResponse)="onCaptchaSuccess($event)"
                (onExpire)="onCaptchaExpire()"></p-captcha>
            </div>
            <ion-button class="btn-main btn-full margin-top" type="submit">Login</ion-button>
          </div>
        </div>
      </div>
    </div>
   </div>
  </div>
  <!-- FOR MOBILE VIEW -->
  <div class="login" *ngIf="isMobileView || isTabView">
    <!-- <div class="login-header-img">
      <img [src]="publicData.company_logo" alt="logo">
    </div> -->
    <div class="login-header setBoxStyle user-data" >
      <div class="login-header-img">
        <img [src]="publicData.company_logo" alt="logo">
      </div>
      <h4 class="logtext">Login </h4>
      <h6 class="subText">Sign in to your account </h6>
      <div class="vaildation-area">
        <div class="col-md-12 user-field">
          <ion-label class="label required">Email</ion-label>
          <ion-input 
          required #email="ngModel" name="email" [(ngModel)]="loginModel.email" 
            class="user-input" type="text" [ngClass]="(email.errors && ( email.touched || loginForm.submitted)) ? 'LoginerrorBorder' : ''"></ion-input>
            <div class="vaiidate-field" *ngIf="email.errors && ( email.touched || loginForm.submitted)">
              <p [hidden]="!email.errors.required">Email is required</p>
              <p [hidden]="!email.errors.pattern">Please enter a valid email</p>
            </div>
        </div>
        <div class="col-md-12 user-field">
          <ion-label class="label required">Password</ion-label>
          <!-- <ion-item> -->
            <ion-input [autocomplete]="'off'" [type]="isTextFieldType ? 'text' : 'password'" required #password="ngModel" name="password"
              [(ngModel)]="loginModel.password" class="user-input" style="background-color: white !important;" [ngClass]="(password.errors && ( password.touched || loginForm.submitted)) ? 'LoginerrorBorder' : ''">
            </ion-input>
            <button (click)="togglePasswordFieldType($event)"
              class="eye-btn" [ngClass]="(password.errors && ( password.touched || loginForm.submitted)) ? 'eye-btn-error-top' : 'eye-btn-top'">
              <ion-icon [name]="isTextFieldType ? 'eye' : 'eye-off'">
              </ion-icon>
            </button>
          <!-- </ion-item> -->
          <div class="vaiidate-field" *ngIf="password.errors && ( password.touched || loginForm.submitted)">
            <p [hidden]="!password.errors.required">Password is required</p>
          </div>
        </div>
      </div>
      <div class="setReset">
        <!-- <label class="rmbr-me-btn">
          <p-checkbox [(ngModel)]="loginModel.remember_me" styleClass="custom-checkbox rmbr-me-checkbox"
            name="rememberMe" id="rememberMe" binary="true">
          </p-checkbox>Remember me
        </label>        -->
        <a class="sign-in-btn" [routerLink]="['/forgot']">Forgot Password?</a>
      </div>
      <div class="setWidth">
        <div *ngIf="false" class="capthcha">
          <p-captcha #recaptcha siteKey="{{env.captcha}}" (onResponse)="onCaptchaSuccess($event)"
            (onExpire)="onCaptchaExpire()"></p-captcha>
        </div>
        <ion-button class="btn-main btn-full margin-top" type="submit">Login</ion-button>
      </div>
    </div>
  </div>
</form>