import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(
    public sharedService : SharedService
  ) { }

  getServiceAPI(id){
    const url = `${config.settingDataGet}?clinicId=${id}`;
    return this.sharedService.getData(url)
  }
  updateServiceAPI(data){
    const url = `${config.settingDataGet}`;
    return this.sharedService.putData(url,data)
  }
}