import { Utils } from '../utils';
import { AppService } from '../app.service';
import { ProviderService } from './provider.service';
import { Component, OnInit, OnDestroy, HostListener, ElementRef, ViewChild } from '@angular/core';
import { PopUpService } from '../shared/pop-up/pop-up.service'
import { Title } from '@angular/platform-browser';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { getLanguage } from './../languages';
import { LanguageInterface } from './../languages/interface.language';
import { ProviderColumns } from './provider.model';
import { Subscription } from 'rxjs';
import { AppExService } from '../app-ex.service';
import * as _ from 'underscore';
import { ClinicService } from '../clinics/clinics.service';
import { config } from 'src/app/config';
import { AuthenticateService } from '../authenticate/authenticate.service';
import { data } from 'jquery';
import { PlannerService } from '../planner/planner.service';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss']
})
export class ProviderComponent implements OnInit, OnDestroy {
  tableColumns = ProviderColumns;
  tableColumnsLabel = ProviderColumns.map(obj => {
    return obj.value;
  })
  isBtnDisable:Boolean = true;
  filtertableColumns = ['firstName', 'employeePosition', 'skills', 'patient']
  filtertableColumnsDev = [...this.filtertableColumns];
  getAddProviderName: any;
  rows = 50;
  providerId: any;
  total = 0;
  providerData = null;
  savedPayload = null;
  rowNumber = 1
  email: any;
  mailSent = false;
  page: number;
  pageSize = 50;
  searchText = '';
  clinicId = null;
  confirmDelete = false;
  confirmResetPassword = false;
  langSubs: Subscription;
  selectedProvider: any;
  getProviderlist: any[] = [];
  integrationWithSdBook:any;
  pageStrings: LanguageInterface;
  userData = null;
  filterStatus = true;
  showTeam = false;
  selectedTeam = [];
  checkElSorted = true;
  listProvider = [];
  addedProviderId: any;
  isMobileView: Boolean = false;
  isTabView = window.innerWidth > 600 && window.innerWidth <= 800;
  assignedPatientAPIType: any;

  listClinic: any[] = []
  @ViewChild('searchInput') searchInput: ElementRef;
  debounceFunction = _.debounce(() => this.createFilterData(), 500);
  clinicFilterSubs: Subscription;
  statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];
  statusOptionsAll = [
    { label: 'All', value: null },
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];
  changeRolePopup: Boolean = false;

  userRoleTypeModel: any;
  userRoleTypeList: Array<any> = [];
  selectedRowOfAddRole: any;
  ifUserHaveAllRoles: boolean = false;
  ifUserHaveAllRolesData = { provider: '', planner: '' };
  selectedOneValue: String = "val1";
  ChangeRoleClinicIds: Array<any> = [];
  isEdit: Boolean = false;
  allClinicList: any[] = [];
  allRowData: any;
  responseData: any[]=[];
  CurrentClinicName: any;
  userRole = "";
  providerFullName: string = '';

  imgBaseURL = config.baseUrl;
  constructor(
    public authService: AuthenticateService,
    public providerService: ProviderService,
    public appService: AppService,
    public utils: Utils,
    public popUpService: PopUpService,
    public title: Title,
    public messageService: MessageService,
    public router: Router,
    public appExService: AppExService,
    public clinicService: ClinicService,
    public plannerService: PlannerService
  ) { this.isMobileView = window.innerWidth <= 600; }

  createFilterData() {
    const payload = { page: this.page, limit: this.rows }
    if (this.searchText !== '') {
      payload["searchParam"] = this.searchText;
    }
    if (this.clinicId) {
      payload["clinicId"] = this.clinicId;
    }
    this.getProvider(payload);
  }

  callDelete(data) {
    this.selectedProvider = data;
    this.confirmDelete = true;
  }

  callResetPassword(data) {
    this.selectedProvider = data;
    this.confirmResetPassword = true;
  }

  callEditPage(data) {
    if (data.isActive === true) {
      this.router.navigate(["/provider/provider-details"], {
        queryParams: { id: data.id },
      });
    }
  }
  filter(data) {
    console.log('hello')
    this.appService.providerProfileSidebarState({ state: true, ...data });
  }
  updateTableData(data) {
    if (data.success) {
      this.getAddProviderName = data.providerName;
      const payload = { page: this.page, limit: this.rows }
      if (this.searchText !== '') {
        payload["searchParam"] = this.searchText;
      }
      if (this.clinicId) {
        payload["clinicId"] = this.clinicId;
      }
      this.getProvider(payload, data);
    }
  }

  updateTableDataFilter() {
    const payload = { page: this.page, limit: this.rows }
    if (this.searchText !== '') {
      payload["searchParam"] = this.searchText;
    }
    if (this.clinicId) {
      payload["clinicId"] = this.clinicId;
    }
    if (this.filterStatus !== null) {
      payload["status"] = this.filterStatus;
    }
    this.getProvider(payload);
  }

  ngOnInit(): void {
    const remD = JSON.parse(localStorage.getItem('optplanner_user'));
    this.userRole = remD.userType.type;
    // this.getPatientList({ page: 1, limit: 1000, status: true, sortBy: 'name' });
    this.appService.updateHeaderName({ name: 'Providers', subName: 'All Providers', count: 0 });
    this.langSubs = this.appExService.getLanguageOptions.subscribe(data => {
      if (data.lang) {
        this.pageStrings = getLanguage(data.lang);
        const str = this.pageStrings;
        // this.tableColumns[0].header = 'Provider Name';
        // this.tableColumns[1].header = 'Email';
        // this.tableColumns[2].header = str.lblSkills;
        // this.tableColumns[3].header = 'Provider Team';
        // this.tableColumns[4].header = 'Status';

      }
    });
    this.clinicFilterSubs = this.appExService.getFilteredClinicList.subscribe(data => {
      if (data.clinicNo) {
        const payload = { page: this.page, limit: this.rows };
        payload["clinicId"] = data.clinicNo;
        this.searchText = '';
        this.clinicId = data.clinicNo;
        this.getProvider(payload);
      }
    })
    this.getClinicList();
  }

  loadContactPageData(res) {

  }

  resetCheckBoxList() {

  }

  ngOnDestroy(): void {
    this.langSubs && this.langSubs.unsubscribe();
    this.clinicFilterSubs && this.clinicFilterSubs.unsubscribe();
  }

  getClinicList() {
    this.listClinic = JSON.parse(localStorage.getItem('optplanner_user')).assignedClinics.map(obj => {
      return {
        name: obj.label, value: obj.value
      };
    });
  }

  /**
   * To popup opening subscription
   */
  subscribePopups() {
  }

  /**
   * To load contact list
   * @param event Table event
   */
  loadProvider(event: LazyLoadEvent) {
    if (event.sortField && (this.page == (event.first / this.rows + 1) && this.rows == event.rows)) {
      this.sortWithPagination(event);
    }
    else {
      this.rows = event.rows;
      this.pageSize = event.first + this.rows;
      this.rowNumber = event.first + 1;
      this.page = event.first / this.rows + 1;
      const payload = { page: this.page, limit: this.rows }
      if (this.searchText !== '') {
        payload["searchParam"] = this.searchText;
      }
      if (this.clinicId) {
        payload["clinicId"] = this.clinicId;
      }
      this.getProvider(payload);
    }
  }
  onSortProvider(event) {
    if (event.order == 1) {
      this.checkElSorted = true;
    }
    else {
      this.checkElSorted = false;
    }
  }

  sortWithPagination(event) {
    this.getProviderlist.sort((data1, data2) => {
      let value1 = data1[event.sortField];
      let value2 = data2[event.sortField];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.sortOrder * result);
    });
  }

  filterTable(event) {
    if (event.hasOwnProperty('itemValue')) {
      if (this.filtertableColumns.includes(event.itemValue)) {
        this.filtertableColumnsDev.splice(this.tableColumnsLabel.indexOf(event.itemValue), 0, event.itemValue);
      } else {
        this.filtertableColumnsDev.splice(this.filtertableColumnsDev.indexOf(event.itemValue), 1);
      }
    } else {
      this.filtertableColumnsDev = event.value;
    }
    if (this.filtertableColumnsDev.length <= 0) {
      this.filtertableColumns = ['firstName', 'employeePosition', 'skills', 'patient'];
      this.filtertableColumnsDev = [...this.filtertableColumns];
    }

  }

  updateSpecField(rowData, type, i = 0) {
    const dataToSend = { contact_id: rowData.id };
    if (type === "Status") {
      dataToSend["id"] = rowData.userId;
      dataToSend["sectionId"] = rowData.id;
    } else {
      dataToSend["groups"] = rowData.groupList;
    }
    dataToSend["isProvider"] = true;
    this.providerService.updateProviderStatus(dataToSend).subscribe(
      (response) => {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: response?.body?.message ? response?.body?.message : response?.message,
        });
        this.updateTableDataFilter();
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong' });
        this.updateTableDataFilter();
      });
  }

  getProvider(data, addedData?) {
    this.appService.showCustomLoader(true);
    this.providerService.getProvider({ ...data, status: this.filterStatus }).subscribe((response: any) => {
      if (response.data && response.data.length > 0) {
        this.appService.showCustomLoader(false);
        // this.integrationWithSdBook = response?.isIntegrationWithSdBook;
        this.getProviderlist = response.data.map((el) => ({
          formData: el,
          id: el.id,
          email: el.email,
          phone: el.phoneNo,
          userId: el.userId,
          city: el.city,
          isActive: el.isActive,
          clinics: el.clinics ? el.clinics.map(obj => obj.clinicName).join(", ") : '',
          clinicIds: el.clinics ? el.clinics.map(obj => obj.id) : '',
          country: el.country,
          firstName: el.firstName,
          employeePosition: el.jobTitle,
          gender: el.gender,
          lastName: el.lastName,
          providerAddress: el.providerAddress,
          //  profilePic: el.profilePic,
          documentId: el.documentId,
          skills: el.priority,
          state: el.state,
          payroll:el.payroll,
          street: el.street,
          zipCode: el.zipCode,
          providerSkills: el.providerSkills.sort((a, b) => a.skill.skillName.localeCompare(b.skill.skillName)),
          assignedPatient: el.assignedPatients.filter((obj) => {
            if (obj.patientActive) {
              return obj
            }
          }) || [],
          toggleAttachment: true,
          togglePatient: true,
          skillsName: el.providerSkills ? el.providerSkills.map(obj => obj.skill.skillName).join(", ") : ''
        }));
        this.sortWithPagination({ sortField: 'firstName', sortOrder: this.checkElSorted ? 1 : -1 });
        // setTimeout(() => {
        //   if (!addedData?.edit) {
        //     this.getProviderlist.forEach(item => {
        //       if (item.formData.employeeId == addedData?.id) {
        //         this.selectedTeam = [];
        //         this.callAssignTeam({ id: item?.id, assignedPatient: [] })
        //         this.addedProviderId = item?.id
        //       }
        //     });
        //   }
        // }, 800);
       
      setTimeout(() => {
        if (!addedData?.edit) {
          for (let item of this.getProviderlist) {
            if (item.formData.employeeId === addedData?.id) {
              this.selectedTeam = [];
              this.callAssignTeam({ id: item?.id, assignedPatient: [] });
              this.addedProviderId = item?.id;
              break;
            }
          }
        }
      }, 800);
      }
      else {
        this.getProviderlist = [];
        this.appService.showCustomLoader(false);
      }
      this.total = response.count;
    }, (error) => {
      this.appService.showCustomLoader(false);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong' });
    })
  }


  deleteProvider() {
    this.providerService.deleteProvider(this.selectedProvider.id).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Provider deleted successfully' });
      this.confirmDelete = false;
      this.getProvider({ page: this.page, limit: this.rows });
      this.getPatientList({ page: 1, limit: 1000, status: true, sortBy: 'name' });

    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong' });
    });
  }

  getPatientList(data) {
    // this.providerService.getPatientList(data).subscribe((response: any) => {
    //   if (response.data) {
    //     this.listProvider = response.data.map(obj => {
    //       return { name: `${obj.firstName} ${obj.lastName}`, value: obj.id }
    //     });
    //   }
    // });
  }

  onSaveClick() {
    const payload = {
      providerId: this.providerId,
      patientIds: this.selectedTeam.map(obj => obj.value),
    };
    if (this.selectedTeam.length === 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select any value.' });
    } else {
      this.showTeam = false;
      if (this.assignedPatientAPIType === 0) {//table data is blank call post API
        this.providerService.addProviderTeam(payload).subscribe((res) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: `Patient assigned successfully from the team` });
          this.getProviderlist.forEach(item => {
            if (item.formData.employeeId == this.providerId) {
              item.assignedPatients = res.body.data?.assignedPatients
            }
          });
          this.updateTableDataFilter();
          this.getPatientList({ page: 1, limit: 1000, status: true, sortBy: 'name' });
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      } else {//call put API
        this.providerService.updateProviderTeam(payload).subscribe((res) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: `Patient assigned successfully from the team` });
          this.getProviderlist.forEach(item => {
            if (item.formData.employeeId == this.providerId) {
              item.assignedPatients = res.body.data?.assignedPatients
            }
          });
          this.updateTableDataFilter();
          this.getPatientList({ page: 1, limit: 1000, status: true, sortBy: 'name' });
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      }

    }
  }

  // Sortarr( a, b ) {// To Sort array of Object with name
  //   if ( a.firstName < b.firstName ){
  //     return -1;
  //   }
  //   if ( a.firstName > b.firstName ){
  //     return 1;
  //   }
  //   return 0;
  // }

  // onSaveClickk(){
  //   const payload = {
  //     providerId: this.providerData.id,
  //     patientIds: this.selectedTeam.map(obj=>obj.value),
  //   };
  //   this.showTeam = false;
  //   this.providerService.addProviderTeam(payload).subscribe((res) => {
  //     this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.message });
  //     this.providerData.assignedPatients = res.body.data?.assignedPatients.sort(this.Sortarr) || [];
  //     this.getProvider(this.savedPayload);
  //     this.getPatientList({ page: 1, limit: 1000 , status:true, sortBy:'name'});
  //   }, (error) => {
  //     this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
  //   });
  // }

  closeProvider(i) {
    this.selectedTeam.splice(i, 1)
    this.selectedTeam = this.selectedTeam.map(o => o);
  }
  resetPassword(email) {
    this.appService.showCustomLoader(true);
    email = email.toLowerCase();
    this.authService.forgotPassword(email).subscribe((response) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Password Recovery email send successfully' });
      this.appService.showCustomLoader(false);
      this.confirmResetPassword = false;
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
    });
  }

  callAssignTeam(data) {
    this.appService.updatePatientTeamSidebarState({ state: true, data: data });
  }

  addTeam(data) {
    this.selectedTeam = [];
    this.assignedPatientAPIType = data.assignedPatient.length;
    this.providerId = data.id;
    this.showTeam = true;

    let patient = data.assignedPatient
    if (patient) {

      let values = patient ?
        patient.map(obj => {
          return ({
            name: `${obj.firstName} ${obj.lastName}`,
            value: obj.id,
          });
        }) : [];
      let check = values.map(element => {
        return (this.listProvider.filter((obj) => obj.value === element.value)[0]);
      });
      this.selectedTeam = check.filter((obj) => { return obj !== undefined; });
    }
    // this.selectedPatient = {...patient, edit: true};
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  AddRolePopupFun(uData) {
    this.allRowData = uData
    this.providerFullName = this.toTitleCase(uData.firstName + ' ' + uData.lastName);
    this.CurrentClinicName = JSON.parse(localStorage.getItem('optplanner_user')).assignedClinics.find((obj) => obj.value === JSON.parse(localStorage.getItem('assignedClinic')));

    this.userRoleTypeModel = null;
    this.ChangeRoleClinicIds = uData.formData.clinics.map((obj) => { return { name: obj.clinicName, value: obj.id } });
    this.listClinic = [...this.ChangeRoleClinicIds]
    this.selectedRowOfAddRole = uData;
    const payload = {
      id: uData.id,
      clinicId: JSON.parse(localStorage.getItem('assignedClinic')),
      type: 'provider'
    }

    this.userRoleTypeList = [];
    // this.ifUserHaveAllRolesData = [];
    this.ifUserHaveAllRolesData.provider = '';
    this.ifUserHaveAllRolesData.planner = '';
    this.appService.showCustomLoader(true);

    this.authService.getUserTypesByID(payload).subscribe((response) => {
      this.responseData = response.data;
      const roles = response.data.map((obj) => { return obj.type });
      if (!(roles.includes('provider') && roles.includes('planner'))) {
        if (roles.includes('provider')) {
          this.userRoleTypeList.push({ label: 'Planner', value: 2 });
        }
        if (roles.includes('planner')) {
          this.userRoleTypeList.push({ label: 'Provider', value: 3 });
        }
        this.ifUserHaveAllRoles = false
      } else {
        this.ifUserHaveAllRoles = true;
        this.userRoleTypeList.push({ label: 'Planner', value: 2 });
        this.userRoleTypeList.push({ label: 'Provider', value: 3 });
        this.userRoleTypeModel = 3;
        response.data.forEach((obj) => {
          if (obj.type === "provider") {
            this.ifUserHaveAllRolesData.provider += obj.clinicName + ' ';
          } else {
            this.ifUserHaveAllRolesData.planner += obj.clinicName + ' ';
          }
        })
      }

      setTimeout(() => {
        this.appService.showCustomLoader(false);
        this.changeRolePopup = !this.changeRolePopup;
      }, 100);

    }, (error) => {
      this.appService.showCustomLoader(false);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
    })

  }

  addUserRoleFun() {
    const payload = {
      email: this.selectedRowOfAddRole.email,
      firstName: this.selectedRowOfAddRole.firstName,
      gender: this.selectedRowOfAddRole.gender,
      lastName: this.selectedRowOfAddRole.lastName,
      phone: this.selectedRowOfAddRole.phone,
      documentId: this.selectedRowOfAddRole.documentId,
      // clinicIds: this.selectedRowOfAddRole.clinicIds,
      clinicIds: this.ChangeRoleClinicIds.map((obj) => obj.value),
      city: this.selectedRowOfAddRole.providerAddress.city,
      country: this.selectedRowOfAddRole.providerAddress.country,
      state: this.selectedRowOfAddRole.providerAddress.state,
      street: this.selectedRowOfAddRole.providerAddress.street,
      zipCode: this.selectedRowOfAddRole.providerAddress.zipCode,
    }

    if (this.userRoleTypeModel) {
      if (this.ChangeRoleClinicIds.length > 0) {
        this.appService.showCustomLoader(true);
        this.plannerService.addPlanner(payload).subscribe((res) => {
          this.appService.showCustomLoader(false);
          this.changeRolePopup = false;
          this.selectedOneValue = 'val1';
          this.messageService.add({ severity: 'success', summary: 'Success', detail: `Role Added successfully` });

        }, (error) => {
          this.appService.showCustomLoader(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Select a Clinic.' });
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Select a Role.' });
    }
  }

  closeChips(i) {
    this.ChangeRoleClinicIds.splice(i, 1);
    this.ChangeRoleClinicIds = this.ChangeRoleClinicIds.map(o => o);
  }
  sortPatients(assignedPatient: any[]): any[] {
    if (!Array.isArray(assignedPatient)) {
      return assignedPatient;
    }

    return assignedPatient.slice().sort((a, b) => {
      const nameA = (a.firstName || '').toLowerCase();
      const nameB = (b.firstName || '').toLowerCase();

      return nameA.localeCompare(nameB);
    });
  }
  checkClick(e) {
    e.preventDefault();
  }
  editRole(e) {
    let filteredClinics;
    const allClinic = JSON.parse(localStorage.getItem('optplanner_user'));
    this.isEdit = true;
    this.ifUserHaveAllRoles = false;
    this.allClinicList = allClinic.assignedClinics.map(obj => {
      return {
        name: this.toTitleCase(obj.label), value: obj.value
      };
    });
   
    filteredClinics = this.responseData.filter(item => item.type === "provider");
    let clinicNames = filteredClinics.flatMap(item => item.clinicName);
    let formatedClinicName = this.toTitleCaseArray(clinicNames);
    let filteredClinicNames = this.allClinicList.filter(clinic => formatedClinicName.includes(this.toTitleCase(clinic.name)));
    this.ChangeRoleClinicIds = filteredClinicNames;
  }
  onUserRoleChange() {
    if (this.isEdit) {
      let filteredClinics;
      if (this.userRoleTypeModel === 2) {
        filteredClinics = this.responseData.filter(item => item.type === "provider");
      } else if (this.userRoleTypeModel === 3) {
        filteredClinics = this.responseData.filter(item => item.type === "planner");
      } else {
        filteredClinics = [];
      }
      console.log(filteredClinics);
      
      let clinicNames = filteredClinics.flatMap(item => item.clinicName);
      let formatedClinicName = this.toTitleCaseArray(clinicNames);
      let filteredClinicNames = this.allClinicList.filter(clinic => formatedClinicName.includes(this.toTitleCase(clinic.name)));
      this.ChangeRoleClinicIds = filteredClinicNames;
      this.isBtnDisable = false;
    }
  }
  toTitleCaseArray(arr: string[]): string[] {
    return arr.map(str => {
      return str.toLowerCase().split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    });
  }
  updateUserRole() {
    const payload = {
      id: this.userRoleTypeModel === 2 ? this.selectedRowOfAddRole.userId : this.selectedRowOfAddRole.id,
      email: this.selectedRowOfAddRole.email,
      firstName: this.selectedRowOfAddRole.firstName,
      gender: this.selectedRowOfAddRole.gender,
      lastName: this.selectedRowOfAddRole.lastName,
      phone: this.selectedRowOfAddRole.phone,
      documentId: this.selectedRowOfAddRole.documentId,
      clinicIds: this.ChangeRoleClinicIds.map((obj) => obj.value),
      city: this.selectedRowOfAddRole.providerAddress.city,
      country: this.selectedRowOfAddRole.providerAddress.country,
      state: this.selectedRowOfAddRole.providerAddress.state,
      street: this.selectedRowOfAddRole.providerAddress.street,
      zipCode: this.selectedRowOfAddRole.providerAddress.zipCode,
    }

    if (this.userRoleTypeModel === 2) {
      if (this.ChangeRoleClinicIds.length > 0) {
        this.appService.showCustomLoader(true);
        this.plannerService.updatePlanner(payload).subscribe((res) => {
          this.appService.showCustomLoader(false);
          this.changeRolePopup = false;
          this.isEdit=false;
          this.AddRolePopupFun(this.allRowData)
          this.selectedOneValue = 'val1';
          this.messageService.add({ severity: 'success', summary: 'Success', detail: `Role Added successfully` });
          this.isBtnDisable=false;
        }, (error) => {
          this.appService.showCustomLoader(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Select a Clinic.' });
      }
    }
    else if (this.userRoleTypeModel === 3) {
      if (this.ChangeRoleClinicIds.length > 0) {
        this.appService.showCustomLoader(true);
        this.providerService.updateProvider(payload).subscribe((res) => {
          this.appService.showCustomLoader(false);
          this.isEdit=false;
          this.AddRolePopupFun(this.allRowData);
          this.changeRolePopup = false;
          this.selectedOneValue = 'val1';
          this.messageService.add({ severity: 'success', summary: 'Success', detail: `Role Updated successfully` });

        }, (error) => {
          this.appService.showCustomLoader(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Select a Clinic.' });
      }
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Select a Role.' });
    }
  }

}