import { Utils } from '.././utils';
import { AfterViewInit, Component, ViewChild, OnInit, HostListener, NgZone, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service'
import { DataService } from './data.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { MessageService } from 'primeng/api';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import * as moment from 'moment';
import * as _ from 'underscore';
import { getLanguage } from './.././languages';
import { LanguageInterface } from './.././languages/interface.language';
import { AppExService } from 'src/app/app-ex.service';
import { SchedulingService } from './scheduling.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';
import { config } from 'src/app/config';
import { Router } from '@angular/router';
import { SettingService } from '../setting/setting.service';
import { PatientService } from '../patient/patient.service';
import { ProviderService } from '../provider/provider.service';
import tippy from 'tippy.js';
import { DynamicDialog } from './dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Item } from 'angular2-multiselect-dropdown';
import { Table } from 'primeng/table';
import { MultiSelect } from 'primeng/multiselect';
import { skillStatusListDeletePopup } from './scheduling.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss'],
  providers: [DialogService],
  animations: [
    trigger('toolbarAnimation', [
      state('hidden', style({
        visibility:'hidden',
        height: '0px',
        opacity: 0,
        overflow: 'hidden',
        padding: '0px 0px 0px 0px'
      })),
      state('visible', style({
        visibility:'visible',
        height: '62px',
        opacity: 1,
        padding: '8px 10px 0px 10px',
      })),
      transition('hidden <=> visible', animate('500ms ease-in-out'))
    ])
  ]
})
export class SchedulingComponent implements OnInit, AfterViewInit {
  exporting: boolean = false;
  overlayTimeout: any;
  overlayVisible : boolean = false;
  @ViewChild('op') overlayPanel!: OverlayPanel;
  @ViewChild('target') targetElement!: ElementRef;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild("superadminDaily") superadminDaily!: any;
  @ViewChild("superadminWeek") superadminWeek!: any;
  @ViewChild("superadminMonth") superadminMonth!: any;
  @ViewChild('filterBoxElement') filterBoxElement: ElementRef;
  @ViewChild('dt') dt: Table;
  @ViewChild('multiDateRangeFilterRef') multiDateRangeFilterRef: MultiSelect
  appliedFiltersCount: number = 0;
  defDate = new Date();
  isIndicatorVisible: boolean = false;
  spotDetails: any;
  calendarVisible = true;
  selectAllIcon: any[] = []
  Tippyinstance: any
  calendarPlugins = [
    dayGridPlugin,
    timeGrigPlugin,
    interactionPlugin,
    listPlugin

  ];
  checkBoxItem = 0;
  checkBox_count: Array<any> = [];
  checkBoxArr: Array<any> = [];
  checkBoxArrId: Array<any> = [];
  select_all_check = false;
  ii = 0;
  sessionDataColumns = [
    { field: 'check', header: '' },
    { field: 'srno', header: '' },
    { field: 'shiftDate', header: 'Shift Date' },
    { field: 'startTime', header: 'Start Time' },
    { field: 'endTime', header: 'End Time' },
    { field: 'patientName', header: 'Patient Name' },
    { field: 'providerName', header: 'Provider Name' },
    { field: 'sessionType', header: 'Session Type' },
  ];
  calendarWeekends = true;
  canClick = true;
  getHolidays: any[] = []
  viewType = localStorage?.scheduleViewType ? localStorage.scheduleViewType : 'Week';
  selectedEvent = null;
  showEvent = false;
  showEventSchdule = false;
  EventDeleteConfirmPopup = false;
  viewTypeScudule: any = 'current';
  callHolidayFirst = 0;
  currentDate = new Date();
  upcomingHolidayData: any
  showEventProvider = false;
  show = true;
  deleteEventTypeDisable: Boolean = true;
  hide: any;
  langSubs: Subscription;
  clinicFilterSubs: Subscription;
  calendarRerenderSubs: Subscription;
  Customtitle:any = '';
  clinicName:any;
  isButtonDisabled:Boolean = false;
  pageStrings: LanguageInterface;
  @ViewChild('op', { static: false }) op: OverlayPanel;
  @ViewChild('actualTarget', { static: false }) actualTarget: HTMLDivElement;
  eventsMonths = [];
  events: any[] = [];
  eventsWeeks: any[] = [];
  showEventData = null;
  savedPayload = null;
  schedulingClinicList: any = [];
  schedulingClinicListData: any = [];
  listClinicIDs: any = [];
  viewBy = 'patients';
  customRecurrence:boolean;
  loggedInPid:any;
  userRole = ""
  show1 = false;
  openTextMsg = false;
  showIcon = true;
  openTextMsgSubs: Subscription;
  apiCall: Subscription;
  CustoTodayBtn: Boolean = true;
  disableDates: any = [];
  filterIsPinned: Boolean = false;
  ListViewToggle: Boolean = false;
  isUnavailabilityEvent: Boolean;
  deleteEventComment = '';
  planned_count: any = 0;
  un_planned_count: any = 0;
  escalated_count: any = 0;
  unavailability_count: any = 0;
  unavailability_pending_count: any = 0;
  unavailability_denied_count: any = 0;
  converted_count: any = 0;
  // current, upcomingWithRecurrence
  listSkilltype = [
    { label: 'Current', value: 'current' },
    { label: 'Upcoming With Recurrence', value: 'upcomingWithRecurrence' },
  ];
  listSkillStatus = skillStatusListDeletePopup;
  filterCols = [
    { label: 'All', value: 'ALL' },
    { label: 'Individual & Group', value: 'I&G' },
    { label: 'BCBA', value: 'BCBA' },
    { label: 'OT', value: 'OT' },
    { label: 'Speech', value: 'SPEECH' },
    { label: 'Planned', value: 'PLANNED' },
    { label: 'Un-Planned', value: 'UNPLANNED' },
    { label: 'Escalated', value: 'ESCALATED' },
    { label: 'Unavailability', value: 'UNAVAILABILITY' },
    { label: 'Session not in team', value: 'MALE' },
    // { label: 'Female', value: 'FEMALE' },
  ];
  lastChecked: any = null;
  statusChecked: any = null;
  genderChecked: any = null;
  crApptIdChecked: any = null;
  convertedSessionChecked: any = null
  solverType = [
    { label: 'Individual & Group', value: 'I&G', checked: false },
    { label: 'BCBA', value: 'BCBA', checked: false },
    { label: 'OT', value: 'OT', checked: false },
    { label: 'Speech', value: 'SPEECH', checked: false },
  ]
  crApptId = [
    { label: 'Missing CR', value: 'crApptId', checked: false },
  ]
  isScheduled = [
    { label: 'Not Converted', value: 'isScheduled', checked: false },
  ]
  sessionStatus = [
    { label: 'Planned', value: 'PLANNED', checked: false },
    { label: 'Un-Planned', value: 'UNPLANNED', checked: false },
    { label: 'Escalated', value: 'ESCALATED', checked: false },
    { label: 'Unavailability', value: 'UNAVAILABILITY', checked: false },
    { label: 'Is-Pinned Only', value: 'IsPin', checked: false },
  ]
  genderChoice = [
    { label: 'Session not in team', value: 'MALE', checked: false },
    // { label: 'Female', value: 'FEMALE',checked:false },
    // { label: 'Prefer not to say', value: 'PREFER',checked:false },
  ]
  filterCheckedData = {
    solverType: null,
    sessionStatus: null,
    genderChoice: null,
    crApptId: null,
    isScheduled: null
  }
  filterDataCols = 'ALL';
  IndividualGroupToolTipData: any;
  OTToolTipData: any;
  BCBAToolTipData: any;
  SpeechToolTipData: any;
  ViewByPPList: any;
  clinicId: any
  clinicStartTime: any
  clinicTimeZone: any
  clinicEndTime: any
  viewStatusScudule: any = 'CLIENT_CANCELLATION';
  isMobileView: any = window.innerWidth <= 600;
  isTabView = window.innerWidth > 600 && window.innerWidth <= 800;
  EventsDataArr: any = [];
  CalFillterCaller: any;
  ActiveFillterPlanned: boolean = true;
  ActiveFillterUnPlanned: boolean = true;
  ActiveFillterUnavailability: boolean = true;
  ActiveFillterEscalated: boolean = true;
  ActiveFillterINDIVIDUAL: boolean = true;
  ActiveFillterSPEECH: boolean = true;
  ActiveFillterBCBA: boolean = true;
  ActiveFillterOT: boolean = true;
  ClinicWisePatientDataArr: any = [];
  ClinicWiseProviderDataArr: any = [];
  ProviderAccessSolverTypes: any = [];
  holidayList: any
  ListViewTableData: any = [];
  updateEventRefreshCal = false;
  resourceIDs = [];

  imgBaseURL = config.baseUrl;

  dynamicDialogref: DynamicDialogRef;
  filterbox: boolean = false;
  eventDeleteType: any;
  ListViewTableTotal = 0;
  rows = 10
  tablePaginatorFirst: number = 0;
  getProviderListByPatientId: any = [];
  UpcomingSessionsData: any;
  highlightFilter: Boolean = true;
  onApplyFilterBtn: Boolean = false;
  providerJobTitleFilterList: any = [];
  providerFilterByJobId: any = [];
  providerFilterByJobIdsArry: any = [];

  iCallLink = 'https://calendar.google.com/calendar/u/0/r?cid=webcal://66.34.223.36:8090/api/v1/icall/provider/:prodID'
  constructor(
    private cdr: ChangeDetectorRef,
    public settingService: SettingService,
    public messageService: MessageService,
    public dialogService: DialogService,
    readonly schedulingService: SchedulingService,
    public appService: AppService,
    public patientService: PatientService,
    public providerService: ProviderService,
    public utils: Utils,
    public appExService: AppExService,
    public router: Router,
    private titlecasepipe: TitleCasePipe,
    private ngZone: NgZone,
  ) {
    this.isMobileView = window.innerWidth <= 600;
  }
  ngOnInit() {
    this.holidayList = JSON.parse(localStorage.getItem('allHolidayList'));
    this.clinicId = JSON.parse(localStorage.getItem('assignedClinic'));
    this.getSettings(this.clinicId);
    const remD = JSON.parse(localStorage.getItem('optplanner_user'));
    if (this.router.url === '/scheduling/all-scheduling/add-session') {
      console.log(this.router.url);
      setTimeout(() => {
        this.filter();
      }, 500);
    }
    this.callHolidayFirst++;
    this.userRole = remD.userType.type;

    if (this.userRole === 'provider') {
      let findIndex;
      this.sessionStatus.forEach((obj, i) => { if (obj.value === 'UNPLANNED') { findIndex = i; } });

      this.sessionStatus.splice(findIndex, 1);
      const filteredClinics = remD.assignedClinics.filter(clinic => {
        if (this.userRole === "provider") {
          return clinic.roles.includes("provider");
        } else if (this.userRole === "planner") {
          return clinic.roles.includes("planner") && !clinic.roles.includes("provider");
        } else {
          return false;
        }
      });
      this.schedulingClinicList = filteredClinics.map((obj) => {
        return ({
          name: obj.label,
          value: obj.value,
        })
      });
      this.listClinicIDs = this.schedulingClinicList.map(o => o.value);
      remD.assignedClinics.forEach((obj) => {
        if (obj.value == JSON.parse(localStorage.getItem('assignedClinic'))) {
          this.schedulingClinicListData = [{
            name: obj.label,
            value: obj.value,
          }];
        }
      });
    }

    this.appService.updateHeaderName({ name: 'Scheduling', subName: 'All Scheduling', count: 0 });
    this.viewBy = localStorage.scheduleViewBy || 'patients';
    if (localStorage?.scheduleListView) {
      this.ListViewToggle = JSON.parse(localStorage.scheduleListView);
    }
    this.loggedInPid = JSON.parse(localStorage.getItem('optplanner_user')).providerId;


    window["my"] = window["my"] || {};
    window["my"]["namespace"] = window["my"]["namespace"] || {};
    window["my"]["namespace"]["publicFunc"] = this.publicFunc.bind(this);

    this.langSubs = this.appExService.getLanguageOptions.subscribe(data => {
      if (data.lang) {
        this.pageStrings = getLanguage(data.lang);
      }
    });
    this.clinicFilterSubs = this.appExService.getFilteredClinicList.subscribe(data => {
      if (data.clinicNo) {
        this.providerFilterByJobIdsArry = [];
        this.providerFilterByJobId = [];
        this.getSettings(data.clinicNo);
        this.onProviderChange();
      }
    })
    this.getUserRole();
    if (this.viewBy == 'patients') {
      this.filterCols = [
        { label: 'All', value: 'ALL' },
        { label: 'Individual & Group', value: 'I&G' },
        { label: 'BCBA', value: 'BCBA' },
        { label: 'OT', value: 'OT' },
        { label: 'Speech', value: 'SPEECH' },
        { label: 'Planned', value: 'PLANNED' },
        { label: 'Un-Planned', value: 'UNPLANNED' },
        { label: 'Escalated', value: 'ESCALATED' },
        { label: 'Session not in team', value: 'MALE' },
        // { label: 'Female', value: 'FEMALE' },
      ];
    } else {
      this.filterCols = [
        { label: 'All', value: 'ALL' },
        { label: 'Individual & Group', value: 'I&G' },
        { label: 'BCBA', value: 'BCBA' },
        { label: 'OT', value: 'OT' },
        { label: 'Speech', value: 'SPEECH' },
        { label: 'Planned', value: 'PLANNED' },
        { label: 'Un-Planned', value: 'UNPLANNED' },
        { label: 'Escalated', value: 'ESCALATED' },
        { label: 'Unavailability', value: 'UNAVAILABILITY' },
        { label: 'Session not in team', value: 'MALE' },
        // { label: 'Female', value: 'FEMALE' },
      ];
    }
  }
  handleCheckbox(event: MouseEvent) {
    event.stopPropagation();
  }
  onSolverTypeChange(event: any, changedCheckbox: any) {
    const checked = event.target.checked;
    if (checked) {
      if (event.target !== this.lastChecked) {
        if (this.lastChecked) {
          this.lastChecked.checked = false;
        }
        changedCheckbox.checked = true;
        this.filterCheckedData.solverType = changedCheckbox.value;
      } else {
        this.filterCheckedData.solverType = changedCheckbox.value;
      }
    }
    else {
      this.filterCheckedData.solverType = null;
    }
    this.lastChecked = event.target;
  }
  isCheckDisable: boolean = false;
  onStatusChange(event: any, changedCheckbox: any) {
    const checked = event.target.checked;
    if (checked) {
      if (event.target !== this.statusChecked) {
        if (this.statusChecked) {
          this.statusChecked.checked = false;
        }
        changedCheckbox.checked = true;
        this.filterCheckedData.sessionStatus = changedCheckbox.value;
      } else {
        this.filterCheckedData.sessionStatus = changedCheckbox.value;
      }
    }
    else {
      this.filterCheckedData.sessionStatus = null;
    }
    this.statusChecked = event.target;
    console.log(this.statusChecked.value, checked);

    this.statusChecked.value === 'UNAVAILABILITY' && checked ? this.isCheckDisable = true : this.isCheckDisable = false;
  }
  onGenderChange(event: any, changedCheckbox: any) {
    const checked = event.target.checked;
    if (checked) {
      if (event.target !== this.genderChecked) {
        if (this.genderChecked) {
          this.genderChecked.checked = false;
        }
        changedCheckbox.checked = true;
        this.filterCheckedData.genderChoice = changedCheckbox.value;
      } else {
        this.filterCheckedData.genderChoice = changedCheckbox.value;
      }
    }
    else {
      this.filterCheckedData.genderChoice = null;
    }
    this.genderChecked = event.target;
  }
  onCrAppIdChange(event: any, changedCheckbox: any) {
    const checked = event.target.checked;
    if (checked) {
      if (event.target !== this.crApptIdChecked) {
        if (this.crApptIdChecked) {
          this.crApptIdChecked.checked = false;
        }
        changedCheckbox.checked = true;
        this.filterCheckedData.crApptId = changedCheckbox.value;
      } else {
        this.filterCheckedData.crApptId = changedCheckbox.value;
      }
    }
    else {
      this.filterCheckedData.crApptId = null;
    }
    this.crApptIdChecked = event.target;
  }
  onSessionChange(event: any, changedCheckbox: any) {
    const checked = event.target.checked;
    if (checked) {
      if (event.target !== this.convertedSessionChecked) {
        if (this.convertedSessionChecked) {
          this.convertedSessionChecked.checked = false;
        }
        changedCheckbox.checked = true;
        this.filterCheckedData.isScheduled = changedCheckbox.value;
      } else {
        this.filterCheckedData.isScheduled = changedCheckbox.value;
      }
    }
    else {
      this.filterCheckedData.isScheduled = null;
    }
    this.convertedSessionChecked = event.target;
  }

  onApplyFilter() {
    if(this.providerFilterByJobId.length > 0){
      this.multiDateRangeFilterRef.overlayVisible = false
      this.filterByJobIdProviderList();
    }
    this.onApplyFilterBtn = true;
    this.updateDataFilter(this.filterCheckedData);
    let selectedFilters = 0;

    if (this.filterCheckedData.solverType && this.filterCheckedData.solverType.length) {
      selectedFilters++;
    }
    if (this.filterCheckedData.sessionStatus && this.filterCheckedData.sessionStatus.length) {
      selectedFilters++;
    }
    if (this.filterCheckedData.genderChoice && this.filterCheckedData.genderChoice.length) {
      selectedFilters++;
    }
    if (this.filterCheckedData.isScheduled && this.filterCheckedData.isScheduled.length) {
      selectedFilters++;
    }

    // Update the applied filters count
    this.appliedFiltersCount = selectedFilters;
    if (this.select_all_check === true) {
      this.checkBoxArrId = [];
      this.ListViewTableData.forEach(item => {

        if (item.listcheckBoxShowIcon) {
          this.checkBoxArrId.push(item.extendedProps.id);
        }
      });
    }
    else {
      this.checkBoxArrId = [];
      this.select_all_check = false;
      this.checkBoxArr = []
    }
  }

  onClear() {
    if(this.providerFilterByJobId.length > 0){
      this.multiDateRangeFilterRef.overlayVisible = false;
      this.clearJobIdFilter();
    }
    this.highlightFilter = true;
    this.solverType.forEach(Item => {
      Item.checked = false;
    });
    this.sessionStatus.forEach(Item => {
      Item.checked = false;
    })
    this.genderChoice.forEach(Item => {
      Item.checked = false;
    })
    this.crApptId.forEach(Item => {
      Item.checked = false;
    })
    this.isScheduled.forEach(Item => {
      Item.checked = false;
    })
    this.filterCheckedData.solverType = null;
    this.filterCheckedData.sessionStatus = null;
    this.filterCheckedData.genderChoice = null;
    this.filterCheckedData.crApptId = null;
    this.filterCheckedData.isScheduled = null;
    this.lastChecked = null;
    this.statusChecked = null;
    this.genderChecked = null;
    this.onApplyFilterBtn = false;
    this.updateDataFilter(this.filterCheckedData);
    this.checkBoxArrId = [];
    this.checkBoxArr = [];
    this.select_all_check = false;
    this.appliedFiltersCount = 0;
    this.isCheckDisable = false;
  }
  
  getSettings(cId){
    this.settingService.getServiceAPI(cId).subscribe((response)=>{
      console.log('response',response)
      let data = response.data;      
      if(data){
        this.clinicStartTime = data.clinicStartTime
        this.clinicEndTime = data.clinicEndTime
        this.clinicTimeZone = data.timezone;
        this.clinicName = response.data.clinic?.clinicName
      }
      let EndTime = data.clinicEndTime;
      let EndTimeHour = Number(EndTime.slice(0, 2)) + 1;
      const DataEndTime = EndTime.replace(EndTime.slice(0, 2), EndTimeHour.toString());

      this.calendarOptions3.slotMinTime = "00:00:00"
      this.calendarOptions3.slotMaxTime = "24:00:00"
      // this.calendarOptions4.slotMinTime = this.clinicStartTime
      // this.calendarOptions4.slotMaxTime = DataEndTime.toString();
      this.calendarOptions4.slotMinTime = "00:00:00"
      this.calendarOptions4.slotMaxTime = "24:00:00"
      this.calendarOptionsDaygrid.slotMinTime = "00:00:00"
      this.calendarOptionsDaygrid.slotMaxTime = "24:00:00"
      this.calendarOptionsDaygridWeek.slotMinTime = "00:00:00"
      this.calendarOptionsDaygridWeek.slotMaxTime = "24:00:00"
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }
  calendarOptions3: CalendarOptions = {
    schedulerLicenseKey: environment.schedulerLicenseKey,
    initialView: 'resourceTimelineDay',
    eventBackgroundColor: '#EBE778',
    eventTextColor: '#000',
    eventClick: this.handleEventClick.bind(this), //
    // filterResourcesWithEvents: true,
    slotDuration: '01:00',
    slotMinTime: "07:00:00",
    slotMaxTime: "20:30:00",
    eventDragStart: (info) => {
      const patientId = info.event.extendedProps?.patientId ? info.event.extendedProps?.patientId : null;
      this.getProviderListByPatientId = [];
      if (this.viewBy != 'patients') {
        if (patientId) {
          this.ClinicWiseProviderDataArr.forEach((obj) => {
            if (obj.patientIdsInTeam.includes(patientId))
              this.getProviderListByPatientId.push(obj.id)
          });

          this.getProviderListByPatientId.forEach((obj) => {
            const resource = this.superadminDaily.calendar.getResourceById(obj);
            if (resource != null) {
              resource.setExtendedProp('class', 'resourceHighlight')
            }
          })
        }
      }
    },
    eventDragStop: (info) => {
      if (this.viewBy != 'patients') {
        this.getProviderListByPatientId.forEach((obj) => {
          const resource = this.superadminDaily.calendar.getResourceById(obj);
          if (resource != null) {
            resource.setExtendedProp('class', '')
          }
        })
      }
    },
    eventMouseEnter: ({ el, event }) => {
      const elm = el;
      let title = '';
      if (!elm.className.includes('HolidayEventStyle')) {
        if (elm.children[2] && (elm.children[2].innerHTML)) {
          title = elm.children[2].innerHTML;
        }
        if (title === '') {
          title = elm.children[0].children[0].children[0]["innerText"];
        }
        if (!this.isMobileView && !this.isTabView) {
          let Finaltitle = '';
          if (!event._def.extendedProps.isUnavailability) {
            Finaltitle = title + ' ' + event._def.title + ' (' + event._def.extendedProps.spotName + ')';
          } else {
            Finaltitle = title + ' ' + event._def.title;
          }
          this.Tippyinstance = tippy(elm, { content: Finaltitle });
          this.Tippyinstance.show();
        }
      }
    },
    eventMouseLeave: () => {
      if (!this.isMobileView && !this.isTabView) {
        if (this.Tippyinstance) {
          this.Tippyinstance.destroy();
        }
      }
    },
    eventMinWidth: 15,
    editable: true,
    eventStartEditable: true,
    droppable: true,
    eventDrop: this.popup.bind(this),
    eventResize: this.popup.bind(this),
    displayEventTime: true,
    expandRows: true,
    plugins: this.calendarPlugins,
    customButtons: {
      next: {
        text: 'Next',
        click: this.nextDay.bind(this),
      },
      prev: {
        text: 'Prev',
        click: this.prevDay.bind(this),
      },
      today: {
        text: "Today",
        click: this.currentDay.bind(this),
      },
    },
    resourceOrder: 'title,id',
    aspectRatio: 1.5,
    eventBorderColor: 'transparent',
    resourceLabelContent: (arg) => {
      const resourceData = arg.resource;
      const viewBy = this.viewBy;
      return {
        html: (`
          <div style="display:flex; flex-direction:row; width:100%;justify-content: left;" class="${resourceData.extendedProps.class}">
          <div style="">${(resourceData.extendedProps.documentId && (resourceData.extendedProps.documentId !== 'string'))
            ? `<img
          style="
            height: 29px;
            width: 29px;
            border-radius: 50%;
            min-width: 29px;
    min-height: 29px;
          "
          src= ${this.imgBaseURL + resourceData.extendedProps.documentId}>` : `<div class="schedulingNoUserDP">
          `+ `${resourceData.id != '-1' ? this.titlecasepipe.transform(`${arg.resource.title.split(" ")[0].charAt(0)}`) + this.titlecasepipe.transform(`${arg.resource.title.split(" ")[1].charAt(0)}`) : 'UA'}` +
            `</div>`}</div>
          <div style='display: flex; height:41px;top: -7px;left: 2px; align-items:center;padding:3px;position: relative;' >
          ${resourceData.id != '-1' ? `<p style="font-size:13px; height:20px;color:#EF7444;cursor: pointer;" onclick='window.my.namespace.publicFunc({event:` + resourceData.id + `,type:\"resourceNavigate\",})'>` + this.titlecasepipe.transform(`${arg.resource.title}`) + `${(resourceData.extendedProps.isActive) === false ? ' <span style="background: #e8e8e8c4;padding: 2px 4px;;color: #da0101;border-radius: 5px;">Deactive</span>' : ''}` + `<br>
          <span style="color:#848484 !important;">${(viewBy === 'providers' ? 'Session hours: <u><b>' + resourceData.extendedProps.sessionHours + ' hrs</b></u>' : resourceData.extendedProps.email)}</span>
          </p>`: `<p style="font-size:13px; height:20px;color:#EF7444;">Unassigned` + `${(resourceData.extendedProps.isActive) === false ? ' <span style="background: #e8e8e8c4;padding: 2px 4px;;color: #da0101;border-radius: 5px;">Deactive</span>' : ''}` + `<br>
          <span style="color:#848484 !important;">NA</span>
          </p>` }
            </div>
          </div>
      `)
      };
    },
    // events: [],
    slotLabelFormat: [

      {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        meridiem: 'lowercase',
        week: 'numeric'
      }
    ],
    eventTimeFormat: { // like '02:30:pm'
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      meridiem: 'lowercase',
    },
    resourceAreaWidth: '22%',
    resourceAreaColumns: [
      {
        headerContent: {
          html: ((`
        <div>
          <div style='
          color: #656565;
          font-size: 15px;
          position: relative;
          display: flex;flex-direction: column;
          '>
          ${this.isMobileView ?
              `<div>
          <div style='display:flex;align-items: center;'>
          <input type='radio' id="viewByMobilePatients" name='viewByroleMobile' value='patients' onclick="(function(e){window['my']['namespace']['publicFunc']({event:'Day',type:'dailySuperadmin'})})()">
          <label style='margin-bottom: 0px !important;margin-left: 4px;'>Patients</label>
          </div>
          <br>
          <div style='display:flex;align-items: center;'>
          <input type='radio' id="viewByMobileProviders" name='viewByroleMobile' value='providers' onclick="(function(e){window['my']['namespace']['publicFunc']({event:'Day',type:'dailySuperadmin'})})()">
          <label style='margin-bottom: 0px !important;margin-left: 4px;'>Providers</label>
          </div>
          <br>
          </div>` : `<select
           id="dailySuperadmin"
           onchange="(function(e){window['my']['namespace']['publicFunc']({event:'Day',type:'dailySuperadmin'})})()"
           style="
             font-size: 14px;
             color: #656565;
             border:none;background-color:transparent; cursor:pointer;">
             <option value='patients'>View by Patients</option>
             <option value='providers'>View by Providers</option>
            </select>`}
          </div>

        </div>
        `))
        }
      },
    ],
    resources: [],
  };

  calendarOptions4: CalendarOptions = {
    initialView: 'resourceTimelineWeek',
    firstDay: 1,
    schedulerLicenseKey: environment.schedulerLicenseKey,
    eventClick: this.handleEventClick.bind(this), //
    // filterResourcesWithEvents: true,
    slotDuration: '01:00',
    slotMinTime: "07:00:00",
    slotMaxTime: "20:30:00",
    eventMouseEnter: ({ el, event }) => {
      const elm = el;
      let title = '';

      if (!elm.className.includes('HolidayEventStyle')) {
        if (elm.children[2] && (elm.children[2].innerHTML)) {
          title = elm.children[2].innerHTML;
        }
        if (title === '' && !(event.extendedProps.allDay)) {
          // title = elm.children[0].children[0].children[1]["innerText"];
          title = elm.children[0].children[0].children[0]["innerText"];
        }
        if (event.extendedProps.allDay) {
          title = 'All Day'
        }
        if (!this.isMobileView && !this.isTabView) {
          let Finaltitle = '';
          if (!event._def.extendedProps.isUnavailability) {
            Finaltitle = title + ' ' + event._def.title + ' (' + event._def.extendedProps.spotName + ')';
          } else {
            Finaltitle = title + ' ' + event._def.title;
          }
          this.Tippyinstance = tippy(elm, {
            content: Finaltitle, appendTo: document.body, placement: 'auto',
          });
          this.Tippyinstance.show();
        }
      }
    },
    eventMouseLeave: () => {
      if (!this.isMobileView && !this.isTabView) {
        if (this.Tippyinstance) {
          this.Tippyinstance.destroy();
        }
      }
    },
    eventMinWidth: 15,
    eventBackgroundColor: '#EBE778',
    eventBorderColor: 'transparent',
    eventTextColor: '#000',
    editable: true,
    eventStartEditable: true,
    droppable: true,
    eventDragStart: (info) => {
      const patientId = info.event.extendedProps?.patientId ? info.event.extendedProps?.patientId : null;
      this.getProviderListByPatientId = [];

      if (this.viewBy != 'patients') {
        if (patientId) {
          this.ClinicWiseProviderDataArr.forEach((obj) => {
            if (obj.patientIdsInTeam.includes(patientId))
              this.getProviderListByPatientId.push(obj.id)
          });
          this.getProviderListByPatientId.forEach((obj) => {
            const resource = this.superadminWeek.calendar.getResourceById(obj);
            if (resource != null) {
              resource.setExtendedProp('class', 'resourceHighlight')
            }
          })
        }
      }
    },
    eventDragStop: (info) => {
      if (this.viewBy != 'patients') {
        this.getProviderListByPatientId.forEach((obj) => {
          const resource = this.superadminWeek.calendar.getResourceById(obj);
          if (resource != null) {
            resource.setExtendedProp('class', '')
          }
        })
      }
    },
    eventDrop: this.popup.bind(this),
    eventResize: this.popup.bind(this),
    displayEventTime: true,
    expandRows: true,
    plugins: this.calendarPlugins,
    customButtons: {
      next: {
        text: 'Next',
        click: this.nextWeek.bind(this),
      },
      prev: {
        text: 'Prev',
        click: this.prevWeek.bind(this),
      },
      today: {
        text: "Today",
        click: this.currentWeek.bind(this),
      },
    },
    eventTimeFormat: { // like '02:30:pm'
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      meridiem: 'lowercase',
    },
    resourceOrder: 'title,id',
    resourceLabelContent: (arg) => {
      const resourceData = arg.resource;
      const viewBy = this.viewBy;
      return {
        html: (`
          <div  style="display:flex; flex-direction:row; width:100%;justify-content: left;" class="${resourceData.extendedProps.class}">
          <div style="">${(resourceData.extendedProps.documentId && (resourceData.extendedProps.documentId !== 'string'))
            ? `<img
          style="
            height: 29px;
            width: 29px;
            border-radius: 50%;
            min-width: 29px;
    min-height: 29px;
          "
          src= ${this.imgBaseURL + resourceData.extendedProps.documentId}>` : `<div class="schedulingNoUserDP">
          `+ `${resourceData.id != '-1' ? this.titlecasepipe.transform(`${arg.resource.title.split(" ")[0].charAt(0)}`) + this.titlecasepipe.transform(`${arg.resource.title.split(" ")[1].charAt(0)}`) : 'UA'}` +
            `</div>`}</div>
        <div style='display: flex; height:41px;top: -7px;
            left: 2px; align-items:center;padding:3px;position: relative;' >
            ${resourceData.id != '-1' ? `<p style="font-size:13px; height:20px;color:#EF7444;cursor: pointer;" onclick='window.my.namespace.publicFunc({event:` + resourceData.id + `,type:\"resourceNavigate\",})'>` + this.titlecasepipe.transform(`${arg.resource.title}`) + `${(resourceData.extendedProps.isActive) === false ? ' <span style="background: #e8e8e8c4;padding: 2px 4px;;color: #da0101;border-radius: 5px;">Deactive</span>' : ''}` + `<br>
            <span style="color:#848484 !important;">${(viewBy === 'providers' ? 'Session hours: <u><b>' + resourceData.extendedProps.sessionHours + ' Hrs</b></u>' : resourceData.extendedProps.email)}</span>
            </p>`: `<p style="font-size:13px; height:20px;color:#EF7444;">Unassigned` + `${(resourceData.extendedProps.isActive) === false ? ' <span style="background: #e8e8e8c4;padding: 2px 4px;;color: #da0101;border-radius: 5px;">Deactive</span>' : ''}` + `<br>
            <span style="color:#848484 !important;">NA</span>
            </p>` }
            
            </div>
          </div>
      `)
      };
    },
    // events: [],
    slotLabelFormat: [
      {

      },
      {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        meridiem: 'lowercase',
        week: 'numeric'
      }
    ],
    resourceAreaColumns: [
      {
        headerContent: {
          html: ((`
          <div>
          <div style='
          color: #656565;
          font-size: 15px;
          position: relative;
          display: flex;flex-direction: column;
          '> ${this.isMobileView ?
              `<div>
          <div style='display:flex;align-items: center;'>
          <input type='radio' id="viewByMobilePatients" name='viewByroleMobile' value='patients' onclick="(function(e){window['my']['namespace']['publicFunc']({event:'Week',type:'superadminWeek'})})()">
          <label style='margin-bottom:0px !important;margin-left: 4px;'>Patients</label>
          </div>
          <br>
          <div style='display:flex;align-items: center;'>
          <input type='radio' id="viewByMobileProviders" name='viewByroleMobile' value='providers' onclick="(function(e){window['my']['namespace']['publicFunc']({event:'Week',type:'superadminWeek'})})()">
          <label style='margin-bottom:0px !important;margin-left: 4px;'>Providers</label>
          </div>
          <br>
          </div>` : `<select
           id="superadminWeek"
           onchange="(function(e){window['my']['namespace']['publicFunc']({event:'Week',type:'superadminWeek'})})()"
           style="
             font-size: 14px;
             color: #656565;
             border:none;background-color:transparent; cursor:pointer;">
             <option value='patients'>View by Patients</option>
             <option value='providers'>View by Providers</option>
            </select>`
            }
          </div>
        </div>
        `))
        }
      },
    ],
    resources: [],
    resourceAreaWidth: '22%'
  };

  // calendarListWeek: CalendarOptions = {
  //   schedulerLicenseKey: environment.schedulerLicenseKey,
  //   plugins: this.calendarPlugins,
  //   initialView: 'listWeek',
  //   eventClick: this.handleEventClick.bind(this), //
  //   customButtons:{},
  //   headerToolbar: false,
  //   events: [],
  // }
  calendarOptions5: CalendarOptions = {
    schedulerLicenseKey: environment.schedulerLicenseKey,
    initialView: 'resourceTimelineMonth',
    eventBackgroundColor: '#EBE778',
    eventClick: this.handleEventClick.bind(this), //
    // filterResourcesWithEvents: true,
    eventTextColor: '#000',
    eventBorderColor: 'transparent',
    eventDragStart: (info) => {
      const patientId = info.event.extendedProps?.patientId ? info.event.extendedProps?.patientId : null;
      this.getProviderListByPatientId = [];

      if (this.viewBy != 'patients') {
        if (patientId) {
          this.ClinicWiseProviderDataArr.forEach((obj) => {
            if (obj.patientIdsInTeam.includes(patientId))
              this.getProviderListByPatientId.push(obj.id)
          });

          this.getProviderListByPatientId.forEach((obj) => {
            const resource = this.superadminMonth.calendar.getResourceById(obj);
            if (resource != null) {
              resource.setExtendedProp('class', 'resourceHighlight')
            }
          })
        }
      }
    },
    eventDragStop: (info) => {
      if (this.viewBy != 'patients') {
        this.getProviderListByPatientId.forEach((obj) => {
          const resource = this.superadminMonth.calendar.getResourceById(obj);
          if (resource != null) {
            resource.setExtendedProp('class', '')
          }
        })
      }
    },
    eventMouseEnter: ({ el, event }) => {
      const elm = el;
      let title = '';
      if (!elm.className.includes('HolidayEventStyle')) {
        if (elm.children[2] && (elm.children[2].innerHTML)) {
          title = elm.children[2].innerHTML;
        }
        if (title === '') {
          title = elm.children[0].children[0].children[0]["innerText"];
        }
        if (!this.isMobileView && !this.isTabView) {
          let Finaltitle = '';
          if (!event._def.extendedProps.isUnavailability) {
            Finaltitle = title + ' ' + event._def.title + ' (' + event._def.extendedProps.spotName + ')';
          } else {
            Finaltitle = title + ' ' + event._def.title;
          }
          this.Tippyinstance = tippy(elm, { content: Finaltitle });
          this.Tippyinstance.show();
        }
      }
    },
    eventMouseLeave: () => {
      if (!this.isMobileView && !this.isTabView) {
        if (this.Tippyinstance) {
          this.Tippyinstance.destroy();
        }
      }
    },
    editable: true,
    eventDurationEditable: false,
    eventStartEditable: true,
    droppable: true,
    eventDrop: this.popup.bind(this),
    eventResize: this.popup.bind(this),
    displayEventTime: true,
    expandRows: true,
    plugins: this.calendarPlugins,
    customButtons: {
      next: {
        text: 'Next',
        click: this.nextMonth.bind(this),
      },
      prev: {
        text: 'Prev',
        click: this.prevMonth.bind(this),
      },
      today: {
        text: "Today",
        click: this.currentMonth.bind(this),
      },
    },
    eventTimeFormat: { // like '02:30:pm'
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      meridiem: 'lowercase',
    },
    resourceOrder: 'title,id',
    resourceLabelContent: (arg) => {
      const resourceData = arg.resource;
      const viewBy = this.viewBy;
      return {
        html: (`
          <div  style="display:flex; flex-direction:row; width:100%;justify-content: left;" class="${resourceData.extendedProps.class}">
          <div style="">${(resourceData.extendedProps.documentId && (resourceData.extendedProps.documentId !== 'string'))
            ? `<img
          style="
            height: 29px;
            width: 29px;
            border-radius: 50%;
            min-width: 29px;
    min-height: 29px;
          "
          src= ${this.imgBaseURL + resourceData.extendedProps.documentId}>` : `<div class="schedulingNoUserDP">
          `+ `${resourceData.id != '-1' ? this.titlecasepipe.transform(`${arg.resource.title.split(" ")[0].charAt(0)}`) + this.titlecasepipe.transform(`${arg.resource.title.split(" ")[1].charAt(0)}`) : 'UA'}` +
            `</div>`}</div>
            <div style='display: flex; height:41px;top: -7px;
            left: 2px; align-items:center;padding:3px;position: relative;' >
            ${resourceData.id != '-1' ? `<p style="font-size:13px; height:20px;color:#EF7444;cursor: pointer;" onclick='window.my.namespace.publicFunc({event:` + resourceData.id + `,type:\"resourceNavigate\",})'>` + this.titlecasepipe.transform(`${arg.resource.title}`) + `${(resourceData.extendedProps.isActive) === false ? ' <span style="background: #e8e8e8c4;padding: 2px 4px;;color: #da0101;border-radius: 5px;">Deactive</span>' : ''}` + `<br>
            <span style="color:#848484 !important;">${(viewBy === 'providers' ? 'Session hours: <u><b>' + resourceData.extendedProps.sessionHours + ' hrs</b></u>' : resourceData.extendedProps.email)}</span>
            </p>`: `<p style="font-size:13px; height:20px;color:#EF7444;">Unassigned` + `${(resourceData.extendedProps.isActive) === false ? ' <span style="background: #e8e8e8c4;padding: 2px 4px;;color: #da0101;border-radius: 5px;">Deactive</span>' : ''}` + `<br>
            <span style="color:#848484 !important;">NA</span>
            </p>` }
            </div>
          </div>
      `)
      };
    },
    // events: [],
    resourceAreaWidth: '22%',
    resourceAreaColumns: [
      {
        headerContent: {
          html: ((`
          <div>
          <div style='
          color: #656565;
          font-size: 15px;
          position: relative;
          display: flex;flex-direction: column;
          '> ${this.isMobileView ?
              `<div>
          <div style='display:flex;align-items: center;'>
          <input type='radio' id="viewByMobilePatients" name='viewByroleMobile' value='patients' onclick="(function(e){window['my']['namespace']['publicFunc']({event:'Month',type:'superadminMonth'})})()">
          <label style='margin-bottom:0px !important;margin-left: 4px;'>Patients</label>
          </div>
          <br>
          <div style='display:flex;align-items: center;'>
          <input type='radio' id="viewByMobileProviders" name='viewByroleMobile' value='providers' onclick="(function(e){window['my']['namespace']['publicFunc']({event:'Month',type:'superadminMonth'})})()">
          <label style='margin-bottom:0px !important;margin-left: 4px;'>Providers</label>
          </div>
          <br>
          </div>` : `<select
           id="superadminMonth"
           onchange="(function(e){window['my']['namespace']['publicFunc']({event:'Month',type:'superadminMonth'})})()"
           style="
             font-size: 14px;
             color: #656565;
             border:none;background-color:transparent; cursor:pointer;">
             <option value='patients'>View by Patients</option>
             <option value='providers'>View by Providers</option>
            </select>`
            }
          </div>
        </div>
        `))
        }
      },
    ],
    resources: []
  };

  calendarOptionsDaygrid: CalendarOptions = {
    schedulerLicenseKey: environment.schedulerLicenseKey,
    initialView: 'timeGridDay',
    slotDuration: '01:00',
    slotMinTime: "07:00:00",
    slotMaxTime: "20:30:00",
    allDaySlot: false,
    eventMouseEnter: ({ el, event }) => {
      const elm = el;
      let title = '';
      if (!elm.className.includes('HolidayEventStyle')) {
        if (elm.children[2] && (elm.children[2].innerHTML)) {
          title = elm.children[2].innerHTML;
        }
        if (title === '') {
          title = elm.children[0].children[0].children[0]["innerText"];
        }
        if (!this.isMobileView && !this.isTabView) {
          let Finaltitle = '';
          if (!event._def.extendedProps.isUnavailability) {
            Finaltitle = event._def.title + ' (' + event._def.extendedProps.spotName + ')';
          } else {
            Finaltitle = title + ' ' + event._def.title;
          }
          this.Tippyinstance = tippy(elm, { content: Finaltitle });
          this.Tippyinstance.show();
        }
      }
    },
    eventMouseLeave: () => {
      if (!this.isMobileView && !this.isTabView) {
        if (this.Tippyinstance) {
          this.Tippyinstance.destroy();
        }
      }
    },
    // locale: 'en-GB',
    allDayText: 'All-day',
    plugins: this.calendarPlugins,
    expandRows: true,
    slotLabelFormat: [
      {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        meridiem: 'lowercase',
        week: 'numeric'
      }
    ],
    eventClick: this.handleEventProviderClick.bind(this), //
    eventContent: (arg, createElement) => {
      let innerText = '';
      if (arg.event.extendedProps && !arg.event.extendedProps.isUnavailability) {
        const prop = arg.event.extendedProps;
        const typeObj = prop.patient;
        const date = prop.sessionDate || prop.shiftDate;
        const img = (prop.patientProfilePic || typeObj?.documentId || '/assets/images/Group_212@2x.png')
        const name = prop.patientName || this.titlecasepipe.transform(`${typeObj?.firstName} ${typeObj?.lastName}`);
        const nameLetter = prop.patientName || `${this.titlecasepipe.transform(typeObj?.firstName.charAt(0))}${this.titlecasepipe.transform(typeObj?.lastName.charAt(0))}`;
        const endTime = moment(`${date}T${prop.endTime}`).format('hh:mm a');
        const startTime = moment(`${date}T${prop.startTime}`).format('hh:mm a');
        innerText = `<div style="display:flex; flex-direction:row; width:100%;justify-content: left;">
        ${typeObj?.documentId ?
            `<img style="    margin-top: 3px;
                      margin-left: 2px;height: 29px;width: 29px;border-radius: 50%;
                      min-width: 29px;
                      min-height: 29px;
                      " src=${this.imgBaseURL + img}>` :
            `<div style="display: inline-block;">
                      <div class="schedulingPopupNoUserDP">
                      ${nameLetter}
                      </div>
                    </div>`}
                      <p style="margin-left: 5px;font-size:13px; height:20px;font-weight:bold" >
                        ${name} &nbsp;
                      </p>
                      <p style="margin-left: 5px;font-size:13px; height:20px;" >
                        ${startTime} - ${endTime}
                      </p>
                    </div>`
      }
      if (arg.event.extendedProps && arg.event.extendedProps.isUnavailability) {
        const prop = arg.event.extendedProps;
        const date = prop.sessionDate || prop.shiftDate;
        const reason = prop.unavailabilityReasonTypeDTO.type;
        const endTime = moment(`${prop.startDate}T${prop.endTime}`).format('hh:mm a');
        const startTime = moment(`${prop.startDate}T${prop.startTime}`).format('hh:mm a');
        innerText = `<div style="display:flex; flex-direction:row; width:100%;justify-content: left;    text-overflow: ellipsis;
        white-space: nowrap;">
                    `+ `
                    ${prop.allDay === true ? `<p style="margin-left: 5px;font-size:13px; height:20px;margin-bottom: 0px;" >Unavailable</p>` : `<p style="margin-left: 5px;font-size:13px; height:20px;margin-bottom: 0px;" >${startTime} - ${endTime}</p>`}
                    `+ `</div>
                  <div style="display:flex; flex-direction:row; width:100%;justify-content: left;    text-overflow: ellipsis;
                  white-space: nowrap;">
                    <p style="margin-left: 5px;font-size:13px; height:20px;font-weight:bold" >
                      ${reason}
                    </p>
                  </div>`
      }
      if (arg.event.extendedProps && arg.event.extendedProps.holiday) {
        const prop = arg.event.extendedProps;
        innerText = `<div style="background:#81F170;width:100%;border-radius: 3px;">` + ` 
        <p style="margin-left: 5px;font-size:13px;height:20px;color: black;margin-bottom: 5px;display:none"></p>
        `+ `
                      <p style="margin-left: 5px;font-size:13px;height:20px;color: black;margin-bottom: 0px;">
                        ${prop.reason}
                      </p>
                    </div>`
      }
      let italicEl = document.createElement('div');
      italicEl.className = "EventOverflowClass";
      italicEl.innerHTML = innerText

      let arrayOfDomNodes = [italicEl]
      return { domNodes: arrayOfDomNodes }
    },
    customButtons: {
      next: {
        text: 'Next',
        click: this.nextDay.bind(this),
      },
      prev: {
        text: 'Prev',
        click: this.prevDay.bind(this),
      },
      today: {
        text: "Today",
        click: this.currentDay.bind(this),
      },
    },
    events: []
  };

  calendarOptionsDaygridWeek: CalendarOptions = {
    schedulerLicenseKey: environment.schedulerLicenseKey,
    initialView: 'timeGridWeek',
    slotDuration: '01:00',
    slotMinTime: "07:00:00",
    slotMaxTime: "20:30:00",
    allDaySlot: false,
    eventMouseEnter: ({ el, event }) => {
      const elm = el;
      let title = '';
      if (!elm.className.includes('HolidayEventStyle')) {
        if (elm.children[2] && (elm.children[2].innerHTML)) {
          title = elm.children[2].innerHTML;
        }
        if (title === '') {
          title = elm.children[0].children[0].children[0]["innerText"];
        }
        if (!this.isMobileView && !this.isTabView) {
          let Finaltitle = '';
          if (!event._def.extendedProps.isUnavailability) {
            Finaltitle = event._def.title + ' (' + event._def.extendedProps.spotName + ')';
          } else {
            Finaltitle = title + ' ' + event._def.title;
          }
          this.Tippyinstance = tippy(elm, { content: Finaltitle });
          this.Tippyinstance.show();
        }
      }
    },
    eventMouseLeave: () => {
      if (!this.isMobileView && !this.isTabView) {
        if (this.Tippyinstance) {
          this.Tippyinstance.destroy();
        }
      }
    },
    firstDay: 1,
    eventBackgroundColor: '#EBE778',
    // locale: 'en-GB',
    eventBorderColor: 'transparent',
    eventTextColor: '#000',
    plugins: this.calendarPlugins,
    expandRows: true,
    allDayText: 'All-day',
    slotLabelFormat: [
      {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        meridiem: 'lowercase',
        week: 'numeric'
      }
    ],
    eventClick: this.handleEventProviderClick.bind(this), //
    eventContent: (arg, createElement) => {

      let innerText = '';
      if (arg.event.extendedProps && !arg.event.extendedProps.isUnavailability) {
        const prop = arg.event.extendedProps;
        const typeObj = prop.patient;
        const date = prop.sessionDate || prop.shiftDate;
        const name = prop.patientName || this.titlecasepipe.transform(`${typeObj?.firstName} ${typeObj?.lastName}`);
        const nameLetter = prop.patientName || `${this.titlecasepipe.transform(typeObj?.firstName.charAt(0))}${this.titlecasepipe.transform(typeObj?.lastName.charAt(0))}`;
        const img = (prop.patientProfilePic || typeObj?.documentId || '/../../assets/images//Group_212@2x.png')
        const endTime = moment(`${date}T${prop.endTime}`).format('hh:mm a');
        const startTime = moment(`${date}T${prop.startTime}`).format('hh:mm a');
        innerText = `<div style="color: white;display:flex; flex-direction:row; width:100%;justify-content: left;">
                      ${typeObj?.documentId ?
            `<img class="m-week-event-img" style="    margin-top: 3px;
                        margin-left: 2px;height: 29px;width: 29px;border-radius: 50%;
                        min-width: 29px;
                        min-height: 29px;
                        " src=${this.imgBaseURL + img}>` :
            `<div style="display: inline-block;">
                        <div class="schedulingPopupNoUserDP">
                        ${nameLetter}
                        </div>
                      </div>`}
                      <div style="color: white;margin-left: 5px;font-size:13px; height:20px;font-weight:bold" >
                        ${name} &nbsp; ${startTime} - ${endTime}
                      </div>
                    </div>`
      }
      if (arg.event.extendedProps && arg.event.extendedProps.isUnavailability) {
        const prop = arg.event.extendedProps;
        const date = prop.sessionDate || prop.shiftDate;
        const reason = prop.unavailabilityReasonTypeDTO.type;
        const endTime = moment(`${prop.startDate}T${prop.endTime}`).format('hh:mm a');
        const startTime = moment(`${prop.startDate}T${prop.startTime}`).format('hh:mm a');
        innerText = `<div style="color: white;display:flex; flex-direction:row; width:100%;justify-content: left;    text-overflow: ellipsis;
        white-space: nowrap;">
                      `+ `
                      ${prop.allDay === true ? `<p style="margin-left: 5px;font-size:13px; height:20px;margin-bottom: 0px;" >Unavailable</p>` : `<p style="margin-left: 5px;font-size:13px; height:20px;margin-bottom: 0px;" >${startTime} - ${endTime}</p>`}
                      `+ `</div>
                    <div style="display:flex; flex-direction:row; width:100%;justify-content: left;    text-overflow: ellipsis;
                    white-space: nowrap;">
                      <p style="color: white;margin-left: 5px;font-size:13px; height:20px;font-weight:bold" >
                        ${reason}
                      </p>
                    </div>`
      }
      if (arg.event.extendedProps && arg.event.extendedProps.holiday) {
        const prop = arg.event.extendedProps;
        innerText = `<div  style="background:#81F170;width:100%;border-radius: 3px;">` + ` 
        <p class="holiday-week" style="margin-left: 5px;font-size:13px;height:20px;color: black;margin-bottom: 5px;"> ${prop.reason}</p>
         </div>`
      }
      let italicEl = document.createElement('div');
      italicEl.className = "EventOverflowClass";

      italicEl.innerHTML = innerText

      let arrayOfDomNodes = [italicEl]
      return { domNodes: arrayOfDomNodes }
    },
    customButtons: {
      next: {
        text: 'Next',
        click: this.nextWeek.bind(this),
      },
      prev: {
        text: 'Prev',
        click: this.prevWeek.bind(this),
      },
      today: {
        text: "Today",
        click: this.currentWeek.bind(this),
      },
    },
    events: [],

  };

  calendarOptionsDaygridMonth: CalendarOptions = {
    schedulerLicenseKey: environment.schedulerLicenseKey,
    initialView: 'dayGridMonth',
    eventTextColor: '#000',
    eventBorderColor: 'transparent',
    plugins: this.calendarPlugins,
    expandRows: true,
    eventClick: this.handleEventProviderClick.bind(this), //
    eventMouseEnter: ({ el, event }) => {
      const elm = el;
      let title = '';
      if (!elm.className.includes('HolidayEventStyle')) {
        if (elm.children[2] && (elm.children[2].innerHTML)) {
          title = elm.children[2].innerHTML;
        }
        if (title === '') {
          title = elm.children[0].children[0].children[0]["innerText"];
        }
        if (!this.isMobileView && !this.isTabView) {
          let Finaltitle = '';
          if (!event._def.extendedProps.isUnavailability) {
            Finaltitle = event._def.title + ' ' + event._def.title + ' (' + event._def.extendedProps.spotName + ')';
          } else {
            Finaltitle = title + ' ' + event._def.title;
          }
          this.Tippyinstance = tippy(elm, { content: Finaltitle });
          this.Tippyinstance.show();
        }
      }
    },
    eventMouseLeave: () => {
      if (!this.isMobileView && !this.isTabView) {
        if (this.Tippyinstance) {
          this.Tippyinstance.destroy();
        }
      }
    },
    eventContent: (arg, createElement) => {
      let innerText = '';
      if (arg.event.extendedProps && !arg.event.extendedProps.isUnavailability) {
        const prop = arg.event.extendedProps;
        const typeObj = prop.patient;
        const img = (prop.patientProfilePic || typeObj?.documentId || '/../../assets/images//Group_212@2x.png')
        const date = prop.sessionDate || prop.shiftDate;
        const name = prop.patientName || this.titlecasepipe.transform(`${typeObj?.firstName} ${typeObj?.lastName}`);
        const nameLetter = prop.patientName || `${this.titlecasepipe.transform(typeObj?.firstName.charAt(0))}${this.titlecasepipe.transform(typeObj?.lastName.charAt(0))}`;
        const endTime = moment(`${date}T${prop.endTime}`).format('hh:mm a');
        const startTime = moment(`${date}T${prop.startTime}`).format('hh:mm a');
        innerText = `<div class="m-event-div" style="color: white;display:flex; flex-direction:row; width:100%;justify-content: left;">
        ${typeObj?.documentId ?
            `<img class="m-week-event-img" style="    margin-top: 3px;
          margin-left: 2px;height: 29px;width: 29px;border-radius: 50%;
          min-width: 29px;
          min-height: 29px;
          " src=${this.imgBaseURL + img}>` :
            `<div style="display: inline-block;">
          <div class="schedulingPopupNoUserDP">
          ${nameLetter}
          </div>
        </div>`}
        <p style="color: white;margin-left: 5px;font-size:13px; height:20px;font-weight:bold" >
                        ${name}
                      </p>
                    </div>`
      }
      if (arg.event.extendedProps && arg.event.extendedProps.isUnavailability) {
        const prop = arg.event.extendedProps;
        const date = prop.sessionDate || prop.shiftDate;
        const reason = prop.unavailabilityReasonTypeDTO.type;
        const endTime = moment(`${prop.startDate}T${prop.endTime}`).format('hh:mm a');
        const startTime = moment(`${prop.startDate}T${prop.startTime}`).format('hh:mm a');
        innerText = `<div style="display:flex; flex-direction:row; width:100%;justify-content: left;text-overflow: ellipsis;
        white-space: nowrap;">
                    `+ `
                    ${prop.allDay === true ? `<p style="margin-left: 5px;font-size:13px; height:20px;margin-bottom: 0px;color:white" >Unavailable</p>` : `<p style="margin-left: 5px;font-size:13px; height:20px;margin-bottom: 0px;color:white" >${startTime} - ${endTime}</p>`}
                    `+ `</div>
                  <div style="display:flex; flex-direction:row; width:100%;justify-content: left;    text-overflow: ellipsis;
                  white-space: nowrap;">
                    <p style="color: white;margin-left: 5px;font-size:13px; height:20px;font-weight:bold" >
                      ${reason}
                    </p>
                  </div>`
      }
      if (arg.event.extendedProps && arg.event.extendedProps.holiday) {
        const prop = arg.event.extendedProps;
        innerText = `<div class="m-event-holiday-div" style="background:#81F170;width:100%;border-radius: 3px;">` + ` 
        <p style="margin-left: 5px;font-size:13px;height:20px;color: black;margin-bottom: 5px;display:none"></p>
        `+ `
                      <p style="margin-left: 5px;font-size:13px;height:40px;color: black;margin-bottom: 0px;">
                        ${prop.reason}
                      </p>
                    </div>`
      }
      let italicEl = document.createElement('div');
      italicEl.className = "EventOverflowClass";

      italicEl.innerHTML = innerText

      let arrayOfDomNodes = [italicEl]
      return { domNodes: arrayOfDomNodes }
    },
    customButtons: {
      next: {
        text: 'Next',
        click: this.nextMonth.bind(this),
      },
      prev: {
        text: 'Prev',
        click: this.prevMonth.bind(this),
      },
      today: {
        text: "Today",
        click: this.currentMonth.bind(this),
      },
    },
    events: []
  };
  searchText = '';
  searchTextListView = '';
  debounceFunction = _.debounce(() => this.createFilterData(false), 1000);
  debounceFunctionListView = _.debounce(() => this.searchListView(), 500);


  dialogShow(event, isNewResource, cc) {
    console.log(cc);
    this.dynamicDialogref = this.dialogService.open(DynamicDialog, {
      data: {
        viewBy: this.viewBy,
        customRecurrence: this.customRecurrence,
        newResource: isNewResource,
        isPinned: event.event.extendedProps.isPinned,
        recurrenceType: event.event.extendedProps.recurrence
      },
      header: 'Confirmation',
      width: '70%',
      closable: false,
      styleClass: 'dynamicDialogStyle',
      contentStyle: { "max-height": "500px", "overflow": "auto" },
      baseZIndex: 10000
    });

    this.dynamicDialogref.onClose.subscribe((response) => {
      // this.dropEventUpdate(event,response.isPinnedstatus,response.recorrenceStatus);
      if (response.status) {
        if (event.event.extendedProps.isPinned || event.event.extendedProps.recurrence === 'customRecurrence') {
          // this.dialogShow(event)
          this.dropEventUpdate(event, true, response.recorrenceStatus)
        } else {
          this.dropEventUpdate(event, event.event.extendedProps.isPinned, this.customRecurrence)
        }
      } else {
        event.revert();
      }
    });
  }

  popup(event) {
    if (!this.isMobileView && !this.isTabView) {
      if (this.Tippyinstance) {
        this.Tippyinstance.destroy();
      }
    }
    const NewResourceId = event.newResource != null ? event.newResource.id : Number(event.event._def.resourceIds[0]);

    if (event.event.extendedProps.recurrence === 'customRecurrence') {
      this.customRecurrence = true;
    }
    else {
      this.customRecurrence = false;
    }
    const getProviderTeamList = this.ClinicWiseProviderDataArr.filter((obj) => { return obj.id == NewResourceId })[0]?.patientIdsInTeam

    const patientId = event.event.extendedProps?.patient ? event.event.extendedProps?.patientId : null;


    if (NewResourceId != -1 && patientId != null) {
      if (getProviderTeamList.includes(event.event.extendedProps?.patientId)) {
        // if(event.event.extendedProps.isPinned || event.event.extendedProps.recurrence === 'customRecurrence'){
        if (event.event.extendedProps.recurrence === 'customRecurrence') {
          // this.dropEventUpdate(event,true,false)          
          this.dialogShow(event, false, 1);
        } else {
          this.dropEventUpdate(event, event.event.extendedProps.isPinned, this.customRecurrence)
        }
      } else {
        if (event.hasOwnProperty('newResource') && event.newResource != null) {
          this.dialogShow(event, true, 2);
        } else {
          if (event.event.extendedProps.recurrence === 'customRecurrence') {
            // this.dropEventUpdate(event,true,this.customRecurrence);            
            this.dialogShow(event, false, 3);
          } else {
            this.dropEventUpdate(event, event.event.extendedProps.isPinned, this.customRecurrence)
          }
        }
      }
    } else {
      if (event.event.extendedProps.recurrence === 'customRecurrence') {
        // this.dropEventUpdate(event,true,false)
        this.dialogShow(event, false, 4);
      } else {
        this.dropEventUpdate(event, event.event.extendedProps.isPinned, this.customRecurrence)
      }
    }
  }

  dropEventUpdate(event, isPinnedres, isRecorrenceRes) {

    const UpdateNewEventPayload = {
      customRecurrenceForm: {
      }
    };
    const CurrnetEventId = event.event.extendedProps.id;
    const NewResourceId = event.newResource != null ? event.newResource.id : Number(event.event._def.resourceIds[0]);
    const NewEventStart = event.event.start;
    const NewEventEnd = event.event.end;

    this.appService.showCustomLoader(true);
    this.schedulingService.getSchedulingById(CurrnetEventId).subscribe((response: any) => {
      const data = response.data;
      if (data) {

        if (isRecorrenceRes) {
          // if(isRecorrenceRes != 'doNotRepeat'){
          if (data.customRecurrenceDTO.hasOwnProperty("weekDays")) {
            const week = data.customRecurrenceDTO.weekDays.map((obj) => { return obj.type });
            data.customRecurrenceDTO.weekDays = [...week];
          }
          UpdateNewEventPayload.customRecurrenceForm = data.customRecurrenceDTO;
          // UpdateNewEventPayload["allRecurrencePinned"] = true;
        } else {
          delete UpdateNewEventPayload.customRecurrenceForm;
        }

        UpdateNewEventPayload["startTime"] = moment(NewEventStart, ["h:mm A"]).format('kk:mm:ss');
        UpdateNewEventPayload["endTime"] = moment(NewEventEnd, ["h:mm A"]).format('kk:mm:ss');
        UpdateNewEventPayload["id"] = Number(data.id);
        // UpdateNewEventPayload["isPinned"] = isRecorrenceRes ? false : isPinnedres;
        UpdateNewEventPayload["isPinned"] = isRecorrenceRes ? false : true;
        UpdateNewEventPayload["placeOfServiceId"] = Number(data.placeOfServiceDTO.id);
        UpdateNewEventPayload["recurrence"] = isRecorrenceRes ? "customRecurrence" : "doNotRepeat";
        UpdateNewEventPayload["sessionSkillForm"] = data.sessionSkillForm;
        UpdateNewEventPayload["shiftDate"] = moment(NewEventEnd).format('YYYY-MM-DD');
        UpdateNewEventPayload["spotId"] = Number(data.spot.id);
        UpdateNewEventPayload["type"] = isRecorrenceRes ? "upcomingWithRecurrence" : "current";

        if (this.viewBy === 'patients') {
          UpdateNewEventPayload["patientId"] = Number(NewResourceId);
          UpdateNewEventPayload["providerId"] = data.hasOwnProperty("provider") ? Number(data.provider.id) : -1;
        } else {
          UpdateNewEventPayload["patientId"] = data.hasOwnProperty("patient") ? Number(data.patient.id) : -1;
          UpdateNewEventPayload["providerId"] = Number(NewResourceId);
        }

        this.schedulingService.updateScheduling(UpdateNewEventPayload).subscribe((response) => {
          // this.onClear();
          this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
          event.revert();
          this.appService.showCustomLoader(false);
        }, () => {

          this.schedulingService.getSchedulingById(CurrnetEventId).subscribe((response: any) => {
            const data = response.data;

            let badgeClass;
            let sessionColor;
            let EventTexColor = '#ffffff';
            if (data.patient?.providerChoice == 'M') {
              badgeClass = data.spot?.type + 'Bedge Male';
            } else if (data.patient?.providerChoice == 'F') {
              badgeClass = data.spot?.type + 'Bedge Female';
            } else {
              badgeClass = data.spot?.type + 'Bedge';
            }


            if (data.isEscalated) {
              sessionColor = 'providerLoginMonthViewRed'
            } else {
              if (this.viewBy === 'patients') {
                if ((!data.hasOwnProperty("provider") || data?.provider?.id == -1)) {
                  sessionColor = 'providerLoginMonthViewYellow';
                  EventTexColor = 'black';
                } else {
                  if (data?.patient?.id == -1) {
                    sessionColor = 'providerLoginMonthViewYellow';
                    EventTexColor = 'black';
                  } else {
                    sessionColor = 'providerLoginMonthViewGreen';
                    EventTexColor = '#ffffff';
                  }
                }
              } else {// viewBy == provider
                if ((!data.hasOwnProperty("patient") || data?.patient?.id == -1)) {
                  sessionColor = 'providerLoginMonthViewYellow';
                  EventTexColor = 'black';
                } else {
                  if ((!data.hasOwnProperty("provider") || data?.provider?.id == -1)) {
                    sessionColor = 'providerLoginMonthViewYellow';
                    EventTexColor = 'black';
                  } else {
                    sessionColor = 'providerLoginMonthViewGreen';
                    EventTexColor = '#ffffff';
                  }
                }
              }
            }



            event.event._def.ui.classNames = [badgeClass, sessionColor, data.isOutsideHrs === true ? 'clinicOutsideBadge' : '', data.recurrence === 'customRecurrence' ? 'customReccurrenceBadge' : ''];
            event.event._def.ui.textColor = EventTexColor;
            if (this.viewBy === 'patients') {
              if (data.hasOwnProperty("patient")) {
                event.event.setExtendedProp('patient', data.patient);
              } else {
                event.event.setExtendedProp('patient', { id: -1, firstName: "UnAssigned", lastName: "", email: "NA", documentId: null, isActive: null });
              }
            } else {// view by provider
              if (data.hasOwnProperty("provider")) {
                event.event.setExtendedProp('provider', data.provider);
              } else {
                event.event.setExtendedProp('provider', { id: -1, firstName: "UnAssigned", lastName: "", email: "NA", documentId: null, isActive: null });
              }
            }

            event.event.setExtendedProp('startTime', moment(data.startTime, ["h:mm A"]).format('kk:mm:ss'));
            event.event.setExtendedProp('endTime', moment(data.endTime, ["h:mm A"]).format('kk:mm:ss'));
            event.event.setExtendedProp('shiftDate', moment(data.shiftDate).format('YYYY-MM-DD'));
            event.event.setExtendedProp('isEscalated', data.isEscalated);
            event.event.setExtendedProp('isPinned', data.isPinned);
            event.event.setExtendedProp('recurrence', data.recurrence);

            if (data.isEscalated) {
              event.event.setExtendedProp('escalatedReason', data.escalatedReason);
            }
            this.appService.showCustomLoader(false);

            const payload = {
              fromDate: this.savedPayload.fromDate,
              toDate: this.savedPayload.toDate,
              viewBy: this.savedPayload.viewBy,
              sectionType: 'scheduling'
            }

            this.schedulingService.getCount(payload).subscribe((res) => {
              console.log('escalated', res)
              const counts = res.data;

              this.escalated_count = counts.escalatedCount;
              this.un_planned_count = counts.unPlannedCount;
              this.planned_count = counts.plannedCount;

            });

          });
          // this.updateEventRefreshCal = true;
          this.onProviderChange();
        });
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      event.revert();
      this.appService.showCustomLoader(false);
    });
  }

  nextDay(e) {
    this.updateEventRefreshCal = false;
    this.filterbox = false;
    this.superadminDaily.calendar.incrementDate({ days: 1 });
    let d = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
    let Current = moment(this.superadminDaily.calendar.currentData.currentDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    if (d !== Current) {
      this.CustoTodayBtn = false;
    } else if (d === Current) {
      this.CustoTodayBtn = true;

    }
    this.onProviderChange();
  }
  prevDay(e) {
    this.updateEventRefreshCal = false;
    this.filterbox = false;
    this.superadminDaily.calendar.incrementDate({ days: -1 });
    let d = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
    let Current = moment(this.superadminDaily.calendar.currentData.currentDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    if (d !== Current) {
      this.CustoTodayBtn = false;
    } else if (d === Current) {
      this.CustoTodayBtn = true;

    }
    this.onProviderChange();
  }
  currentDay(e) {
    this.updateEventRefreshCal = false;
    this.filterbox = false;
    this.superadminDaily.calendar.today();
    let d = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
    let Current = moment(this.superadminDaily.calendar.currentData.currentDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    if (d === Current) {
      this.CustoTodayBtn = true;
    } else {
      this.CustoTodayBtn = false;
    }
    this.onProviderChange();
  }
  DayDateFilter(e) {
    this.updateEventRefreshCal = false;
    this.filterbox = false;
    this.superadminDaily.calendar.gotoDate(e);
    let d = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
    let Current = moment(this.superadminDaily.calendar.currentData.currentDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    if (d !== Current) {
      this.CustoTodayBtn = false;
    } else if (d === Current) {
      this.CustoTodayBtn = true;
    }
    this.onProviderChange();
  }
  nextWeek(e) {
    this.updateEventRefreshCal = false;
    if (this.canClick) {
      this.canClick = false;
      setTimeout(() => {
        this.canClick = true;
      }, 750);
      this.filterbox = false;
      this.superadminWeek.calendar.next();
      this.onProviderChange();
    }
  }

  prevWeek(e) {
    this.updateEventRefreshCal = false;
    if (this.canClick) {
      this.canClick = false;
      setTimeout(() => {
        this.canClick = true;
      }, 500);
      this.filterbox = false;
      this.superadminWeek.calendar.prev();
      this.onProviderChange();
    }
  }
  currentWeek(e) {
    this.updateEventRefreshCal = false;
    this.superadminWeek.calendar.today();
    this.onProviderChange();
  }
  WeekDateFilter(e) {
    this.updateEventRefreshCal = false;
    this.superadminWeek.calendar.gotoDate(e);
    this.onProviderChange();
  }
  nextMonth(e) {
    this.updateEventRefreshCal = false;
    if (this.canClick) {
      this.canClick = false;
      setTimeout(() => {
        this.canClick = true;
      }, 500);
      this.filterbox = false;
      this.superadminMonth.calendar.next();
      this.onProviderChange();
    }
  }
  prevMonth(e) {
    this.updateEventRefreshCal = false;
    if (this.canClick) {
      this.canClick = false;
      setTimeout(() => {
        this.canClick = true;
      }, 500);
      this.filterbox = false;
      this.superadminMonth.calendar.prev();
      this.onProviderChange();
    }
  }
  currentMonth(e) {
    this.updateEventRefreshCal = false;
    this.superadminMonth.calendar.today();
    this.onProviderChange();
  }
  MonthDateFilter(e) {
    this.updateEventRefreshCal = false;
    // this.superadminMonth.calendar.gotoDate(e);
    if (this.canClick) {
      this.canClick = false;
      setTimeout(() => {
        this.canClick = true;
      }, 1200);
      this.superadminMonth.calendar.gotoDate(e);
      this.onProviderChange();
    }
  }

  createFilterData(status) {
    this.updateEventRefreshCal = status;
    if (this.searchText !== '') {
      // const dates = this.getCalendarDateRange();
      const payload = { viewBy: this.viewBy, fromDate: this.savedPayload.fromDate, toDate: this.savedPayload.toDate };
      payload["searchBy"] = this.searchText;

      if (this.filterIsPinned) {
        payload["isPinned"] = this.filterIsPinned;
      }
      this.savedPayload = payload;
      this.getScheduling(payload);
    } else {

      // const dates = this.getCalendarDateRange();
      const payload = { viewBy: this.viewBy, fromDate: this.savedPayload.fromDate, toDate: this.savedPayload.toDate };

      if (this.filterIsPinned) {
        payload["isPinned"] = this.filterIsPinned;
      }
      this.savedPayload = payload
      this.getScheduling(payload);
    }
  }
  getUserRole() {
    const remD = JSON.parse(localStorage.getItem('optplanner_user'));
    this.userRole = remD.userType.type;
    if (this.userRole === 'provider') {
      this.viewBy = 'providers';
      this.iCallLink = this.iCallLink.replace(":prodID", remD.providerId.toString());
    }
  }

  open() {
    window.open(this.iCallLink, '_blank');
  }

  ngAfterViewInit(): void {
    this.onProviderChange();

    setTimeout(() => {
      if (!this.isMobileView) {
        if (this.viewType === 'Day' && document.getElementById('dailySuperadmin')) {
          document.getElementById('dailySuperadmin')["value"] = this.viewBy;
        }
        if (this.viewType === 'Week' && document.getElementById('superadminWeek')) {
          document.getElementById('superadminWeek')["value"] = this.viewBy;
        }
        if (this.viewType === 'Month' && document.getElementById('superadminMonth')) {
          document.getElementById('superadminMonth')["value"] = this.viewBy;
        }
      }
      else {
        if (this.viewType === 'Day') {
          this.viewBy === 'providers' ? $('#viewByMobileProviders').attr('checked', 'checked') : $('#viewByMobilePatients').attr('checked', 'checked');
        }
        if (this.viewType === 'Week') {
          this.viewBy === 'providers' ? $('#viewByMobileProviders').attr('checked', 'checked') : $('#viewByMobilePatients').attr('checked', 'checked');
        }
        if (this.viewType === 'Month') {
          this.viewBy === 'providers' ? $('#viewByMobileProviders').attr('checked', 'checked') : $('#viewByMobilePatients').attr('checked', 'checked');
        }
      }
    }, 10);
    this.calendarRerenderSubs = this.appExService.CalendarToRerender$.subscribe(data => {
      this.reRenderCalendar();
    })
  }
  reRenderCalendar() {
    if (this.viewType === 'Day') {
      setTimeout(() => {
        let calendarCall = this.superadminDaily.getApi();
        calendarCall.render();
      }, 10);

    }
    if (this.viewType === 'Week') {
      setTimeout(() => {
        let calendarCall = this.superadminWeek.getApi();
        calendarCall.render();
      }, 10);
    }
    if (this.viewType === 'Month') {
      setTimeout(() => {
        let calendarCall = this.superadminMonth.getApi();
        calendarCall.render();
      }, 10);
    }
  }
  checkPastDays(e) {
    const props = e.extendedProps;

    let cDate = new Date();

    let pastTwoDayFromCurrent = cDate.setDate(cDate.getDate() - 2);
    let cNewDate = (new Date(pastTwoDayFromCurrent).toLocaleString('en-US'));
    let selectedSessionDate = moment(props.shiftDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let currentDatelocal = moment(new Date(cNewDate.split(",")[0]), "YYYY-MM-DD").format("YYYY-MM-DD");
    if (selectedSessionDate < currentDatelocal) {
      e["listcheckBoxShowIcon"] = false;
      this.selectAllIcon.push(false)
    } else {
      e["listcheckBoxShowIcon"] = true;
      this.selectAllIcon.push(true)
    }

    if (this.viewBy === 'providers') {
      const findProvider = this.ClinicWiseProviderDataArr.find((obj) => obj.id === props.providerId)

      if (findProvider != undefined) {
        return {
          startTime: e.start,
          endTime: e.end,
          patientName: e.extendedProps.patient?.firstName + ' ' + e.extendedProps.patient?.lastName,
          providerName: e.extendedProps.provider?.firstName + ' ' + e.extendedProps.provider?.lastName,
          sessionType: e.extendedProps.spotName,
          extendedProps: e.extendedProps,
          className: e.className,
          listcheckBoxShowIcon: e.listcheckBoxShowIcon
        };
      } else {
        if (this.providerFilterByJobIdsArry.length === 0 && props.providerId === -1) {
          return {
            startTime: e.start,
            endTime: e.end,
            patientName: e.extendedProps.patient?.firstName + ' ' + e.extendedProps.patient?.lastName,
            providerName: e.extendedProps.provider?.firstName + ' ' + e.extendedProps.provider?.lastName,
            sessionType: e.extendedProps.spotName,
            extendedProps: e.extendedProps,
            className: e.className,
            listcheckBoxShowIcon: e.listcheckBoxShowIcon
          };
        }
      }
    } else {
      return {
        startTime: e.start,
        endTime: e.end,
        patientName: e.extendedProps.patient?.firstName + ' ' + e.extendedProps.patient?.lastName,
        providerName: e.extendedProps.provider?.firstName + ' ' + e.extendedProps.provider?.lastName,
        sessionType: e.extendedProps.spotName,
        extendedProps: e.extendedProps,
        className: e.className,
        listcheckBoxShowIcon: e.listcheckBoxShowIcon
      };
    }
  }
  handleEventClick(e) {
    const props = e.event.extendedProps;

    let cDate = new Date();

    let pastTwoDayFromCurrent = cDate.setDate(cDate.getDate() - 2);
    let cNewDate = (new Date(pastTwoDayFromCurrent).toLocaleString('en-US'));

    let selectedSessionDate = moment(props.shiftDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let currentDatelocal = moment(new Date(cNewDate.split(",")[0]), "YYYY-MM-DD").format("YYYY-MM-DD");

    if (selectedSessionDate < currentDatelocal) {
      this.showIcon = false
    }
    else {
      this.showIcon = true;
    }
    this.isUnavailabilityEvent = e.event.extendedProps.isUnavailability;
    if (!props?.holiday) {// hide on holiday event    
      this.showEvent = true;
      const endTime = moment(`${props.shiftDate}T${props.endTime}`).format('hh:mm a').toUpperCase();
      const startTime = moment(`${props.shiftDate}T${props.startTime}`).format('hh:mm a').toUpperCase();
      this.showEventData = {
        ...props,
        startTimeStr: startTime,
        endTimeStr: endTime,
        className: e.event.classNames.includes('providerLoginMonthViewUnavltyAprv') ? ['test', 'providerLoginMonthViewUnavltyAprv'] : e.event.classNames,
        sessionSkillsString: props.sessionSkills ? props.sessionSkills.map(obj => obj.skill.skillName).join(", ") : ''
      };
      this.openEventDetailsPopUp(e);
    }
  }

  handleEventProviderClick(e) {
    const props = e.event.extendedProps;
    this.isUnavailabilityEvent = e.event.extendedProps.isUnavailability;
    if (!props?.holiday) {// hide on holiday event
      this.showEventProvider = true;
      const endTime = moment(`${props.sessionDate || props.shiftDate}T${props.endTime}`).format('hh:mm a');
      const startTime = moment(`${props.sessionDate || props.shiftDate}T${props.startTime}`).format('hh:mm a');
      this.showEventData = {
        ...props,
        startTimeStr: startTime,
        endTimeStr: endTime,
        sessionSkillsString: props.additionalSkills ? props.additionalSkills.map(obj => obj.skill.skillName).join(", ") : ''
      };
      if (!props.hasOwnProperty("patient")) {
        this.showEventData["patient"] = this.showEventData.provider
      }
      this.openEventDetailsProPopUp(e);
    }

  }

  editScheduling() {
    this.schedulingService.getSchedulingById(this.showEventData.id).subscribe((response: any) => {
      this.showEvent = false;
      this.appService.updateFilterSidebarState({ state: true, edit: true, ...response.data });
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  openDeleScheduling(event) {
    this.deleteEventTypeDisable = event.recurrence === "customRecurrence" ? true : false;
    this.deleteEventComment = '';
    this.showEventSchdule = true;
    this.showEventProvider = false;
    this.showEvent = false;
    this.viewTypeScudule = 'current';
    this.viewStatusScudule = 'CLIENT_CANCELLATION';

  }

  closeConfirmDeletePopup() {
    if (!this.plannedShiftDeletePopup) {
      this.EventDeleteConfirmPopup = false;
      this.showEventSchdule = true;
    } else {
      this.EventDeleteConfirmPopup = false;
    }
  }

  confirmDelete(type) {
    if (this.viewStatusScudule === 'ERROR' || this.viewStatusScudule === 'OTHER') {
      if (this.deleteEventComment != '') {
        let AlphaCheck = this.deleteEventComment.match(/[a-zA-Z]/);
        if (AlphaCheck != null) {
          // this.deleteSchedulingByList()
          this.showEventSchdule = false;
          this.eventDeleteType = type;
          this.EventDeleteConfirmPopup = true;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please add some alphabet character in reason field.' })
        }
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter a reason.' })
      }
    }
    else {
      this.showEventSchdule = false;
      this.eventDeleteType = type;
      this.EventDeleteConfirmPopup = true;
      this.deleteEventComment = '';
    }
  }
  clearField() {
    this.deleteEventComment = '';
  }
  deleteScheduling() {
    this.plannedShiftDeletePopup = false;
    this.EventDeleteConfirmPopup = false;
    this.isButtonDisabled = true;
    this.appService.showCustomLoader(true);
    let ids;
    if ((this.ListViewToggle === true && this.checkBoxArrId.length >= 1) && this.eventDeleteType === 'multiple') {
      ids = this.checkBoxArrId;
    }
    else {
      ids = this.showEventData.id;
    }

    // current, upcomingWithRecurrence
    this.schedulingService.deleteScheduling(ids, this.viewTypeScudule, this.viewStatusScudule, this.deleteEventComment).subscribe((response: any) => {
      this.EventDeleteConfirmPopup = false;
      this.deleteEventComment = '';
      this.showEvent = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });
      this.isButtonDisabled = false;
      this.onProviderChange(this.savedPayload);
      this.plannedShiftDeletePopup = false;
      this.checkBoxItem = 0;
      this.checkBox_count = [];
      this.checkBoxArr = [];
      this.checkBoxArrId = [];
      this.select_all_check = false;
      this.toggle2();
      this.clearSearch();
      this.appService.showCustomLoader(false);
    }, (error) => {
      this.isButtonDisabled = false;
      this.showEvent = false;
      this.EventDeleteConfirmPopup = false;
      this.plannedShiftDeletePopup = false;
      this.appService.showCustomLoader(false);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  filterBoxToggle() {
    this.filterbox = !this.filterbox;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    const clickedInside = this.filterBoxElement.nativeElement.contains(target);
    if (!clickedInside) {
      if (this.filterbox) {
        this.filterbox = true;
      }
    }
  }
  clickedOutside(event) {
    if (event.target.closest('.overlay') && !event.target.closest('.profileSection')) {
      this.showEventProvider = false;
      this.showEvent = false;
    }
  }

  openEventDetailsPopUp(args) {
    this.selectedEvent = args.e;
    if (args.event.extendedProps.isScheduled == true) {
      this.getSelectedSpotDetails(args.event.extendedProps.id)
    }
    this.showEvent = true;
    let yPos = args["jsEvent"]["clientY"] - 35;
    setTimeout(() => {
      let clttX = args["jsEvent"]["clientX"];
      const ele = document.getElementById("actualTarget");
      const expectedWidth = clttX + 315;
      const expectedHeight = yPos + ele.clientHeight;
      if (expectedWidth > (window.innerWidth || document.documentElement.clientWidth)) {
        const normalizedCordidate = expectedWidth - window.innerWidth;
        clttX = clttX - normalizedCordidate - 18;
      }
      if (expectedHeight > (window.innerHeight || document.documentElement.clientHeight)) {
        const normalizedCordidate = expectedHeight - window.innerHeight;
        yPos = yPos - normalizedCordidate - 18;
      }
      ele.style.left = `${clttX}px`;
      ele.style.top = `${yPos}px`;
    }, 2);
  }
  getSelectedSpotDetails(id: any) {
    this.providerService.getSpotDetails(id).subscribe((res: any) => {
      this.spotDetails = res.data
    })
  }
  openEventDetailsProPopUp(args) {
    this.selectedEvent = args.e;
    this.showEventProvider = true;
    let yPos = args["jsEvent"]["clientY"] - 35;
    setTimeout(() => {
      let clttX = args["jsEvent"]["clientX"];
      const ele = document.getElementById("actualTarget");
      const expectedWidth = clttX + 400
        ;
      const expectedHeight = yPos + ele.clientHeight;
      if (expectedWidth > (window.innerWidth || document.documentElement.clientWidth)) {
        const normalizedCordidate = expectedWidth - window.innerWidth;
        clttX = clttX - normalizedCordidate - 18;
      }
      if (expectedHeight > (window.innerHeight || document.documentElement.clientHeight)) {
        const normalizedCordidate = expectedHeight - window.innerHeight;
        yPos = yPos - normalizedCordidate - 18;
      }
      ele.style.left = `${clttX}px`;
      ele.style.top = `${yPos}px`;
    }, 2);
  }

  searchListView() {
    this.dt.filterGlobal(this.searchTextListView, 'contains')

    setTimeout(() => {
      if (this.dt.filteredValue?.length === 0) {
        this.dt.paginator = false;
      } else {
        this.dt.paginator = true;
        this.dt.filterGlobal(this.searchTextListView, 'contains')
      }
    }, 0)
  }
  clearSearch() {
    this.searchTextListView = '';
    if (this.ListViewToggle) {
      this.dt.filterGlobal('', 'contains')
    }
  }

  ngOnDestroy() {
    localStorage.scheduleListView = false;
    localStorage.scheduleViewType = 'Week';
    if (this.openTextMsgSubs) {
      this.openTextMsgSubs.unsubscribe();
    }
    if (this.calendarRerenderSubs) {
      this.calendarRerenderSubs.unsubscribe();
    }
    if (this.clinicFilterSubs) {
      this.clinicFilterSubs.unsubscribe();
    }
    if (this.langSubs) {
      this.langSubs.unsubscribe();
    }
    this.apiCall && this.apiCall.unsubscribe();
    window["my"]["namespace"]["publicFunc"] = null;
  }

  toggle1() {
    this.show1 = !this.show1;
  }

  toggle2() {
    if (this.searchText != '') {
      this.searchText = '';
      this.show1 = !this.show1;
      // const dates = this.getCalendarDateRange();
      const payload = { viewBy: this.viewBy, fromDate: this.savedPayload.fromDate, toDate: this.savedPayload.toDate };
      if (this.filterIsPinned) {
        payload["isPinned"] = this.filterIsPinned;
      }
      this.savedPayload = payload
      this.getScheduling(payload);
    } else {
      this.show1 = false;
    }
  }

  publicFunc(e) {
    this.providerFilterByJobId = [];
    this.providerFilterByJobIdsArry = [];
    this.viewType = e.event != -1 ? e.event : this.viewType;
    if (!this.isMobileView) {
      if (this.viewType === 'Day' && document.getElementById('dailySuperadmin')) {
        this.viewBy = document.getElementById('dailySuperadmin')["value"];
      }
      if (this.viewType === 'Week' && document.getElementById('superadminWeek')) {
        this.viewBy = document.getElementById('superadminWeek')["value"];

      }
      if (this.viewType === 'Month' && document.getElementById('superadminMonth')) {
        this.viewBy = document.getElementById('superadminMonth')["value"];
      }
    }
    else {
      if (this.viewType === 'Day') {
        this.viewBy = document.querySelector('input[name="viewByroleMobile"]:checked')["value"];
      }
      if (this.viewType === 'Week') {
        this.viewBy = document.querySelector('input[name="viewByroleMobile"]:checked')["value"];
      }
      if (this.viewType === 'Month') {
        this.viewBy = document.querySelector('input[name="viewByroleMobile"]:checked')["value"];
      }
    }
    if (e.type === 'resourceNavigate') {
      if (this.viewBy === 'patients') {
        if (e.event != -1) {
          this.ngZone.run(() => {
            this.router.navigate(['patient/patient-details'], { queryParams: { id: e.event } });
          });
        }
      } else {
        if (e.event != -1) {
          this.ngZone.run(() => {
            this.router.navigate(['provider/provider-details'], { queryParams: { id: e.event } });
          });
        }
      }
    } else {
      localStorage.scheduleViewBy = this.viewBy;
      this.updateEventRefreshCal = false;
      this.onProviderChange();
    }
    if (this.viewBy == 'patients') {
      this.filterCols = [
        { label: 'All', value: 'ALL' },
        { label: 'Individual & Group', value: 'I&G' },
        { label: 'BCBA', value: 'BCBA' },
        { label: 'OT', value: 'OT' },
        { label: 'Speech', value: 'SPEECH' },
        { label: 'Planned', value: 'PLANNED' },
        { label: 'Un-Planned', value: 'UNPLANNED' },
        { label: 'Escalated', value: 'ESCALATED' },
        { label: 'Session not in team', value: 'MALE' },
        // { label: 'Female', value: 'FEMALE' },
      ];
    } else {
      this.filterCols = [
        { label: 'All', value: 'ALL' },
        { label: 'Individual & Group', value: 'I&G' },
        { label: 'BCBA', value: 'BCBA' },
        { label: 'OT', value: 'OT' },
        { label: 'Speech', value: 'SPEECH' },
        { label: 'Planned', value: 'PLANNED' },
        { label: 'Un-Planned', value: 'UNPLANNED' },
        { label: 'Escalated', value: 'ESCALATED' },
        { label: 'Unavailability', value: 'UNAVAILABILITY' },
        { label: 'Session not in team', value: 'MALE' },
        // { label: 'Female', value: 'FEMALE' },
      ];
    }

  }

  getCalendarDateRange() {
    let curr = null;
    let first = 0 // First day is the day of the month - the day of the week
    let last = 0 // last day is the first day + 6
    let firstday = null;
    let lastday = null;
    if (this.viewType === 'Day') {
      setTimeout(() => {
        this.Customtitle = this.superadminDaily.calendar.currentData.viewTitle;
      }, 0);
      curr = this.superadminDaily.calendar.currentData.currentDate;

      this.defDate = this.superadminDaily.calendar.getDate();

      first = curr.getDate();
      last = first + 0;
      firstday = new Date(curr.setDate(first)).toUTCString();
      lastday = new Date(curr.setDate(last)).toUTCString();

      let d = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
      let Current = moment(curr, "YYYY-MM-DD").format("YYYY-MM-DD");

      if (d === Current) {
        this.CustoTodayBtn = true;
      } else {
        this.CustoTodayBtn = false;
      }
    }
    if (this.viewType === 'Week') {
      setTimeout(() => {
        this.Customtitle = this.superadminWeek.calendar.currentData.viewTitle;
      }, 0);

      this.defDate = this.superadminWeek.calendar.getDate();

      // curr = new Date(this.superadminWeek.calendar.currentData.currentDate);      
      // const currentDate = moment(curr).utc().format("YYYY-MM-DD HH:mm:ss");      
      // const newDate = new Date(currentDate);

      // const first = newDate.getDate() - newDate.getDay(); // First day is the day of the month - the day of the week
      // const last = first + 6; // last day is the first day + 6
      // firstday = new Date(curr.setDate(first)).toUTCString();
      // lastday = new Date(curr.setDate(last)).toUTCString();


      firstday = this.superadminWeek.calendar.currentData.dateProfile.activeRange.start
      // lastday = this.superadminWeek.calendar.currentData.dateProfile.activeRange.end
      lastday = moment(this.superadminWeek.calendar.currentData.dateProfile.activeRange.start, "YYYY-MM-DD").add(6, 'days').format("YYYY-MM-DD");//For Week end date Start day +6 days


    }
    if (this.viewType === 'Month') {
      setTimeout(() => {
        this.Customtitle = this.superadminMonth.calendar.currentData.viewTitle;
      }, 0);
      curr = new Date(this.superadminMonth.calendar.currentData.currentDate);

      this.defDate = this.superadminMonth.calendar.getDate();

      const date = curr;
      first = (new Date(date.getFullYear(), date.getMonth(), 1)).getDate();
      last = (new Date(date.getFullYear(), date.getMonth() + 1, 0)).getDate();
      // firstday = this.superadminMonth.calendar.currentData.dateProfile.currentRange.start;
      // lastday = this.superadminMonth.calendar.currentData.dateProfile.currentRange.end;

      firstday = this.superadminMonth.calendar.currentData.dateProfile.currentRange.start;
      lastday = moment(this.superadminMonth.calendar.currentData.dateProfile.currentRange.end, "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD");
    }

    if (this.viewType === 'Day') {
      this.calendarOptions3.visibleRange = { start: firstday, end: lastday };
      this.calendarOptionsDaygrid.visibleRange = { start: firstday, end: lastday };
    }
    if (this.viewType === 'Week') {
      this.calendarOptions4.visibleRange = { start: firstday, end: lastday };
      this.disableDates = this.enumerateDaysBetweenDates(moment(firstday).format('YYYY-MM-DD'), moment(lastday).format('YYYY-MM-DD'));
    }
    if (this.viewType === 'Month') {
      this.calendarOptions5.visibleRange = { start: firstday, end: lastday };
    }
    const d = moment(firstday).utcOffset();
    if (this.viewType === 'Day') {
      return {
        fromDate: moment(firstday).subtract(d, 'minutes').format('YYYY-MM-DD'),
        toDate: moment(lastday).subtract(d, 'minutes').format('YYYY-MM-DD'),
        viewBy: this.viewBy
      };
    }
    else {
      return {
        fromDate: moment(firstday).format('YYYY-MM-DD'),
        toDate: moment(lastday).format('YYYY-MM-DD'),
        viewBy: this.viewBy
      };
    }
  }

  enumerateDaysBetweenDates(startDate, endDate) {
    let dates = [];

    let currDate = moment(startDate).startOf('day');
    let lastDate = moment(endDate).startOf('day');
    dates.push(currDate.clone().toDate());
    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
      dates.push(currDate.clone().toDate());
    }

    return dates;
  }

  callAfterViewInit() {

    this.updateEventRefreshCal = false;

    this.calendarOptions3.initialDate = this.defDate;
    this.calendarOptionsDaygrid.initialDate = this.defDate;

    this.calendarOptions4.initialDate = this.defDate;
    this.calendarOptionsDaygridWeek.initialDate = this.defDate;

    this.calendarOptions5.initialDate = this.defDate;
    this.calendarOptionsDaygridMonth.initialDate = this.defDate;

    setTimeout(() => {
      if (!this.isMobileView) {
        if (this.viewType === 'Day' && document.getElementById('dailySuperadmin')) {
          document.getElementById('dailySuperadmin')["value"] = this.viewBy;
        }
        if (this.viewType === 'Week' && document.getElementById('superadminWeek')) {
          document.getElementById('superadminWeek')["value"] = this.viewBy;
        }
        if (this.viewType === 'Month' && document.getElementById('superadminMonth')) {
          document.getElementById('superadminMonth')["value"] = this.viewBy;
        }
      }
      else {
        if (this.viewType === 'Day') {
          this.viewBy === 'providers' ? $('#viewByMobileProviders').attr('checked', 'checked') : $('#viewByMobilePatients').attr('checked', 'checked');
        }
        if (this.viewType === 'Week') {
          this.viewBy === 'providers' ? $('#viewByMobileProviders').attr('checked', 'checked') : $('#viewByMobilePatients').attr('checked', 'checked');
        }
        if (this.viewType === 'Month') {
          this.viewBy === 'providers' ? $('#viewByMobileProviders').attr('checked', 'checked') : $('#viewByMobilePatients').attr('checked', 'checked');
        }
      }

      this.onProviderChange();
    }, 10)
    localStorage.scheduleViewType = this.viewType;
    this.onClear()
  }

  onProviderChange(e = null) {
    const dates = this.getCalendarDateRange();
    const payload = { viewBy: this.viewBy, ...dates };
    this.cdr.detectChanges();
    if (this.searchText !== '') {
      payload["searchBy"] = this.searchText;
    }

    if (this.filterIsPinned) {
      payload["isPinned"] = this.filterIsPinned;
    }
    this.savedPayload = payload;
    this.showEvent = false;
    this.showEventSchdule = false;
    this.showEventProvider = false;
    if (this.userRole != 'provider') {
      // this.savedPayload = payload
    } else {
      this.savedPayload = payload
      this.savedPayload["clinicIds"] = this.schedulingClinicListData.map((obj) => { return obj.value });
    }
    this.getScheduling(payload);
  }

  onSchedulingClinicChange(event) {
    if (event.value.length > 0) {
      this.savedPayload["clinicIds"] = event.value.map((obj) => { return obj.value });
      this.getScheduling(this.savedPayload);
    } else {
      JSON.parse(localStorage.getItem('optplanner_user')).assignedClinics.forEach((obj) => {
        if (obj.value == JSON.parse(localStorage.getItem('assignedClinic'))) {
          this.schedulingClinicListData = [{
            name: obj.label,
            value: obj.value,
          }];
          this.savedPayload["clinicIds"] = [obj.value];
          this.getScheduling(this.savedPayload);
        }
      });
    }
  }

  resetCalenderData() {
    this.calendarOptions3.events = [];
    this.calendarOptions4.events = [];
    this.calendarOptions5.events = [];
    if (!this.updateEventRefreshCal) {
      this.calendarOptions3.resources = [];
      this.calendarOptions4.resources = [];
      this.calendarOptions5.resources = [];
    }
  }
  HolidayYearChange = 0;
  getScheduling(data) {
    if (new Date().getFullYear() < Number(moment(data.fromDate).format('YYYY'))) {
      if (this.HolidayYearChange === 0) {
        this.getHoliday();
        this.HolidayYearChange++;
      }
    }

    const payload = data;
    let HolidayPayload = payload;
    this.resetCalenderData();
    if (this.Tippyinstance) {
      this.Tippyinstance.destroy();
    }
    if (this.ListViewToggle) {
      this.appService.showCustomLoader(true);
    } else {
      this.appService.showCustomLoader({ state: true, message: `<span style="font-weight: bold;color: #000000;">Fetching Data</span>` });
    }
    if (this.userRole != 'provider') {
      if (this.searchText !== "") {
        if (this.viewBy === 'patients') {
          this.getPatient({ page: 1, limit: 1000, sortBy: 'name', status: true, searchParam: this.searchText }, data);
          // this.getProvider(data);
        }
        else {
          // this.getProvider({ page: 1, limit: 1000, sortBy: 'name', status:true,searchParam:this.searchText});
          this.getPatient({ page: 1, limit: 1000, sortBy: 'name', status: true }, { page: 1, limit: 1000, sortBy: 'name', status: true, searchParam: this.searchText, fromDate: data.fromDate, toDate: data.toDate });
        }
      }
      else {
        this.getPatient({ page: 1, limit: 1000, sortBy: 'name', status: true }, data);
        // this.getProvider(data)
      }
    } else {
      this.prepareScheduling(this.savedPayload);
    }
    if (this.ListViewToggle) {
      this.dt.reset();
    }

  }
  sessionNotInTeam: any;
  holidayCount:any[] = []
  prepareScheduling(payload) {
    console.log("prepareScheduling" + payload);

    this.schedulingService.getScheduling(payload).subscribe((response: any) => {

      if (response.data) {
        this.IndividualGroupToolTipData = response.data.individualAndGroupCount;
        this.BCBAToolTipData = response.data.bcbaCount;
        this.OTToolTipData = response.data.otCount;
        this.SpeechToolTipData = response.data.speechCount;
        this.ProviderAccessSolverTypes = response.data?.solverTypes ? response.data?.solverTypes : [];
        this.sessionNotInTeam = response.data.sessionNotInTeam;
        if (this.userRole === 'provider') {
          this.solverType = [];

          if (this.IndividualGroupToolTipData > 0 || this.ProviderAccessSolverTypes.includes('INDIVIDUAL_AND_GROUP')) {
            if (this.filterCheckedData.solverType === 'I&G') {
              this.solverType.push({ label: 'Individual & Group', value: 'I&G', checked: true })
            } else {
              this.solverType.push({ label: 'Individual & Group', value: 'I&G', checked: false })
            }
          }
          if (this.BCBAToolTipData > 0 || this.ProviderAccessSolverTypes.includes('BCBA')) {
            // this.solverType.push({ label: 'BCBA', value: 'BCBA',checked:false })
            if (this.filterCheckedData.solverType === 'BCBA') {
              this.solverType.push({ label: 'BCBA', value: 'BCBA', checked: true })
            } else {
              this.solverType.push({ label: 'BCBA', value: 'BCBA', checked: false })
            }
          }
          if (this.OTToolTipData > 0 || this.ProviderAccessSolverTypes.includes('OT')) {
            // this.solverType.push({ label: 'OT', value: 'OT',checked:false })
            if (this.filterCheckedData.solverType === 'OT') {
              this.solverType.push({ label: 'OT', value: 'OT', checked: true })
            } else {
              this.solverType.push({ label: 'OT', value: 'OT', checked: false })
            }
          }
          if (this.SpeechToolTipData > 0 || this.ProviderAccessSolverTypes.includes('SPEECH')) {
            // this.solverType.push({ label: 'Speech', value: 'SPEECH',checked:false });
            if (this.filterCheckedData.solverType === 'SPEECH') {
              this.solverType.push({ label: 'Speech', value: 'SPEECH', checked: true })
            } else {
              this.solverType.push({ label: 'Speech', value: 'SPEECH', checked: false })
            }
          }
        }
        const arr = [];
        const type = this.viewBy === 'patients' ? 'patient' : 'provider';
        let cal = this.superadminDaily;
        if (this.viewType === 'Week') {
          cal = this.superadminWeek;
        }
        if (this.viewType === 'Month') {
          cal = this.superadminMonth;
        }
        let list = response.data || response.data;
        const providerView = this.userRole === 'provider';

        if (this.viewBy === 'providers') {
          // this.ViewByPPList = list.provider;
          if (!this.filterIsPinned) {
            // check unavailability
            this.schedulingService.getUnavailabilityScheduling(payload, this.loggedInPid, this.userRole).subscribe((res: any) => {

              list["unavailabilityDtos"] = this.userRole === 'provider' ? res.data.providerUnavailabilityDTOs : res.data;
              // if(this.callHolidayFirst === 1){
              this.upcomingHolidayData = '';
              // this.schedulingService.getHolidayScheduling(HolidayPayload).subscribe((res: any) => {
              this.getHolidays = this.holidayList;
              if (this.getHolidays.length === 0) {
                this.upcomingHolidayData += 'No Holidays';
              }
              else {
                for (let index = 0; index < this.getHolidays.length; index++) {
                  const element = this.getHolidays[index];
                  if (index === 0) {
                  }
                  let isValidHoliday = this.HolidayDateChecker(element.holidayDate);
                  if (isValidHoliday) {
                    this.upcomingHolidayData += element.holidayName + ': ' + element.holidayDate + '<br>';
                    this.holidayCount.push(element.holidayName);
                    console.log(this.holidayCount);
                    
                  }

                  // this.upcomingHolidayData += element.holidayName +': '+ element.holidayDate+'<br>';

                }
              }
              list["holidayDtos"] = res.data.holidays;

              // },(error:any)=>{
              // },()=>{
              //   this.assignCalenderData(list, type,providerView, cal, arr);
              //   this.iCallLink = response.data.icalendarLink;
              //   this.appService.showCustomLoader(false);
              // }
              // );       
            }
              , (error: any) => {

              }, () => {
                setTimeout(() => {
                  this.assignCalenderData(list, type, providerView, cal, arr);
                }, 100);
                this.iCallLink = response.data.icalendarLink;
                // this.appService.showCustomLoader(false);
              }
            );
          } else {
            setTimeout(() => {
              this.assignCalenderData(list, type, providerView, cal, arr);
            }, 100);
            this.iCallLink = response.data.icalendarLink;
            // this.appService.showCustomLoader(false);
          }
        } else {
          // this.ViewByPPList = list.patient;
          list["unavailabilityDtos"] = [];
          if (!this.filterIsPinned) {
            this.upcomingHolidayData = '';
            // this.schedulingService.getHolidayScheduling(HolidayPayload).subscribe((res: any) => {
            // if(this.callHolidayFirst === 1){
            this.getHolidays = this.holidayList;
            console.log(this.getHolidays.length);

            if (this.getHolidays.length === 0) {
              this.upcomingHolidayData += 'No Holidays';
            }
            else {
              for (let index = 0; index < this.getHolidays.length; index++) {
                const element = this.getHolidays[index];
                if (index === 0) {
                  // this.upcomingHolidayData += 'Upcoming holidays...';
                }

                let isValidHoliday = this.HolidayDateChecker(element.holidayDate);
                if (isValidHoliday) {
                  this.upcomingHolidayData += element.holidayName + ': ' + element.holidayDate + '<br>';
                }

                // this.upcomingHolidayData += element.holidayName +': '+ element.holidayDate+'<br>';

              }
            }
            // }
            // this.callHolidayFirst++;
            list["holidayDtos"] = this.holidayList;

            // },(error:any)=>{
            // },()=>{
            setTimeout(() => {
              this.assignCalenderData(list, type, providerView, cal, arr);
            }, 100);
            this.iCallLink = response.data.icalendarLink;
            // this.appService.showCustomLoader(false);
            // });
          } else {
            setTimeout(() => {
              this.assignCalenderData(list, type, providerView, cal, arr);
            }, 100);
            this.iCallLink = response.data.icalendarLink;
            // this.appService.showCustomLoader(false);
          }
        }
      }
    });
  }

  getHoliday() {
    this.schedulingService.getHolidayScheduling(this.savedPayload).subscribe((res: any) => {
      if (res.data) {
        res.data.holidays.map(obj => {
          if (this.holidayList.filter((Hobj) => { return Hobj.id === obj.id }).length === 0) {
            this.holidayList.push(obj);
          }
        })
      }
    });
  }

  HolidayDateChecker(Holidaydate) {
    const StartDate = this.savedPayload.fromDate;
    const EndDate = this.savedPayload.toDate;

    if (Holidaydate >= StartDate && Holidaydate <= EndDate) {
      return true;
    } else {
      return false;
    }
  }

  getPatient(data, data2): any {
    this.patientService.getSchdlCalPatientList(data).subscribe((response: any) => {
      if (response.data) {
        console.log('patient',response)
        this.ClinicWisePatientDataArr = response.data;
      }
      this.providerService.getSchdlCalProviderListData(data2).subscribe((response: any) => {
        if (response.data) {
          console.log('provider',response)
          let providerJobTitle = [];
          this.providerJobTitleFilterList = [];
          if (this.providerFilterByJobIdsArry.length > 0) {
            this.ClinicWiseProviderDataArr = response.data.filter((element) => {
              if (this.providerFilterByJobIdsArry.length === 0) {
                return element;
              } else if (this.providerFilterByJobIdsArry.includes(element.jobTitle)) {
                return element;
              }
            });
          } else {
            this.ClinicWiseProviderDataArr = response.data;
            console.log("Provider data", this.ClinicWiseProviderDataArr)
          }

          if (this.viewBy === 'providers') {
            response.data.filter((obj) => { obj.isActive ? providerJobTitle.push(obj.jobTitle) : null });
            this.providerJobTitleFilterList = [...new Set(providerJobTitle)].map((obj) => {
              if (obj === "NEW HIRED BT") {
                return { label: "New Hired BT", value: obj }
              } else {
                return { label: obj, value: obj }
              }
            });
            console.log("Provider Job Title List", this.providerJobTitleFilterList)
          }
          // this.providerJobTitleFilterList.unshift({label:'All',value:'All'});
        }
        this.prepareScheduling(this.savedPayload);
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      });
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    })
  }

  clearJobIdFilter() {
    // if(this.providerFilterByJobId.length > 0){
    this.providerFilterByJobIdsArry = [];
    this.providerFilterByJobId = [];
    this.getScheduling(this.savedPayload)
    // }
  }

  openJobIdFilterDefaultStop(e) {
    e.stopPropagation();
  }
  openJobIdFilter(e) {
    this.multiDateRangeFilterRef.overlayVisible = !this.multiDateRangeFilterRef.overlayVisible;
    e.stopPropagation();
  }

  filterByJobIdProviderList() {
    this.providerFilterByJobIdsArry = [];
    this.providerFilterByJobId.filter((obj) => { this.providerFilterByJobIdsArry.push(obj.value) });

    this.getScheduling(this.savedPayload)
  }

  // getProvider(data){
  //   this.providerService.getSchdlCalProviderListData(data).subscribe((response:any)=>{
  //     if (response.data) {
  //       this.ClinicWiseProviderDataArr = response.data;
  //     }
  //   },(error)=>{
  //     this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
  //   });
  // }

  assignCalenderData(list, type, providerView, cal, arr) {
    console.log("START=====>>>>", performance.now());
    console.time('Execution Time START');
    if (this.Tippyinstance) {
      this.Tippyinstance.destroy();
    }
    // this.appService.showCustomLoader(false);
    // this.appService.showCustomLoader({state: true, message:'Rendring Data...'});

    this.calendarOptions4.events = [];
    this.calendarOptionsDaygridWeek.events = [];
    this.calendarOptions5.events = [];
    this.calendarOptionsDaygridMonth.events = [];
    this.calendarOptions3.events = [];
    this.calendarOptionsDaygrid.events = [];

    this.EventsDataArr = [];
    this.planned_count = 0;
    this.un_planned_count = 0;
    this.escalated_count = 0;
    this.converted_count = 0;
    this.unavailability_count = 0;
    this.unavailability_pending_count = 0;
    this.unavailability_denied_count = 0;
    this.resourceIDs = [];
    this.checkBoxItem = 0;
    this.checkBox_count = [];
    this.checkBoxArr = [];
    this.checkBoxArrId = [];
    this.select_all_check = false;
    // if((this.userRole !== 'provider')){
    //   this.ViewByPPList.forEach((obj)=>{
    //     this.resourceIDs.push(obj.id)
    //   });
    // }

    if (this.viewBy === 'patients') {
      this.ClinicWisePatientDataArr.forEach((element) => {
        if (element.isActive) {
          if (element.id != -1) {
            if (!this.updateEventRefreshCal) {
              cal.calendar.addResource({
                id: element.id, title: element.firstName + ' ' + element.lastName,
                documentId: element.documentId,
                email: element.email,
                isActive: element.isActive,
                class: '',
                // eventConstraint: JSON.stringify(element)
              });
            }
            this.resourceIDs.push(element.id)
          }
        }
      });
    } else {
      this.ClinicWiseProviderDataArr.forEach((element) => {
        console.log("Provider Session Hours : ", element.providerSessionHour)
        if (element.isActive) {
          if (element.id != -1) {
            if (!this.updateEventRefreshCal) {
              cal.calendar.addResource({
                id: element.id, title: element.firstName + ' ' + element.lastName,
                documentId: element.documentId,
                email: element.email,
                availableHours: element.providerAvailabilityHours,
                sessionHours: element.providerSessionHour,
                isActive: element.isActive,
                class: '',
                // eventConstraint: JSON.stringify(element)
              });
            }
            this.resourceIDs.push(element.id)
          }
        }
      });
    }


    list.scheduleDtos && list.scheduleDtos.forEach((obj, i) => {


      if (obj.hasOwnProperty("patientId")) {
        if (!this.updateEventRefreshCal && obj.patientId === -1) {
          if (this.providerFilterByJobIdsArry.length === 0) {
            cal.calendar.addResource({
              id: -1, title: 'Zzzzzzzz',
              documentId: null,
              email: 'NA',
              isActive: null,
              class: '',
            });
          }
        }
        this.resourceIDs.push(-1)
        if (obj.patientId === -1) {
          if (this.providerFilterByJobIdsArry.length === 0) {
            obj["patient"] = { id: -1, firstName: "UnAssigned", lastName: "", email: "NA", documentId: null, isActive: null }
          }
        } else {
          let data = this.ClinicWisePatientDataArr.filter((objj) => { return objj.id === obj.patientId })[0];
          obj["patient"] = data;
        }
      }
      if (obj.hasOwnProperty("providerId")) {
        if (!this.updateEventRefreshCal && obj.providerId === -1) {
          if (this.providerFilterByJobIdsArry.length === 0) {
            cal.calendar.addResource({
              id: -1, title: 'Zzzzzzzz',
              documentId: null,
              email: 'NA',
              isActive: null,
              class: '',
            });
          }
        }
        this.resourceIDs.push(-1)
        if (obj.providerId === -1) {
          if (this.providerFilterByJobIdsArry.length === 0) {
            obj["provider"] = { id: -1, firstName: "UnAssigned", lastName: "", email: "NA", documentId: null, isActive: null }
          }
        } else {
          let data = this.ClinicWiseProviderDataArr.filter((objj) => { return objj.id === obj.providerId })[0];
          obj["provider"] = data;
        }
      }
      let EventTexColor = '#ffffff';
      const objTypeData = obj[type];
      const sessionDate = providerView ? (obj.sessionDate || obj.shiftDate) : obj.shiftDate
      const startDate = `${sessionDate}T${obj.startTime}`;
      const endDate = `${sessionDate}T${obj.endTime}`;
      const dates = { fromDate: moment(startDate).format('hh:mm a'), toDate: moment(endDate).format('hh:mm a') };
      let name = '';
      if (providerView && obj.patientName) {
        name = `${obj.patientName}`;
      }
      else {
        name = `${objTypeData?.firstName} ${objTypeData?.lastName}`;
      }

      obj["isUnavailability"] = false;

      this.resourceIDs.push(objTypeData?.id)
      let badgeClass;
      let sessionColor

      if (this.viewBy == 'providers') {
        if (obj.providerId != -1) {
          let providerAsPerSession = this.ClinicWiseProviderDataArr.find((pobj) => pobj.id === obj.providerId);
          if (providerAsPerSession) {
            if (!providerAsPerSession.patientIdsInTeam.includes(obj.patientId)) {
              badgeClass = obj.spotType + 'Bedge Male';
            } else {
              badgeClass = obj.spotType + 'Bedge';
            }
          } else {
            badgeClass = obj.spotType + 'Bedge';
          }
        } else {
          badgeClass = obj.spotType + 'Bedge';
        }
      } else {
        if (obj.patientId != -1) {
          let patientAsPerSession = this.ClinicWisePatientDataArr.find((pobj) => pobj.id === obj.patientId);
          if (patientAsPerSession) {
            if (!patientAsPerSession.providerIdsInTeam.includes(obj.providerId)) {
              badgeClass = obj.spotType + 'Bedge Male';
            } else {
              badgeClass = obj.spotType + 'Bedge';
            }
          } else {
            badgeClass = obj.spotType + 'Bedge';
          }
        } else {
          badgeClass = obj.spotType + 'Bedge';
        }
      }

      // if(obj.patient?.providerChoice == 'M'){
      //   badgeClass = obj.spotType+'Bedge Male';
      // }else if(obj.patient?.providerChoice == 'F'){
      //   badgeClass = obj.spotType+'Bedge Female';
      // }else{
      //   badgeClass = obj.spotType+'Bedge';
      // }

      if (obj.isScheduled && obj.isEscalated) {
        sessionColor = 'providerLoginMonthViewPurple'
        EventTexColor = '#fff';
        this.escalated_count++
        this.converted_count++
      }
      else if (obj.isEscalated) {
        sessionColor = 'providerLoginMonthViewRed'
        obj["colorTooltip"] = 'Escalated'
        this.escalated_count++;
      } else {
        if (this.viewBy === 'patients') {
          if ((!obj.hasOwnProperty("provider") || obj?.provider?.id == -1)) {
            sessionColor = 'providerLoginMonthViewYellow'
            obj["colorTooltip"] = 'Un-planned'
            this.un_planned_count++;
            EventTexColor = 'black';
          } else {
            if (obj.isScheduled) {
              sessionColor = 'providerLoginMonthViewPurple'
              EventTexColor = '#fff';
              this.converted_count++;
            }
            else if (obj?.patient?.id == -1) {
              sessionColor = 'providerLoginMonthViewYellow'
              obj["colorTooltip"] = 'Un-planned'
              this.un_planned_count++;
              EventTexColor = 'black';
            } else {
              sessionColor = 'providerLoginMonthViewGreen'
              obj["colorTooltip"] = 'Planned'
              this.planned_count++;
              EventTexColor = '#ffffff';
            }
          }
        } else {// viewBy == provider
          if (obj.isScheduled) {
            sessionColor = 'providerLoginMonthViewPurple'
            EventTexColor = '#fff';
            this.converted_count++
          }
          else if ((!obj.hasOwnProperty("patient") || obj?.patient?.id == -1)) {
            sessionColor = 'providerLoginMonthViewYellow'
            obj["colorTooltip"] = 'Un-planned'
            this.un_planned_count++;
            EventTexColor = 'black';
          } else {
            if (obj.provider?.id == -1) {
              sessionColor = 'providerLoginMonthViewYellow'
              obj["colorTooltip"] = 'Un-planned'
              this.un_planned_count++;
              EventTexColor = 'black';
            } else {
              sessionColor = 'providerLoginMonthViewGreen'
              obj["colorTooltip"] = 'Planned'
              this.planned_count++;
              EventTexColor = '#ffffff';
            }
          }
        }
      }

      if (this.userRole === 'provider' && !obj.hasOwnProperty("patient")) {
        obj["patient"] = obj.provider
      }


      let eventName = '';
      if (this.viewBy === 'patients') {
        if (obj.hasOwnProperty("provider")) {
          eventName = `${obj.provider?.firstName} ${obj.provider?.lastName}`;
        }
      } else {// view by provider
        if (obj.hasOwnProperty("patient")) {
          if (obj.patient) {
            eventName = `${obj.patient?.firstName} ${obj.patient?.lastName}`;
          }
        }
      }
      arr.push({
        tooltip: `${dates.fromDate} - ${dates.toDate}`,
        constraint: this.viewBy === 'patients' || obj.isScheduled ? JSON.stringify(obj) : null,
        resourceId: this.userRole === 'provider' ? '' : objTypeData?.id,
        title: eventName ? eventName : '',
        extendedProps: obj,
        eventId: obj.id,
        start: startDate,
        end: endDate,
        // backgroundColor: obj.isEscalated ? '#802000' : (!obj.hasOwnProperty("provider") || obj.provider.id==-1) ? '#ffb84d' : '#1d8248',
        textColor: EventTexColor,
        // className: [obj.patient?.providerChoice == 'M' ? 'Male' : obj.patient?.providerChoice == 'F' ? 'Female' : '', obj.isEscalated ? 'providerLoginMonthViewRed' : (!obj.hasOwnProperty("provider") || obj.provider.id==-1) ? 'providerLoginMonthViewYellow' : 'providerLoginMonthViewGreen'],
        className: [badgeClass, sessionColor, obj.isOutsideHrs === true ? 'clinicOutsideBadge' : '', obj.recurrence === 'customRecurrence' ? 'customReccurrenceBadge' : '']
      });
    });


    if (list.scheduleDtos.length === 0) {
      if (this.searchText !== '') {
        let resourceA = cal.calendar.getResourceById(-1);
        if (resourceA) {
          resourceA.remove();
        }
      }
    }

    if (this.viewBy != 'patients') {

      list.unavailabilityDtos && list.unavailabilityDtos.forEach((objU) => {
        if ((this.userRole !== 'manager')) {
          objU.status === 'approved' ? this.unavailability_count++ : '';
          objU.status === 'pending' ? this.unavailability_pending_count++ : '';
          objU.status === 'disApproved' ? this.unavailability_denied_count++ : '';
          objU["isUnavailability"] = true;
          if (objU.status != 'pending' && objU.status != 'disApproved') {
            if (objU.allDay) {
              arr.push({
                constraint: JSON.stringify(objU),
                resourceId: this.userRole === 'provider' ? '' : objU.providerId,
                title: objU.allDay === true ? objU.unavailabilityReasonTypeDTO.type + ' All Day' : objU.unavailabilityReasonTypeDTO.type + ` ${moment(objU.startDate + 'T' + objU.startTime).format('hh:mm a')} - ${moment(objU.startDate + 'T' + objU.endTime).format('hh:mm a')}`,
                extendedProps: objU,
                eventId: objU.id,
                start: `${objU.startDate}T${this.calendarOptions4.slotMinTime}`,
                end: `${objU.endDate}T${this.calendarOptions4.slotMaxTime}`,
                backgroundColor: objU.status === 'approved' ? '#88898b' : objU.status === 'pending' ? '#fea4b4' : '#d76868',
                textColor: '#ffffff',
                className: objU.status === 'approved' ? 'providerLoginMonthViewUnavltyAprv AllDayAprvTimeHide' : objU.status === 'pending' ? 'providerLoginMonthViewUnavltyPndng' : 'providerLoginMonthViewUnavltyDenied',
              });
            }
            else {
              arr.push({
                tooltip: objU.allDay === true ? objU.unavailabilityReasonTypeDTO.type + ' All Day' : objU.unavailabilityReasonTypeDTO.type + ` ${moment(objU.startDate + 'T' + objU.startTime).format('hh:mm a')} - ${moment(objU.startDate + 'T' + objU.endTime).format('hh:mm a')}`,
                constraint: JSON.stringify(objU),
                resourceId: this.userRole === 'provider' ? '' : objU.providerId,
                title: objU.allDay === true ? objU.unavailabilityReasonTypeDTO.type + ' All Day' : objU.unavailabilityReasonTypeDTO.type,
                extendedProps: objU,
                eventId: objU.id,
                start: `${objU.startDate}T${objU.startTime}`,
                end: `${objU.endDate}${objU.allDay == true ? `T20:00:00` : `T` + objU.endTime}`,
                backgroundColor: objU.status === 'approved' ? '#88898b' : objU.status === 'pending' ? '#fea4b4' : '#d76868',
                textColor: '#ffffff',
                className: objU.status === 'approved' ? 'providerLoginMonthViewUnavltyAprv' : objU.status === 'pending' ? 'providerLoginMonthViewUnavltyPndng' : 'providerLoginMonthViewUnavltyDenied',
              });
            }
          }

        } else {
          if (objU.status === 'approved') {

            objU.status === 'approved' ? this.unavailability_count++ : '';
            objU.status === 'pending' ? this.unavailability_pending_count++ : '';
            objU.status === 'disApproved' ? this.unavailability_denied_count++ : '';
            objU["isUnavailability"] = true;
            if (objU.status != 'pending') {
              arr.push({
                tooltip: objU.allDay === true ? objU.unavailabilityReasonTypeDTO.type + ' All Day' : objU.unavailabilityReasonTypeDTO.type + ` ${moment(objU.startDate + 'T' + objU.startTime).format('hh:mm a')} - ${moment(objU.startDate + 'T' + objU.endTime).format('hh:mm a')}`,
                constraint: JSON.stringify(objU),
                resourceId: objU.providerId,
                title: objU.allDay === true ? objU.unavailabilityReasonTypeDTO.type + ' All Day' : objU.unavailabilityReasonTypeDTO.type,
                extendedProps: objU,
                eventId: objU.id,
                start: `${objU.startDate}T${objU.startTime}`,
                end: `${objU.endDate}${objU.allDay == true ? `T20:00:00` : `T` + objU.endTime}`,
                backgroundColor: objU.status === 'approved' ? '#88898b' : objU.status === 'pending' ? '#fea4b4' : '#d76868',
                textColor: '#ffffff',
                className: objU.status === 'approved' ? 'providerLoginMonthViewUnavltyAprv' : objU.status === 'pending' ? 'providerLoginMonthViewUnavltyPndng' : 'providerLoginMonthViewUnavltyDenied',
              });
            }
          }
        }
      });
    }

    this.EventsDataArr = arr;

    if (this.onApplyFilterBtn) {
      this.updateDataFilter(this.filterCheckedData);
    } else {
      this.filterCheckedData.genderChoice = null;
      this.filterCheckedData.sessionStatus = null;
      this.filterCheckedData.solverType = null;
      this.updateDataFilter(this.filterCheckedData);
    }

    if (this.Tippyinstance) {
      this.Tippyinstance.destroy();
    }
  }
  plannedShiftDeletePopup: Boolean = false;
  confirmViewMultiDeleteCall() {
    this.deleteEventComment = '';
    this.viewStatusScudule = 'CLIENT_CANCELLATION';
    this.viewTypeScudule = 'current';
    this.plannedShiftDeletePopup = true;
  }

  check_all(ev) {
    if (ev.checked) {
      this.checkBoxArr = [];
      this.checkBoxArrId = [];
      let i = 0;
      if (this.dt.filteredValue) {
        this.dt.filteredValue.forEach(el => {
          if (this.ListViewTableData[i].listcheckBoxShowIcon) {
            this.checkBoxArr[i] = true;
            if (this.searchTextListView !== '') {
              this.checkBoxArrId.push(this.dt.filteredValue[i].extendedProps.id)
            } else {
              this.checkBoxArrId.push(this.ListViewTableData[i].extendedProps.id)
            }
            this.ii++;
            this.checkBox_count.push(this.ii);
          }
          i++;
        });
      } else {
        this.ListViewTableData.forEach(el => {
          if (this.ListViewTableData[i].listcheckBoxShowIcon) {
            this.checkBoxArr[i] = true;
            this.checkBoxArrId.push(this.ListViewTableData[i].extendedProps.id)
            this.ii++;
            this.checkBox_count.push(this.ii);
          }
          i++;
        });
      }
      this.select_all_check = true;
    } else {
      this.checkBoxArr = [];
      this.ListViewTableData.forEach(el => {
        this.checkBoxArr.push(false);
        this.checkBoxArrId.pop();
        this.checkBox_count.pop();
      });

      this.select_all_check = false;
    }
    console.log(this.checkBoxArrId);


  }

  check_sub(event, rowData, rowindex) {
    if (event.checked) {
      this.checkBoxItem++;
      this.checkBox_count.push(this.checkBoxItem);
      this.checkBoxArrId.push(rowData.extendedProps.id);
      this.checkBoxArr[rowindex] = true;

    } else {

      this.checkBox_count.pop();
      this.checkBoxArr[rowindex] = false;
      let index = this.checkBoxArrId.indexOf(rowData.extendedProps.id);

      this.checkBoxArrId.splice(index, 1);
    }
    if (this.ListViewTableData.length == this.checkBoxArrId.length) {
      this.select_all_check = true;
    } else {
      this.select_all_check = false;
    }
    console.log(this.checkBoxArrId);

  }

  listWeekViewData() {
    this.onClear();
    this.filterbox = false;
    this.appService.showCustomLoader(true);
    localStorage.scheduleListView = this.ListViewToggle;

    if (!this.ListViewToggle) {
      this.reRenderCalendar();
      this.checkBoxItem = 0;
      this.checkBox_count = [];
      this.checkBoxArr = [];
      this.checkBoxArrId = [];
    }

    setTimeout(() => {
      this.appService.showCustomLoader(false);
      this.reRenderCalendar();
    }, 1000);
  }
  updateDataFilter(event) {
    if ((this.filterCheckedData.solverType === null && this.filterCheckedData.sessionStatus === null && this.filterCheckedData.genderChoice === null && this.filterCheckedData.crApptId === null && this.filterCheckedData.isScheduled === null)) {
      this.highlightFilter = true;
      this.solverType.forEach(Item => {
        Item.checked = false;
      });
      this.sessionStatus.forEach(Item => {
        Item.checked = false;
      })
      this.genderChoice.forEach(Item => {
        Item.checked = false;
      })
      this.crApptId.forEach(Item => {
        Item.checked = false;
      })
      this.isScheduled.forEach(Item => {
        Item.checked = false;
      })
    } else {
      this.highlightFilter = false;
    }

    console.log(this.filterCheckedData);
    console.log(this.solverType);
    console.log(this.highlightFilter);
    // this.filterDataCols = event;
    let arrSt = [];
    let arrSS = [];
    let arrCR = [];
    let arr = [];
    this.ListViewTableData = [];
    let finalPPList;
    switch (event.solverType) {
      case "BCBA":
        this.EventsDataArr.forEach(element => {
          if (element.className[0].includes('BCBABedge')) {
            arrSt.push(element);
          }
        });
        break;
      case "OT":
        this.EventsDataArr.forEach(element => {
          if (element.className[0].includes('OTBedge')) {
            arrSt.push(element);
          }
        });
        break;
      case "SPEECH":
        this.EventsDataArr.forEach(element => {
          if (element.className[0].includes('SPEECHBedge')) {
            arrSt.push(element);
          }
        });
        break;
      case "I&G":
        this.EventsDataArr.forEach(element => {
          if (element.className[0].includes('INDIVIDUALBedge') ||
            element.className[0].includes('GROUPBedge') ||
            element.className[0].includes('BEHAVIOURBedge') ||
            element.className[0].includes('NAPBedge') ||
            element.className[0].includes('NORMALBedge')) {
            arrSt.push(element);
          }
        });
        break;

      default:
        arrSt = [...this.EventsDataArr];
        break;
    }

    switch (event.sessionStatus) {
      case "PLANNED":
        arrSt.forEach(element => {
          if (element.className.includes('providerLoginMonthViewGreen')) {
            arrSS.push(element);
          }
        });
        break;
      case "UNPLANNED":
        arrSt.forEach(element => {
          if (element.className.includes('providerLoginMonthViewYellow')) {
            arrSS.push(element);
          }
        });
        break;
      case "ESCALATED":
        arrSt.forEach(element => {
          if (element.className.includes('providerLoginMonthViewRed')) {
            arrSS.push(element);
          }
        });
        break;
      case "UNAVAILABILITY":
        arrSt.forEach(element => {
          if (element.className.includes('providerLoginMonthViewUnavltyAprv')) {
            arrSS.push(element);
          }
        });
        break;
      case "IsPin":
        arrSt.forEach(element => {
          if ((!element.className.includes('providerLoginMonthViewUnavltyAprv')) && element.extendedProps.isPinned) {
            console.log(element);

            arrSS.push(element);
          }
        })
        break;

      default:
        arrSS = [...arrSt];
        break;
    }

    switch (event.genderChoice) {
      case "MALE":
        arrSS.forEach(element => {

          if (element.className[0].includes('Male')) {
            arrCR.push(element);
          }
        });
        break;
      case "FEMALE":
        arrSS.forEach(element => {
          if (element.className[0].includes('Female')) {
            arrCR.push(element);
          }
        });
        break;
      case "PREFER":
        arrSS.forEach(element => {
          if (!element.className[0].includes('Male')) {
            if (!element.className.includes('providerLoginMonthViewUnavltyAprv')) {
              arrCR.push(element);
            }
          }
        });
        break;

      default:
        arrCR = [...arrSS];
        break;
    }
    //   switch (event.crApptId) {
    //     case "crApptId":
    //       arrCR.forEach(element => {

    //         if(element.extendedProps.crApptId === undefined){
    //         arr.push(element);
    //         }
    //       })
    //     break;

    //   default:
    //     arr = [...arrCR];
    //     break;
    // }
    switch (event.isScheduled) {
      case "isScheduled":
        arrCR.forEach(element => {

          if (!element.extendedProps.isScheduled) {
            arr.push(element);
          }
        })
        break;

      default:
        arr = [...arrCR];
        break;
    }
    if (this.userRole !== 'provider') {
      finalPPList = this.removeDuplicates(this.resourceIDs);
      finalPPList.forEach((obj) => {
        this.holidayList.forEach((objH) => {
          objH['holiday'] = true;
          arr.push({
            tooltip: objH.holidayName,
            constraint: JSON.stringify(objH),
            resourceId: this.userRole === 'provider' ? '' : obj,
            title: objH.holidayName,
            extendedProps: objH,
            start: `${objH.holidayDate}T07:00:00`,
            end: `${objH.holidayDate}T20:00:00`,
            backgroundColor: '#81F170',
            textColor: '#000000',
            className: 'HolidayEventStyle'
          });

        });
      });
    } else {
      this.holidayList.forEach((objH) => {
        objH['holiday'] = true;
        objH['reason'] = objH.holidayName;
        arr.push({
          tooltip: objH.holidayName,
          constraint: JSON.stringify(objH),
          title: objH.holidayName,
          extendedProps: objH,
          start: `${objH.holidayDate}T07:00:00`,
          end: `${objH.holidayDate}T20:00:00`,
          backgroundColor: '#81F170',
          textColor: '#000000',
          className: 'HolidayEventStyle'
        });
      });
    }

    if (this.viewType === 'Week') {
      this.calendarOptions4.events = arr;
      this.calendarOptionsDaygridWeek.events = arr;
    } else if (this.viewType === 'Month') {
      this.calendarOptions5.events = arr;
      this.calendarOptionsDaygridMonth.events = arr;
    } else {
      this.calendarOptions3.events = arr;
      this.calendarOptionsDaygrid.events = arr;
    }
    this.appService.showCustomLoader(false);

    this.selectAllIcon = []
    arr.filter((obj) => {
      if (!obj.className.includes('providerLoginMonthViewUnavltyAprv') && !obj.className.includes('HolidayEventStyle')) {
        const data = this.checkPastDays(obj);
        if (data) {
          this.ListViewTableData.push(data)
        }
      }
    });

    this.sortListView({ sortField: 'shiftDate', sortOrder: 1 });
    this.filterbox = false;
    this.ListViewTableTotal = this.ListViewTableData.length;
    console.log("END=====>>>>", performance.now());
    console.timeEnd('Execution Time END');
    if (this.Tippyinstance) {
      this.Tippyinstance.destroy();
    }
  }

  sortListView(event) {
    this.ListViewTableData.sort((data1, data2) => {
      let value1 = data1?.extendedProps[event.sortField];
      let value2 = data2?.extendedProps[event.sortField];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.sortOrder * result);
    });
  }


  removeDuplicates(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }

  filter() {
    console.log(this.clinicTimeZone);

    this.appService.updateFilterSidebarState({ state: true, clinicStartTime: this.clinicStartTime, timezone: this.clinicTimeZone });
  }

  subscribePopups() {
    this.openTextMsgSubs = this.appService.textpopup.subscribe(res => {
      this.openTextMsg = res;
    });
  }

  toggle() {
    this.show = !this.show
  }

  updateTableData(data) {
    if (data.success) {
      this.searchText = '';
      // this.onProviderChange();
      this.createFilterData(false);
    }
  }
  tConvert(time) {
    time = time.slice(0, 5);// Remove seconds...
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value      
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  closeEventProviderPopup() {
    this.showEventProvider = false;
    this.UpcomingSessionsData = null;
  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  upComingEventTimeConvert(event) {
    return moment(`${event.shiftDate}T${event.time}`).format('hh:mm a')
  }

  showUpComingSessions(event) {

    const startTime = event.startTime.split(':');
    const startTimeMinute = this.pad(Number(startTime[1]) + 1);
    const finalStartTime = startTime[0] + ':' + startTimeMinute;

    let spotType = '';
    if (event.spotType != 'BCBA' && event.spotType != 'OT' && event.spotType != 'SPEECH') {
      spotType = 'INDIVIDUAL_AND_GROUP';
    } else {
      spotType = event.spotType;
    }

    this.appService.showCustomLoader(true);

    const payload = {
      patientId: event.patient.id,
      fromDate: event.shiftDate + ' ' + finalStartTime + ':00',
      solverType: spotType
    }
    this.schedulingService.getUpcomingSessionDetails(payload).subscribe((response: any) => {
      this.appService.showCustomLoader(false);
      if (response.data) {
        this.UpcomingSessionsData = response.data;
      }
    }, (error) => {
      this.appService.showCustomLoader(false);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }
  getDynamicYearRange(): string {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 1;
    const endYear = currentYear + 5;
    return `${startYear}:${endYear}`;
  }
  exportData() {
    console.log(this.filterCheckedData);
    let jobTitle = this.providerFilterByJobId.map((item: any) => {
      return item.label
    })

    if (this.exporting) return;
    this.exporting = true;
    this.appService.showCustomLoader(true);
    const payload = {
      startDate: this.savedPayload.fromDate,
      endDate: this.savedPayload.toDate,
      viewBy: this.viewBy == 'providers' ? 'provider' : 'patient'
    }
    const filterForm = {
      "isSessionConverted": this.filterCheckedData.isScheduled == "isScheduled" ? true : null,
      "isSessionInTeam": this.filterCheckedData.genderChoice == "MALE" ? true : null,
      "jobTitle": jobTitle,
      "sessionStatus": this.filterCheckedData?.sessionStatus?.toUpperCase(),
      "solverType": this.filterCheckedData.solverType == "I&G" ? "INDIVIDUAL_AND_GROUP" : this.filterCheckedData.solverType
    }
    this.schedulingService.exportDocs(payload, filterForm).subscribe((response: any) => {
      this.appService.showCustomLoader(false);
      const file = new Blob([response], { type: 'application/octet-stream;charset=utf-8' });
      saveAs(file, `${this.clinicName}_${this.savedPayload.fromDate}to${this.savedPayload.toDate}_Provider-Patient_Sessions` + '.xlsx');

      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Sessions exported successfully!' });
      this.exporting = false;
    }, (error) => {
      this.appService.showCustomLoader(false);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.' });
      this.exporting = false;
    });
  }
  indicatorBoxToogle() {
    this.isIndicatorVisible = !this.isIndicatorVisible;
  }
 
  isOverlayPersistent: boolean = false;
  
  onMouseEnter(event: MouseEvent, op: OverlayPanel) {
    if (!op) return;
  
    clearTimeout(this.overlayTimeout);
    
    // Show the overlay only if it’s not already persistent
    if (!this.isOverlayPersistent) {
      op.show(event);
    }
  }
  
  onMouseLeave(op: OverlayPanel) {
    if (!op) return;
  
    // Close the overlay only if it's not persistent
    this.overlayTimeout = setTimeout(() => {
      if (!this.isOverlayPersistent) {
        op.hide();
      }
    }, 700);
  }
  
  toggleOverlay(event: MouseEvent, op: OverlayPanel) {
    if (!op) return;
  
    this.isOverlayPersistent = !this.isOverlayPersistent;
  
    if (this.isOverlayPersistent) {
      op.show(event); // ✅ Pass the event to avoid TypeScript error
    } else {
      op.hide();
    }
  }
  
  // getFormattedCount(data: string) {
  //   if (!data) return { planned: 0, unplanned: 0 };
  
  //   let parts = data.match(/\d+/g); // Extract numbers dynamically
  //   return {
  //     planned: parts ? parts[0] : 0, // First number (Planned)
  //     unplanned: parts ? parts[1] : 0 // Second number (Unplanned)
  //   };
  // }

  getFormattedCount(data: string) {
    if (!data) return { planned: 0, unplanned: 0 };
  
    let parts = data.match(/\d+/g); // Extract numbers dynamically
    return {
      planned: parts ? Number(parts[0]) : 0, // Convert to number
      unplanned: parts ? Number(parts[1]) : 0 // Convert to number
    };
  }
  
  
  
}