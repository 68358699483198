import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { config } from '../config';


@Injectable({
  providedIn: 'root'
})
export class ContractsService {

constructor(public sharedService: SharedService) { }

updateContract(formData) {
  return this.sharedService.putData(config.addContract, formData);
}

addContract(formData) {
  return this.sharedService.postData(config.addContract, formData);
}

getContract(data) {
  let url = `${config.getContractTable}`;
  url = url.replace(":page", data.page).replace(":limit", data.limit);
  if(data.searchParam){
    url = `${url}&searchParam=${encodeURIComponent(data.searchParam)}`;
  }
  return this.sharedService.getData(url);
}
getContractList(data) {
  let url = `${config.getContractListTable}`;
  url = `${url}${data}/contractsByClinics`;
  return this.sharedService.getData(url);
}
deleteContract(id) {
  return this.sharedService.deleteData(`${config.deleteContract}${id}`);
}


}
