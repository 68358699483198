export const PatientColumns = [
  {label: 'Patient Name', value: 'firstName' },
  {label: 'Email', value: 'email' },
  {label: 'Phone', value: 'phone' },
  {label: 'Skills', value: 'skillsName' },
  {label: 'Address', value: 'address' },
  {label: 'Groups', value: 'groups' },
  {label: 'Assigned Provider', value: 'patientTeam' },
  // {label: 'Authorized Units', value: 'authorizedUnits' },
  // {label: 'Total Units', value: 'totalUnits' },
  // {label: 'Status', value: 'status' },
  // {label: 'Action', value: 'actions' }
];
export const HistoryColumns = [
  { field: 'serial_no', header: 'S.No.' },
  { field: 'supervisorName', header: 'Supervisor Name' },
  { field: 'assignedDate', header: 'Assigned Date' },
  { field: 'unassignedDate', header: 'Unassigned Date' },
];
export class PatientModel {
  
        aggerssiveness: number
        city: string
        clinicIds: Array<any>
        country: string
        email: string
        firstName: string
        gender: string
        groupsIds: Array<any>
        lastName: string
        phone: any
        // profilePic: string
        id: any;
        documentId	:string
        patientLocation:Object
        state: string
        street: string
        zipCode: string
        providerChoice:string
        patientId:string
        patientSkillForms = []
    constructor(obj?) {
      this.patientSkillForms = [];
      this.aggerssiveness = obj ? obj.aggerssiveness : '';
      this.city = obj ? obj.city:''; 
      this.clinicIds = obj ? obj.clinicIds:[]; 
      this.groupsIds = obj ? obj.groupsIds:[]; 
      this.country = obj ? obj.country : '';
      this.patientLocation = obj ? obj.patientLocation : '';
      this.email = obj ? obj.email : '';
      this.firstName = obj ? obj.firstName : '';
      this.gender = obj ? obj.gender : '';
      this.groupsIds = obj ? obj.groupsIds : '';
      this.lastName = obj ? obj.lastName : '';
      this.phone = obj ? obj.phone : '';
      this.documentId = obj ? obj.documentId : '';
      // this.profilePic = obj ? obj.profilePic : '';
      this.state = obj ? obj.state : '';
      this.street = obj ? obj.street : '';
      this.zipCode = obj ? obj.zipCode : '';
      
    }
  }