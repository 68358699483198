<div (click)="specialSessionTypeSelected=false;profileSection = false; overlay = false;teamSection = false;addScroll()"
  [ngClass]="overlay?'overlay-block':'overlays'" [@moreFilterSectionEmp]="overlay"></div>
<div class="personal-inner-shift property-details filter-detail employee-details addSessionInTab"
  [@moreFilterSectionEmp]="profileSection">
  <form *ngIf="profileSection" #pageContainingForm="ngForm" >
    <div>
      <div class="top-header">
        <span class="header-text headerTxtcolor">
          {{edit ? editOption : addOption }}
          <!-- Add Session -->

        </span>
        <span pTooltip="Close" tooltipPosition="top" class="close-sidebar pointer"
      (click)="specialSessionTypeSelected=false;profileSection = false; overlay = false;displayBasic=false;PopupOPenedOnce = false; showIsPinned = schedulingUser.isPinned">
          <img src="../../assets/images/close.svg" alt="close" />
        </span>
      </div>
      <div class="row personal-wrapper filter-wrappers-providers emp-filter-wrapper">
        <div class="col-md-12 form-group user-field selected-contact-data">
          <ion-label class="label required"> {{pageStrings.lblSpots}} <span class="blue-circle myheaderS" [pTooltip]="'Enter session type for a session'"
            style="position: relative;
            top: -1px;"
            tooltipPosition="top">
            <img src="../../assets/images/listing-info.svg" alt="info"></span>
          </ion-label>
          <div [ngClass]="(spotId.errors && ( spotId.touched || pageContainingForm.submitted)) ? 'errorBorder' : ''"
            class="search_ico filter-ico dropDwnDgn">
            <p-dropdown #Sessiondropdown (onChange)="setLimits($event);" required="true" #spotId="ngModel" name="spotId"
              [(ngModel)]="schedulingUser.spotId" styleClass="hederDrop" appendTo="body" class="dropdown-custom"
              filter="true" placeholder="--Select--" [options]="listSpots">
              <ng-template let-item pTemplate="selectedItem">
                <span style="vertical-align:middle;margin-right: 10px;">{{item.label}}</span>
                <span style="color: red;" *ngIf="item.label == 'Behavioral Supervision'|| item.label =='Occupation Individual Therapy' || item.label =='Speech Individual Therapy'">
                  <i class="fa fa-solid fa-flag"></i>
                </span>
                <span class="ml-1 icon-color" *ngIf="item.isOutsideClinicHours === true">
                  <i class="fa fa-solid fa-clock"></i>
                </span>
              </ng-template>
              <ng-template let-sessionType pTemplate="item">
                <div class="ui-helper-clearfix" style="position: relative;">
                  <div style="font-size:13px;display: inline-block;margin-right: 10px;">{{sessionType.label}}</div> 
                  <span style="color: red;" *ngIf="sessionType.label == 'Behavioral Supervision'|| sessionType.label =='Occupation Individual Therapy' || sessionType.label =='Speech Individual Therapy'">
                    <i class="fa fa-solid fa-flag"></i>
                  </span>
                  <span class="ml-1 icon-color" *ngIf="sessionType.isOutsideClinicHours === true">
                    <i class="fa fa-solid fa-clock"></i>
                  </span>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="vaiidate-field" *ngIf="spotId.errors && ( spotId.touched || pageContainingForm.submitted)">
            <p [hidden]="!spotId.errors.required">Please enter {{pageStrings.lblSpots}}</p>
          </div>
        </div>
        <div class="col-md-12 form-group user-field selected-contact-data">
          <ion-label class="label" [ngClass]="(!specialSessionTypeSelected) ? 'required' : ''"> {{pageStrings.lblPatientIDNo}} <span class="blue-circle myheaderS" [pTooltip]="'Enter patient\'s name'"
            style="position: relative;
            top: -1px;"
            tooltipPosition="top">
            <img src="../../assets/images/listing-info.svg" alt="info"></span>
          </ion-label>
          <div
            [ngClass]="(patientId.errors && ( patientId.touched || pageContainingForm.submitted)) ? 'errorBorder' : ''"
            class="search_ico filter-ico dropDwnDgn">
            <p-dropdown #Patientdropdown [required]="!specialSessionTypeSelected" #patientId="ngModel" name="patientId" [(ngModel)]="schedulingUser.patientId"
              styleClass="hederDrop patDrpDwnWd" panelStyleClass="patientAddUpdateSchedl" appendTo="body" class="dropdown-custom" filter="true" placeholder="Select"
              [options]="listPatient" (onChange)="onPatientChange()" [disabled]="isPatientModuleCall"
              [ngClass]="{'disableDropdown': isPatientModuleCall === true}">
            </p-dropdown>
            
          </div>
          <div class="vaiidate-field" *ngIf="patientId.errors && ( patientId.touched || pageContainingForm.submitted)">
            <p [hidden]="!patientId.errors.required">Please enter {{pageStrings.lblPatientIDNo}}</p>
          </div>
        </div>
        <div class="col-md-12 form-group user-field selected-contact-data">
          <ion-label class="label" [ngClass]="(specialSessionTypeSelected || prvdrPtntModuleAddSession) ? 'required' : ''">{{specialSessionTypeSelected ? '' : 'Assigned'}} Provider  <span class="blue-circle myheaderS" [pTooltip]="'Select provider\'s name'"
            style="position: relative;
            top: -1px;"
            tooltipPosition="top">
            <img src="../../assets/images/listing-info.svg" alt="info"></span>
          </ion-label>
          <div [ngClass]="(providerId.errors && ( providerId.touched || pageContainingForm.submitted)) ? 'errorBorder' : ''"
           class="search_ico filter-ico dropDwnDgn">
            <p-dropdown #Providerdropdown [required]="specialSessionTypeSelected || prvdrPtntModuleAddSession" #providerId="ngModel" name="providerId" (onChange)="warn()" [(ngModel)]="schedulingUser.providerId" styleClass="hederDrop patDrpDwnWd" appendTo="body"
              class="dropdown-custom" filter="true" placeholder="Select" [options]="listProvider" [disabled]="isProviderModuleCall"
              [ngClass]="{'disableDropdown': isProviderModuleCall === true}">
            </p-dropdown>
          </div>
          <div *ngIf="providerNameUnavlable" class="vaiidate-field">
            <p>Provider is unavailable between selected time duration</p>
          </div>
          <ng-container *ngIf="specialSessionTypeSelected || prvdrPtntModuleAddSession">
            <div class="vaiidate-field" *ngIf="providerId.errors && ( providerId.touched || pageContainingForm.submitted)">
              <p [hidden]="!providerId.errors.required">Please assigned a Provider</p>
            </div>
          </ng-container>
        </div>
        <div class="col-md-12 form-group user-field selected-contact-data DateChooosePatient1">
          <div class="startpatientDate1">
            <ion-label class="label required"> 
              <!-- {{pageStrings.lblShiftDate}} -->
              Sesssion Date
               <span class="blue-circle myheaderS" [pTooltip]="'Select date for a session'"
              style="position: relative;
              top: -1px;"
              tooltipPosition="top">
              <img src="../../assets/images/listing-info.svg" alt="info"></span>
            </ion-label>
            <div
            class="sched_datetime"
              [ngClass]="(shiftDate.errors && ( shiftDate.touched || pageContainingForm.submitted)) ? 'errorBordercl' : ''">
              <p-calendar #Calendardropdown  required="true" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="getDynamicYearRange()" #shiftDate="ngModel" (onSelect)="warn();" (onInput)="warn();" name="shiftDate" [(ngModel)]="schedulingUser.shiftDate"
                appendTo="body" [showIcon]="true" inputId="icon">
              </p-calendar>
            </div>
            <div class="vaiidate-field"
              *ngIf="shiftDate.errors && ( shiftDate.touched || pageContainingForm.submitted)">
              <p [hidden]="!shiftDate.errors.required">Please enter {{pageStrings.lblShiftDate}}</p>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-12 form-group user-field selected-contact-data DateChooosePatient">
          <input [ngxTimepicker]="startTimepicker" [value]="schedulingUser.startTime" name="startTime" [(ngModel)]="schedulingUser.startTime">
          <ngx-material-timepicker #startTimepicker></ngx-material-timepicker>
        </div> -->
        <!-- <div class="col-md-12 form-group user-field selected-contact-data DateChooosePatient">
          <input [ngxTimepicker]="endTimepicker" [value]="schedulingUser.endTime" name="endTime" [(ngModel)]="schedulingUser.endTime">
          <ngx-material-timepicker #endTimepicker></ngx-material-timepicker>
        </div> -->
        
        <div class="col-md-12 form-group user-field selected-contact-data DateChooosePatient">
          <div class="startpatientTime">
            <ion-label class="label required"> 
              <!-- {{pageStrings.lblShiftStartTime}}  -->
              Session Start Time
              <span class="blue-circle myheaderS" [pTooltip]="'Enter start time for a session'"
              style="position: relative;
              top: -1px;"
              tooltipPosition="top">
              <img src="../../assets/images/listing-info.svg" alt="info"></span>
            </ion-label>
            <div
              >
              <!-- <p-calendar (ngModelChange)="changeTime();" #startTime="ngModel" (onSelect)="warn()" (onInput)="warn();" required="true" name="startTime"
                [(ngModel)]="schedulingUser.startTime" [timeOnly]="true" [hourFormat]="12"></p-calendar> -->
                <!-- <p-dropdown [(ngModel)]="startTimedrpdwnHrs" (onChange)="warn()" name="startTimeHrs" styleClass="timedrpdwnHrs" appendTo="body" class="dropdown-custom"
                    [options]="startTimeHrs">
                </p-dropdown>
                <p-dropdown [(ngModel)]="startTimedrpdwnMnts" (onChange)="warn()" name="startTimeMnts" styleClass="timedrpdwnMnts" appendTo="body" class="dropdown-custom"
                    [options]="startTimeMnts">
                </p-dropdown>
                <p-dropdown [(ngModel)]="startTimedrpdwnAMPM" (onChange)="warn()" name="startTimeAMPM" styleClass="timedrpdwnAMPM" appendTo="body" class="dropdown-custom"
                    [options]="startTimeAMPM">
                </p-dropdown> -->
              </div>
              <input  class="pickerInp" [ngxTimepicker]="startTimepicker" (click)="onTimePickerClick()" [value]="schedulingUser.startTime" name="startTime" [(ngModel)]="schedulingUser.startTime" readonly>
                <ngx-material-timepicker #startTimepicker (timeSet)="warn()" [enableKeyboardInput]="true" [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn"></ngx-material-timepicker>
                <ng-template #cancelBtn>
                  <button class="dialog-cancel-btn" style="margin-right: 10px;">Cancel</button>
                  </ng-template>
                  <ng-template #confirmBtn>
                  <button class="dialog-save-btn">Ok</button>
                  </ng-template>
                <!-- <div class="vaiidate-field"
              *ngIf="startTime.errors && ( startTime.touched || pageContainingForm.submitted)">
              <p [hidden]="!startTime.errors.required">Please enter {{pageStrings.lblShiftStartTime}}</p>
            </div> -->

          </div>
          <div class="startpatientTime">
            <ion-label class="label required"> 
              <!-- {{pageStrings.lblShiftEndTime}} -->
              Session End Time
               <span class="blue-circle myheaderS" [pTooltip]="'Enter end time for a session'"
              style="position: relative;
              top: -1px;"
              tooltipPosition="left">
              <img src="../../assets/images/listing-info.svg" alt="info"></span>
            </ion-label>
            <div>
              <!-- <p-calendar #endTime="ngModel" (onSelect)="warn();" (onInput)="warn();" required="true" name="endTime" [(ngModel)]="schedulingUser.endTime"
                [timeOnly]="true" [hourFormat]="12"></p-calendar> -->
                <!-- <p-dropdown [(ngModel)]="endTimedrpdwnHrs" (onChange)="warn()" name="endTimeHrs" styleClass="timedrpdwnHrs" appendTo="body" class="dropdown-custom"
                    [options]="startTimeHrs">
                </p-dropdown>
                <p-dropdown [(ngModel)]="endTimedrpdwnMnts" (onChange)="warn()" name="endTimeMnts" styleClass="timedrpdwnMnts" appendTo="body" class="dropdown-custom"
                    [options]="startTimeMnts">
                </p-dropdown>
                <p-dropdown [(ngModel)]="endTimedrpdwnAMPM" (onChange)="warn()" name="endTimeAMPM" styleClass="timedrpdwnAMPM" appendTo="body" class="dropdown-custom"
                    [options]="startTimeAMPM">
                </p-dropdown> -->
                <!-- <div class="col-md-12 form-group user-field selected-contact-data DateChooosePatient">
                  <input [ngxTimepicker]="endTimepicker" [value]="schedulingUser.endTime" name="endTime" [(ngModel)]="schedulingUser.endTime">
                  <ngx-material-timepicker #endTimepicker></ngx-material-timepicker>
                </div> -->
                <input class="pickerInp" [ngxTimepicker]="endTimepicker" (click)="onTimePickerClick()" [value]="schedulingUser.endTime" name="endTime" [(ngModel)]="schedulingUser.endTime" readonly>
                  <ngx-material-timepicker #endTimepicker (timeSet)="warn()" [enableKeyboardInput]="true" [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn"></ngx-material-timepicker>
                  <ng-template #cancelBtn>
                    <button class="dialog-cancel-btn" style="margin-right: 10px;">Cancel</button>
                    </ng-template>
                    <ng-template #confirmBtn>
                    <button class="dialog-save-btn">Ok</button>
                    </ng-template>
            </div>
            <!-- <div class="vaiidate-field" *ngIf="endTime.errors && ( endTime.touched || pageContainingForm.submitted)">
              <p [hidden]="!endTime.errors.required">Please enter {{pageStrings.lblShiftEndTime}}</p>
            </div> -->
          </div>
        </div>

        <div class="col-md-12 form-group user-field selected-contact-data">
          <ion-label class="label required"> Place Of Service <span class="blue-circle myheaderS" [pTooltip]="'Select place of service '"
            style="position: relative;
            top: -1px;"
            tooltipPosition="top">
            <img src="../../assets/images/listing-info.svg" alt="info"></span>
          </ion-label>
          <div [ngClass]="(placeOfServiceId.errors && ( placeOfServiceId.touched || pageContainingForm.submitted)) ? 'errorBorder' : ''"
            class="search_ico filter-ico dropDwnDgn">
            <p-dropdown #Servicedropdown  required="true" #placeOfServiceId="ngModel" name="placeOfServiceId"
              [(ngModel)]="schedulingUser.placeOfServiceId" styleClass="hederDrop" appendTo="body" class="dropdown-custom"
              filter="true" placeholder="Select" [options]="ListPlaceOfService">
            </p-dropdown>
          </div>
          <div class="vaiidate-field" *ngIf="placeOfServiceId.errors && ( placeOfServiceId.touched || pageContainingForm.submitted)">
            <p [hidden]="!placeOfServiceId.errors.required">Please enter place of service</p>
          </div>
        </div>


        <ng-container *ngIf="edit">
          <div *ngIf="this.schedulingUser.recurrence !== 'doNotRepeat'" class="col-md-12 form-group user-field selected-contact-data" style="display: none;">
            <ion-label class="label">Type<span class="blue-circle myheaderS" [pTooltip]="''"
              style="position: relative;top: -1px;"tooltipPosition="top">
              <img src="../../assets/images/listing-info.svg" alt="info"></span>
            </ion-label>
            <div class="search_ico filter-ico dropDwnDgn">
              <p-dropdown name="type" [(ngModel)]="schedulingUser.type" styleClass="hederDrop" appendTo="body"
                class="dropdown-custom" filter="false" placeholder="Select" [options]="typeList">
              </p-dropdown>
            </div>
          </div>
        </ng-container>
        <div class="col-md-12 form-group user-field selected-contact-data">
          <ion-label class="label required">{{pageStrings.lblRecurrenceInterval}} <span class="blue-circle myheaderS" [pTooltip]="'Select occurence of a session'"
            style="position: relative;
            top: -1px;"
            tooltipPosition="top">
            <img src="../../assets/images/listing-info.svg" alt="info"></span>
          </ion-label>
          <div class="search_ico filter-ico dropDwnDgn">
            <p-dropdown #Typedropdown name="recurrence" (onChange)="reccurnceIsPinned()" styleClass="hederDrop" panelStyleClass="SCHDL-customReccurencePanelClass"
              [(ngModel)]="schedulingUser.recurrence" appendTo="body" class="dropdown-custom" filter="true"
              placeholder="Select" [options]="ReapetsTypeSet1">

              <ng-template pTemplate="selectedItem" let-options="options">
                <div class="country-item country-item-value" *ngIf="schedulingUser.recurrence">
                  <div>{{schedulingUser.recurrence == 'customRecurrence' ? 'Custom Recurrence' : 'Do Not Repeat'}}</div>
                </div>
                </ng-template>
                <ng-template let-dpData pTemplate="item">
                <div class="country-item SCHDL-drpdwn-value-pad" (click)="change(dpData)">
                  <div >{{dpData.label}}</div>
                </div>
              </ng-template>
              
            </p-dropdown>
          </div>
          <!-- <p *ngIf="reccIsPinnedPopup"><span><i class="fa fa-exclamation-triangle" aria-hidden="true" style="color: red;"></i></span>All the upcoming session will be isPinned</p> -->
        </div>
        <!-- <div class="col-md-12 form-group user-field selected-contact-data">
          <ion-label class="label"> {{pageStrings.lblIsPinned}} <span class="blue-circle myheaderS" [pTooltip]="'Select is pinned option if you want to get a selected provider.'"
            style="position: relative;
            top: -1px;"
            tooltipPosition="top">
            <img src="../../assets/images/listing-info.svg" alt="info"></span>
          </ion-label>
          <p-inputSwitch name="isPinned" [(ngModel)]="showIsPinned" class="mt-1" ></p-inputSwitch>
        </div> -->
<!-- -------------------------IS PINNED -------------------->
<div class="col-md-12 mt-0 skillform"  *ngIf="schedulingUser.patientId && schedulingUser.providerId">
  <div>
  <span>
    <ion-label class=" labelSkillform" style="color: #000000;">Is Pinned <span class="blue-circle myheaderS" 
      [pTooltip]="'Click on Yes button if you want to pinned'"
      style="position: relative;
      top: -1px;"
      tooltipPosition="top">
      <!-- <img src="../../assets/images/listing-info.svg" alt="info"> -->
    </span>
    </ion-label>
  </span>
  <span><p style="font-size:13px;color: #656565;">Select is pinned option if you want to get a selected provider.</p></span>
  </div>
  <div class="search_ico filter-ico top-margin" style="margin-left: 24%;margin-top: -5px;">
    <!-- <div *ngIf="!showIsPinned" class="addnew" style="width: fit-content; margin-left: 10px; height: 40px; border: 1px solid transparent; display: flex; flex-direction: row; align-items: center;
   border-radius: 5px; background-color:  transparent; outline: none; text-align: start; font-size: small;">
      <button  type="button" class="toggleBtn" name="isPinned" (click)="isPinnedYesNoClick(true)">Yes</button>
    </div>
    <div *ngIf="showIsPinned" class="addnew" style="width: fit-content; margin-left: 10px; height: 40px; border: 1px solid transparent; display: flex; flex-direction: row; align-items: center;
   border-radius: 5px; background-color:  transparent; outline: none; text-align: start; font-size: small;">
      <button type="button" class="toggleNoBtn" name="isPinned" (click)="isPinnedYesNoClick(false)">No</button>
    </div> -->
    <p-inputSwitch name="isPinned" (onChange)="reccurnceIsPinned()" [(ngModel)]="showIsPinned" class="mt-1" ></p-inputSwitch>
  </div>
</div>


<div class="col-md-12 mt-3 skillform" *ngIf="reccIsPinnedPopup">
  <div>
    <span>
      <ion-label class=" labelSkillform" style="color: #000000;">Is Pinned Type<span class="blue-circle myheaderS" 
        [pTooltip]="'Click on Yes button if you want to pinned'"
        style="position: relative;
        top: -1px;"
        tooltipPosition="top">
      </span>
      </ion-label>
    </span>
    <span><p style="font-size:13px;color: #656565;">Choose 'Yes' for pinning all future sessions, or select 'No' to pin only the current session.</p></span>
    </div>

    <!-- Choose 'Yes' for pinning all future sessions, or select 'No' to pin only the current session."  -->
    <div style="margin-left: 10%;margin-top: -5px;display: flex;align-items: center;justify-content: space-between;" class="radio-btn-wrapper">
      <label for="All" style="margin: 0px;font-size: 14px;color: black;">Yes</label>
        <p-radioButton name="check_isPinnedType" value="true" [(ngModel)]="allRecurrencePinned"></p-radioButton>
      <label for="Current" style="margin: 0px;font-size: 14px;color: black;">No</label>
        <p-radioButton name="check_isPinnedType" value="false" [(ngModel)]="allRecurrencePinned"></p-radioButton> 
    </div>
</div>
<!--------------------------IS PINNED  -------------->

        <!-- <div class="col-md-12 mt-0 skillform"
          style="display: flex; flex-direction: row;  align-items: center;padding-bottom: 5px;">
          <ion-label class=" labelSkillform">Additional Skills <span class="blue-circle myheaderS" [pTooltip]="''"
            style="position: relative;
            top: -1px;"
            tooltipPosition="top">
            <img src="../../assets/images/listing-info.svg" alt="info"></span>
          </ion-label>
          <div class="search_ico filter-ico top-margin">
            <div class="addnew" style="width: fit-content; height: 40px; border: 1px solid transparent; display: flex; flex-direction: row; align-items: center;
         border-radius: 5px; background-color:  transparent; outline: none; text-align: start; font-size: small;">
              <button type="button" class="addSkillbtn" (click)="showBasicDialog()"> Add Skill </button>
            </div>
          </div>
        </div> -->
        <!-- Additional skills  -->
        <div *ngIf="!specialSessionTypeSelected" class="col-md-12 mt-0 skillform" style="margin-top: 15px !important;">
          <div>
          <span>
            <ion-label class=" labelSkillform" style="color: #000000;">Additional Skills <span class="blue-circle myheaderS" [pTooltip]="'Click on add skills,select skills and skill type'"
              style="position: relative;
              top: -1px;"
              tooltipPosition="top">
              <!-- <img src="../../assets/images/listing-info.svg" alt="info"> -->
            </span>
            </ion-label>
          </span>
          <span><p style="font-size:13px;color: #656565;">Click on add skills,select skills and skill type</p></span>
          </div>
          <div class="search_ico filter-ico top-margin" style="margin-left: 16%;margin-top: -5px;">
            <div class="addnew" style="width: fit-content; margin-left: 10px; height: 40px; border: 1px solid transparent; display: flex; flex-direction: row; align-items: center;
           border-radius: 5px; background-color:  transparent; outline: none; text-align: start; font-size: small;">
              <button type="button" (click)="showBasicDialog()">
                  <ng-container *ngIf="schedulingUser.sessionSkillForm.length > 0 else elseAddSkills">
                  Add More
                </ng-container>
                <ng-template #elseAddSkills>
                  Add Skill
                </ng-template>  
              </button>
            </div>
          </div>
          
        </div>
        <!-- Additional skils -->
        <div *ngFor="let item of schedulingUser.sessionSkillForm;let i = index"
          style="display: flex; flex-direction: row; width: 100%;">
          <div class="col-md-8 form-group user-field selected-contact-data mt-0">
            <ion-label class="label ">Skills <span class="blue-circle myheaderS" [pTooltip]="''"
              style="position: relative;
              top: -1px;"
              tooltipPosition="top">
              <img src="../../assets/images/listing-info.svg" alt="info"></span>
            </ion-label>
            <div class="search_ico filter-ico top-margin dropDwnDgn">
              <p-dropdown name="skillId{{i}}" [(ngModel)]="item.skillId" styleClass="hederDrop skillscDrp"
                appendTo="body" class="dropdown-custom" filter="true" placeholder="Select" [options]="listSkills">
                <ng-template let-item pTemplate="selectedItem">
                  <span >{{item.label}}</span>
                  <span style="color: red;margin-left: 3px;" *ngIf="item.label == 'Behavioral Supervision'|| item.label =='Occupation Individual Therapy' || item.label =='Speech Individual Therapy'">
                    <i class="fa fa-solid fa-flag"></i>
                  </span>
                </ng-template>
                <ng-template let-sessionType pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;">
                    <div style="font-size:13px;display: inline-block;margin-left: 10px;">{{sessionType.label}}</div> 
                    <span style="color: red;" *ngIf="sessionType.label == 'Behavioral Supervision'|| sessionType.label =='Occupation Individual Therapy' || sessionType.label =='Speech Individual Therapy'">
                      <i class="fa fa-solid fa-flag"></i>
                    </span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>

          <div class=" form-group user-field selected-contact-data mt-0">
            <ion-label class="label ">Skill Type <span class="blue-circle myheaderS" [pTooltip]="''"
              style="position: relative;
              top: -1px;"
              tooltipPosition="top">
              <img src="../../assets/images/listing-info.svg" alt="info"></span>
            </ion-label>
            <div class="search_ico filter-ico top-margin dropDwnDgn">
              <p-dropdown [disabled]="true" name="skillTypeId{{i}}" [(ngModel)]="item.skillTypeId"
                styleClass="hederDrop" appendTo="body" class="dropdown-custom" filter="true" placeholder="Select"
                [options]=" listSkilltype ">
              </p-dropdown>
              <img style="margin-left: 10px;margin-right: 5px;cursor: pointer;" tooltipPosition="top" pTooltip="Delete" (click)="onRemoveSkill(i)" class="edit-icons"
                src="../../assets/images/delete.svg" alt="">
            </div>
          </div>
        </div>

      </div>
      <div class="save-reset">
        <div class="more-filterbtns marginbtn">
          <ion-button (click)="pageContainingForm.valid && createScheduling('noskip')" [disabled]="isButtonDisabled || (edit ? !pageContainingForm.dirty : false)"
          class="btn-save save-btn btn-save-head btnAl"
            type="submit">
            {{edit ? pageStrings.lblUpdate : pageStrings.lblAdd}}
          </ion-button>
        </div>
        <!-- <div >
          <button  type="submit" class="dialog-save-btn" (click)="pageContainingForm.valid && createScheduling('noskip')"  [disabled]="edit ? !pageContainingForm.dirty : false">{{edit ? pageStrings.lblUpdate : pageStrings.lblAdd}}</button>
        </div> -->
      </div>
    </div>
  </form>
</div>
<app-custom></app-custom>
<!-- popup -->
<!-- <div class="row personal-wrapper filter-wrappers-providers emp-filter-wrapper">

  <p-dialog styleClass="mtSolver" class="skillsform add-user custom-popup common-popup-css"
    header="Additional Skill Form" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <div class="dropdownskills">
      <div style="   
          margin-left: -9px;
          padding-left: 20px;
          margin-bottom: 0px;
          padding-right: 0px;
          padding-top: 10px;" 
          class="col-md-12 form-group user-field selected-contact-data mt-0">
        <ion-button type="button" class="btn-main setWidth" (click)="onAddSkills();">+ Add</ion-button>
      </div>
      <div class="row">
        <div style="margin-bottom: 0px;
    padding-left: 30px;
        " class="col-md-6 form-group user-field selected-contact-data mt-0">
          <ion-label class="label ">Skills <span class="blue-circle myheaderS" [pTooltip]="''"
            style="position: relative;
            top: -1px;"
            tooltipPosition="top">
            <img src="../../assets/images/listing-info.svg" alt="info"></span>
          </ion-label>
        </div>
        <div style="margin-bottom: 0px;
    padding-left: 20px;
        " class="col-md-6 form-group user-field selected-contact-data mt-0">
          <ion-label class="label ">Skill Type <span class="blue-circle myheaderS" [pTooltip]="''"
            style="position: relative;
            top: -1px;"
            tooltipPosition="top">
            <img src="../../assets/images/listing-info.svg" alt="info"></span>
          </ion-label>
        </div>

      </div>
      <div style="    max-height: calc(100vh - 262px);
      overflow-y: auto;
      overflow-x: hidden;">
        <div class="row" *ngFor="let item of addSkillsList">
          <div style="    margin-bottom: 3px;
    
    padding-left: 30px;

          " class="col-md-6 form-group user-field selected-contact-data mt-0">
            <div class="search_ico filter-ico top-margin">
              <p-dropdown [(ngModel)]="item.skillId" styleClass="hederDrop" appendTo="body" class="dropdown-custom"
                filter="true" placeholder="Select" [options]="listSkills">
              </p-dropdown>
            </div>
          </div>

          <div style="margin-bottom: 3px;
padding-left: 20px;
    padding-right: 30px;
          " class="col-md-6 form-group user-field selected-contact-data mt-0">
            <div class="search_ico filter-ico top-margin">
              <p-dropdown [(ngModel)]="item.skillTypeId" styleClass="hederDrop" appendTo="body" class="dropdown-custom"
                filter="true" placeholder="Select" [options]="listSkilltype">
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="setWidth setCenter">
      <ion-button [disabled]="
        (
          (!addSkillsList.length)
        )
        " type="button" class="btn-main setWidth" (click)="onSaveClick();">Save</ion-button>
      <ion-button [disabled]="
        (
          (!addSkillsList.length)
        )
        " type="button" class="btn-main setWidth" (click)="onSaveCloseClick();">Save & Close</ion-button>
      <ion-button type="button" class="btn-default setWidth" (click)="displayBasic=false">Cancel</ion-button>
    </div>
  </p-dialog>
</div> -->










<div style="background-color: #EEE;" class="row personal-wrapper filter-wrappers-providers emp-filter-wrapper">

  <!-- <p-dialog styleClass="mtSolver" class="skillsform add-user custom-popup common-popup-css"
    [(visible)]="displayBasic" [style]="{width: '50vw'}"  position="center" [draggable]="false" [modal]="true">
    <p-header>
      <span style="color: black;">
        Additional Skill Form
      </span>
      <span class="close-pop" (click)="displayBasic = false;" pTooltip="Close" tooltipPosition="top">
        <img src="../../assets/images//close.png" alt="close" />
      </span>
    </p-header>
    <div class="dropdownskills">
    
      <div class="row">
        <div style="margin-bottom: 0px;padding-left: 30px;" class="col-md-6 form-group user-field selected-contact-data skillType-label-div mt-0">
          <ion-label class="label ">Skills <span class="blue-circle myheaderS" 
            [pTooltip]="'Enter your skills'"
            style="position: relative;
            top: -1px;"
            tooltipPosition="top">
            <img src="../../assets/images/listing-info.svg" alt="info"></span>
          </ion-label>
        </div>

        <div style="margin-bottom: 0px;
        padding-left: 20px;
            " class="col-md-6 form-group user-field selected-contact-data skillType-label-div mt-0">
              <ion-label class="label ">Skill Type <span class="blue-circle myheaderS" [pTooltip]="''"
                style="position: relative;
                top: -1px;"
                tooltipPosition="top">
                <img src="../../assets/images/listing-info.svg" alt="info"></span>
              </ion-label>
            </div>     
      </div>
      <div style="max-height: calc(100vh - 262px);overflow-y: auto;overflow-x: hidden;">
        <div class="row" *ngFor="let item of addSkillsList; let i = index">
          <div style="margin-bottom: 3px;padding-left: 30px;" class="col-md-6 form-group user-field selected-contact-data skillType-drdwn-div mt-0">
            <div class="search_ico filter-ico top-margin">
              <p-dropdown [(ngModel)]="item.skillId" (ngModelChange) = "addNew(i)" styleClass="hederDrop" appendTo="body" class="dropdown-custom"
                filter="true" placeholder="Select" [options]="listSkills">
                <ng-template let-item pTemplate="selectedItem">
                  <span >{{item.label}}</span>
                  <span style="color: red;margin-left: 3px;" *ngIf="item.label == 'Behavioral Supervision'|| item.label =='Occupation Individual Therapy' || item.label =='Speech Individual Therapy'">
                    <i class="fa fa-solid fa-flag"></i>
                  </span>
                </ng-template>
                <ng-template let-sessionType pTemplate="item">
                  <div class="ui-helper-clearfix" style="position: relative;">
                    <div style="font-size:13px;display: inline-block;margin-left: 10px;">{{sessionType.label}}</div> 
                    <span style="color: red;margin-left: 3px" *ngIf="sessionType.label == 'Behavioral Supervision'|| sessionType.label =='Occupation Individual Therapy' || sessionType.label =='Speech Individual Therapy'">
                      <i class="fa fa-solid fa-flag"></i>
                    </span>
                  </div>
                </ng-template>
              </p-dropdown>
              
            </div>

          </div>

          <div style="margin-bottom: 3px;padding-left: 20px;padding-right: 30px;" class="col-md-6 form-group user-field selected-contact-data skillType-drdwn-div mt-0">
            <div class="search_ico filter-ico top-margin">
              <p-dropdown [(ngModel)]="item.skillTypeId" [disabled]="true" styleClass="hederDrop" appendTo="body" class="dropdown-custom" optionValue="value" [options]="listSkilltype">
                </p-dropdown>

                <img *ngIf="i!=0" tooltipPosition="top" pTooltip="Delete" style="margin-left: 15px; cursor: pointer;"
                  (click)="onRemoveSkillAdd(i)" class="edit-icons" src="../../assets/images/delete.svg" alt="">
                  <div *ngIf="i==0" style="width: 10px;margin-left: 15px;"></div>
            </div>
          </div>

        </div>
      </div>


    </div>

    <div class="setWidth setCenter">
      <button class="dialog-cancel-btn" (click)="displayBasic=false">Cancel</button>
      <button [disabled]="((!addSkillsList.length))" class="dialog-save-btn" (click)="onSaveClick();">Save</button>
      <button [disabled]="((!addSkillsList.length))" class="dialog-saveAndCancel-btn" (click)="onSaveCloseClick();">Save & Close</button>
    </div>
  </p-dialog> -->
  <!------------------------- SIDEBAR S-01 ------------------------------>
  <p-sidebar [showCloseIcon]="false" position="right" appendTo="body" styleClass="assignProviderSideBarr" class="AddpatPopup" 
  [(visible)]="displayBasic" (onHide)="onHideAdditionalSkillForm()">
    <h5 style="border-bottom: 1px solid #dedcdc;padding: 12px 15px;"><em class="pi pi-arrow-left" style="margin-left: 0px;font-size: 18px;color: black;margin-right: 15px;cursor: pointer;" (click)="displayBasic=false;"></em>Additional Skill Form </h5>
    <div class="dropdownskills" style="padding-top: 10px;overflow-y: scroll;height: 80vh;">
      <ng-container *ngIf="!isMobileView && !isTabView">
      <div class="row" style="margin: 0;">
        <div style="margin-bottom: 5px;" class="col-md-7 form-group user-field selected-contact-data mt-0 ">
          <ion-label class="label fntlvl">Skills <span class="blue-circle myheaderS" 
            [pTooltip]="'Enter required skill for session'" style="position: relative;top: -1px;"tooltipPosition="top">
            <img src="../../assets/images/listing-info.svg" alt="info"></span>
          </ion-label>
        </div>
        <div style="margin-bottom: 5px;" class=" form-group user-field selected-contact-data mt-0 ">
          <ion-label class="label fntlvl">Type <span class="blue-circle myheaderS" 
            [pTooltip]="'Type of session skill'" style="position: relative;top: -1px;" tooltipPosition="top">
            <img src="../../assets/images/listing-info.svg" alt="info"></span>
          </ion-label>
        </div>
     
      </div>
      <div >
        <div class="row" style="margin: 0px;" *ngFor="let item of addSkillsList; let i = index">
        <div style="margin-bottom: 7px;" class="col-md-7 form-group user-field selected-contact-data mt-0">
          <div class=" filter-ico top-margin drpAl dropDwnDgn" >
            <!-- <p-dropdown [(ngModel)]="item.skillId" (onChange)="onAddSkills(i);" styleClass="hederDrop2" panelStyleClass="skillDropdown drpDwnClr" class="dropdown-custom itemSkills"
              filter="true" placeholder="Select" [options]="listSkills">
              <ng-template let-item pTemplate="selectedItem">
                <span >{{item.label}}</span>
                <span style="color: red;margin-left: 3px;" *ngIf="item.label == 'Behavioral Supervision'|| item.label =='Occupation Individual Therapy' || item.label =='Speech Individual Therapy'">
                  <i class="fa fa-solid fa-flag"></i>
                </span>
              </ng-template>
              <ng-template let-sessionType pTemplate="item">
                <div class="ui-helper-clearfix" style="position: relative;">
                  <div style="font-size:13px;display: inline-block;margin-left: 10px;">{{sessionType.label}}</div> 
                  <span style="color: red;margin-left: 5px" *ngIf="sessionType.label == 'Behavioral Supervision'|| sessionType.label =='Occupation Individual Therapy' || sessionType.label =='Speech Individual Therapy'">
                    <i class="fa fa-solid fa-flag"></i>
                  </span>
                </div>
              </ng-template>
            </p-dropdown> -->
            <p-dropdown [(ngModel)]="item.skillId" (ngModelChange) = "addNew(i);onShiftAddSkillsForm($event,i);" styleClass="AddSchdlhederDrop2" class="dropdown-custom itemSkills"
                filter="true" placeholder="Select" [options]="listSkills" panelStyleClass="AddShiftSkillDropdown drpDwnClr">
                <ng-template let-item pTemplate="selectedItem">
                  <span >{{item.label}}</span>
                  <span style="color: red;margin-left: 3px;" *ngIf="item.label == 'Behavioral Supervision'|| item.label =='Occupation Individual Therapy' || item.label =='Speech Individual Therapy'">
                    <i class="fa fa-solid fa-flag"></i>
                  </span>
                </ng-template>
                <ng-template let-sessionType pTemplate="item">
                  <ng-container *ngIf="!skillsSelectedValues.includes(sessionType.value)">
                    <div class="ui-helper-clearfix" style="position: relative;">
                      <div style="font-size:13px;display: inline-block;margin-left: 10px;">{{sessionType.label}}</div> 
                      <span style="color: red;margin-left: 3px" *ngIf="sessionType.label == 'Behavioral Supervision'|| sessionType.label =='Occupation Individual Therapy' || sessionType.label =='Speech Individual Therapy'">
                        <i class="fa fa-solid fa-flag"></i>
                      </span>
                    </div>
                  </ng-container>
                </ng-template>
              </p-dropdown>
          </div>
        </div>
        <div style="margin-bottom: 7px;padding-left: 0px;"
            class="col-md-5 form-group user-field selected-contact-data mt-0">
          <div class="search_ico filter-ico top-margin menu drpAl dropDwnDgn AdditionalSkillDrpDwn">
            <!-- <p-dropdown [(ngModel)]="item.priority" (onChange)="onAddSkills(i);" styleClass="AddSchdlhederDrop3"  panelStyleClass="levelDropdown drpDwnClr"
              class="dropdown-custom" filter="true" placeholder="Select" [options]="listpriority">
            </p-dropdown> -->
            <p-dropdown [(ngModel)]="item.skillTypeId" [disabled]="true" styleClass="AddSchdlhederDrop3" appendTo="body" class="dropdown-custom" optionValue="value" [options]="listSkilltype">
            </p-dropdown>
            <img *ngIf="i > 0 && item.skillId != null" tooltipPosition="top" pTooltip="Delete" style="margin-left: 15px; cursor: pointer;"
                  (click)="onRemoveSkillAdd(i);itemCheck(item.skillId)" class="edit-icons" src="../../assets/images/delete.svg" alt="">
                  <div *ngIf="i==0" style="width: 10px;margin-left: 15px;"></div>
            <!-- <span *ngIf="i == 0" style="width: 9px;margin-left: 15px;">
            </span>
            <img *ngIf="i > 0" tooltipPosition="top" pTooltip="Delete" style="margin-left: 15px; cursor: pointer;"
                (click)="onRemoveSkillAdd(i)" class="edit-icons" src="../../assets/images/delete.svg" alt=""> -->
          </div>
        </div>
        </div>
      </div>
  </ng-container>
<!-------------------------MOBILE VIEW START   ------------------------------>
<ng-container *ngIf="isMobileView || isTabView">
  <div
  style="display: flex; width: 100%;">
  <div class="col-md-8 form-group user-field selected-contact-data mt-0" style="padding-right:0px;">
    <ion-label class="label ">Skills <span class="blue-circle myheaderS" 
      [pTooltip]="'Enter your skills'"
      style="position: relative;
      top: -1px;"
      tooltipPosition="top">
      <img src="../../assets/images/listing-info.svg" alt="info"></span>
    </ion-label>
    <div class="search_ico filter-ico top-margin dropDwnDgn drpAl cusMargin" *ngFor="let item of addSkillsList; let i = index">
      <p-dropdown [(ngModel)]="item.skillId" (ngModelChange) = "addNew(i);onShiftAddSkillsForm($event,i);" styleClass="AddSchdlhederDrop2" class="dropdown-custom itemSkills"
      filter="true" placeholder="Select" [options]="listSkills" panelStyleClass="AddShiftSkillDropdown drpDwnClr">
      <ng-template let-item pTemplate="selectedItem">
        <span >{{item.label}}</span>
        <span style="color: red;margin-left: 3px;" *ngIf="item.label == 'Behavioral Supervision'|| item.label =='Occupation Individual Therapy' || item.label =='Speech Individual Therapy'">
          <i class="fa fa-solid fa-flag"></i>
        </span>
      </ng-template>
      <ng-template let-sessionType pTemplate="item">
        <ng-container *ngIf="!skillsSelectedValues.includes(sessionType.value)">
          <div class="ui-helper-clearfix" style="position: relative;">
            <div style="font-size:13px;display: inline-block;margin-left: 10px;">{{sessionType.label}}</div> 
            <span style="color: red;margin-left: 3px" *ngIf="sessionType.label == 'Behavioral Supervision'|| sessionType.label =='Occupation Individual Therapy' || sessionType.label =='Speech Individual Therapy'">
              <i class="fa fa-solid fa-flag"></i>
            </span>
          </div>
        </ng-container>
      </ng-template>
    </p-dropdown>
     
    </div>
  </div>
  <div class=" form-group user-field selected-contact-data mt-0">
    <ion-label class="label ">Type <span class="blue-circle myheaderS" 
      [pTooltip]="''"
      style="position: relative;
      top: -1px;"
      tooltipPosition="top">
      <img src="../../assets/images/listing-info.svg" alt="info"></span>
    </ion-label>
    <div class="search_ico filter-ico top-margin dropDwnDgn drpAl cusMargin mr-left AdditionalSkillDrpDwn" *ngFor="let item of addSkillsList; let i = index">
      <p-dropdown [(ngModel)]="item.skillTypeId" [disabled]="true" styleClass="AddSchdlhederDrop3" appendTo="body" class="dropdown-custom" optionValue="value" [options]="listSkilltype">
      </p-dropdown>
      <img *ngIf="i > 0 && item.skillId != null" tooltipPosition="top" pTooltip="Delete" style="margin-left: 15px; cursor: pointer;"
            (click)="onRemoveSkillAdd(i);itemCheck(item.skillId)" class="edit-icons" src="../../assets/images/delete.svg" alt="">
            <div *ngIf="i==0" style="width: 10px;margin-left: 15px;"></div>
    </div>
  </div>


</div>
</ng-container>
<!-------------------------MOBILE VIEW END  ---------------------------------->
    </div>
    <div class="setWidth setCenter" style="text-align: center;position: absolute;z-index: 99999;bottom: 10px;left: 0px;border-top: 1px solid #dedcdc;padding: 12px 15px;background: #fff;">
      <button class="dialog-cancel-btn" (click)="displayBasic=false;skillsSelectedValues=[];">Cancel</button>
      <button [disabled]="skillDisable" class="dialog-save-btn" (click)="onSaveCloseClick()">Save</button>
      <!-- <button [disabled]="" class="dialog-save-btn" (click)="onSaveCloseClick();">Save</button> -->
    </div>
     
  </p-sidebar>
  <!--------------------------SIDEBAR S-02  ------------------------------->
</div>


<div class="confirm">
  <p-dialog 
styleClass="mt"
position="center" [draggable]="false" [visible]="isPinnedOpen"
  class="popup-element custom-popup add-contact add-contact-wrapper" [modal]="true">
  <p-header>
    <span style="color: black;">
      <!-- {{pageStrings.lblDeleteHeader}} --> Confirmation
    </span>
    <span class="close-pop2" (click)="isPinnedOpen = false;"><img src="../../assets/images//close.png" alt="close" /></span>
  </p-header>
  <form>
    <div class="col-12 pt-3 pl-0 pr-0">
      <div class="vaildation-area dropdown-top fColor" style="color: #656565;
        text-align: left;">
      If you click on <span style="background: #041d5b;color: white;padding: 2px 4px;border-radius: 4px;">Yes</span>, choosen provider will be same after planning.
      If you click on <span style="background: #c7c7c7;color: #010101;padding: 2px 4px;border-radius: 4px;">No</span>, The choosen provider may differ during the course of planning:        
    </div>
    </div>
  </form>

  <p-footer class="btns">
    <div class="mCenter" >
      <!-- <ion-button class="btn-main" (click)="ClickOnYes()">Yes</ion-button>
      <ion-button class="btn-default setWidth" (click)="CloseAndExit()">No</ion-button> -->
      <button class="dialog-yes-btn" (click)="ClickOnYes()">Yes</button>
      <button class="dialog-no-btn" (click)="CloseAndExit()">No</button>
    </div>
  </p-footer>
</p-dialog>
</div>



<p-dialog styleClass="mt" position="center" [draggable]="false" [visible]="providerUnavlablePopup"
  class="popup-element custom-popup add-contact add-contact-wrapper" [modal]="true">
  <p-header>
    <span style="color: black;">
      Alert
    </span>
    <span class="close-pop" (click)="providerUnavlablePopup = false;"><img src="../../assets/images//close.png" alt="close" /></span>
  </p-header>
  <form>
    <div class="col-12 pt-3 pl-0 pr-0">
      <div class="vaildation-area dropdown-top fColor" style="color: #656565;
      text-align: left;">
          {{providerNameUnavlable}} (Provider) is unavailable between selected time duration
      </div>
    </div>
  </form>

  <p-footer class="btns">
    <div class="mCenter" >
      <ion-button class="btn-save-radius" (click)="providerUnavlablePopup = false;">Close
      </ion-button>
    </div>
  </p-footer>
</p-dialog>

<div class="alertPop">
<p-dialog styleClass="mt" position="center" [draggable]="false" [visible]="authWarnPopup"
  class="popup-element custom-popup add-contact add-contact-wrapper" [modal]="true">
  <p-header>
    <span style="color: black;">
      Alert <span><i class="fa fa-exclamation-triangle" aria-hidden="true" style="color: red;"></i></span>
    </span>
    <span class="close-pop" (click)="closeAuthWarnPopup();"><img src="../../assets/images//close.png" alt="close" /></span>
  </p-header>
  <form>
    <div class="col-12 pt-3 pl-0 pr-0">
      <ng-container *ngIf="specialSessionSelectedTEXT != '' else normalSkillMessage" >
        <div class="vaildation-area dropdown-top fColor" style="color: #656565;
        text-align: left;">
          The patient has already completed the total authorised sessions for <span style="color: #818fe2;text-decoration: underline;">{{selectedSessionTypeTEXT}}</span>. Would you still like to create a session?
        </div>
      </ng-container>
      <ng-template #normalSkillMessage>
        <div class="vaildation-area dropdown-top fColor" style="color: #656565;
        text-align: left;">
           This patient doesn't have authorizations for <span style="color: #818fe2;text-decoration: underline;">{{selectedSessionTypeTEXT}}</span>. Would you still like to create a session?
        </div>
      </ng-template>
    </div>
  </form>

  <p-footer class="btns">
    <div class="mCenter" >
      <ion-button class="btn-save-radius" (click)="closeAuthWarnPopup();">Close</ion-button>
    </div>
  </p-footer>
</p-dialog>

<!-- <div class="confirm">
  <p-dialog 
styleClass="mt"
position="center" [draggable]="false" [visible]="reccIsPinnedPopup"
  class="popup-element custom-popup add-contact add-contact-wrapper" [modal]="true">
  <p-header>
    <span style="color: black;">
       Confirmation
    </span>
    <span class="close-pop2" (click)="reccIsPinnedPopup = false;"><img src="../../assets/images//close.png" alt="close" /></span>
  </p-header>
  <form>
    <div class="col-12 pt-3 pl-0 pr-0">
      <div class="vaildation-area dropdown-top fColor" style="color: #656565;
        text-align: left;">
       Choose 'Yes' for pinning all future sessions, or select 'No' to pin only the current session."    
    </div>
    </div>
  </form>

  <p-footer class="btns">
    <div class="mCenter" >
      <button class="dialog-yes-btn" (click)="ClickOnOk('recurrencePinned')">Yes</button>
      <button class="dialog-no-btn" (click)="ClickOnOk('notrecurrencePinned')">No</button>
    </div>
  </p-footer>
</p-dialog>
</div> -->