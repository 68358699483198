import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class PlannerService {

constructor(public sharedService: SharedService) { }
updatePlanner(formData){
  return this.sharedService.putData(config.addEditPlanner,formData)
}
addPlanner(formData){

    return this.sharedService.postData(config.addEditPlanner,formData)
}
getPlanner(data) {
  let url = `${config.getPlannerTable}`;
  url = url.replace(":page", data.page).replace(":limit", data.limit);
  if(data.searchParam){
    url = `${url}&searchParam=${encodeURIComponent(data.searchParam)}`;
  }
  if(data.clinicId){
    url = `${url}&clinicId=${data.clinicId}`;
  }
  if(data.status !== null && data.status !== undefined){
    url = `${url}&status=${data.status}`;
  }
  return this.sharedService.getData(url);
}
deletePlanner(id) {
  return this.sharedService.deleteData(`${config.deletePlanner}/${id}`);
}

addImage(formData){
  return this.sharedService.postData(config.uploadImage,formData)
}
 
}






