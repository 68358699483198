import { Utils } from './../utils';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Component, OnInit, OnDestroy, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { InitialService } from './initial.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-initial',
  templateUrl: './initial.component.html',
  styleUrls: ['./initial.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InitialComponent implements OnInit, OnDestroy {
  // @Output() myOutputChange: EventEmitter<any> = new EventEmitter();
  inputVariabletrue;
  urlHideSidebar = ['/maintainance/maintainance-page', '/error/error-page'];
  isSidebarVisible = true;

  makeSidebarVisible: boolean;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  publicData = null;
  selectedIndex = 0;
  fullView = true;
  rolesList: Array<any> = [];
  totalCount = {};
  isMobile:Boolean = false;
  sideBarSub: Subscription;
  editStatusSub: Subscription;
  urlToOpen = null;
  constructor(
    public platform: Platform,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public utils: Utils,
    public appService: AppService,
    public initialService: InitialService,
    public route: ActivatedRoute,
    public router: Router

  ) {

    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          const currentUrl = this.router.url;
          if (this.urlHideSidebar.includes(currentUrl)) {
            this.isSidebarVisible = false;

          }
          else {
            this.isSidebarVisible = true;
          }
        }
      }
    );
    this.isMobile=window.innerWidth <= 880;
  }

  mapData(data){
    this.publicData = {
      "company_name": data.company_name !== null ? data.company_name : 'Opta Planner',
      "company_title": data.company_title !== null ? data.company_title : 'Login',
      "company_logo": '../../assets/images/Web-Logo@2x.png',
      "company_fav_icon": '../../assets/images/Web-Logo@2x.png'};
    document.title = this.publicData.company_name;
    localStorage.setItem("pubDataSaas", JSON.stringify(this.publicData));
    this.appService.updateCacheCard.next('null');
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.urlToOpen = !!params.urlToOpen || false;
    });
    this.initializeApp();
    this.sideBarSub = this.appService.sideBarStateSource$.subscribe((state: any) => {
      this.fullView = state;
    });
  }

  ngOnDestroy() {
    if (this.sideBarSub) {
      this.sideBarSub.unsubscribe();
    }
    this.editStatusSub && this.editStatusSub.unsubscribe();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  /**
   * To get roles
   */
  getRoles() {
    this.initialService.getRoles().subscribe((response) => {
      this.rolesList = response.data.length > 0 ? this.augmentRoles(response.data) : [];
      this.appService.updateRoles(this.rolesList);
    });
  }

  /**
   * To get count
   */
  getCount() {
    this.initialService.getCount().subscribe((response) => {
      this.totalCount = response.data;
      this.appService.updateCount(this.totalCount);
    });
  }

  /**
   * To augment roles list
   * @param data Roles list
   */
  augmentRoles(data) {
    const updatedData = data.map((role) => ({
      label: role.name,
      value: role.id
    }));
    return updatedData;
  }
  getData(value) {
    this.makeSidebarVisible = value;
  }
  getInputData(value){
    // this.myOutputChange.emit(value);
    setTimeout(() => {
      this.makeSidebarVisible = value;
    }, 0);
  }
  isLoginPage(): boolean {
    return this.router.url === '/login';
  }
}
