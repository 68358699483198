import {Component} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';

@Component({
    template: `
                <ng-container *ngIf="isPinned === true && customRecurrence === false">
                <form>
                <div class="col-12 pt-2 pb-2 pl-0 pr-0">
                <div class="vaildation-area dropdown-top fColor" style="color: #656565;
                    text-align: left;padding: 15px 20px;">

                    If you click on 
                        <span style="background: #041d5b;color: white;padding: 2px 4px;border-radius: 4px;">Yes</span>, 
                        The chosen provider will be the same after planning. 

                    If you click on 
                        <span style="background: #c7c7c7;color: #010101;padding: 2px 4px;border-radius: 4px;">No</span>, 
                        The chosen provider may differ during the course of planning. 

                </div>
                </div>
            </form>

            <div class="PinnedBtnCenter" >
                <button class="dialog-yes-btn" (click)="updateIsPinned(true,customRecurrence);">Yes</button>
                <button class="dialog-no-btn" (click)="updateIsPinned(false,customRecurrence);">No</button>
            </div>
            </ng-container>

            <ng-container *ngIf="customRecurrence === true && isPinned === false">
                <form>
                <div class="col-12 pt-2 pb-2 pl-0 pr-0">
                <div class="vaildation-area dropdown-top fColor" style="color: #656565;
                    text-align: left;padding: 15px 20px;">

                    If you click on 
                        <span style="background: #041d5b;color: white;padding: 2px 4px;border-radius: 4px;">Yes</span>, 
                        The session will remain custom recurrence.
                    <br>
                    If you click on 
                        <span style="background: #c7c7c7;color: #010101;padding: 2px 4px;border-radius: 4px;">No</span>, 
                        The session will show as do not repeat.

                    </div>
                </div>
            </form>

            <div class="PinnedBtnCenter" >
                <button class="dialog-yes-btn" (click)="updateIsPinned(true,isPinned);">Yes</button>
                <button class="dialog-no-btn" (click)="updateIsPinned(false,isPinned);">No</button>
            </div>
            </ng-container>

            <ng-container *ngIf="customRecurrence === true && isPinned === true">
            <form>
            <div class="col-12 pt-2 pb-2 pl-0 pr-0">
            <div class="vaildation-area dropdown-top fColor dynamicDialogDropDown" style="color: #656565;
                text-align: left;padding: 15px 20px;">

                <span style="border: 1px solid #b1b1b1;display: flex;border-radius: 4px;padding: 10px;justify-content: space-between;">
                
                    Select is pinned option if you want to get a selected provider.
                    <p-inputSwitch name="isPinned" [(ngModel)]="toggleIsPinned" class="mt-1" ></p-inputSwitch>

                </span>
                
                <span style="border: 1px solid #b1b1b1;display: flex;border-radius: 4px;padding: 10px;margin-top: 10px;justify-content: space-between;">
                        Select occurence of a session.

                    <p-dropdown name="recurrence" styleClass="hederDrop" panelStyleClass=""
                    [(ngModel)]="drpDwnRecurrence" appendTo="body" class="dropdown-custom"
                    placeholder="Select" [options]="ReapetsTypeSet1" baseZIndex="12000">
                    </p-dropdown>
                </span>
                </div>
            </div>
        </form>

        <div class="PinnedBtnCenter" >
            <button class="dialog-yes-btn" (click)="updateIsPinned(toggleIsPinned,drpDwnRecurrence === 'doNotRepeat' ? false : true);">Submit</button>
        </div>
        </ng-container>
    `
})
export class ProviderDynamicDialog {

    viewBy = '';
    customRecurrence:boolean;
    isPinned:any;
    toggleIsPinned:any;
    ReapetsTypeSet1 = [
        {label: 'Custom Recurrence', value: 'customRecurrence'},
        {label: 'Do Not Repeat', value: 'doNotRepeat'}
    ];
    drpDwnRecurrence:any;

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

    ngOnInit() {
        const ConfigData = this.config.data;
        this.viewBy = ConfigData.viewBy;
        this.customRecurrence = ConfigData.customRecurrence;
        this.isPinned = ConfigData.isPinned;
        this.toggleIsPinned = ConfigData.isPinned;
        this.drpDwnRecurrence = ConfigData.recurrenceType;
    }

    updateIsPinned(isPinnedstatus,recorrenceStatus) {
        const data = {isPinnedstatus: isPinnedstatus,recorrenceStatus:recorrenceStatus };
        this.ref.close(data);
    }
}