import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PatientComponent } from './patient.component';
import { PatientDetailsComponent } from './patient-details/patient-details.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'all-patient',
    pathMatch: 'full'
  }, 
  {
    path: 'all-patient',
    component: PatientComponent
  },
  {
    path: 'patient-details',
    component: PatientDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContactRoutingModule { }

