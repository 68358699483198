import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProviderService } from 'src/app/provider/provider.service';
import { MessageService } from 'primeng/api';
import { LanguageInterface } from '../../languages/interface.language';

import { config } from 'src/app/config';
import { PatientTeamService } from 'src/app/patient-team/patient-team.service';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { HistoryColumns } from '../patient.model';


@Component({
  selector: 'app-add-patient-team',
  templateUrl: './add-patient-team.component.html',
  styleUrls: ['./add-patient-team.component.scss'],
})
export class AddPatientTeamComponent implements OnInit {
  today: string = new Date().toISOString().split('T')[0];
  assignedDate:any = this.today;
  // unassignedDate:any = this.today;
  providerId:any;
  first: number = 0;
  totalRecords = 0;
  callType:any = 'patient';
  displaysidebar = false;
  listProvider:any = [];
  listPatient = [];
  selectedTeam = [];
  searchTextPTeam:any;
  openSupervisorHistory:boolean=false;
  HistoryColumns = HistoryColumns;
  historyData:any[] = [];
  selectedPatient: any;
  edit:boolean = false;
  btnDisable:boolean = true;
  patientId:any;
  patientTeamSubscription: Subscription;
  providerTeamSubscription: Subscription;
  primaryProviderTeamSubscription: Subscription;
  imgBaseURL = config.baseUrl;
  selectedPrimary: any;
  listPrimaryProvider:any = [];
  showPrimaryDailog:boolean = false;
  selectedProvider:any;
  isChanged:Boolean = true;
  @Output() updatePatientTableData = new EventEmitter<any>();
  @Output() updatePatientDetailData = new EventEmitter<any>();

  @Output() updateProviderTableData = new EventEmitter<any>();
  @Output() updateProviderDetailData = new EventEmitter<any>();
  pageStrings: LanguageInterface;
  findIndexSelectedIds:Array<any> = [];
  listPatientIds:Array<any> = [];
  listProviderIds:Array<any> = [];
  isMobileView = window.innerWidth <= 600;
  minDate:any;
  constructor(
    public appService: AppService,
    public providerService: ProviderService,
    public messageService: MessageService,
    public patientTeamService: PatientTeamService,
    ) { }
  ngOnInit() {
    const today = new Date();
    this.minDate = today.toISOString().split('T')[0];
  this.primaryProviderTeamSubscription = this.appService.primaryproviderTeamSource$.subscribe((res:any) =>{
    this.getPrimaryProviderList(res);
  })
    this.providerTeamSubscription = this.appService.providerTeamSidebarSource$.subscribe((res: any) => {    
      //Call from patient Module...
      this.btnDisable = true;  
        this.getProviderList(res);
    });
    this.patientTeamSubscription = this.appService.patientTeamSidebarSource$.subscribe((res: any) => {    
      //Call from provider Module...
      this.btnDisable = true;  
        this.getPatientList({ page: 1, limit: 1000 , status:true, sortBy:'name'},res);
    });
  }

  getProviderList(res) {
    console.log(res.data.id);
    this.appService.showCustomLoader(true);
    this.providerService.getProviderListData().subscribe((response: any) => {
      if (response.data.length > 0) {
        this.listProvider = response.data.map(obj => {
          const primaryDtoItem = obj.primaryProviderDTO.find(dto => dto.patientId === res.data.id);
          return ({ name: `${obj.firstName} ${obj.lastName} ${obj?.employeeId ? ` (${obj.employeeId})` : ''}`, value: obj.id, documentId:obj.documentId,firstName:obj.firstName,lastName:obj.lastName,bcbaSkillExists:obj.bcbaSkillExists,primaryProvider:primaryDtoItem ? primaryDtoItem.primary : false });        });
        this.callType = 'patient';

        if (res.state) {
          this.edit = res.data.providerInfoList?.length > 0;
          this.callAssignTeam(res.data);
        }
        this.appService.showCustomLoader(false);
      }else{
        this.appService.showCustomLoader(false);
        this.messageService.add({ severity: 'warn', summary: 'Alert', detail: 'No provider present in the clinic'});
        this.displaysidebar = false;
      }
    },(error)=>{
      this.appService.showCustomLoader(false);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message ? error.error.message : 'Something went wrong.'});
    });
  }
  selectedPatientFullName:string = "";
  getPrimaryProviderList(res) {
    console.log('YES');
    
    this.selectedPatientFullName = res.data.formData.firstName +' '+ res.data.formData.lastName
    
    this.patientId = res.data.id
    this.appService.showCustomLoader(true);
    this.providerService.getProviderListData().subscribe((response: any) => {
      if (response.data.length > 0) {
        this.listPrimaryProvider = response.data.map(obj => {
          
          const primaryDtoItem = obj.primaryProviderDTO.find(dto => dto.patientId === res.data.id);
          
          return ({ name: `${obj.firstName} ${obj.lastName} ${obj?.employeeId ? ` (${obj.employeeId})` : ''}`,assignedDate:primaryDtoItem?.assignedDate, value: obj.id, documentId:obj.documentId,firstName:obj.firstName,lastName:obj.lastName,bcbaSkillExists:obj.bcbaSkillExists,primaryProvider:primaryDtoItem ? primaryDtoItem.selected : false });
        }).filter(provider => provider.bcbaSkillExists);
        // this.listPrimaryProvider.length ? this.listPrimaryProvider.unshift({name:'Select',value:null}) : ''
        this.selectedProvider = this.listPrimaryProvider.find(provider => provider?.primaryProvider === true);
        console.log(this.selectedProvider);
        this.assignedDate = this.selectedProvider?.assignedDate;
        // this.unassignedDate = this.selectedProvider?.unassignedDate

      //  if( this.selectedProvider === undefined ){
      //   this.listPrimaryProvider.shift()
      //  }
        this.callType = 'patient';

        if (res.state) {
          this.edit = res.data.providerInfoList?.length > 0;
          this.showPrimaryDailog = true;
          this.openSupervisorHistory = false;
        }
        this.appService.showCustomLoader(false);
      }else{
        this.appService.showCustomLoader(false);
        this.messageService.add({ severity: 'warn', summary: 'Alert', detail: 'No provider present in the clinic'});
        this.displaysidebar = false;
      }
    },(error)=>{
      this.appService.showCustomLoader(false);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message ? error.error.message : 'Something went wrong.'});
    });
    this.getAssignedProvider();
  }

  previousSupervisor:any;
  getAssignedProvider(){
    this.patientTeamService.getAssignedProvider(this.patientId).subscribe((res:any)=>{
      this.previousSupervisor = res.data?.providerName ? res.data?.providerName : '---';
    })
  }
providerData(data){
  this.providerId = data.value;
  this.isChanged = false;
}
onFormChange(){
  this.isChanged = false;
}
addPrimaryProvider(){
  if (this.assignedDate && this.selectedProvider) {
    const payload = {
      patientId:this.patientId,
      providerId:this.selectedProvider?.value,
      assignedDate:this.assignedDate
    }
    this.patientTeamService.addPrimaryProvider(payload).subscribe((res)=>{
      this.showPrimaryDailog = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.message});
      this.updatePatientTableData.emit({success:true});
      this.updatePatientDetailData.emit({success:true});
      this.isChanged = true;
    },(error)=>{
        this.appService.showCustomLoader(false);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message ? error.error.message : 'Something went wrong.'});
      })
  }else if(!this.assignedDate){
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select assigned date.'});
  }else if(!this.selectedProvider){
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select Provider first!'});
  }
  
 }
  getPatientList(data,res) {
    this.appService.showCustomLoader(true);
    this.providerService.getPatientList(data).subscribe((response: any) => {
      if (response.data.length > 0) {
        this.listPatient = response.data.map(obj => {
          return { name: `${obj.firstName} ${obj.lastName} (${obj?.patientId})`, value: obj.id,firstName:obj.firstName,lastName:obj.lastName }
        });
        this.callType = 'provider';

        if (res.state) {
          this.edit = res.data.assignedPatient?.length > 0;
          this.callAssignTeam(res.data);
        }  
        this.appService.showCustomLoader(false);
      }else{
        this.appService.showCustomLoader(false);
        this.messageService.add({ severity: 'warn', summary: 'Alert', detail: 'No patient present in the clinic'});
        this.displaysidebar = false;
      }
    },(error)=>{
      this.appService.showCustomLoader(false);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message ? error.error.message : 'Something went wrong.'});
    });
  }

  callAssignTeam(data){    
    if(this.callType === 'patient' ){// for patient module...
      if(data.providerInfoList?.length > 0){
        this.callAssignTeamEdit(data);
      }
      else{
      this.displaysidebar = true;
      this.searchTextPTeam = '';
        this.selectedTeam = [];
        this.selectedPatient = {...data};
        this.listProviderIds = this.listProvider.map((obj)=>{return (obj.value)});
      }
    }else{// for provider module...
      if(data.assignedPatient?.length > 0){
        this.callAssignTeamEdit(data);
      }
      else{
      this.displaysidebar = true;
      this.searchTextPTeam = '';
        this.selectedTeam = [];
        this.selectedPatient = {...data};
        this.listPatientIds = this.listPatient.map((obj)=>{return (obj.value)});
      }
    }

  }
  callAssignTeamEdit(data){
    if(this.callType === 'patient' ){// for patient module...
      this.displaysidebar = true;
      this.searchTextPTeam = '';
  
      let selectedIds = data.providerInfoList ? data.providerInfoList.map((obj)=>{return (obj.id)}): [];
      this.listProviderIds = this.listProvider.map((obj)=>{return (obj.value)});
      this.findIndexSelectedIds = [];

  
      selectedIds.forEach((obj)=>{
        // findIndexSelectedIds.push(this.listProvider[listProviderIds.indexOf(obj)]);
        const data = this.listProvider[this.listProviderIds.indexOf(obj)];
        if(data){
          this.findIndexSelectedIds.push(data);
        }
      });
      this.selectedTeam = [...this.findIndexSelectedIds];
      
      this.selectedPatient = {...data, edit: true}; 

    }else{// for provider module...
      this.displaysidebar = true;
      this.searchTextPTeam = '';
      this.listPatientIds = [];
      
      let selectedIds = data.assignedPatient ? data.assignedPatient.map((obj)=>{return (obj.id)}): [];
      this.listPatientIds = this.listPatient.map((obj)=>{return (obj.value)});
      this.findIndexSelectedIds = [];
  
      selectedIds.forEach((obj)=>{
        const data = this.listPatient[this.listPatientIds.indexOf(obj)];
        if(data){
          this.findIndexSelectedIds.push(data);
        }
      });
      this.selectedTeam = [...this.findIndexSelectedIds];
      

      this.selectedPatient = {...data, edit: true};   
    }
 
  }

  closeProvider(i){
    this.selectedTeam.splice(i,1);
    this.selectedTeam = this.selectedTeam.map(o=>o);
    this.btnDisable = false;
  }
  onSaveClick(){
    if(this.callType === 'patient' ){// for patient module...
      let finalIds = [];
      this.selectedTeam.map((obj)=>{
        const data = this.listProvider[this.listProviderIds.indexOf(obj.value)];
        if(data){
          finalIds.push(data.value);
        }
      })

      const payload = {
        patientId: this.selectedPatient.id,
        providerIds: finalIds,
      };

      if(this.edit && this.findIndexSelectedIds.length > 0){
        this.patientTeamService.updatePatientTeam(payload).subscribe((response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Patient team updated successfully.' });
          this.displaysidebar = false;
          this.updatePatientTableData.emit({success:true});
          this.updatePatientDetailData.emit({success:true});
  
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      }else{
        this.patientTeamService.addPatientTeam(payload).subscribe((res) => {
          this.displaysidebar = false;
          this.updatePatientTableData.emit({success:true});
          this.updatePatientDetailData.emit({success:true});
          this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.message });
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      }
    }else{// for provider module...
      let finalIds = [];
      this.selectedTeam.map((obj)=>{
        const data = this.listPatient[this.listPatientIds.indexOf(obj.value)];
        if(data){
          finalIds.push(data.value);
        }
      })

      const payload = {
        providerId: this.selectedPatient.id,
        patientIds: finalIds,
      };
      
      if(this.edit && this.findIndexSelectedIds.length > 0){
        this.providerService.updateProviderTeam(payload).subscribe((response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Provider team updated successfully.' });
          this.displaysidebar = false;
          this.updateProviderTableData.emit({success:true});
          this.updateProviderDetailData.emit({success:true});
  
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      }else{
        this.providerService.addProviderTeam(payload).subscribe((res) => {
          this.displaysidebar = false;
          this.updateProviderTableData.emit({success:true});
          this.updateProviderDetailData.emit({success:true});
          this.messageService.add({ severity: 'success', summary: 'Success', detail: res.body.message });
        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
        });
      }
    }

  }

  changeCheckBox(){
    this.btnDisable = false;
  }

  ngOnDestroy() {
    if (this.providerTeamSubscription) {
      this.providerTeamSubscription.unsubscribe();
    }
    if(this.primaryProviderTeamSubscription){
      this.primaryProviderTeamSubscription.unsubscribe();
    }
    if(this.patientTeamSubscription){
      this.patientTeamSubscription.unsubscribe();
    }
    this.displaysidebar = false;
    this.btnDisable = true;
  }
  checkdata(data:any,event:any){
    data.isChecked = event.checked;
    console.log(data);
    if(data.isChecked === false){
      data.primaryProvider = false;
    }
  }
  // checkIsPrimary(event: any, provider: any) {
//   if (event.checked) {
//     // Uncheck other checkboxes
//     this.listProvider.forEach((p) => {
//       if (p !== provider) {
//         p.primaryProvider = false;
//       }
//     });

//     this.btnDisable = false;
//     this.selectedPrimary = provider;
//     this.providerId = provider.value;
//   } else {
//     this.selectedPrimary = null;
//     this.btnDisable = false;
//   }
// }
checkIsPrimary(event: any, provider: any) {
  if (event.checked) {
    this.listProvider.forEach((p) => {
      if (p !== provider) {
        p.primaryProvider = false;
      }
    });

    this.btnDisable = false;
    this.selectedPrimary = provider;
    this.providerId = provider?.value;
  } else {
    this.selectedPrimary = null;
    this.btnDisable = false;
  }
}

disableOtherCheckboxes(selectedProvider: any) {
  this.listProvider.forEach(provider => {
    if (provider !== selectedProvider) {
      provider.disabled = true;
    }
  });
}

enableAllCheckboxes() {
  this.listProvider.forEach(provider => {
    provider.disabled = false;
  });
}

get truncatedPatientFullName() {
  const maxLength = !this.isMobileView ? 20 : 6;
  var patientFullName = this.selectedPatientFullName;
  if (patientFullName.length > maxLength) {
  return patientFullName.substring(0, maxLength) + '...';
  }
  return patientFullName;
}
getAllHistory(page,size){
  const payload = {
    page: page,
    size: size,
    patientId: this.patientId
  };
  
  this.patientTeamService.supervisorHistory(payload).subscribe((res: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    this.historyData = res.data.map((item: any, index: number) => {
      let assignedDate = item.assignedDate ? new Date(item.assignedDate) : null;
      let unassignedDate = item.unassignedDate ? new Date(item.unassignedDate) : null;
  
      if (assignedDate) assignedDate.setHours(0, 0, 0, 0);
      if (unassignedDate) unassignedDate.setHours(0, 0, 0, 0);
  
      const status = assignedDate
        ? assignedDate <= today
          ? 'Ongoing...'
          : 'Upcoming...'
        : 'N/A';
  
      const isOngoing = assignedDate && (unassignedDate === null || unassignedDate >= today) && assignedDate <= today;
      return {
        ...item,
        serial_no: index + 1,
        assignedDate: assignedDate ? item.assignedDate : 'N/A',
        unassignedDate: item.unassignedDate ? item.unassignedDate : status,
        isOngoing
      };
    });  
    this.openSupervisorHistory = true;
  });
  
}
loadHistoryData(event: any) {
  const page = event.first / event.rows + 1;
  const size = event.rows;
  this.getAllHistory(page,size)
}
}