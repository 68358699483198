import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { config } from '../config';
@Injectable({
  providedIn: 'root'
})
export class SpotsService {

constructor(public sharedService: SharedService) { }

updateSpot(formData){
  return this.sharedService.putData(config.addEditSpot,formData)
}
addSpot(formData){
  return this.sharedService.postData(config.addEditSpot,formData)
}
getSpot(data) {
  let url = `${config.getSpotTable}`;
  url = url.replace(":page", data.page).replace(":limit", data.limit);
  if(data.searchParam){
    url = `${url}&searchParam=${encodeURIComponent(data.searchParam)}`;
  }
  if(data.clinicId){
    url = `${url}&clinicId=${data.clinicId}`;
  }
  return this.sharedService.getData(url);
}

addGroups(data) {
  const getGroupUrl = `${config.getGroups}`;
  return this.sharedService.postData(getGroupUrl,data);
}

getGroups(){
  return this.sharedService.getData(config.getGroups);
}

deleteSpot(id) {
  return this.sharedService.deleteData(`${config.deleteSpot}/${id}`);
}
 
}
