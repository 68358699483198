export const  DailyViewData = [
  {
    id: '1',
    resource: '2',
    start: '2022-02-02 00:30:30',
    end: '2022-02-02 02:01:30',
    text: 'Meeting 1',
    fontColor: 'black',
    backColor: '#EBE778',
    cssClass: 'testEvent',
    html: `
        <div style='display: flex;
                    flex-direction: column;
                    padding: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;'>
          <div>Leonardo Dicaprio</div>
          <div>3:30 AM-5:00 AM</div>
          <div style='color:#656565'>
            Critical Care
          </div>
        </div>
    `
  },
  {
    id: '3',
    resource: '3',
    start: '2022-02-02 06:30:30',
    end: '2022-02-02 09:31:30',
    text: 'Meeting 1',
    fontColor: 'black',
    backColor: '#EBE778',
    cssClass: 'testEvent',
    html: `
        <div style='display: flex;
                    flex-direction: column;
                    padding: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;'>
          <div>Leonardo Dicaprio</div>
          <div>06:30 AM-09:31 AM</div>
          <div style='color:#656565'>
          Ambulatory Care
          </div>
        </div>
    `
  },
  {
    id: '4',
    resource: '4',
    start: '2022-02-02 14:00:30',
    end: '2022-02-02 17:31:30',
    text: 'Meeting 1',
    fontColor: 'black',
    backColor: '#EBE778',
    cssClass: 'testEvent',
    html: `
        <div style='display: flex;
                    flex-direction: column;
                    padding: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;'>
          <div>Leonardo Dicaprio</div>
          <div>02:00 AM-05:31 AM</div>
          <div style='color:#656565'>
          Ambulatory Care
          </div>
        </div>
    `
  },
  {
    id: '5',
    resource: '5',
    start: '2022-02-02 04:00:30',
    end: '2022-02-02 08:31:30',
    text: 'Meeting 1',
    fontColor: 'black',
    backColor: '#EBE778',
    cssClass: 'testEvent',
    html: `
        <div style='display: flex;
                    flex-direction: column;
                    padding: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;'>
          <div>Leonardo Dicaprio</div>
          <div>04:00 AM-08:31 AM</div>
          <div style='color:#656565'>
          Ambulatory Care
          </div>
        </div>
    `
  },
  {
    id: '2',
    resource: '1',
    start: '2022-02-02 00:00:00',
    end: '2022-02-02 24:00:00',
    text: 'Unavailable All Day',
    fontColor: 'white',
    backColor: '#F27038',
    cssClass: 'fullDayEvent'
  },
];


export const  WeeksViewData = [
  {
    id: '1',
    resource: '2',
    start: '2022-01-02 00:30:30',
    end: '2022-01-03 00:01:30',
    text: 'Meeting 1',
    fontColor: 'black',
    backColor: '#EBE778',
    cssClass: 'testEvent',
    html: `
        <div style='display: flex;
                    flex-direction: column;
                    padding: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;'>
          <div>Leonardo Dicaprio</div>
          <div>3:30 AM-5:00 AM</div>
          <div style='color:#656565'>
            Critical Care
          </div>
        </div>
    `
  },
  {
    id: '3',
    resource: '3',
    start: '2022-01-04 06:30:30',
    end: '2022-01-05 00:00:30',
    text: 'Meeting 1',
    fontColor: 'black',
    backColor: '#EBE778',
    cssClass: 'testEvent',
    html: `
        <div style='display: flex;
                    flex-direction: column;
                    padding: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;'>
          <div>Leonardo Dicaprio</div>
          <div>06:30 AM-09:31 AM</div>
          <div style='color:#656565'>
          Ambulatory Care
          </div>
        </div>
    `
  },
  {
    id: '4',
    resource: '4',
    start: '2022-01-06 14:00:30',
    end: '2022-01-07 17:31:30',
    text: 'Meeting 1',
    fontColor: 'black',
    backColor: '#EBE778',
    cssClass: 'testEvent',
    html: `
        <div style='display: flex;
                    flex-direction: column;
                    padding: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;'>
          <div>Leonardo Dicaprio</div>
          <div>02:00 AM-05:31 AM</div>
          <div style='color:#656565'>
          Ambulatory Care
          </div>
        </div>
    `
  },
  {
    id: '5',
    resource: '5',
    start: '2022-01-08 04:00:30',
    end: '2022-01-09 08:31:30',
    text: 'Meeting 1',
    fontColor: 'black',
    backColor: '#EBE778',
    cssClass: 'testEvent',
    html: `
        <div style='display: flex;
                    flex-direction: column;
                    padding: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;'>
          <div>Leonardo Dicaprio</div>
          <div>04:00 AM-08:31 AM</div>
          <div style='color:#656565'>
          Ambulatory Care
          </div>
        </div>
    `
  },
  {
    id: '2',
    resource: '1',
    start: '2022-01-05 00:00:00',
    end: '2022-01-10 00:00:00',
    text: 'Unavailable All Day',
    fontColor: 'white',
    backColor: '#F27038',
    cssClass: 'fullDayEvent fullDayWeekEvent'
  },
];


export const  DailyViewResourceData = [
  { img:"~/assets/Group 212.png", cssClass : "hideMe", userName: 'David Lee', userPin: '#P-00016', minHeight: 60, name: "1", html: 'test 1', id: "1"},
  { img:"~/assets/Group 212.png", cssClass : "hideMe", userName: 'Olivia Jean', userPin: '#P-00016', minHeight: 60, name: "Olivia Jean \n #P-00016", html: 'test2', id: "2"},
  { img:"~/assets/Group 212.png", cssClass : "hideMe", userName: 'Samantha San', userPin: '#P-00016', minHeight: 60, name: "Samantha San \n #P-00016", html: 'test3', id: "3"},
  { img:"~/assets/Group 212.png", cssClass : "hideMe", userName: 'Cindy Anderson', userPin: '#P-00016', minHeight: 60, name: "Cindy Anderson \n #P-00016", html: 'test4', id: "4"},
  { img:"~/assets/Group 212.png", cssClass : "hideMe", userName: 'David Lee', userPin: '#P-00016', minHeight: 60, name: "David Lee \n #P-00016", html: 'test4', id: "5"},
  { img:"~/assets/Group 212.png", cssClass : "hideMe", userName: 'David Lee', userPin: '#P-00016', minHeight: 60, name: "David Lee \n #P-00016", html: 'test4', id: "6"},
];
