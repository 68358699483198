import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProviderComponent } from './provider.component';
import { ProviderDetailsComponent } from './provider-details/provider-details.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'all-provider',
    pathMatch: 'full' // ✅ Correct use of `pathMatch`
  }, 
  {
    path: 'all-provider',
    component: ProviderComponent
  },
  {
    path: 'provider-details',
    component: ProviderDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderRoutingModule { }


