import { Utils } from '../../utils';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from '../../app.service';
import { ChartOptions, ChartType } from 'chart.js';
import { Color, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import { getLanguage } from 'src/app/languages';
import { LanguageInterface } from 'src/app/languages/interface.language';
import { AppExService } from 'src/app/app-ex.service';
import { MessageService } from 'primeng/api';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { PatientService } from '../patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { config } from 'src/app/config';
import { PatientTeamService } from 'src/app/patient-team/patient-team.service';
import { SchedulingService } from 'src/app/scheduling/scheduling.service';
import * as pluginLabels from 'chartjs-plugin-labels';

import { SkillsService } from 'src/app/skills/skills.service';
import { SettingService } from 'src/app/setting/setting.service';
import { ProviderService } from 'src/app/provider/provider.service';
import { PatientDynamicDialog } from './patient-dynamic-dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import tippy from 'tippy.js';
import { Table } from 'primeng/table';
import { SpotsService } from 'src/app/spots/spots.service';


@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss'],
  providers: [DialogService]
})
export class PatientDetailsComponent implements OnInit {
  payersList: any[] = [];
  isExpired: boolean = false;
  isEdit: boolean
  formChanged = false;
  confirmDelete: boolean = false;
  display: boolean = false;
  listSpots: any[] = [];
  specialSessionTypelistIds: any;
  today: string = new Date().toISOString().split('T')[0];
  authFrequencyList: any[] = [
    { name: "Once", value: "ONCE" },
    { name: "Weekly", value: "WEEKLY" },
    { name: "Monthly", value: "MONTHLY" }

  ]
  limitTypeList: any[] = [
    { name: "None", value: null },
    { name: "Hard", value: "HARD" },
    { name: "Soft", value: "SOFT" }
  ]
  facilityList: any[] = [];
  formData: any = {
    id: null,
    authFrequency: null,
    endDate: this.today,
    minutePerUnit: null,
    spotId: null,
    startDate: this.today,
    totalUnit: null,
    limitType: null,
    visitLimit: null,
    payor: null,
    code: null,
    authNumber: null,
    billingFacility: null
  };
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('actualTargetSolverdetail', { static: false }) actualTarget: HTMLDivElement;
  @ViewChild('containerwrapper', { static: false }) containerwrapper: HTMLDivElement;
  @ViewChild("fullWeek") fullWeek!: any;
  @ViewChild('filterBoxElement') filterBoxElement: ElementRef;
  @ViewChild('assignProviderTable') assignProviderTable: Table;


  calendarVisible = true;
  calendarPlugins = [dayGridPlugin, timeGrigPlugin];
  calendarWeekends = true;
  viewType = 'Day';
  show = true;
  hide: any;
  skillDisable = true;
  viewTypeTab = 'basic';
  Tippyinstance: any
  id = null;
  reason = null;
  showTeam = false;
  displayBasic: boolean
  addSkillsList = [];
  patientData = null;
  pageStrings: LanguageInterface;
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  openMail = false;
  openMailSubs: Subscription;
  openTextMsgSubs: Subscription;
  langSubs: Subscription;
  openTextMsg = false;
  selectedEvent = null;
  showEvent = false;
  showEventData = null;
  savedPayload = null;
  Customtitle: any;
  CustoTodayBtn: Boolean = true;
  disableDates: any = [];
  showIcon = true;
  CalendarFirstWeekDate: any;
  planned_count: any = 0;
  esclated_count: any = 0;
  listpriority = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
  ];
  listSkills: any[] = [];
  patientSkillsFormsData: any[] = [];
  CalendarFirstWeekDateindex = 1;
  calendarRerenderSubs: Subscription
  AssignedProviderTableColumns = [
    { field: 'firstName', header: 'Name' },
    { field: 'email', header: 'Email' },
    { field: 'number', header: 'Phone No.' },
    { field: 'address', header: 'Address' },
    { field: 'providerActive', header: 'Status' },
    { field: 'action', header: 'Action' },
  ];

  AuthorizationPatientTableColumns = [
    { field: 'sessionType', header: 'Session Type' },
    { field: 'startDate', header: 'Start Date' },
    { field: 'endDate', header: 'End Date' },
    { field: 'insuranceType', header: 'Insurance Type' },
    { field: 'insuranceCompanyName', header: 'Insurance Company ' },
    { field: 'insurancePlanName', header: 'Insurance Plan' },
    { field: 'code', header: 'Code' },
    { field: 'authFrequency', header: 'Auth Frequency' },
    { field: 'minutePerUnit', header: 'Minutes Per Unit' },
    { field: 'authorisedUnit', header: 'Authorized Units' },
    { field: 'totalUnit', header: 'Total Units' },
    { field: 'takenUnit', header: 'Taken Units' },
    { field: 'remainingUnit', header: 'Remaining Units' },
    { field: 'limitType', header: 'Limit Type' },
    { field: 'visitLimit', header: 'Visit Limit' },
    { field: 'billingFacility', header: 'Facility' },
    { field: 'authNumber', header: 'Auth number' },
    { field: 'action', header: 'Action' },

    // { field: 'weekDays', header: 'Days' },
  ];

  scheduledInfoTableColumns = [
    { field: 'sessionType', header: 'Session Type' },
    { field: 'expectedSessionUnitPerWeek', header: 'Expected Session Unit Per Week' },
    { field: 'scheduledSessionUnitPerWeek', header: 'Scheduled Session Unit Per Week' },
    { field: 'plannedSessionUnitPerWeek', header: 'Planned Session Unit Per Week' },
    { field: 'remainingSessionUnitPerWeek', header: 'Remaining Session Unit Per Week' }
  ];

  patientauthrztnData: any;
  scheduledInfoData: any = [
    { solverType: 'Individual', ES_UPW: '70', SS_UPW: '68', PS_UPW: '60', RS_UPW: '2' },
    { solverType: 'BCBA', ES_UPW: '3', SS_UPW: '2', PS_UPW: '25', RS_UPW: '1' },
    { solverType: 'Group', ES_UPW: '40', SS_UPW: '38', PS_UPW: '9', RS_UPW: '2' },
    { solverType: 'OT', ES_UPW: '2', SS_UPW: '1', PS_UPW: '5', RS_UPW: '4' },
    { solverType: 'Speech', ES_UPW: '3', SS_UPW: '2', PS_UPW: '1', RS_UPW: '1' }
  ];

  lastChecked: any = null;
  statusChecked: any = null;
  genderChecked: any = null;
  solverType = [
    { label: 'Individual & Group', value: 'I&G', checked: false },
    { label: 'BCBA', value: 'BCBA', checked: false },
    { label: 'OT', value: 'OT', checked: false },
    { label: 'Speech', value: 'SPEECH', checked: false },
  ]
  sessionStatus = [
    { label: 'Planned', value: 'PLANNED', checked: false },
    { label: 'Escalated', value: 'ESCLATED', checked: false },
    { label: 'Is-Pinned Only', value: 'IsPin', checked: false },
  ]
  genderChoice = [
    { label: 'Male', value: 'MALE', checked: false },
    { label: 'Female', value: 'FEMALE', checked: false },
    // { label: 'Prefer not to say', value: 'PREFER',checked:false },
  ]
  filterCheckedData = {
    solverType: null,
    sessionStatus: null,
    genderChoice: null,
  }

  highlightFilter: Boolean = true;
  onApplyFilterBtn: Boolean = false;

  stateOptionsvalue: string = 'table';
  ScheduleInfoFilterCalendar: any = [new Date(), new Date()];
  providerDeleteConfirmBox: Boolean = false;
  providerDeleteConfirmBoxid: any;
  providerDeleteConfirmBoxindex: any;
  imgBaseURL = config.baseUrl;
  isMobileView: Boolean = false;
  isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
  isTabView = window.innerWidth > 600 && window.innerWidth <= 800;
  weekDateFilterData = new Date();
  filterCols = [
    { label: 'All', value: 'ALL' },
    { label: 'Individual & Group', value: 'I&G' },
    { label: 'BCBA', value: 'BCBA' },
    { label: 'OT', value: 'OT' },
    { label: 'Speech', value: 'SPEECH' },
    { label: 'Planned', value: 'PLANNED' },
    { label: 'Escalated', value: 'ESCLATED' },
    { label: 'Male', value: 'MALE' },
    { label: 'Female', value: 'FEMALE' },
  ];
  filterDataCols = 'ALL';
  EventsDataArr: any = [];
  AssignProviderfilterStatus = true;
  statusOptionsAll = [
    { label: 'All', value: null },
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];



  public PtntbarChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    // scaleShowVerticalLines: true,
    responsive: true,
    plugins: {
      labels: {
        render: function (args) {
          return '';
        }
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          // max: 100,
          min: 0,
          stepSize: 10
        },
        stacked: false
      }],
      xAxes: [{
        ticks: {
          max: 40,
          min: 0,
          stepSize: 1
        },
        stacked: false
      }]
    },

    legend: {
      display: true,
      position: 'right',
      align: 'center',
      labels: {
        padding: 20,
        usePointStyle: true,
        // pointStyle: 'circle',
      }

    },

  };
  public PtntbarChartLabels = [];
  // public barChartType = 'bar';
  public PtntbarChartPlugins = [];
  public PtntbarChartType: ChartType = 'horizontalBar';
  public PtntbarChartLegend = true;
  public PtntbarChartColors: Color[] = [
    { backgroundColor: '#6AB3E6' },
    { backgroundColor: '#305487' },
    { backgroundColor: '#538DCB' },
    { backgroundColor: '#C7E5FA' },
  ]
  public cPtnthartColors: any[] = [
    {
      backgroundColor: ["#AE7DF0", "#009698"]
    }];
  public PtntbarChartData = [
    {
      data: [0, 0, 0, 0], fill: false, borderColor: '#6AB3E6', color: '#6AB3E6',
      pointBackgroundColor: '#6AB3E6', label: 'Expected Session', lineTension: 0,
      pointStyle: 'rect',
    },
    {
      data: [0, 0, 0, 0], fill: false, borderColor: '#305487', color: '#305487',
      pointBackgroundColor: '#305487', label: 'Schedule Session', lineTension: 0,
      pointStyle: 'rect',
    },
    {
      data: [0, 0, 0, 0], fill: false, borderColor: '#538DCB', color: '#538DCB',
      pointBackgroundColor: '#538DCB', label: 'Planned Session', lineTension: 0,
      pointStyle: 'rect',
    },
    {
      data: [0, 0, 0, 0], fill: false, borderColor: '#C7E5FA', color: '#C7E5FA',
      pointBackgroundColor: '#C7E5FA', label: 'Remaining Session', lineTension: 0,
      pointStyle: 'rect',
    }
  ];
  IndividualGroupToolTipData: any;
  OTToolTipData: any;
  BCBAToolTipData: any;
  SpeechToolTipData: any;

  routerNavigateSelectors: boolean = false;
  clinicStartTime: any;
  clinicEndTime: any;
  clinicId: any

  getHolidays: any[] = [];
  upcomingHolidayData: any;
  dynamicDialogref: DynamicDialogRef;
  filterbox: boolean = false;
  ListViewTableTotal = 0;
  tablePaginatorFirst: number = 0;

  sessionDataColumns = [
    { field: 'indicator', header: '' },
    { field: 'shiftDate', header: 'Shift Date' },
    { field: 'startTime', header: 'Start Time' },
    { field: 'endTime', header: 'End Time' },
    // { field: 'patientName', header: 'Patient Name' },
    { field: 'providerName', header: 'Provider Name' },
    { field: 'sessionType', header: 'Session Type' },
  ];
  ListViewTableData: any = [];
  checkBoxItem = 0;
  checkBox_count: Array<any> = [];
  checkBoxArr: Array<any> = [];
  checkBoxArrId: Array<any> = [];
  ListViewToggle: Boolean = false;
  skillsSelectedValues = [];



  constructor(
    public spotService: SpotsService,
    public skillsSevice: SkillsService,
    public settingService: SettingService,
    public appService: AppService,
    public utils: Utils,
    public messageService: MessageService,
    public dialogService: DialogService,
    readonly schedulingService: SchedulingService,
    public appExService: AppExService,
    public route: ActivatedRoute,
    public patientService: PatientService,
    public router: Router,
    public patientTeamService: PatientTeamService,
    public providerService: ProviderService,
  ) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
    this.isMobileView = window.innerWidth <= 600;
  }
  holidayList: any
  ngOnInit() {
    this.getAllFacilityList();
    this.holidayList = JSON.parse(localStorage.getItem('allHolidayList'))
    this.PtntbarChartOptions.legend.position = this.isMobileView ? 'top' : 'right';
    this.routerNavigateSelectors = true;
    this.PtntbarChartPlugins = [pluginLabels];
    this.clinicId = JSON.parse(localStorage.getItem('assignedClinic'));
    this.getSettings(this.clinicId);
    this.appService.updateHeaderName({ name: 'Patients', subName: 'Patient Details', count: 0 });
    this.langSubs = this.appExService.getLanguageOptions.subscribe(data => {
      if (data.lang) {
        this.pageStrings = getLanguage(data.lang);
        this.getSkillList({ page: 1, limit: 1000 });
      }
    });
    this.route.queryParams.subscribe((params) => {
      this.id = parseInt(params.id);
      this.getAuthrztnPatient(this.id)

      // const date = moment(this.ScheduleInfoFilterCalendar[0]).format('YYYY-MM-DD');
      // this.getScheduledInfoPatient(this.id,date)
      this.getScheduledInfoPatientByDefault()
    });
    if (localStorage?.patientListView) {
      this.ListViewToggle = JSON.parse(localStorage.patientListView);
    }
    if (localStorage?.PatientDetailsViewTypeTab) {
      setTimeout(() => {
        this.viewTypeTab = localStorage.PatientDetailsViewTypeTab;
        this.changeViewTab(this.viewTypeTab);
      }, 1000);
    }
  }
  getSettings(cId) {
    this.settingService.getServiceAPI(cId).subscribe((response) => {
      let data = response.data;
      if (data) {
        this.clinicStartTime = data.clinicStartTime
        this.clinicEndTime = data.clinicEndTime
      }
      let EndTime = data.clinicEndTime;
      let EndTimeHour = Number(EndTime.slice(0, 2)) + 1;
      const DataEndTime = EndTime.replace(EndTime.slice(0, 2), EndTimeHour.toString());

      this.calendarOptionsWeekview.slotMinTime = this.clinicStartTime
      this.calendarOptionsWeekview.slotMaxTime = DataEndTime.toString();
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  onSolverTypeChange(event: any, changedCheckbox: any) {
    const checked = event.target.checked;
    if (checked) {
      if (event.target !== this.lastChecked) {
        if (this.lastChecked) {
          this.lastChecked.checked = false;
        }
        changedCheckbox.checked = true;
        this.filterCheckedData.solverType = changedCheckbox.value;
      } else {
        this.filterCheckedData.solverType = changedCheckbox.value;
      }
    }
    else {
      this.filterCheckedData.solverType = null;
    }
    this.lastChecked = event.target;
  }

  onStatusChange(event: any, changedCheckbox: any) {
    const checked = event.target.checked;
    if (checked) {
      if (event.target !== this.statusChecked) {
        if (this.statusChecked) {
          this.statusChecked.checked = false;
        }
        changedCheckbox.checked = true;
        this.filterCheckedData.sessionStatus = changedCheckbox.value;
      } else {
        this.filterCheckedData.sessionStatus = changedCheckbox.value;
      }
    }
    else {
      this.filterCheckedData.sessionStatus = null;
    }
    this.statusChecked = event.target;
  }

  onGenderChange(event: any, changedCheckbox: any) {
    const checked = event.target.checked;
    if (checked) {
      if (event.target !== this.genderChecked) {
        if (this.genderChecked) {
          this.genderChecked.checked = false;
        }
        changedCheckbox.checked = true;
        this.filterCheckedData.genderChoice = changedCheckbox.value;
      } else {
        this.filterCheckedData.genderChoice = changedCheckbox.value;
      }
    }
    else {
      this.filterCheckedData.genderChoice = null;
    }
    this.genderChecked = event.target;
  }
  onApplyFilter() {
    this.onApplyFilterBtn = true;
    if (this.filterCheckedData.solverType != null || this.filterCheckedData.sessionStatus != null || this.filterCheckedData.genderChoice != null) {
      this.updateDataFilter(this.filterCheckedData);
    }
  }
  onClear() {
    this.highlightFilter = true;
    this.solverType.forEach(Item => {
      Item.checked = false;
    });
    this.sessionStatus.forEach(Item => {
      Item.checked = false;
    })
    this.genderChoice.forEach(Item => {
      Item.checked = false;
    })
    this.filterCheckedData.solverType = null;
    this.filterCheckedData.sessionStatus = null;
    this.filterCheckedData.genderChoice = null;
    this.lastChecked = null;
    this.statusChecked = null;
    this.genderChecked = null;
    this.updateDataFilter(this.filterCheckedData)
  }

  select(evt) {
    let start = new Date(evt);
    start.setDate(start.getDate());
    this.ScheduleInfoFilterCalendar[0] = start;

    let end = new Date(start);
    end.setDate(start.getDate() + 6);
    this.ScheduleInfoFilterCalendar[1] = end;
    setTimeout(() => {
      this.filterScheduleInfo()
    }, 100);
  }

  filterScheduleInfo() {

    const date = moment(this.ScheduleInfoFilterCalendar[0]).format('YYYY-MM-DD');
    this.getScheduledInfoPatient(this.id, date)

  }
  isAuthToogle(item: any) {
    this.isExpired = item;
    this.getAuthrztnPatient(this.id)
  }
  getAuthrztnPatient(pid) {
    this.patientService.getAuthrztnPatientDataAPI(pid, this.isExpired).subscribe((response: any) => {
      if (response.data) {
        this.patientauthrztnData = response.data;
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    })
  }

  getScheduledInfoPatientByDefault() {
    const WeekStartDate = new Date(moment().startOf('week').format("YYYY-MM-DD"));
    const WeekEndDate = new Date(moment().endOf('week').format("YYYY-MM-DD"));

    const StartDate = moment(this.ScheduleInfoFilterCalendar[0].setFullYear(WeekStartDate.getFullYear(), WeekStartDate.getMonth(), WeekStartDate.getDate() + 1)).format("YYYY-MM-DD");
    const EndDate = moment(this.ScheduleInfoFilterCalendar[1].setFullYear(WeekEndDate.getFullYear(), WeekEndDate.getMonth(), WeekEndDate.getDate() + 1)).format("YYYY-MM-DD");

    this.getScheduledInfoPatient(this.id, StartDate)
  }

  getScheduledInfoPatient(pid, date) {
    this.appService.showCustomLoader(true);
    // const WeekStartDate = new Date(moment().startOf('week').format("YYYY-MM-DD"));
    // const WeekEndDate = new Date(moment().endOf('week').format("YYYY-MM-DD"));  

    // const StartDate = moment(this.ScheduleInfoFilterCalendar[0].setFullYear(WeekStartDate.getFullYear(), WeekStartDate.getMonth(), WeekStartDate.getDate() +1)).format("YYYY-MM-DD");
    // const EndDate = moment(this.ScheduleInfoFilterCalendar[1].setFullYear(WeekEndDate.getFullYear(), WeekEndDate.getMonth(), WeekEndDate.getDate() +1)).format("YYYY-MM-DD");

    this.patientService.getScheduledInfoPatientDataAPI(pid, date).subscribe((response: any) => {

      this.appService.showCustomLoader(false);
      this.PtntbarChartLabels = [];
      this.PtntbarChartData[0].data = [];
      this.PtntbarChartData[1].data = [];
      this.PtntbarChartData[2].data = [];
      this.PtntbarChartData[3].data = [];
      if (response.data) {
        this.scheduledInfoData = response.data;
        response.data.map((el) => {
          this.PtntbarChartLabels.push(`${el.solverType === 'INDIVIDUAL_AND_GROUP' ? this.isMobileView ? 'I&G' : 'Individual & Group' : el.solverType}`);
          this.PtntbarChartData[0].data.push(el.expectedSession === 0 ? 0 : el.expectedSession);
          this.PtntbarChartData[1].data.push(el.scheduledSession === 0 ? 0 : el.scheduledSession);
          this.PtntbarChartData[2].data.push(el.plannedSession === 0 ? 0 : el.plannedSession);
          this.PtntbarChartData[3].data.push(el.remainingSession === 0 ? 0 : el.remainingSession);
        });

        const IndividualGroupData = response.data.filter((obj) => { return obj.solverType === 'INDIVIDUAL_AND_GROUP' })[0];
        const BCBAData = response.data.filter((obj) => { return obj.solverType === 'BCBA' })[0];
        const OTData = response.data.filter((obj) => { return obj.solverType === 'OT' })[0];
        const SpeechData = response.data.filter((obj) => { return obj.solverType === 'SPEECH' })[0];

        this.IndividualGroupToolTipData = 'Expected Session Per Week: ' + IndividualGroupData?.expectedSession +
          '<br>Scheduled Session Per Week: ' + IndividualGroupData?.scheduledSession + '<br>Planned Session Per Week: ' +
          IndividualGroupData?.plannedSession + '<br>Remaining Session Per Week: ' + IndividualGroupData?.remainingSession;

        this.BCBAToolTipData = 'Expected Session Per Week: ' + BCBAData?.expectedSession +
          '<br>Scheduled Session Per Week: ' + BCBAData?.scheduledSession + '<br>Planned Session Per Week: ' +
          BCBAData?.plannedSession + '<br>Remaining Session Per Week: ' + BCBAData?.remainingSession;

        this.OTToolTipData = 'Expected Session Per Week: ' + OTData?.expectedSession +
          '<br>Scheduled Session Per Week: ' + OTData?.scheduledSession + '<br>Planned Session Per Week: ' +
          OTData?.plannedSession + '<br>Remaining Session Per Week: ' + OTData?.remainingSession;

        this.SpeechToolTipData = 'Expected Session Per Week: ' + SpeechData?.expectedSession +
          '<br>Scheduled Session Per Week: ' + SpeechData?.scheduledSession + '<br>Planned Session Per Week: ' +
          SpeechData?.plannedSession + '<br>Remaining Session Per Week: ' + SpeechData?.remainingSession;

      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      this.appService.showCustomLoader(false);

    })
  }

  calendarOptionsWeekview: CalendarOptions = {
    schedulerLicenseKey: environment.schedulerLicenseKey,
    initialView: 'timeGridWeek',
    firstDay: 1,
    eventBackgroundColor: '#041D5B',
    eventBorderColor: 'transparent',
    // locale: 'en-GB',
    allDaySlot: false,
    editable: true,
    eventDrop: this.popup.bind(this),
    eventResize: this.popup.bind(this),
    displayEventTime: true,
    eventTextColor: '#fff',
    slotDuration: '01:00',
    slotMinTime: "07:00:00",
    slotMaxTime: "20:30:00",
    eventMouseEnter: ({ el, event }) => {
      const elm = el;
      let title = '';
      if (!elm.className.includes('HolidayClass')) {
        if (elm.children[2] && (elm.children[2].innerHTML)) {
          title = elm.children[2].innerHTML;
        }
        if (title === '') {
          title = elm.children[0].children[0].children[0]["innerText"];
        }
        if (!this.isMobileView && !this.isTabView) {
          this.Tippyinstance = tippy(elm, { content: title + ' ' + event._def.title });
          this.Tippyinstance.show();
        }
      }
    },
    eventMouseLeave: () => {
      if (!this.isMobileView && !this.isTabView) {
        this.Tippyinstance.destroy();
      }
    },
    allDayText: 'All-day',
    slotLabelFormat: [
      {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        meridiem: 'lowercase',
        week: 'numeric'
      }
    ],
    eventTimeFormat: { // like '02:30:pm'
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      meridiem: 'lowercase',
    },
    customButtons: {
      next: {
        text: 'Next',
        click: this.nextWeek.bind(this),
      },
      prev: {
        text: 'Prev',
        click: this.prevWeek.bind(this),
      },
      today: {
        text: "Today",
        click: this.currentWeek.bind(this),
      },
    },
    events: [],
    eventClick: this.handleEventClick.bind(this), // 
  };

  dialogShow(event) {
    this.dynamicDialogref = this.dialogService.open(PatientDynamicDialog, {
      header: 'Confirmation',
      width: '70%',
      closable: false,
      styleClass: 'dynamicDialogStyle',
      contentStyle: { "max-height": "500px", "overflow": "auto" },
      baseZIndex: 10000
    });

    this.dynamicDialogref.onClose.subscribe((response) => {
      if (response) {
        this.dropEventUpdate(event, response)
      } else {
        this.dropEventUpdate(event, response)
      }
    });
  }

  popup(event) {
    this.Tippyinstance.destroy();
    if (event.event.extendedProps.isPinned) {
      // this.dialogShow(event)
      this.dropEventUpdate(event, true)
    } else {
      this.dropEventUpdate(event, false)
    }
  }

  dropEventUpdate(event, isPinnedres) {

    const UpdateNewEventPayload = {
      customRecurrenceForm: {
      }
    };
    const CurrnetEventId = event.event.extendedProps.eventId;
    const NewEventStart = event.event.start;
    const NewEventEnd = event.event.end;

    this.appService.showCustomLoader(true);
    this.schedulingService.getSchedulingById(CurrnetEventId).subscribe((response: any) => {
      const data = response.data;

      if (data) {
        if (data.recurrence != 'doNotRepeat') {
          UpdateNewEventPayload.customRecurrenceForm = null;
        } else {
          delete UpdateNewEventPayload.customRecurrenceForm;
        }

        UpdateNewEventPayload["startTime"] = moment(NewEventStart, ["h:mm A"]).format('kk:mm:ss');
        UpdateNewEventPayload["endTime"] = moment(NewEventEnd, ["h:mm A"]).format('kk:mm:ss');
        UpdateNewEventPayload["id"] = Number(data.id);
        UpdateNewEventPayload["isPinned"] = isPinnedres;
        UpdateNewEventPayload["placeOfServiceId"] = Number(data.placeOfServiceDTO.id);
        UpdateNewEventPayload["recurrence"] = "doNotRepeat";
        UpdateNewEventPayload["sessionSkillForm"] = data.sessionSkillForm;
        UpdateNewEventPayload["shiftDate"] = moment(NewEventEnd).format('YYYY-MM-DD');
        UpdateNewEventPayload["spotId"] = Number(data.spot.id);
        UpdateNewEventPayload["type"] = "current";

        UpdateNewEventPayload["patientId"] = data.hasOwnProperty("provider") ? Number(data.patient.id) : -1;
        UpdateNewEventPayload["providerId"] = data.hasOwnProperty("provider") ? Number(data.provider.id) : -1;

        this.schedulingService.updateScheduling(UpdateNewEventPayload).subscribe((response) => {

          this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });

        }, (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
          event.revert();
          this.appService.showCustomLoader(false);
        }, () => {

          this.schedulingService.getSchedulingById(CurrnetEventId).subscribe((response: any) => {
            const data = response.data;

            event.event._def.ui.backgroundColor = data.isEscalated === true ? '#802000' : data.hasOwnProperty("provider") ? '#1d8248' : '#ffb84d',
              event.event._def.ui.textColor = data.isEscalated === true ? '#ffffff' : '#000000',

              event.event.setExtendedProp('escalatedReason', data.escalatedReason ? data.escalatedReason : null);
            event.event.setExtendedProp('startTime', moment(data.startTime, ["h:mm A"]).format('kk:mm:ss'));
            event.event.setExtendedProp('endTime', moment(data.endTime, ["h:mm A"]).format('kk:mm:ss'));
            event.event.setExtendedProp('shiftDate', moment(data.shiftDate).format('YYYY-MM-DD'));
            event.event.setExtendedProp('isPinned', data.isPinned);

            this.appService.showCustomLoader(false);

            const payload = {
              fromDate: this.savedPayload.fromDate,
              toDate: this.savedPayload.toDate,
              patientId: data?.patient.id,
              sectionType: 'patient'
            }

            this.schedulingService.getCount(payload).subscribe((res) => {
              const counts = res.data;

              this.esclated_count = counts.escalatedCount;
              this.planned_count = counts.plannedCount;

            });


          });
        });
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      event.revert();
      this.appService.showCustomLoader(false);
    });
  }

  nextWeek(e) {
    this.filterbox = false;
    this.fullWeek.calendar.next();
    let d = moment(this.CalendarFirstWeekDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let Current = moment(this.fullWeek.calendar.currentData.dateProfile.currentRange.start, "YYYY-MM-DD").format("YYYY-MM-DD");
    if (d !== Current) {
      this.CustoTodayBtn = false;
    } else if (d === Current) {
      this.CustoTodayBtn = true;
    }
    this.id &&
      this.onProviderChange({ value: this.id });
  }
  prevWeek(e) {
    this.filterbox = false;
    this.fullWeek.calendar.prev();
    let d = moment(this.CalendarFirstWeekDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let Current = moment(this.fullWeek.calendar.currentData.dateProfile.currentRange.start, "YYYY-MM-DD").format("YYYY-MM-DD");
    if (d !== Current) {
      this.CustoTodayBtn = false;
    } else if (d === Current) {
      this.CustoTodayBtn = true;

    }
    this.id &&
      this.onProviderChange({ value: this.id });
  }
  currentWeek(e) {
    this.filterbox = false;
    this.fullWeek.calendar.today();
    let d = moment(this.CalendarFirstWeekDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let Current = moment(this.fullWeek.calendar.currentData.dateProfile.currentRange.start, "YYYY-MM-DD").format("YYYY-MM-DD");
    if (d === Current) {
      this.CustoTodayBtn = true;
    }
    this.id &&
      this.onProviderChange({ value: this.id });
  }
  WeekDateFilter(e) {
    this.filterbox = false;
    this.fullWeek.calendar.gotoDate(e);
    let d = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
    this.onProviderChange({ value: this.id });
    let Current = moment(this.fullWeek.calendar.currentData.currentDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    if (d !== Current) {
      this.CustoTodayBtn = false;
    } else if (d === Current) {
      this.CustoTodayBtn = true;

    }
  }
  getCalendarDateRange() {
    if (this.viewTypeTab == 'session') {
      this.Customtitle = this.fullWeek.calendar.currentData.viewTitle;
      if (this.CalendarFirstWeekDateindex === 1) {
        this.CalendarFirstWeekDateindex++;
        this.CalendarFirstWeekDate = this.fullWeek.calendar.currentData.dateProfile.currentRange.start;
      }
    }
    const curr = new Date(this.fullWeek ? this.fullWeek.calendar.currentData.currentDate : new Date());
    const first = curr.getDate() - (curr.getDay() - 1); // First day is the day of the month - the day of the week
    const last = first + 6; // last day is the first day + 6   
    const firstdays = new Date(curr.setDate(first));
    const lastdays = new Date(curr.setDate(last)).toUTCString();

    const firstday = this.fullWeek ? this.fullWeek.calendar.currentData.dateProfile.activeRange.start : firstdays;
    const lastday = this.fullWeek ? moment(this.fullWeek.calendar.currentData.dateProfile.activeRange.start, "YYYY-MM-DD").add(6, 'days').format("YYYY-MM-DD") : lastdays;

    this.calendarOptionsWeekview.visibleRange = {
      start: firstday,
      end: lastday
    };

    this.disableDates = this.enumerateDaysBetweenDates(moment(firstday).format('YYYY-MM-DD'), moment(lastday).format('YYYY-MM-DD'));

    return {
      fromDate: moment(firstday).format('YYYY-MM-DD'),
      toDate: moment(lastday).format('YYYY-MM-DD'),
    };
  }

  enumerateDaysBetweenDates(startDate, endDate) {
    let dates = [];

    let currDate = moment(startDate).startOf('day');
    let lastDate = moment(endDate).startOf('day');
    dates.push(currDate.clone().toDate());
    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
      dates.push(currDate.clone().toDate());
    }

    return dates;
  }


  onProviderChange(e) {
    const dates = this.getCalendarDateRange();
    const payload = { id: e.value, ...dates };
    this.savedPayload = payload
    this.showEvent = false;
    this.getPatient(payload);
  }

  handleDateClick(arg) {
    if (this.id) {
      arg.stopPropagation();
      this.onProviderChange({ value: this.id });
    }
  }

  openDeleScheduling() {
    // this.showEventSchdule = true;
    // this.showEvent = false;
    // this.showEvent = false;
    // this.viewTypeScudule = 'current';
  }

  filterAddSchedule() {
    this.router.navigate(['/scheduling/all-scheduling/add-session']);
  }

  changeViewTab(type) {
    this.viewTypeTab = type;
    localStorage.PatientDetailsViewTypeTab = this.viewTypeTab;
    setTimeout(() => {
      if (this.viewTypeTab == 'session') {
        this.Customtitle = this.fullWeek.calendar.currentData.viewTitle;
        if (this.CalendarFirstWeekDateindex === 1) {
          this.CalendarFirstWeekDateindex++;
          this.CalendarFirstWeekDate = this.fullWeek.calendar.currentData.dateProfile.currentRange.start;
        }
      }
    }, 500);
  }
  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.id = parseInt(params.id);
      // const date = moment(this.ScheduleInfoFilterCalendar[0]).format('YYYY-MM-DD');
      // this.getScheduledInfoPatient(this.id,date)
      this.getScheduledInfoPatientByDefault()
    });
    this.loadEvents();
    if (this.id) {
      setTimeout(() => {
        this.onProviderChange({ value: this.id })
      }, 10);
    }
    this.calendarRerenderSubs = this.appExService.CalendarToRerender$.subscribe(data => {
      this.reRenderCalendar();
    })
  }

  reRenderCalendar() {
    setTimeout(() => {
      let calendarCall = this.fullWeek.getApi();
      calendarCall.render();
    }, 10);
  }

  changeDate(): void {
  }

  filterBoxToggle() {
    this.filterbox = !this.filterbox;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    const clickedInside = this.filterBoxElement?.nativeElement.contains(target);
    if (!clickedInside) {
      if (this.filterbox) {
        this.filterbox = true;
      }
    }
  }
  clickedOutside(event) {
    if (event.target.closest('.overlay') && !event.target.closest('.profileSection')) {
      this.showEvent = false;
    }
  }
  scrollHandler() {

  }
  moveToBottom(scrollHeight) {
    this.containerwrapper["nativeElement"].scrollTop = scrollHeight;
  }
  loadEvents(): void {
  }
  handleEventClick(e) {

    // const props = JSON.parse(e.event.constraint);    

    let cDate = new Date();

    let pastTwoDayFromCurrent = cDate.setDate(cDate.getDate() - 2);
    let cNewDate = (new Date(pastTwoDayFromCurrent).toLocaleString('en-US'));

    let selectedSessionDate = moment(e.event.extendedProps.shiftDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let currentDatelocal = moment(new Date(cNewDate.split(",")[0]), "YYYY-MM-DD").format("YYYY-MM-DD");

    if (selectedSessionDate < currentDatelocal) {
      this.showIcon = false
    }
    else {
      this.showIcon = true;
    }

    this.showEventData = {
      ...e.event.extendedProps,
      ...e.event.extendedProps,
      backgroundColor: e.event.backgroundColor,
      unavailability: e.event.extendedProps.unavailability,
      providerSkills: e.event.extendedProps.providerSkills ? e.event.extendedProps.providerSkills.map(obj => obj.skill.skillName).join(", ") : ''
    };
    if (!e.event.extendedProps?.holiday) {// hide on holiday event
      this.showEvent = true;
      this.openEventDetailsPopUp(e);
    }
  }
  viewWeek(): void {
  }

  openEventDetailsPopUp(args) {
    this.selectedEvent = args.e;
    this.showEvent = true;
    let yPos = args["jsEvent"]["clientY"] - 35;
    setTimeout(() => {
      const ele = document.getElementById("actualTargetSolverdetail");
      let clttX = args["jsEvent"]["clientX"];
      const expectedWidth = clttX + 465;
      const expectedHeight = yPos + ele.clientHeight;
      if (expectedWidth > (window.innerWidth || document.documentElement.clientWidth)) {
        const normalizedCordidate = expectedWidth - window.innerWidth;
        clttX = clttX - normalizedCordidate - 100;
      } else {
        const normalizedCordidate = expectedWidth - window.innerWidth;
        clttX = clttX - normalizedCordidate - 100;
      }
      if (expectedHeight > (window.innerHeight || document.documentElement.clientHeight)) {
        const normalizedCordidate = expectedHeight - window.innerHeight;
        yPos = yPos - normalizedCordidate - 18;
      }
      document.getElementById("actualTargetSolverdetail").style.left = `${clttX}px`;
      document.getElementById("actualTargetSolverdetail").style.top = `${yPos}px`;
    }, 2);
  }

  openDeleCancel() {
    this.showTeam = true;
    this.showEvent = false;
    this.reason = null;
  }
  onSaveClick() {
    const payload = { "cancellingReason": this.reason, "isCancelled": !this.showEventData.isCancelled, "sessionId": this.showEventData.id };
    if (this.patientData.edit) {
      // if(payload.providerIds.length === 0){
      //   this.patientTeamService.deletetPatientTeam(this.selectedPatient.id).subscribe(() => {
      //     this.messageService.add({ severity: 'success', summary: 'Success', detail: `Provider to patient ${successMessage} successfully` });
      //     this.showTeam = false;
      //     this.getPatients({ page: this.page, limit: this.rows });
      //   }, (error) => {
      //     this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong' });
      //   });
      // }
      // else{
      //   this.patientTeamService.updatePatientTeam(payload).subscribe(() => {
      //     this.messageService.add({ severity: 'success', summary: 'Success', detail: `Provider to patient ${successMessage} successfully` });
      //     this.showTeam = false;
      //   this.getPatients({ page: this.page, limit: this.rows });
      // }, (error) => {
      //     this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      //   });
      // }
    }
    else {
      //   this.patientService.cancelpatient(payload).subscribe(() => {
      //     this.messageService.add({ severity: 'success', summary: 'Success', detail: `Session cancelled successfully` });
      //     this.showTeam = false;
      //     this.getPatient(this.savedPayload);
      // }, (error) => {
      //     this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      //   });
    }
  }

  ngOnDestroy() {
    localStorage.PatientDetailsViewTypeTab = 'basic';
    localStorage.patientListView = false;
    if (this.openTextMsgSubs) {
      this.openTextMsgSubs.unsubscribe();
    }
    if (this.openMail) {
      this.openMailSubs.unsubscribe();
    }
    if (this.calendarRerenderSubs) {
      this.calendarRerenderSubs.unsubscribe();
    }
  }
  filter() {
    // this.appService.updateFilterSidebarState({state: true, edit: true, 
    //     rowData: {formData: this.patientData.patientDTO}
    // });
    this.appService.patientProfileSidebarState({
      state: true, edit: true,
      rowData: { formData: this.patientData.patientDTO }
    });

  }

  editScheduling() {
    this.schedulingService.getSchedulingById(this.showEventData.id).subscribe((response: any) => {
      this.showEvent = false;
      this.appService.updateFilterSidebarState({ state: true, edit: true, ...response.data, isPatientModule: true });
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
    });
  }

  subscribePopups() {
    this.openTextMsgSubs = this.appService.textpopup.subscribe(res => {
      this.openTextMsg = res;
    });
    this.openMailSubs = this.appService.openMail.subscribe(res => {
      this.openMail = res;
    });
  }
  HolidayDateChecker(Holidaydate) {
    const StartDate = this.savedPayload.fromDate;
    const EndDate = this.savedPayload.toDate;

    if (Holidaydate >= StartDate && Holidaydate <= EndDate) {
      return true;
    } else {
      return false;
    }
  }
  getHoliday() {
    this.schedulingService.getHolidayScheduling(this.savedPayload).subscribe((res: any) => {
      if (res.data) {
        res.data.holidays.map(obj => {
          this.holidayList.push(obj);
        })
      }
    });
  }

  HolidayYearChange = 0;
  getPatient(payload) {
    if (new Date().getFullYear() < Number(moment(payload.fromDate).format('YYYY'))) {
      if (this.HolidayYearChange === 0) {
        this.getHoliday();
        this.HolidayYearChange++;
      }
    }
    this.planned_count = 0;
    this.esclated_count = 0;
    this.appService.showCustomLoader(true);
    let HolidayPayload = { ...payload };
    let holidayData = [];
    this.upcomingHolidayData = '';


    // this.providerService.providerUnavailabilityHoliday(HolidayPayload).subscribe((response: any) => {
    //   if(response.data){
    this.getHolidays = this.holidayList;
    if (this.getHolidays.length === 0) {
      this.upcomingHolidayData += 'No Holidays';
    }
    else {
      for (let index = 0; index < this.getHolidays.length; index++) {
        const element = this.getHolidays[index];
        if (index === 0) {
        }
        let isValidHoliday = this.HolidayDateChecker(element.holidayDate);
        if (isValidHoliday) {
          this.upcomingHolidayData += element.holidayName + ': ' + element.holidayDate + '<br>';
        }

        // this.upcomingHolidayData += element.holidayName +': '+ element.holidayDate+'<br>';

      }
    }
    //     holidayData = response.data.holidays;
    //   }
    // });
    this.patientService.getPatientrById(payload).subscribe((response: any) => {
      this.appService.showCustomLoader(false);

      if (response.data) {
        this.patientData = response.data;
        const skill = this.patientData.patientDTO.patientSkillDTOS;
        this.patientData.providerInfoList.sort(this.Sortarr);
        const name = `${this.patientData.patientDTO.firstName} ${this.patientData.patientDTO.lastName}`;
        const arr = [];
        this.patientData.sessionList.forEach(element => {
          element["shiftDate"] = element.shiftDate;
          const startDate = `${element.shiftDate}T${element.startTime}`;
          const endDate = `${element.shiftDate}T${element.endTime}`;
          const dates = { fromDate: moment(startDate).format('hh:mm a'), toDate: moment(endDate).format('hh:mm a') };
          let date = `${dates.fromDate} - ${dates.toDate}`;
          date = `${element.provider.firstName} ${element.provider.lastName} (${element.spotName})`;
          let badgeClass;
          if (element.providerChoice == 'M') {
            badgeClass = element.sessionType + 'Bedge Male';
          } else if (element.providerChoice == 'F') {
            badgeClass = element.sessionType + 'Bedge Female';
          } else {
            badgeClass = element.sessionType + 'Bedge';
          }

          if (element.hasOwnProperty("provider") && !element.isEscalated) {
            this.planned_count++;
          }
          if (element.isEscalated) {
            this.esclated_count++;
          }

          const propData = { ...this.patientData, ...element };

          arr.push({
            constraint: JSON.stringify(this.patientData),
            title: date, start: startDate,
            extendedProps: propData,
            end: endDate,
            eventId: element.id,
            backgroundColor: element.isEscalated ? '#802000' : element.hasOwnProperty("provider") ? '#1d8248' : '#ffb84d',
            textColor: element.isEscalated ? '#ffffff' : '#000000',
            className: [badgeClass, propData.recurrence === 'customRecurrence' ? 'customReccurrenceBadge' : ''],
          });

        });

        this.EventsDataArr = arr;

        if (this.onApplyFilterBtn) {
          this.updateDataFilter(this.filterCheckedData);
          this.assignedProviderTableFilter();
        } else {
          this.filterCheckedData.genderChoice = null;
          this.filterCheckedData.sessionStatus = null;
          this.filterCheckedData.solverType = null;
          this.updateDataFilter(this.filterCheckedData);
          this.assignedProviderTableFilter();
        }

        if (this.Tippyinstance) {
          this.Tippyinstance.destroy();
        }
        // this.calendarOptionsWeekview.events = arr;
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong.' });
      this.appService.showCustomLoader(false);
    });
  }

  listWeekViewData() {
    this.onClear();
    this.filterbox = false;
    localStorage.patientListView = this.ListViewToggle;
    if (!this.ListViewToggle) {
      this.reRenderCalendar();
    }
  }

  updateDataFilter(event) {
    if ((this.filterCheckedData.solverType === null && this.filterCheckedData.sessionStatus === null && this.filterCheckedData.genderChoice === null)) {
      this.highlightFilter = true;
      this.solverType.forEach(Item => {
        Item.checked = false;
      });
      this.sessionStatus.forEach(Item => {
        Item.checked = false;
      })
      this.genderChoice.forEach(Item => {
        Item.checked = false;
      })
    } else {
      this.highlightFilter = false;
    }

    let arrSt = [];
    let arrSS = [];
    let arr = [];
    switch (event.solverType) {
      case "BCBA":
        this.EventsDataArr.forEach(element => {
          if (element.className[0]?.includes('BCBABedge')) {
            arrSt.push(element);
          }
        });
        break;
      case "OT":
        this.EventsDataArr.forEach(element => {
          if (element.className[0]?.includes('OTBedge')) {
            arrSt.push(element);
          }
        });
        break;
      case "SPEECH":
        this.EventsDataArr.forEach(element => {
          if (element.className[0]?.includes('SPEECHBedge')) {
            arrSt.push(element);
          }
        });
        break;
      case "I&G":
        this.EventsDataArr.forEach(element => {
          if (element.className[0]?.includes('INDIVIDUALBedge') ||
            element.className[0]?.includes('GROUPBedge') ||
            element.className[0]?.includes('BEHAVIOURBedge') ||
            element.className[0]?.includes('NAPBedge') ||
            element.className[0]?.includes('NORMALBedge')) {
            arrSt.push(element);
          }
        });
        break;
      default:
        arrSt = [...this.EventsDataArr];

        break;
    }

    switch (event.sessionStatus) {
      case "PLANNED":
        arrSt.forEach(element => {
          if (element.backgroundColor === '#1d8248') {
            arrSS.push(element);
          }
        });
        break;
      case "ESCLATED":
        arrSt.forEach(element => {
          if (element.backgroundColor === '#802000') {
            arrSS.push(element);
          }
        });
        break;
      case "IsPin":
        arrSt.forEach(element => {
          if ((!element.className[0]?.includes('providerLoginMonthViewUnavltyAprv')) && element.extendedProps.isPinned) {

            arrSS.push(element);
          }
        })
        break;

      default:
        arrSS = [...arrSt];
        break;
    }

    switch (event.genderChoice) {
      case "MALE":
        arrSS.forEach(element => {
          if (element.className[0]?.includes('Male')) {
            arr.push(element);
          }
        });
        break;
      case "FEMALE":
        arrSS.forEach(element => {
          if (element.className[0]?.includes('Female')) {
            arr.push(element);
          }
        });
        break;

      case "PREFER":
        arrSS.forEach(element => {
          if (!element.className[0]?.includes('Female') && !element.className[0]?.includes('Male')) {
            arr.push(element);
          }
        });
        break;

      default:
        arr = [...arrSS];
        break;
    }

    this.holidayList.forEach((obj) => {
      obj['holiday'] = true;
      obj['reason'] = obj.holidayName;
      arr.push({
        // data: obj,
        constraint: JSON.stringify(obj),
        extendedProps: obj,
        title: obj.holidayName,
        start: `${obj.holidayDate}T${this.clinicStartTime}`,
        end: `${obj.holidayDate}T${this.clinicEndTime}`,
        backgroundColor: '#81F170',
        className: 'HolidayClass',
        textColor: '#000000',
      });
    });

    this.calendarOptionsWeekview.events = [...arr];
    this.ListViewTableData = arr.filter((obj) => {
      if (!obj.className.includes('HolidayClass')) {
        return obj
      }
    });
    this.sortListView({ sortField: 'shiftDate', sortOrder: 1 });
    this.filterbox = false;
    this.ListViewTableTotal = this.ListViewTableData.length;
  }

  sortListView(event) {
    this.ListViewTableData.sort((data1, data2) => {
      let value1 = data1?.extendedProps[event.sortField];
      let value2 = data2?.extendedProps[event.sortField];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.sortOrder * result);
    });
  }

  Sortarr(a, b) {// To Sort array of Object with name
    if (a.firstName < b.firstName) {
      return -1;
    }
    if (a.firstName > b.firstName) {
      return 1;
    }
    return 0;
  }

  updateTableData(data) {
    if (data.success) {
      this.getPatient(this.savedPayload);
    }
  }
  callAssignTeam(data) {
    this.appService.updateProviderTeamSidebarState({ state: true, data: { id: this.id, providerInfoList: data } });
  }
  callAddSession(sendData) {
    this.appService.updateAddSessionSidebarState({ state: true, isPatientModule: true, patientData: sendData, clinicStartTime: this.clinicStartTime });
  }
  confirmViewDeleteCall(rowData, i) {
    if(!rowData.primary){
      this.providerDeleteConfirmBoxid = rowData;
      this.providerDeleteConfirmBoxindex = i;
      this.providerDeleteConfirmBox = true;
    }else{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Primary supervisor deletion is not allowed.' });
    }
    
  }

  deletePatientAssignedTeam(data, index) {
    this.patientTeamService.deletetPatientTeam(data.id).subscribe((response) => {
      this.providerDeleteConfirmBox = false;
      this.patientData.providerInfoList.splice(index, 1);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: response.body.message });
      this.getPatient(this.savedPayload);
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.message : 'Something went wrong' });
    });
  }
  tConvert(time) {
    time = time.slice(0, 5);// Remove seconds...
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value      
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }
  onRemoveSkillAdd(i) {
    this.addSkillsList.splice(i, 1);
    this.addSkillsList = this.addSkillsList.map(obj => obj);
    this.skillDisable = false;
  }
  onAddSkillSaveClick() {
    this.assignValues()

  }
  onAddSkills(event, i) {

    this.skillsSelectedValues[i] = event.skillId;
    let first = [];
    if (this.addSkillsList.length > 0) {
      this.skillDisable = false;
      this.addSkillsList.forEach(obj => {
        if (obj.skillId) {
          first.push(1);
        }
      });
      if (this.addSkillsList.length <= first.length) {
        this.addSkillsList.push({
          "priority": 1,
          "skillId": null,
        });
      }
    }
  }
  onPatientAddSkillsForm(event, i) {

    const data = this.listSkills.filter((obj) => { return obj.value === event.value })[0].label;

    if (data == 'Behavioral Supervision' || data == 'Occupation Individual Therapy' || data == 'Speech Individual Therapy') {
      this.addSkillsList[i].priority = 0;
    }
    else {
      if (this.addSkillsList[i].priority === 0) {
        this.addSkillsList[i].priority = 1;
      }
    }

  }
  assignValues() {
    this.patientSkillsFormsData = [];
    this.addSkillsList.map(obj => obj).forEach(element => (element.skillId) ? this.patientSkillsFormsData.push(element) : '');
    const payload = {
      patientId: this.id,
      patientSkillForms: this.patientSkillsFormsData
    }
    this.patientService.addPatientSkills(payload).subscribe((response: any) => {
      if (response.body.success) {
        this.displayBasic = false;
        this.skillsSelectedValues = [];
        this.addSkillsList = [];
        this.messageService.add({ severity: 'success', summary: 'Success', detail: "Patient skill added successfully" });
        this.getPatient(this.savedPayload);
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
    });
  }
  showBasicDialog() {
    this.addSkillsList = [];
    let data: any[] = [];

    if (this.patientData.patientDTO.patientSkillDTOS.length > 0) {
      let first = [];
      data = this.patientData.patientDTO.patientSkillDTOS.map(obj => { return ({ priority: obj.priority, skillId: obj.skill.id }) });
      this.addSkillsList = data;
      this.addSkillsList.forEach(obj => {
        if (obj.skillId) {
          first.push(1);
        }
      });
      if (this.addSkillsList.length <= first.length) {
        this.addSkillsList.push({
          "priority": 1,
          "skillId": null,
        });
      }
    }
    else {
      this.addSkillsList.push({
        "priority": 1,
        "skillId": null,
      });
    }
    this.displayBasic = true;
    this.skillsSelectedValues = []
    this.addSkillsList.forEach((obj, i) => {
      if (obj.skillId !== null) {
        this.skillsSelectedValues[i] = obj.skillId;
      }
    })
  }
  getSkillList(data) {
    this.skillsSevice.getSkills(data).subscribe((response: any) => {
      if (response.data) {
        this.listSkills = response.data.map(obj => {
          return { label: obj.skillName, value: obj.id }
        });
        this.listSkills.unshift({ label: '--Select--', value: null })
      }
    });
  }
  getTooltipText(color: string) {
    if (color === "#1d8248") {
      return 'Planned';
    }
    else if (color === '#802000') {
      return 'Escalated';
    }
  }

  assignedProviderTableFilter() {
    setTimeout(() => {
      this.assignProviderTable?.filter(this.AssignProviderfilterStatus, 'providerActive', 'contains');
    }, 0);
  }
  get truncatedEmail() {
    const maxLength = 25;
    if (this.patientData.patientDTO.email.length > maxLength) {
      return this.patientData.patientDTO.email.substring(0, maxLength) + '...';
    }
    return this.patientData.patientDTO.email;
  }
  get truncatedPatientName() {
    const maxLength = 20;
    var patientFullName = this.patientData.patientDTO.firstName + ' ' + this.patientData.patientDTO.lastName;
    if (patientFullName.length > maxLength) {
      return patientFullName.substring(0, maxLength) + '...';
    }
    return patientFullName;
  }
  get truncatedStreet() {
    const maxLength = 20;
    if (this.patientData.patientDTO.patientLocation.street.length > maxLength) {
      return this.patientData.patientDTO.patientLocation.street.substring(0, maxLength) + '...';
    }
    return this.patientData.patientDTO.patientLocation.street;
  }
  itemCheck(data) {
    if (data == null) {
      this.skillDisable = true
    }
  }
  getDynamicYearRange(): string {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 1;
    const endYear = currentYear + 5;
    return `${startYear}:${endYear}`;
  }
  getFilteredProviderCount(): number {
    if (this.AssignProviderfilterStatus === true) {
      return this.patientData.providerInfoList.filter(provider => provider.providerActive === true).length;
    }
    // else if(this.AssignProviderfilterStatus === false) {
    //   return this.patientData.providerInfoList.filter(provider => provider.providerActive === false).length;
    // }
    else {
      return this.patientData.providerInfoList.filter(provider => provider.providerActive === false).length + this.patientData.providerInfoList.filter(provider => provider.providerActive === true).length;
    }
  }
  validateFormData(): boolean {
    if (!this.formData.spotId) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select session type.' });
      return false;
    }
    if (!this.formData.authFrequency) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select Authorization Frequency.' });
      return false;
    }
    if (this.formData.minutePerUnit == null) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Enter Minutes per Unit.' });
      return false;
    }
    // if (this.formData.minutePerUnit == 0) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Enter Minutes per Unit Value Greater Than Zero.' });
    //   return false;
    // }
    if (this.formData.totalUnit === null) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Enter Total Unit.' });
      return false;
    }
    if (this.formData.totalUnit == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Enter Total Unit Value Greater Than Zero.' });
      return false;
    }
    if (!this.formData.limitType) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Select limit type.' });
      return false;
    }
    // if (!this.formData.companyInsuranceId) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Select payor.' });
    //   return false;
    // }
    // if (!this.formData.code) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter code.' });
    //   return false;
    // }
    // if (!this.formData.authNumber) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter auth id.' });
    //   return false;
    // }
    // if (!this.formData.billingFacility) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Select facility name.' });
    //   return false;
    // }

    return true;
  }
  addData(item) {
    console.log(this.formData);
    
    if (this.validateFormData()) {
      this.formData.authFrequency = this.formData?.authFrequency?.value;
      this.formData.limitType = this.formData?.limitType?.value
      this.formData.billingFacility = this.formData?.billingFacility?.id
      if (item === 'add') {
        const payload = {
          ...this.formData,
          insuranceId:this.formData?.payor?.insuranceId,
          companyId:this.formData?.payor?.companyId
        }
        this.patientService.addAuthorisation(payload, this.id).subscribe((res: any) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Authorization added successfully.' });
          this.display = false;
          this.getAuthrztnPatient(this.id)
        });
      }
      else if (item === 'edit') {
        const payload = {
          "authFrequency": this.formData.authFrequency,
          "id": this.formData.id,
          "minutePerUnit": this.formData.minutePerUnit,
          "spotId": this.formData.spotId,
          "totalUnit": this.formData.totalUnit,
          "startDate": this.formData.startDate,
          "endDate": this.formData.endDate,
          "visitLimit": this.formData.visitLimit,
          "limitType": this.formData.limitType,
          "insuranceId":this.formData?.payor?.insuranceId,
          "companyId":this.formData?.payor?.companyId,
          "code": this.formData.code,
          "authNumber": this.formData.authNumber,
          "billingFacility": this.formData.billingFacility
        }
        this.patientService.editAuthorisation(payload, this.id).subscribe((res: any) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Authorization updated successfully.' });
          this.display = false;
          this.getAuthrztnPatient(this.id)
        });
      }

    }
  }
  editPage(data: any) {
    this.getPayersList();
    this.getSpots();
    this.formChanged = false;
    this.isEdit = true;
    this.formData = { ...data };
    this.formData.spotId = data.spotDTO.id;

    if (data.billingFacility) {
      this.formData.billingFacility = { "name": data.billingFacility, "id": data.billingFacilityId }
    }
    if (data.payerMap) {
      this.formData.payor = { name: data?.payerMap?.name, companyId: data?.payerMap?.companyId,insuranceId:data?.payerMap?.insuranceId }
    }
    if (this.formData.authFrequency == "WEEKLY") {
      this.formData.authFrequency = { name: "Weekly", value: "WEEKLY" }
    } if (this.formData.authFrequency == "ONCE") {
      this.formData.authFrequency = { name: "Once", value: "ONCE" }
    } if (this.formData.authFrequency == "MONTHLY") {
      this.formData.authFrequency = { name: "Monthly", value: "MONTHLY" }
    }

    if (this.formData.limitType == "SOFT") {
      this.formData.limitType = { name: "Soft", value: "SOFT" }
    } if (this.formData.limitType == "HARD") {
      this.formData.limitType = { name: "Hard", value: "HARD" }
    }
    if (this.formData.limitType == null) {
      this.formData.limitType = { name: "None", value: null }
    }

    this.display = true;
  }
  openAddPanel() {
    this.getPayersList()
    this.isEdit = false;
    this.display = true;
    this.formData = {
      id: null,
      authFrequency: null,
      endDate: this.today,
      minutePerUnit: null,
      spotId: null,
      startDate: this.today,
      totalUnit: null,
      code: null,
      limitType: { name: "Hard", value: "HARD" }
    };
    this.getSpots()
  }

  getSpots() {
    const data = { page: 1, limit: 1000 }
    this.spotService.getSpot(data).subscribe((response: any) => {
      if (response.data) {
        this.listSpots = response.data.map(obj => {
          return { label: obj.name, value: obj.id, isOutsideClinicHours: obj.isOutsideClinicHours }
        })
      }
    })
  }
  selectedAuthId: any
  openDelPopup(id: any) {
    this.confirmDelete = true;
    this.selectedAuthId = id;
  }
  deleteAuth() {
    this.patientService.deleteAuth(this.selectedAuthId).subscribe((res: any) => {
      this.confirmDelete = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Authorization deleted successfully.' });
      this.getAuthrztnPatient(this.id)
    })
  }
  onFormChange() {
    this.formChanged = true;
  }
  limitWords(text: string): string {
    const maxLength = 20;
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }
  getPayersList() {
    this.patientService.payerList(this.id).subscribe((res: any) => {
      this.payersList = res.data.map((item: any) => {
        return { name: item?.name, companyId: item?.companyId,insuranceId:item?.insuranceId }
      })
    })
  }

  getAllFacilityList() {
    this.patientService.getFacility().subscribe((res: any) => {
      this.facilityList = res.data;
    })
  }
  onSpotChange(id: any) {
    this.patientService.getCodeAndMinById(id).subscribe((res: any) => {
      this.formData.code = res.data.code;
      this.formData.minutePerUnit = res.data.minutes;
    })
  }
}
