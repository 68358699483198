import { ToastModule } from 'primeng/toast';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { RouteReuseStrategy} from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { AuthUtils } from './authenticate/autheticate.utils';
import { InitialAuthGuard } from './initial/initial.guard';
import { RoleGuardService } from "./initial/initial-role-guard";
import { AuthenticateGuard } from './authenticate/autheticate.guard';
import { Utils } from './utils';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TooltipModule } from 'primeng/tooltip';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal'
import { BnNgIdleService } from 'bn-ng-idle';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SvgIconDirectiveDirective } from './directives/svg-icon-directive.directive';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SchedulingModule } from '../app/scheduling/scheduling.module';
import { PatientModule } from '../app/patient/patient.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ProviderModule } from './provider/provider.module';

@NgModule({
    declarations: [AppComponent, SvgIconDirectiveDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        ModalModule.forRoot(),
        SharedModule,
        HttpClientModule,
        ToastModule,
        EditorModule,
        TabsModule.forRoot(),
        ProgressSpinnerModule,
        TooltipModule,
        ClickOutsideModule,
        FullCalendarModule,
        FormsModule,
        NgxMaterialTimepickerModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
        AppRoutingModule,
        SchedulingModule,
        PatientModule,
        MultiSelectModule,
        DropdownModule,
        CommonModule,
        DialogModule,
        ProviderModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        MessageService,
        AuthUtils,
        InitialAuthGuard,
        RoleGuardService,
        AuthenticateGuard,
        Title,
        Utils,
        DatePipe,
        BnNgIdleService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ],
    bootstrap: [AppComponent],
    exports: [IonicModule, SvgIconDirectiveDirective]
})

export class AppModule { }
