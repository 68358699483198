import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { config } from '../config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SchedulingService {
  constructor(public sharedService: SharedService,private http:HttpClient) { }

updateScheduling(formData){
  return this.sharedService.putData(config.addEditScheduling,formData)
}
addScheduling(formData){
  return this.sharedService.postData(config.addEditScheduling,formData)
}

getSchedulingById(id) {
  return this.sharedService.getData(`${config.addEditScheduling}/${id}`);
}
getCount(data){
  let url = `${config.getSchedulingSessionCount}?`;
  if(data.sectionType === 'patient'){
    url = url+`startDate=${data.fromDate}&endDate=${data.toDate}&patientId=${data.patientId}&sectionType=${data.sectionType}`
  }else if(data.sectionType === 'provider'){
    url = url+`startDate=${data.fromDate}&endDate=${data.toDate}&providerId=${data.providerId}&sectionType=${data.sectionType}`
  }else{ // for scheduling
    url = url+`startDate=${data.fromDate}&endDate=${data.toDate}&viewBy=${data.viewBy}&sectionType=${data.sectionType}`
  }
  return this.sharedService.getData(url);
}
deleteScheduling(id, type, status, cmnt) {
  if(id.length > 1){
    return this.sharedService.deleteData(`${config.addEditScheduling}/deleteSchedule?comment=${cmnt}&deleteStatus=${status}&sessionIds=${id}&type=${type}`);
    // return this.sharedService.deleteData(`${config.addEditScheduling}/-1/${type}?multiDelete=true&sessionIds=${id}&deleteStatus=${status}&comment=${cmnt}`);
  }else{
    // return this.sharedService.deleteData(`${config.addEditScheduling}/${id}/${type}?multiDelete=false&deleteStatus=${status}&comment=${cmnt}`);
    return this.sharedService.deleteData(`${config.addEditScheduling}/deleteSchedule?comment=${cmnt}&deleteStatus=${status}&id=${id}&type=${type}`);
  } 
}

getCheckProviderUnvlty(data) {
  return this.sharedService.getData(`${config.getCheckProviderUnailabelity}?shiftDate=${data.shiftDate}&startTime=${data.startTime}&endTime=${data.endTime}&providerId=${data.providerId}`);
}

getScheduling(data) {
  const remD = JSON.parse(localStorage.getItem('optplanner_user'));
  const userRole = remD.userType.type;
  if(userRole === 'provider'){
    if(data.hasOwnProperty('clinicIds')){
      let url =`${config.getProviderSchedule}?clinicIds=${data.clinicIds}&startDate=${data.fromDate}&endDate=${data.toDate}`;
      if(data.searchBy){
        url =`${url}&searchBy=${data.searchBy}`
      }
      if(data.isPinned){
        url =`${url}&isPinned=${data.isPinned}`
      }
      return this.sharedService.getData(url);
    }
  }
  else {
    let url =`${config.addEditScheduling}?startDate=${data.fromDate}&endDate=${data.toDate}&viewBy=${data.viewBy}`;
    if(data.searchBy){
      url =`${url}&searchParam=${data.searchBy}`
    }
    if(data.isPinned){
      url =`${url}&isPinned=${data.isPinned}`
    }
    return this.sharedService.getData(url);
  }
}

getUnavailabilityScheduling(data,pid,role) {
  if(role === 'provider'){
    let url =`${config.addEditunavailability}/all/${pid}?fromDate=${data.fromDate}&toDate=${data.toDate}`;
    if(data.searchBy){
      url =`${url}&searchBy=${data.searchBy}`
    }
    return this.sharedService.getData(url);
  }else{
    let url =`${config.getProviderUnavailabilitySchedule}?fromDate=${data.fromDate}&toDate=${data.toDate}`;
    if(data.searchBy){
      url =`${url}&searchBy=${data.searchBy}`
    }
    return this.sharedService.getData(url);
  }

}

getHolidayScheduling(data) {
  let url =`${config.getScheduleholidays}?fromDate=${data.fromDate}&toDate=${data.toDate}`;
  return this.sharedService.getData(url);
}
getUpcomingSessionDetails(data){
  console.log(data);
  
  let url =`${config.getScheduleUpcomingSessions}/${data.patientId}/upcomingSessionDetails?dateTime=${data.fromDate}&solverType=${data.solverType}&limit=1`;
  return this.sharedService.getData(url);
}
exportDocs(payload: any,filterForm:any) {
  
  const url = `${config.exportScheduleDOC}?endDate=${payload.endDate}&startDate=${payload.startDate}&viewBy=${payload.viewBy}`;
  return this.http.post<Blob>(url,filterForm, { responseType: 'blob' as 'json' });
}
}
