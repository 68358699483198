import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core'; 
import { CommonModule, TitleCasePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TagInputModule } from 'ngx-chips';
import { CheckboxModule } from 'primeng/checkbox';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import {TabMenuModule} from 'primeng/tabmenu';
import {MultiSelectModule} from 'primeng/multiselect';
import {LightboxModule} from 'primeng/lightbox';
import {CarouselModule} from 'primeng/carousel';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DragDropModule } from '@angular/cdk/drag-drop';
import {PickListModule} from 'primeng/picklist';
import { ContactRoutingModule } from './scheduling.routing.module';
import { DataService } from './data.service';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { SchedulingComponent } from './scheduling.component';
import { AddShiftsComponent } from './add-shift/add-shifts.component';
import { CustomComponent } from './custom-recurrence for scheduling/custom.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGrigPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimegridPlugin from '@fullcalendar/resource-timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { ToastModule } from 'primeng/toast';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SidebarModule } from 'primeng/sidebar';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import {DynamicDialog} from './dynamicdialog';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGrigPlugin,
  resourceTimelinePlugin,
  resourceTimegridPlugin,
  interactionPlugin

]);



@NgModule({
    declarations: [
       SchedulingComponent,
       AddShiftsComponent,
       CustomComponent,
       DynamicDialog,
      
    ],
    imports: [
      OverlayPanelModule,
      CommonModule,
      EditorModule,
      RouterModule,
      IonicModule,
      SharedModule,
      FormsModule,
      ReactiveFormsModule,
      TableModule,
      InputSwitchModule,
      TagInputModule,
      DropdownModule,
      CheckboxModule,
      ContactRoutingModule,
      ProgressSpinnerModule,
      DialogModule,
      CalendarModule,
      TabMenuModule,
      MultiSelectModule,
      LightboxModule,
      CarouselModule,
      DragDropModule,
      PickListModule,
      FullCalendarModule,
      AccordionModule,
      ButtonModule,
      ToastModule,
      NgxMaterialTimepickerModule,
      SidebarModule,
      DynamicDialogModule

    ],
    entryComponents: [
      DynamicDialog
    ],
    exports:      [ SchedulingComponent,AddShiftsComponent ],
    providers:    [ DataService, TitleCasePipe ]

  })
  export class SchedulingModule { }
  