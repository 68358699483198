import {Component} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';

@Component({
    template: `
                <form>
                <div class="col-12 pt-0 pl-0 pr-0">
                <div class="vaildation-area dropdown-top fColor" style="color: #656565;
                    text-align: left;padding: 15px 20px;">
                    If you would like to assign the same 
                    <!-- <span *ngIf="specialSessionTypeSelected && schedulingUser.patientId ">
                    patient
                    </span> -->
                    <!-- <span *ngIf="!specialSessionTypeSelected && schedulingUser.providerId "> -->
                    provider
                    <!-- </span> -->
                    , Kindly click on 'Yes' or The chosen provider may differ during the course of planning:      
                </div>
                </div>
            </form>

            <div class="PinnedBtnCenter" >
                <button class="dialog-yes-btn" (click)="updateIsPinned(true);">Yes</button>
                <button class="dialog-no-btn" (click)="updateIsPinned(false);">No</button>
            </div>
    `
})
export class PatientDynamicDialog {

            
    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

    ngOnInit() {

    }

    updateIsPinned(status) {
        this.ref.close(status);
    }
}