import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

// Routing Module (Ensure this is correctly set up)
import { ProviderRoutingModule } from './provider.routing.module';

// Components
import { ProviderComponent } from './provider.component';
import { ProviderProfileComponent } from './provider-profile/provider-profile.component';
import { ProviderDetailsComponent } from './provider-details/provider-details.component';
import { ProviderDynamicDialog } from './provider-details/provider-dynamic-dialog';

// Shared & Feature Modules
import { SharedModule } from '../shared/shared.module';
import { SchedulingModule } from '../scheduling/scheduling.module';
import { PatientModule } from '../patient/patient.module';

// PrimeNG & Other UI Modules
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { TabMenuModule } from 'primeng/tabmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import { LightboxModule } from 'primeng/lightbox';
import { CarouselModule } from 'primeng/carousel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PickListModule } from 'primeng/picklist';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { ToastModule } from 'primeng/toast';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SidebarModule } from 'primeng/sidebar';
import { TagInputModule } from 'ngx-chips';

// FullCalendar Setup
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

FullCalendarModule.registerPlugins([dayGridPlugin, timeGridPlugin]);

@NgModule({
  declarations: [
    ProviderComponent,
    ProviderProfileComponent,
    ProviderDetailsComponent,
    ProviderDynamicDialog
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    SharedModule,
    SchedulingModule,
    PatientModule,
    ProviderRoutingModule, // Corrected Routing Import

    // UI Modules
    SidebarModule,
    EditorModule,
    TableModule,
    InputSwitchModule,
    TagInputModule,
    DropdownModule,
    CheckboxModule,
    ProgressSpinnerModule,
    DialogModule,
    CalendarModule,
    TabMenuModule,
    MultiSelectModule,
    LightboxModule,
    CarouselModule,
    DragDropModule,
    PickListModule,
    FullCalendarModule,
    AccordionModule,
    ButtonModule,
    ToastModule,
    ImageCropperModule
  ]
})
export class ProviderModule { }
