import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { appSvgIcons } from './../app.svg.icons';

@Directive({
  selector: '[appSvgIconDirective]'
})
export class SvgIconDirectiveDirective implements OnInit {
  icons : any = appSvgIcons;
  @Input('appSvgIconDirective') icon!: string;


  constructor(
    public el: ElementRef,
    public renderer: Renderer2
  ) { }

  ngOnInit() {
    this.createSVG();
  }

  createSVG(): void {
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML',  this.icons[this.icon]);
  }
}
