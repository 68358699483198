import {Component} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';

@Component({
    template: `
            <form>
            <div class="col-12 pt-2 pb-2 pl-0 pr-0">
                <ng-container *ngIf="newResource === true" >
                    <div class="vaildation-area dropdown-top fColor" style="color: #656565;text-align: left;padding: 15px 20px;">
                        The patient not in the provider team. Do you still want to update the session?
                    </div>
                </ng-container>
                <ng-container *ngIf="customRecurrence === true" >
                    <div class="vaildation-area dropdown-top fColor dynamicDialogDropDown" style="color: #656565;text-align: left;padding: 15px 20px;">
                        <span style="border: 1px solid #b1b1b1;display: flex;border-radius: 4px;padding: 10px;margin-top: 10px;justify-content: space-between;align-items: center;">
                            <span style="margin-right: 10px;font-size: 14px;">
                                If you choose single, the session will be updated for only the chosen session. If you choose repeat, the session will be updated for the chosen session and all the upcoming repeated sessions.
                            </span>
                            <span>
                                <p-dropdown name="recurrence" styleClass="hederDrop" panelStyleClass=""
                                    [(ngModel)]="drpDwnRecurrence" appendTo="body" class="dropdown-custom"
                                    placeholder="Select" [options]="ReapetsTypeSet1" baseZIndex="12000">
                                </p-dropdown>
                            </span>
                        </span>
                    </div>
                </ng-container>
            </div>
        </form>

        <div class="PinnedBtnCenter" >
            <button class="dialog-yes-btn" (click)="updateIsPinned(true);">Update</button>
            <button class="dialog-no-btn" (click)="updateIsPinned(false);">Cancel</button>
        </div>
    `
})
export class DynamicDialog {

    viewBy = '';
    customRecurrence:boolean;
    newResource:boolean;
    isPinned:any;
    toggleIsPinned:any;
    ReapetsTypeSet1 = [
        {label: 'Repeat', value: 'customRecurrence'},
        {label: 'Single', value: 'doNotRepeat'}
    ];
    drpDwnRecurrence:any;

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

    ngOnInit() {        
        const ConfigData = this.config.data;
        console.log(ConfigData);
        
        this.viewBy = ConfigData.viewBy;
        this.customRecurrence = ConfigData.customRecurrence;
        this.newResource = ConfigData.newResource;
        this.isPinned = ConfigData.isPinned;
        this.toggleIsPinned = ConfigData.isPinned;
        // this.drpDwnRecurrence = ConfigData.recurrenceType;
        this.drpDwnRecurrence = 'doNotRepeat';
    }

    updateIsPinned(status) {
        // const data = {isPinnedstatus: isPinnedstatus,recorrenceStatus:recorrenceStatus };
        this.ref.close({status:status,recorrenceStatus:this.drpDwnRecurrence === 'customRecurrence' ? true : false});
        // console.log(status,this.drpDwnRecurrence);
        
    }
}