import { Injectable } from '@angular/core';
import { config } from '../config';
import { SharedService } from '../shared/shared.service';
@Injectable({
  providedIn: 'root'
})
export class SkillsService {

constructor(public sharedService: SharedService) { }


updateSkills(formData) {
  return this.sharedService.putData(config.addEditSkills, formData);
}
addSkill(formData) {
  return this.sharedService.postData(config.addEditSkills, formData);
}
getSkills(data) {
  let url = `${config.getSkillTable}`;
  url = url.replace(":page", data.page).replace(":limit", data.limit);
  if(data.searchParam){
    url = `${url}&searchParam=${encodeURIComponent(data.searchParam)}`;
  }
  if(data.clinicId){
    url = `${url}&clinicId=${data.clinicId}`;
  }
  return this.sharedService.getData(url);
}

deleteSkills(id) {
  return this.sharedService.deleteData(`${config.deleteskills}/${id}`);
}
getGroups(data) {
  const getGroupUrl = `${config.getGroups}`;
  return this.sharedService.postData(getGroupUrl,data);
}
getPlaceOfServiceAPI() {
  const getPlaceOfServiceUrl = `${config.getPlaceOfServiceURL}`;
  return this.sharedService.getData(getPlaceOfServiceUrl);
}
}
